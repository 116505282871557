.new-cred-main{
    display: flex;
    padding-top: 0px;
}
.nwprod-heading{
    width: 60%;
}
.newcredtopmargin{
    margin-top: 60px;
}
.nwinstant{
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.ploan-content1_1{
    width: 78%;
    margin: auto;
}
.nw-cred-box .instant-head span::before{
     background:none;
}
.nw-cred-box .instant-head-cont{
    margin-top: 10px;
    padding-bottom: 30px;
}
@media screen and (max-width:1000px) {
    .new-cred-main{
        flex-direction: column;
    }
    .nwprod-heading{
        width: 100%;
    }
    .nw-cred-box .instant-contnt{
        gap: 13px;
    }
    .newcredtopmargin{
        margin-top: 0px;
    }
}
@media screen and (max-width:500px) {
    .newpl{
        margin-top: 80px;
    }
    .newpl h2{
        font-size: 20px;
    }
    /* .instant-head-cont{
        margin-top: 0px;
    } */
    .instant-contnt{
        gap: 16px;
    }
    .green-instant-box .instant-contnt{
        gap: 18px;
    }
}