.notfound-cont{
    width: 80vw;
    margin: auto;
    align-items: center;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
    font-family:poppinssemibold;
}
.notfound-cont h1{
    font-size: 36px;
    line-height: 36px;
}
.notfound-img{
    height: 246px;
    width: 479px;
    margin-top: 50px;
}
.notfound-btn {
    background-color: #0FA82E;
    width: 356px;
    height: 56px;
    margin-top: 100px;
    border: none; 
    border-radius: 10px;
    font-size: 20px;
}
.notfound-btn a{
    color: #FFFFFF;
    text-decoration: none;
}
.notfound-cont p{
    font-size: 18px;
    color: #011149;
    margin-top: 25px;
}
@media screen and (max-width:600px){
    .notfound-cont{
        margin-top: 40px;
    }
    .notfound-cont h1{
        font-size:24px;
    }
    .notfound-img{
        height:166px;
        width:65vw;
        margin-top: 50px;
    }
    .notfound-btn{
        width: 65vw;
        margin-top: 50px;
    }
    .notfound-cont p{
        font-size: 16px;
        line-height:25px;
    }
}