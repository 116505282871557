.infobg-main-div {
    margin-top: 72px;
    margin-bottom: 40px;
}
.faqpage {
    background-image: linear-gradient(#4D3ECC1A, #4D3ECC05);
}

.infobg {
    background-color: #FAF9FF;
}

.social-media p {
    text-align: left;
    margin-bottom: 6px;
}

.infopage-heading {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.infopage-heading p {
    font-size: 44px;
    color: #011149;
    font-family: "poppinsmedium" !important;
    margin-top: 0px;
    margin-bottom: 0%;
}

.infopage-media-date {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    align-items: center;
}



.infopage-media-date .infographdate p {
    color: #011149;
    font-size: 22px;
    font-family: "poppinssemibold" !important;
    margin-top: 6px;
}

.infopage-media-date .infographmedia span {
    color: #525660;
    font-size: 18px;
    font-family: "poppinsmedium" !important;
}

.infograph-cards-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.infographcard {
    border: 2px solid #E4E2F8;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}

.infographcard:hover {
    border: 2px solid #e4e2f800;
    box-shadow: 0px 10px 20px #6D57DF33;
}

.infograph-cont-main {
    padding: 14px 20px 30px 20px;
}

.infograph-cont-main .info-cont span {
    color: #011149;
    font-size: 18px;
    font-family: 'poppinsmedium' !important;
}

.infograph-cont-main .info-cont p {
    font-size: 14px;
    color: #525660;
}

.infograph-cont-main .info-view-date {
    display: flex;
    justify-content: space-between;
    padding-top: 5%;
}

.infograph-cont-main .info-view-date span {
    color: #015EF7;
    font-size: 14px;
    font-family: 'poppinsmedium' !important;
}

.infograph-cont-main .info-view-date p {
    font-size: 14px;
    color: #8A91A1;
    margin: 0px;
}

.infosection {
    margin-top: 2%;
}

.info-readmore {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.info-readmore button {
    color: #fff;
    background-color: #4D3ECC;
    border-radius: 10px;
    font-family: "poppinsmedium" !important;
    padding: 14px 18px 15px 19px;
    border: none;
    cursor: pointer;
}
.infographic-all-main{
    width: 60%;
    margin: 0 auto;
}
.infographic-all-main img {
    border-radius: 25px;
    width: 100%;
}

.infographic-all-main p {
    color: #525660;
    width: 100%;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
}

.otherinfograph {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2%;
}

.social-icon {
    display: flex;
    gap: 10px;
}

.social-icon a img {
    width: 36px;
    height: 36px;
}


@media screen and (max-width:768px) {
    .infograph-cards-main {
        grid-template-columns: repeat(1, 1fr);
    }

    .infograph-heading {
        margin-top: 20px;
    }

    .infosection {
        margin-top: 8%;
    }

    .infopage-heading p {
        font-size: 20px;
    }

    .infographmedia .social-media {
        margin-top: 0px;
    }
    .infopage-media-date, .infographic-all-main{
        width: 100%;
    }

    .infopage-media-date .infographdate span {
        font-size: 14px;
    }

    .infopage-media-date .infographdate p {
        font-size: 18px;
    }

    .infopage-media-date .infographmedia span {
        font-size: 14px;
    }

    .infographic-all-main p {
        font-size: 12px;
    }

    .otherinfograph {
        font-size: 22px;
        text-align: center;
    }

    .infopagespace {
        margin-bottom: 10%;
    }

    .infographcard:hover {
        border: 2px solid #e4e2f800;
        box-shadow: 0px 10px 20px #6D57DF33;
    }

    .social-icon {
        gap: 5px;
    }

    .social-icon a img {
        width: 28px;
        height: 28px;
    }
}

@media screen and (max-width:380px) {
    .infograph-inner-search-box input[type=text] {
        width: 132px;
        padding-left: 10px;
    }
}