.howToPay,.payment-option,.NACH-section,.forecloser-login,.things-to-know {
    width: 92%;

    margin: 50px auto;
}
.repay-width{
    width: 76%;
    margin: 0 auto;
}
.repay-list li{
    margin: 10px 0px;
}
.eligib-top{
    padding: 20px 0px;
}
.heading-h1 {
    color: #2917a4;
}

.heading-h2 ,.heading-h3{
    color: #011149;
}

.heading-p {
    line-height: 180%;
    color: #263238;
    font-size: 16px;
}
.heading-h4{
    font-size: 26px;
    color: #2917A4;
    font-weight: 700;
    line-height: 30px;
}
.send-lnk {
    color: #1592e6;
    text-decoration: none;
}

.row-imp{
    display: flex;    
    justify-content:space-between;
}
.im-row{
    width: 90%;
    margin: auto;
}
.row-imp img{
    width :28%;
}

.ul-list {
    list-style: none;
    line-height: 24px;
}

.ul-list>li::before{
    content: "\2022";
    color: #eb5f32;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.ol-list li{
    margin-left: -25px;;
}

@media screen and (max-width:720px) {
    .heading-p {
        font-size: 14px;
        line-height: 140%;
    }

    .ploan-container {
        padding: 20px 0px; 
        padding-top: 60px;
    }
    .pl-cont-new .heading-h2{
        margin-top: 0px;
    }
    .row-imp{
        display: grid;
        grid-template-columns: auto;
        gap: 40px;
    }
    .row-imp img{
        width: 100%;
        
    }
    .repay-width{
        width: 90%;
    }
}