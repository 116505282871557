.investorrelation-bg {
  background: linear-gradient(#4d3ecc1a, #4d3ecc05);
  margin-top: 64px;
}

.corporate-hr {
  height: 6px;
  border-radius: 10px;
  margin: 0;
  margin-top: -14px;
  background-color: #4d3ecc;
  height: 5px;
  position: absolute;
  width: 58px;
  border: none;
}

.investor-main {
  width: 80%;
  margin: 0 auto;
}

.investor-heading {
  text-align: center;
  padding-top: 5%;
}

.investor-heading span:first-child {
  font-size: 36px;
  color: #011149;
}

.investor-heading span:nth-last-child(1) {
  color: #4d3ecc;
  font-size: 36px;
  font-weight: 600;
  margin-left: 6px;
}

.investor-content p {
  color: #525660;
  font-size: 18px;
  text-align: center;
  font-family: "poppinsmedium";
  line-height: 140%;
}

.investor-box {
  border-bottom: 1px solid #d5d7dd;
  margin-top: 20px;
  cursor: pointer;
}

.investor-faq-section {
  margin-top: 5%;
}

.qes-investor {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 30px;
  align-items: center;
}

.qes-investor-up {
  color: #4d3ecc;
}

/* .qes-investor span{
    color:#4D3ECC;
} */
.ans-investor {
  margin-left: 4%;
}

.ans-investor p {
  font-size: 18px;
  font-family: "poppinsmedium";
  color: #011149;
  position: relative;
}

.ans-investor a {
  color: #1592e6;
  text-decoration: none;
}

.ans-investor p::after {
  content: "\2022";
  position: absolute;
  color: #011149;
  font-weight: 700;
  display: inline-block;
  left: -20px;
}

.ans-investor span {
  font-size: 18px;
  font-family: "poppinsmedium";
  color: #015ef7;
}

.qes-investor.arrows-up img {
  transform: rotate(180deg);
}

.investor-space {
  margin-top: 30px;
  margin-bottom: 30px;
}

.get-in-touch-investor {
  margin-top: 4%;
  margin-bottom: 4%;
}

.get-in-touch-investor span:first-child {
  font-size: 20px;
  font-family: "poppinssemibold";
  color: #011149;
}

.get-in-touch-investor a {
  font-size: 20px;
  font-family: "poppinsmedium";
  color: #015ef7;
  margin-left: 6px;
  text-decoration: none;
}

@media only screen and (max-width:550px) {
  .get-in-touch-investor a {
    font-size: 16px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 400px) {
  .investor-heading span:first-child {
    font-size: 27px;
  }

  .investor-heading span:nth-last-child(1) {
    font-size: 27px;
  }

  .investor-content p {
    font-size: 14px;
  }

  .qes-investor {
    font-size: 20px;
  }

  .ans-investor span {
    font-size: 14px;
  }

  .ans-investor p {
    font-size: 14px;
  }

  .ans-investor p::after {
    left: -12px;
  }

  .get-in-touch-investor span:first-child {
    font-size: 18px;
  }

  .get-in-touch-investor span:last-child {
    font-size: 18px;
  }

  .get-in-touch-investor span:last-child {
    margin-left: 0px;
  }
}

@media screen and (max-width: 380px) {
  .qes-investor {
    font-size: 18px;
  }
}

@media screen and (max-width: 360px) {
  .ans-investor span::before {
    content: "\a";
    white-space: pre;
  }

  .ans-investor p::after {
    left: -12px;
    top: 0px;
  }
}