.aip-offer-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aip-offer-head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    /* width: 160px; */
}

.aip-offer-body-input{
    margin-top: -23px;
}

.aip-offer-head hr{
    width: 140px;
    /* margin-right: 50px; */
    height: 1px;
    background-color: black;
}

.offer-below-text{
font-size: 11px;
color: #525660;
}

.aip-offer-head p{
    /* width: 143px; */
    font-size: 14px;
    font-family: 'poppinsmedium';
    margin: 0;
    margin-bottom: 10px;
}

.aip-offer-head span{
    font-size: 24px;
    font-weight: bold;
    /* display: inline-block; */
    /* width: 25px; */
}

.aip-offer-head input {
    width: 140px;
    border: none;
    outline: none;
    font-size: 24px;
    font-weight: bold;
}

.aip-offer-body {
    width: 100%;
    margin: 40px 0;
}

/* .aip-offer-body-input input{
    width: 100%;
} */

.aip-offer-body-input [type="range"] {
    width: 100%;
    -webkit-appearance: none;
    height:6px;
    border-radius: 6px;
    background: #f1f1f1;
    outline: none;
    padding: 0;
    margin: 0;
}

/* custom thumb */
.aip-offer-body-input [type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #7a00ff;
    cursor: pointer;
    border: #FFFFFF 4px solid;
    box-shadow: 0px 0px 6px #00000040;
    -webkit-transition: background .15s ease-in-out;
    transition: background .15s ease-in-out;
}

/* .aip-offer-body-input [type="range"]::-webkit-slider-thumb:hover {
    background: #f0932b;
} */

.aip-offer-body-input [type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #f0932b;
    border: #FFFFFF 4px solid;
    box-shadow: 0px 0px 6px #00000040;
    cursor: pointer;
    -webkit-transition: background .15s ease-in-out;
    transition: background .15s ease-in-out;
}

/* .aip-offer-body-input [type="range"]::-moz-range-thumb:hover {
    background: #f9ca24;
} */

/* .slidingBar::-webkit-slidingBar-thumb{
    -webkit-appearance: none;
    appearance: none;
    background: black;
    border: 2px solid white;
}

.aip-offer-body-input-slider::-moz-range-thumb{
    background: black;
} */

.aip-offer-body-p {
    display: flex;
    align-items: center;
    justify-content: space-between; 
}

.aip-offer-body-p p {
    font-size: 14px;
    font-weight: bold;
    margin-top:6px;
}

.aip-offer-body-pol{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px solid black;
}

.aip-offer-body-pol input{
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 0px;
}

.aip-offer-footer{
    margin-top: 50px;
    margin-bottom: 50px;
}

/* .aip-offer-footer button{
    width: 356px;
    height: 56px;
    background-color: #0FA82E;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 500;
} */
.aip-offer-body-tbl,.aip-offer-body-tbl th, .aip-offer-body-tbl td{
    /* border: 0.5px solid black; */
    border-collapse: separate;
    border-spacing: 10px;
}

/* .aip-offer-body-tbl tr{
    padding-top: 20px;
    padding-bottom: 20px;
} */
.aip-offer-body-tbl{
    width: 100%;
    text-align: center;
    color: #011149;
    background-color: #F6F6F8;
    font-size: 14px;
}
.aip-offer-body-tbl tr {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-bottom: 10px;
}

.aip-offer-body-tbl th{
    font-size: 16px;
}

.aip-offer-body-tbl-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: -5%;
}

.aip-offer-body-tbl-bottom-p{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    color: #011149;
}

.aip-offer-body-tbl-bottom-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 11px;
    line-height: 140%;
    color: #525660;
    margin-top: 8px;
}
.aip-offer-body-tbl-bottom-div input[type="checkbox"]{
    accent-color: #0FA82E;
}

.aip-offer-btn {
    margin: 0% 0 8% 0;
    width: 100%;
    height: 56px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-family: 'poppinssemibold';
    cursor: pointer;
    color: #7D85A1;
    background-color: #D5D7DD;
}
.aip-offer-btn-activate {
    background-color: #0FA82E;
    color: #fff;
    outline: none;
}
.checkbox-field{
    border: 0px solid;
    background: #D5D7DD;
}

/* .aip-offer-body-tbl-bottom-div div{
    display: inline;
} */

.addedClass{
    opacity: 0.3;
}

@media only screen and (max-width: 400px) {
    .aip-offer-head p{
        font-size: 15px;
    }

    .aip-offer-body-p p{
        font-size: 12px;
    }

    .aip-offer-body-tbl{
        font-size: 12px;
    }

    .aip-offer-body-tbl th{
        font-size: 14px;
    }

    .aip-offer-body-tbl-bottom-p{
        font-size: 13px;
    }
}

@media only screen and (max-width: 318px) {
    .aip-offer-body-tbl th{
        font-size: 13px;
    }
}

/* @media only screen and (max-width: 600px) {
    .aip-offer-head hr{
        width: 60%;
        margin-right: 60%;
    }
} */