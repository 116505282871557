.desired-loan-amount-input1 {
    margin: 8% 0;
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px  inset;
}

.desired-loan-amount-input1 input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    font-family: 'poppinsmedium';
    padding-bottom: 0px;
}

.desired-loan-amount-input1 input::placeholder {
    font-size: 16px;
    font-family: 'poppinsregular';
}


.desired-loan-amount-input1-active {
    border-bottom: 1px solid #2917A4;

}

@media screen and (max-width:1366px) {
    .desired-loan-amount-input1 input::placeholder {
        font-size: 14px;
    }

    .desired-loan-amount-input1 input {
        font-size: 16px;
    }
}
