.container{
    width: 100% !important;
    margin: auto;
    margin-top: 30px;
}
h1, h2 {
    color: #2917a4;
    line-height: 140%;
  }
.row {
     display: flex;
}
.col-md-6{
  position: relative;
flex: 1;
  padding-right: 15px;
  padding-left: 15px
}
.content-right{
    flex:1;
}
.howtoapply {
	padding:20px 0;
    margin-top: 130px;
}
.howtoapply #stepsbox {
    position: relative;
}
.howtoapply #stepsbox::before {
    content: "";
    background: #F6F6F8;
    width: 8px;
    height: 105%;
    position: absolute;
    left: 8px;
    border-radius: 10px;
    top: -20px;
}
.howtoapply #stepsbox .step {
	margin: 24px 0;
}
.howtoapply #stepsbox .step .stepcheck, .howtoapply #stepsbox .step .stepcontent {
	display:inline-block;
	vertical-align:middle;
}
.howtoapply #stepsbox .step .stepcheck {
	/* background:#C4D3E0; */
	color:#fff;
	height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    line-height: 20px;
	margin-right: 10px;
	z-index: 1;
    position:relative;
}
.howtoapply #stepsbox .step .stepcontent {
	/* background: #fff; */
    /* border: 2px solid #C4D3E0; */
    padding: 0 0px;
    border-radius: 10px;
	width: 76%;
	margin-left: 0px;
	cursor:pointer;
    left: 0;
	transition: left 1s;
}
.howtoapply #stepsbox .step .stepcontent p {
	color: #C4D3E0;
	width: 100%;
	margin-top: 6px;
    line-height:140%;
}
.howtoapply #stepsbox .step .stepcontent p a {
	color: #C4D3E0;
}
.howtoapply #stepsbox .step .stepcontent span {
	/* background: #C4D3E0; */
    color: #6D57DF;
    padding: 0px 0px;
    font-size: 20px;
    line-height: 20px;
    margin-top: 0px;
    display: block;
    text-align: left;
    border-radius: 0px 0px 4px 4px;
    font-weight: 700;
}
.active-step .stepcheck, .active-step .stepcontent span{
	/* background:#21BF26 !important; */
    color: #4D3ECC!important;
    
}
.active-step .stepcontent {
	/* border: 2px solid #21BF26 !important; */
    position: relative;
/* left: 50px !important; */
}
.active-step .stepcontent p, .active-step .stepcontent p a {
	color: #011149 !important;
}
.howtoapply .slidestepbox {
	position:relative;
}
.howtoapply #step-slider {
	min-height: 470px;
}
.howtoapply #step-slider .sp {
	width:50%;
    /* border: 1px solid #E1E8EF; */
    /* padding: 10px 20px; */
    text-align: center;
    margin: 0 auto;
    /* background: #fff; */
    /* border-radius: 20px; */
	left: 0;
    right: 0;
	position:absolute;
	min-height:410px;
    line-height: 22px;
}
.howtoapply #step-slider .sp img {
	width: 80%;
    margin: 0px 0;
}
.stepslider{
    width: 80%;
    margin: 50px auto;
    margin-top: 5%;
}
.stepslider h1 {
    font-size: 36px;
    color: #011149;
    font-weight: 100;
    position: relative;
    margin: 0;
}

.stepslider h1::after {
    content: '';
    background-color: #4d3ecc;
    height: 5px;
    position: absolute;
    bottom: -10px;
    right: 0;
    left: 0;
    border-radius: 10px;
    width: 20%;
}

.slidestepbox #step-previous, .slidestepbox #step-next {
    position: absolute;
    background: #fff;
    bottom: 10% !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 8px;
    font-size: 0;
    padding: 0 !important;
    margin-bottom: 0 !important;
    text-align: center;
    line-height: 49px;
    z-index: 9 !important;
    box-shadow: 0px 0px 4px 0px #adadad;
    border: 0 !important;
    cursor: pointer;
}
.slidestepbox #step-previous {
	left:6%;
}
.slidestepbox #step-next {
	right:6%;
}
.slidestepbox #step-previous::before, .slidestepbox #step-next::before {
    font-size: 12px;
    color: #2917a7;
    background: #fff;
    margin: 0 auto;
    content: "\276f";
    line-height: 100%;
}
.slidestepbox #step-previous {
	transform: rotate(-180deg) !important;
    -webkit-transform: rotate(-180deg) !important;
}
.disable{
    opacity: 0.5;
}
@media screen and (max-width:2048px) and (min-width:1920px) {
    .howtoapply #step-slider .sp img {
        width: 70%;
        min-height: 400px;
        max-height: 500px;
        margin: 0px 0;
    }
  }
  @media screen and (max-width:1900px) and (min-width:1713px) {
    .howtoapply #step-slider .sp img {
        width: 70%;
        min-height: 400px;
        max-height: 500px;
        margin: 0px 0;
    }
}
@media screen and (max-width:1220px){
    .howtoapply #stepsbox::before {
       
        left:1.5%;
    }
    .container{
        margin-left: 0%;
    }
    .content-right{
        flex: 1;
      }
    }

@media screen and (max-width:1000px){
    .content-right{
        display: none;
    }
    .col-md-6 {
        position: relative;
        flex: 1 1;
        /* padding-right: 15px; */
        padding-left: 0px;
    }
    .howtoapply{
        width:90%;
        margin: 0 auto;
        margin-top: 30px;
    }
}

@media screen and (max-width:820px){
.content-right{
    display: none;
}
.howtoapply #stepsbox::before {
    left:1.5%;
}
.active-step .stepcontent {
left: 0 !important;
}
.container{
    margin-left: 0%;
}
}

@media screen and (max-width:660px){

    .howtoapply #stepsbox::before {
        left: 2%;
    }
    .container{
        margin-left: 0%;
    }
     
    }

    @media screen and (max-width:550px){

        .howtoapply #stepsbox::before {
            left: 2%;
        }
        .container{
            margin-left: 0%;
        }
        .howtoapply #stepsbox .step .stepcontent p{
            font-size: 14px;
            width: 130%;
        }
        .stepslider h1::after{
            bottom: -10px;
        }
        }

        @media screen and (max-width:496px){
            .col-md-6 {
                position: relative;
                flex: 1 1;
                padding-right: 15px;
                padding-left: 0px;
            }
        }

@media screen and (max-width:423px){

    .howtoapply #stepsbox::before {
        left: 3%;
        height:100%;
        top: 0;
    }
    .container{
        margin-left: 0%;
    }
    .col-md-6 {
        position: relative;
        flex: 1 1;
    }
    .howtoapply #stepsbox .step .stepcontent {
        background: #fff;
        /* border: 2px solid #C4D3E0; */
        padding: 0px 0px;
        border-radius: 10px;
        width: 60%;
        margin-left: 0px;
        cursor: pointer;
        left: 0;
        transition: left 1s;
        font-size: 11px;
    }
}
@media screen and (max-width:375px){
    .col-md-6 {
        position: relative;
        flex: 1;
    }
   
}
@media screen and (max-width:340px){
    .howtoapply #stepsbox::before {
        left: 3%;
        height:83%;
    }
}
@media screen and (max-width:341px){
    .howtoapply #stepsbox .step .stepcontent{
        margin-left: 37px;
    }
    .stepcheck{
        top:36px;
        left: -2px;
    }
}
@media screen and (max-width:270px){
    .howtoapply #stepsbox .step .stepcontent {
        background: #fff;
        border: 2px solid #C4D3E0;
        padding: 0px 0px;
        border-radius: 10px;
        width:38%;
        margin-left: 0px;
        cursor: pointer;
        left: 0;
        transition: left 1s;
        font-size: 11px;
    }
    .container{
        margin-left: 0%;
    }
    .howtoapply #stepsbox .step .stepcontent span {
        background: #C4D3E0;
        color: #fff;
        /* padding: 0px 10px; */
        /* font-size: 12px; */
        /* line-height: 20px; */
        margin-top: 0px;
        display: block;
        /* text-align: center; */
        /* width: 60px; */
        border-radius: 0px 0px 4px 4px;
    }
    .howtoapply #stepsbox::before {
        left: 5%;
    }
}