.howtoapply {
    width: 92vw;
    margin: auto;
    margin-top: 80px !important;
}

.container {
    width: 100%;
    margin: auto;
    margin-top: 30px;
}

.stepslider h1 {
    font-size: 36px;
    color: #263238;
    font-weight: 100;
    position: relative;
}

.stepslider h1::after {
    content: '';
    background-color: #4d3ecc;
    height: 6px !important;
    position: absolute;
    bottom: -16px;
    right: 0;
    left: 0;
    border-radius: 10px;
    width: 100px !important;
}

h1,
h2 {
    color: #2917a4;
}

.row {
    display: flex;
}

.col-md-6 {
    position: relative;
    flex: 1;
    padding-right: 15px;
    padding-left: 0px
}

.content-right {
    flex: 1;
}

.howtoapply {
    padding: 20px 0;
    margin: 0 auto;
    margin-top: 130px;
}

.howtoapply #stepsbox {
    position: relative;
}

.howtoapply #stepsbox::before {
    content: "";
    background: #F6F6F8;
    width: 8px;
    height: 100%;
    position: absolute;
    left: 8px;
    border-radius: 10px;
    top: 0px;
}

.howtoapply #stepsbox .step {
    margin: 24px 0;
}

.howtoapply #stepsbox .step .stepcheck,
.howtoapply #stepsbox .step .stepcontent {
    display: inline-block;
    vertical-align: middle;
}

.howtoapply #stepsbox .step .stepcheck {
    /* background: #C4D3E0; */
    color: #fff;
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    line-height: 20px;
    margin-right: 10px;
    z-index: 1;
    position: relative;
}
.active-step .stepcheck{
    position: relative;
}
.active-step .stepcheck::after{
    content: '';
    background-color: #4d3ecc;
    height: 100px;
    position: absolute;
    bottom: -30px;
    right: 0;
    left: 42%;
    border-radius: 10px;
    width: 8px;

}

.howtoapply #stepsbox .step .stepcontent {
    /* background: #fff;
    border: 2px solid #C4D3E0; */
    /* padding: 0 20px; */
    border-radius: 10px;
    width: 84% !important;
    margin-left: 0px;
    cursor: pointer;
    left: 0;
    transition: left 1s;
}

.howtoapply #stepsbox .step .stepcontent p {
    color: #8A91A1;
    width: 100%;
    margin-top: 14px !important;
    font-size: 18px;
    font-weight: 700;
}

.howtoapply #stepsbox .step .stepcontent p a {
    color: #C4D3E0;
}

.howtoapply #stepsbox .step .stepcontent span {
    /* background: #C4D3E0; */
    color: #fff;
    padding: 0px 0px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 0px;
    display: block;
    font-weight: 600;
    border-radius: 0px 0px 4px 4px;
}

.active-step .stepcheck,
.active-step .stepcontent span {
    /* background: #21BF26 !important; */
}

.active-step .stepcontent {
    position: relative;
    /* left: 50px !important; */
}

.active-step .stepcontent p,
.active-step .stepcontent p a {
    color: #011149 !important;
}

.howtoapply #stepsbox .step .stepcontent p a {
    color: #4d3ecc !important;
    text-decoration: none;
}

.howtoapply .slidestepbox {
    position: relative;
}
/* .howtoapply .slidemobframe{
    position: absolute;
    left: 22%;
} */
.slidemobframe img{
    width: 288px;
    height: 488px;
}

.howtoapply #step-slider {
    min-height: 470px;
}

.howtoapply #step-slider .sp {
    width: 50%;
    border: none;
    padding: 10px 20px;
    text-align: center;
    margin: 0 auto;
    background: #fff0;
    border-radius: 20px;
    top: -20px;
    left: 0;
    right: 0;
    position: absolute;
    min-height: 410px;
    line-height: 22px;
}

.howtoapply #step-slider .sp img {
    width: 80%;
    margin: 0px 0;
}

#step-slider p {
    line-height: 20px;
    color: #455A64;
    font-size: 14px;
}

.slidestepbox #step-previous,
.slidestepbox #step-next {
    position: absolute;
    background: #fff;
    bottom: 10% !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 8px;
    font-size: 0;
    padding: 0 !important;
    margin-bottom: 0 !important;
    text-align: center;
    line-height: 49px;
    z-index: 9 !important;
    box-shadow: 0px 0px 4px 0px #adadad;
    border: 0 !important;
    cursor: pointer;
}

#step-previous {
    margin-left: -5%;
}

#step-next {
    margin-right: -5%;
}

.slidestepbox #step-previous::before,
.slidestepbox #step-next::before {
    font-size: 12px;
    color: #2917a7;
    background: #fff;
    margin: 0 auto;
    content: "\276f";
    line-height: 100%;
}

.slidestepbox #step-previous {
    transform: rotate(-180deg) !important;
    -webkit-transform: rotate(-180deg) !important;
}
@media screen and (max-width:2048px) and (min-width:1920px) {
    .howtoapply #step-slider .sp img {
        width: 70%;
        min-height: 400px;
        max-height: 500px;
        margin: 0px 0;
    }
  }
/* @media screen and (min-width:1900px) {
   
} */
@media screen and (max-width:1220px) {
    .howtoapply #stepsbox::before {

        left: 1.5%;
    }

    .container {
        margin-left: 0%;
    }

    .content-right {
        flex: 1;
    }
}

@media screen and (max-width:1000px) {
    .content-right {
        display: none;
    }

    .col-md-6 {
        position: relative;
        flex: 1 1;
        padding-right: 15px;
        padding-left: 61px;
    }

    .howtoapply {
        width: 90%;
        margin-top: 30px !important;
    }
}

@media screen and (max-width:820px) {
    .content-right {
        display: none;
    }
    .stepslider h1{
        font-size: 22px !important;
    }
    .active-step .stepcheck::after {
        height: 82px;
        left: 50%;
    }
    .howtoapply #stepsbox::before {
        left: 1.5%;
    }

    .active-step .stepcontent {
        left: 0 !important;
    }

    .container {
        margin-left: 0%;
    }
}

@media screen and (max-width:660px) {
    .howtoapply #stepsbox .step .stepcontent p{
        width: 118% !important;
    }
    .howtoapply #stepsbox::before {
        left: 2%;
    }

    .container {
        margin-left: 0%;
    }

}

@media screen and (max-width:550px) {

    .howtoapply #stepsbox::before {
        left: 2%;
    }

    .container {
        margin-left: 0%;
    }
}

@media screen and (max-width:496px) {
    .col-md-6 {
        position: relative;
        flex: 1 1;
        padding-right: 15px;
        padding-left: 0px;
    }
}

@media screen and (max-width:423px) {
    .stepslider h1{
        font-size: 22px !important;
    }

    .howtoapply #stepsbox::before {
        left: 3%;
    }

    .container {
        margin-left: 0%;
    }

    .col-md-6 {
        position: relative;
        flex: 1 1;
    }

    .howtoapply #stepsbox .step .stepcontent {
        background: #fff;
        /* border: 2px solid #C4D3E0; */
        padding: 0px 0px;
        border-radius: 10px;
        width: 60%;
        margin-left: 0px;
        cursor: pointer;
        left: 0;
        transition: left 1s;
        font-size: 11px;
    }
}

@media screen and (max-width:375px) {
    .col-md-6 {
        position: relative;
        flex: 1;
    }

}

@media screen and (max-width:340px) {
    .howtoapply #stepsbox::before {
        left: 3%;
    }
}

@media screen and (max-width:270px) {
    .howtoapply #stepsbox .step .stepcontent {
        background: #fff;
        border: 2px solid #C4D3E0;
        padding: 0px 20px;
        border-radius: 10px;
        width: 38%;
        margin-left: 0px;
        cursor: pointer;
        left: 0;
        transition: left 1s;
        font-size: 11px;
    }

    .container {
        margin-left: 0%;
    }

    .howtoapply #stepsbox .step .stepcontent span {
        /* background: #C4D3E0; */
        font-size: 20px;
        color: #6D57DF;
        /* padding: 0px 10px; */
        /* font-size: 12px; */
        /* line-height: 20px; */
        margin-top: 0px;
        display: block;
        /* text-align: center; */
        /* width: 60px; */
        border-radius: 0px 0px 4px 4px;
    }

    .howtoapply #stepsbox::before {
        left: 5%;
    }
}