.sry-popup-cont{
    width: 370px;
    padding:12px;
    border-radius: 35px;
    background-color:#fff; 
    opacity: 100%;
    margin: auto;
}
.sry-popup{
    color: #011149;
    font-size: 24px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0px;
    /* padding: 15px; */
}
.sry-popup-lottie{
    width: 60%;
    margin: auto;
    /* padding: 28px; */
    align-items: center;
}
.sry-popup-p {
    font-size: 16px;
    line-height:140%;
    color: #011149;
    text-align: center;
    padding: 29px;
    margin-top: -70px;
}
.sry-popup-h2{
    font-size:18px;
    color: #011149;
    text-align: center; 
    margin-top: -17px;
    line-height: 24px;
} 
.sry-btn{
    width:90%;
    padding: 10px;
    margin:auto;
    background-color: #0FA82E;
    border: none;
    border-radius:10px;
    font-size:18px;
    text-align:center; 
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

}
.linking{
    color: white;
    text-decoration: none;
}
.remove-block{
    display: none;
}
@media only screen and (max-width:400px){
    .sry-popup-cont{
        width: 330px;
        /* height: 440px; */
    }
}
@media only screen and (max-width:350px){
    .sry-popup-cont{
        width: 300px;
        /* height: 415px; */
    }
}
@media only screen and (max-width:310px){
    .sry-popup-cont{
        width:275px; 
    }
}
@media only screen and (max-width:290px){
    .sry-popup-cont{
        width:250px; 
    }
}