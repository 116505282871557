
.embed-top-link a, .embed-top-link span, .embed-top-link{
color:#015ef7 !important;
}
.simple-step-main{
    margin-top: 80px;
}
.simple-steps-heading h2{
    font-size: 30px;
    color: #000;
    text-align: center;
    font-family: 'poppinssemibold';
}
.simple-steps{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10%;
}
.top-cont-simple{
    display: flex;
    align-items: center;
    gap: 20px;
}
.top-cont-simple .simple-count {
    background-color: #9188E4;
    height: 48px;
    width: 48px;
    color: #fff;
    border-radius: 50%;
    font-size: 24px;
    font-family: 'poppinsmedium';
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.botm-cont-simpl p{
    font-size: 18px;
    color: #000;
    font-family: 'poppinsmedium';
    margin-top: 10px;
    margin-bottom: 0px;
}
.top-cont-simple span:last-child{
    font-size: 24px;
    color: #000;
    font-family: 'poppinsmedium';
}
.simple-cont-side{ 
    width: 440px;
    padding-left:30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.simple-image-show-left{
    text-align: end;
    padding: 30px 110px 30px 0px;
}
.simple-step-left{
    position: relative;
    width: 50%;
}

.simple-step-right{
    width: 40%;
}

.simple-steps.active .simple-cont-side{
    border-radius: 35px;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 30px;
    width: 440px;
    transition: all 0.3s ease;
}

.simple-steps.active .simple-step-left::after{
    content: '';
    position: absolute;
    background-color: #6D57DF;
    height: 100%;
    width: 4px;
    border-radius: 4px;
    top: 0px;
    right: -10px;
    transition: all 0.8s ease;
}
.simple-step-left::after{
    content: '';
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 4px;
    border-radius: 4px;
    top: 0px;
    right: -10px;
}
.simple-step-slider{
    margin-top: 40px;
}

.mobile-slider-main{
    display: none;
}

@media screen and (max-width:1000px) {
    .embedhome-banner{
        flex-direction: column-reverse;
    }
    .simple-step-main{
        display: none;
    }
    .mobile-slider-main{
        display: block;
        margin-top: 60px;
    }
    .sub-left img{
        width: 100%;
    }
    .mobile-slider-sub{
        display: flex;
        flex-direction: column;
        gap: 60px;
    }
    .offer-mobile-embed{
        display: flex;
        gap: 20px;
        position: relative;
        align-items: center;
    }
    .offer-mobile-embed::after{
        content: '';
        position: absolute;
        background-color: #B4ADFB;
        height: 70%;
        width: 4px;
        border-radius: 4px;
        bottom: -68px;
        left: 24px;
    }.offer-mobile-embed:last-child:after{
        height: 0px;
    }
    .offer-mobile-embed .sub-right{
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    }
    .offer-mobile-embed .sub-right span{
        font-size: 16px;
        color: #000;
        font-family: 'poppinsmedium';
    }
    .offer-mobile-embed .sub-right p{
        font-size: 12px;
        font-family: 'poppinsregular';
        margin: 0px;
        margin-top: 14px;
    }
    .simple-steps-heading h2{
        text-align: start;
        font-size: 22px;
    }
}