.partner-slider-main{
    width: 90%;
    margin: auto;
}
.partner-slider-card{    
    background-color: #FFFFFF;
    border: 1px solid #b4adfb;
    border-radius: 20px;
    margin: 20px 10px;
    padding: 15px 25px;
    /* height: 310px; */
}
.partner-slider-card p{
    display: flex;
    flex-direction: column;
}
.partner-slider-card p span:nth-child(1){
    color: #525660;
    font-size: 14px;
    line-height: 22px;
}
.partner-slider-card p span:nth-child(2){
    font-size: 16px;
    color: #011149;
    line-height: 22px;
}
@media (max-width:450px) {
    .partner-slider-main{
        width: 100%;
    }
    .partner-slider-card{
        margin: 10px 2px;
    }
}
