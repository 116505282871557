.otp-form-body {
  margin: 6%;
}
.login-right {
  width: 440px;
  background-color: #fff;
  box-shadow: 0px 0px 20px #11357026;
  border-radius: 25px;
}
.otp-head {
  font-size: 20px;
  font-family: 'poppinssemibold';
  color: #011149;
  text-align: center;
  margin-bottom: 6px;
}
.otp-head-p{
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  margin: 0;
}
.sp-otp-content{
  color: #6D57DF;
}
.enter-otp{
  display: flex;
  margin: 40px 6% ;
  gap: 6%;

}
.otp-boxes {
  min-width: 52px;
  height: 52px;
  border-radius: 10px;
  background-color: #F9F9F9;
  /* box-shadow: 0px 4px 2px rgba(64, 64, 64, 0.2); */
  -webkit-filter: drop-shadow(0px 4px 2px rgba(64, 64, 64, 0.2));
  /* border: none; */
  }
.otp-invalid{
  box-shadow: 0px 4px 2px rgba(168, 15, 15, 0.6);
}
.otp-boxes input{
  border: none;
  width: 90%;
  border-radius: 10px;
  height: 90%;
  font-size: 24px;
  text-align: center;
}
.otp-boxes input:focus{
  outline: none;
}
.resend-option{
  color: #6D57DF;
  font-size: 16px;
  text-decoration: none;
  font-family: 'poppinsmedium';
}
.otp-timer-slot{
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
}
.otp-timer-slot.err{
  color:#D0342C;
}
.limit-over.err-text{
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  margin: 20px 0;
}
.otp-btn{
  margin-top: 30px;
}
.otp-btn{
  width: 100%;
  height: 55px;
  border-radius: 10px;
  border: none;
  font-family: 'poppinssemibold';
  font-size: 18px;
  color: #7D85A1;
  cursor: pointer;
}
.activate-btn{
  background-color: #0FA82E;
  color: #fff;
  border: none;
  outline: none;
}
.inCase-of-Invalid{
  font-size: 16px;
  color: #D0342C;
  text-align: center;
}
@media screen and (max-width:500px) {
  .enter-otp{
    margin: 20px 0 50px;
    gap: 30px;
  }
}
@media screen and (max-width:380px) {
  .enter-otp{
    margin: 40px auto;
    gap:7%;
    width: 80%;
  }
  .otp-form-body{
    margin:6%;
  }
  .otp-boxes{
    height: 45px;
    min-width:45px;
  }
}