.contact-bann {
  width: 80%;
  margin: 0 auto;
  margin-top: 80px;
  padding-bottom: 80px;
  /* background-color: #f9fafe; */
}

.contact-homebanner {
  display: flex;
  margin: 40px 0px;
  padding-top: 25px;
}

.grievance-address{
  margin-top: 6px !important;
  margin-left: 0px !important;
  line-height: 140% !important;
  font-size: 16px !important;
}

.contact-homebanner .left-top {
  position: absolute;
  top: 0;
  font-family: 'poppinsmedium';
  color: #015ef7;
}

.contact-homebanner .left-top a,.contact-homebanner .left-top span {
  color: #015ef7;
}

.contact-right-content {
  /* width: 40%; */
  margin: auto;
}

.contact-right-content img {
  width: 100%;
  margin-top: 20px;
}



.contact-left-content {
  width: 60%;
  max-width: 650px;
  padding: 7% 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-top: 55px;
  /* justify-content: center; */
}

.contact-left-content h1 {
  font-size: 40px;
  color: #011149;
  position: relative;
  font-weight: 600;
  line-height: 50px;
  font-family: 'poppinssemibold';
}

.contact-left-content hr {
  width: 14%;
  height: 7px;
  background: #4d3ecc;
  border: none;
  margin-left: 0;
  margin-top: -20px;
  margin-bottom: 30px;
  border-radius: 25px;
}

.contact-home-words {
  font-size: 26px;
  color: #000;
  margin-bottom: 20px;
  line-height: 140%;
  margin-top: -20px;
  /* text-align: center; */
}

.contact-service a {
  font-size: 16px;
  color: #fff;
  background-color: #4d3ecc;
  border-radius: 10px;
  padding: 14px 24px;
  text-decoration: none;
}

.policymain-container-head h1 {
  font-size: 26px;
  font-family: 'poppinssemibold';
  margin-top: 0px;
}

.contact-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 26px;
}

.contact-card-people {
  padding: 28px;
  box-shadow: 0 8px 20px #1a39600d;
  border-radius: 25px;
  background: white;
}

.contact-bold {
  font-size: 18px;
  color: #4d3ecc;
  font-weight: 600;
  line-height: 140%;
  margin-top: 0px;
}
.contact-tel{
  text-decoration: none;
  color: #015ef7;
}

.contact-card-people hr {
  width: 50px;
  height: 4px;
  background: #4d3ecc;
  border: none;
  margin-left: 0;
  margin-top: -14px;
  margin-bottom: 14px;
  border-radius: 10px;
}

.contact-people-no {
  /* display: flex; */
  /* text-align: center; */
  margin-top: 0px;
  align-items: center;
}


.contact-people-no p:last-child {
  color: #015ef7;
  font-size: 16px;
  font-family: poppinsmedium !important;
  margin-top: 6px;
  margin-bottom: 0px;
  line-height: 140%;
}

.contact-time {
  font-size: 12px;
  color: #8a91a1;
  line-height: 140%;
}

.contact-people-no-1 {
  margin-top: 14px;
  align-items: center;
}

.contact-people-no-1 p:first-child {
  font-size: 16px;
  margin-bottom: 0;
  color: #000;
  margin-top: 0;

}

.contact-people-no-1 p:last-child {
  color: #015ef7;
  font-size: 16px;
  font-family: poppinsmedium !important;
  margin-left: 6px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.contact-address {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  margin-top: 50px;
}

.moneywide-address {
  font-size: 16px;
  color: #015ef7;
  font-family: poppinsmedium !important;
  margin-top: 4px;
}

.contact-hr {
  padding-top: 24px;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  align-items: center;
  /* margin-bottom: 80px; */
  border-radius: 25px;
  box-shadow: 0 8px 20px #1a39600d;
  background-color: #fff;
}

.contact-j {
  color: #011149;
  font-size: 36px;
  font-weight: 600;
  margin: 0;
}

.contact-s {
  font-size: 16px;
  color: #8a91a1;
  margin-top: 6px;
  margin-bottom: 0;
}

.contact-map {
  width: 100% !important;
  height: 100% !important;
  border: 0px;
  border-radius: 25px !important;
}

@media screen and (max-width: 960px) {
  .contact-homebanner .left-top {
    position: relative;
    top: 0;
  }

  .contact-homebanner {
    display: flex;
    flex-direction: column;
    margin: 10% 0;
    padding: 0 8%;
  }

  .contact-left-content {
    width: 100%;
    text-align: center;
    padding: 0;
    padding-top: 0px;
  }
  .contact-right-content img {
    width: 100%;
    margin-top: 30px;
}

  .contact-left-content hr {
    margin: auto;
    margin-top: -16px;
  }

  .contact-home-words {
    align-items: center;
  }
  .contact-home-words{
    margin-top: 0px;
  }
}

@media screen and (max-width: 750px) {
  .contact-left-content h1 {
    font-size: 32px;
  }

  .contact-card {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact-address {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 28px;
    grid-gap: 30px;
  }

  .contact-map {
    width: 100% !important;
    min-height: 250px !important;
  }

  .contact-hr {
    flex-direction: column;
    padding: 30px 20px;
    align-items: baseline;
    gap: 16px;
    margin-top: 30px;
  }
}

@media screen and (max-width:510px) {
  .contact-service a {
    display: none;
  }

  .contact-left-content h1 {
    font-size: 24px;
  }

  .contact-home-words {
    font-size: 22px;
  }

  .contact-bann {
    margin-top: 70px;
  }
}

@media only screen and (max-width:480px){
  .contact-bann{
    width: 90%;
    margin-top: 64px;
  }
  .contact-right-content img{
    margin-top: 0px;
  }
  .contact-right-content img{
    margin-top: 0px;
  }
  .contact-s{
    margin-top: 4px;
    margin-bottom: 20px;
  }
  .contact-bold{
    margin-top: 0px;
  }
  .moneywide-address{
    line-height: 140%;
    margin-top: 0px;
  }
  .contact-j {
    font-size: 24px;
    font-family: 'poppinssemibold';
}
.contact-hr{
  gap: 0px;
}
.contact-people-no p:last-child{
  font-size: 14px;
  line-height: 140%;
}
.grievance-address{
  font-size: 14px;
}
}

@media screen and (max-width:440px) {
  .moneywide-address {
    font-size: 12px;
  }

  .contact-people-no-1 p:first-child {
    font-size: 12px;
  }
  .contact-s {
    font-size: 12px;
  }
}