.career-section {
    background-image: url('../../Icons/career.webp');
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: top;
    background-color: #f9fafe;
    background-size: 100% 340px;
}

.remove_back {
    background-image: none !important;
}

.career-banner {
    position: relative;
    margin-top: 82px;
}

.car-box {
    width: 45% !important;
    display: flex !important;
    justify-content: end !important;
    align-items: center;
}

.career-top-content {
    margin-top: 40px;
    width: 80%;
    position: relative;
}

.career-heading {
    font-family: 'poppinssemibold';
    margin: 0;
    font-size: 80px;
    margin-top: 20px;
    line-height: 120px;
    color: #fff;
}

.career-top-content p {
    font-size: 18px;
    padding-bottom: 35px;
    line-height: 140%;
    color: #fff;
    margin: 0;
    margin-top: -14px;
}

.career-top-content .left-top {
    position: absolute;
    top: -50px;
    left: 0;
}

.career-top-content .left-top,
.career-top-content .left-top span,
.career-top-content .left-top a {
    color: #fff !important;
}


.slider-con {
    width: 80%;
    position: relative;
}

.change-dir {
    flex-direction: column;
}

.manage-Width {
    width: 80%;
    position: relative;
}

.join-us-content {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 18%;
}

.join-us-content h3 {
    margin: 0;
    font-family: 'poppinsregular';
}

.filter-box {
    position: relative;
}

.select-arr {
    position: absolute;
    right: 20px;
    top: 13px;
    z-index: 1;
}

.filter-box select {
    width: 320px;
    height: 56px;
    font-size: 16px;
    background: #FFFFFF;
    border: 2px solid #F6F6F8;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 10px;
    padding: 10px 20px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    color: #011149;
    z-index: 2;
    cursor: pointer;
}


.filter-box select option[value=""][disabled] {
    padding: 10px 20px;
    color: #8A91A1;
}

.career-slider {
    margin: auto;
    margin-top: 120px;
}

.career-slider-boxing {
    margin: auto 0;
    margin-top: 20px;
}

.career-slider-main button {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
    border-radius: 10px;
    margin: 0 -30px;
}

.rotate-Left {
    transform: rotate(-90deg);
}

.rotate-Right {
    transform: rotate(90deg);
}

.rotate-Left div,
.rotate-Right div {
    position: relative;
    left: 0px;
    top: -8px;
}


.career-slider-card {
    font-size: 18px;
    line-height: 27px;
    color: #525660;
    background: #FFFFFF;
    border: 2px solid #E4E2F8;
    border-radius: 25px;
    margin: 30px 10px;
    padding: 30px 30px;
    position: relative;
    overflow: hidden;
    padding-top: 45px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px; */
}

.career-slider-card:hover {
    box-shadow: 0px 16px 30px rgba(17, 53, 112, 0.16);
    cursor: pointer;
}

.top-left-des {
    position: absolute;
    top: 0;
    left: -20px;
}

.bottom-right-des {
    position: absolute;
    bottom: 0px;
    right: -34px;
}

.hr-right-des {
    position: absolute;
    bottom: -6px;
    right: 0px;
}

.hr-left-des {
    position: absolute;
    top: 0px;
    left: 0px;
}

.career-slider-card p:first-of-type {
    font-size: 20px;
    line-height: 140%;
    color: #4D3ECC;
    font-family: 'poppinssemibold' !important;
    text-align: center;
    margin: 0;
}

.career-slider-card p:first-of-type span {
    font-size: 20px;
    color: #4D3ECC;
    font-family: 'poppinssemibold' !important;
}

.career-slider-card p:nth-child(2) {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #525660;
    margin: 0;
    margin-top: -12px;
}

.career-slider-card p:nth-child(3) {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #011149;
    margin-bottom: 20px;
    margin-top: 14px;
}




.career-slider-card p:nth-child(4) {
    font-size: 20px;
    line-height: 30px;
    color: #015EF7;
    text-align: center;
    cursor: pointer;
    font-family: 'poppinsmedium';
}

.perks {
    margin-top: 170px;
}

.put-in-row {
    display: flex;
    justify-content: space-between;
}

.in-left {
    position: relative;
}

.in-left h3:last-of-type {
    font-size: 80px;
    color: #3427A3;
    margin: 0;
    margin-top: -20px;
    font-family: 'poppinssemibold';
}

.in-left h3:first-of-type {
    font-size: 44px;
    color: #011149;
    margin: 0;
}

.in-left p {
    font-size: 18px;
    line-height: 140%;
    color: #011149;
    margin: 0;
    margin-top: -14px;
}

.perks-left-ig {
    position: relative;
    left: -40%;
}

.in-right {
    position: relative;
    display: flex;
    align-items: flex-end;
    right: -46px;
    bottom: 30px;
}

.careers-reasons {
    position: absolute;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(109, 87, 223, 0.2);
    border-radius: 10px;
    height: 80px;
    width: 450px;
}

.careers-reasons img {
    width: 70px;
}

.careers-reasons p {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.careers-reasons p span:first-of-type,
.mob-reasons p span:first-of-type {
    font-size: 26px;
    line-height: 140%;
    font-family: 'poppinssemibold';
    color: #4D3ECC;

}

.careers-reasons p span:last-of-type,
.mob-reasons p span:last-of-type {
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.rs-1 {
    right: 260px;
    top: -60px;
}

.rs-2 {
    right: 110px;
    top: 80px;
}

.rs-3 {
    right: 135px;
    top: 220px;
}

.rs-4 {
    right: -10px;
    bottom: 0px;

}

.rs-5 {
    right: -180px;
    bottom: 56px;

}

.hr-boxing {
    background: #4D3ECC;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 25px;
    padding: 34px 160px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    margin: 80px 0;
    position: relative;
    overflow: hidden;
}

.hr-boxing .hr-cont {
    display: flex;
    flex-direction: column;
}

.hr-boxing .hr-cont span:first-of-type {
    font-family: 'poppinssemibold';
    font-size: 40px;
    line-height: 128%;
}

.hr-boxing .hr-cont span:last-of-type {
    color: #F6F6F8;
    font-size: 20px;
    line-height: 30px;
}

.hr-boxing .hr-intro {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 20px;
    line-height: 30px;
}

.mw-about {
    background: #FFFFFF;
    margin: 0 0;
    background-image: url('../../Icons/img.webp');
    background-position: top right;
    background-repeat: no-repeat;
    min-height: 338px;
    margin-bottom: 20px;
}

.mw-about .in-left {
    width: 44%;
}

.mw-about .mw-heading {
    font-size: 36px;
    line-height: 140%;
    color: #011149;
    margin-bottom: 10px;
    margin-top: 25px;
}

.mw-about p {
    font-size: 16px;
    line-height: 140%;
    color: #525660;
    margin-top: 10px;
}

.mob-reasons-display {
    display: none;
}

.mob-career-im {
    display: none;
}

.section-heading-career {
    font-size: 36px;
    text-align: center;

}

@media (max-width:1100px) {
    .career-top-content .left-top {
        position: relative;
    }
    .in-left h3:last-of-type {
        margin-top: -10px;
        margin-bottom: 10px;
    }

    .section-heading-caree {
        font-size: 22px;
    }

    .perks {
        margin-top: 100px;
    }

    .manage-no-case {
        margin-top: 60px !important;
    }

    .car-box {
        width: 100% !important;
    }

    .career-banner {
        margin-top: 62px;
    }

    .career-slider {
        margin-top: 190px;
    }

    .careers-reasons p span:first-of-type,
    .mob-reasons p span:first-of-type {
        font-size: 20px;
    }

    .careers-reasons p span:last-of-type,
    .mob-reasons p span:last-of-type {
        font-size: 12px;
    }

    .manage-Width {
        width: 86%;
    }

    .join-us-content {
        justify-content: space-between;
        gap: 0;
    }

    .careers-reasons {
        display: none;
    }

    .in-left {
        width: 100%;
    }

    .mob-reasons-display {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 25px;
        position: relative;
    }

    .mob-reasons-display>img {
        position: absolute;
        right: -35px;
        bottom: -40px;
    }

    .in-right {
        display: none;
    }

    .mob-reasons {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        gap: 20px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(109, 87, 223, 0.2);
        border-radius: 10px;
        height: 80px;
        z-index: 10;
    }

    .mob-reasons p {
        display: flex;
        flex-direction: column;
    }

    .perks-left-ig {
        position: absolute;
        left: -91px;
        top: 300px;
    }

    .hr-boxing {
        padding: 25px 30px;
        flex-direction: column;
        gap: 14px;
    }

    .hr-boxing .hr-intro {
        gap: 6px;
        font-size: 16px;
    }

    .mw-about .in-left {
        width: 100%;
    }

    .mw-about {
        background-image: none;
        margin-bottom: 50px !important;
    }

    .mw-cont {
        background: #FFFFFF;
        box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
        border-radius: 20px;
        padding: 11px 14px;
    }

    .hr-boxing .hr-cont span:first-of-type,
    .in-left h3:first-of-type {
        font-size: 30px;
    }

    .hr-boxing .hr-cont span:last-of-type,
    .in-left p,
    .career-top-content p {
        font-size: 14px;
    }

    .career-top-content p,
    .career-heading {
        text-align: center;
        margin: 0;
        line-height: 140%;
    }

    .career-top-content p {
        margin: 0 20px;
    }


    .in-left h3:last-of-type,
    .career-heading {
        font-size: 44px;
    }

    .career-slider-card {
        height: 230px;
        box-shadow: 0px 9.35px 28.05px rgba(17, 53, 112, 0.16);
    }

    .career-slider-card p:first-of-type,
    .mw-about .mw-heading {
        font-size: 22px;
    }

    .career-slider-card p:nth-child(2),
    .career-slider-card p:nth-child(3),
    .mw-about p {
        font-size: 12px;
        margin-bottom: 30px;
    }

    .career-slider-card p:nth-child(2) {
        margin-top: -6px;
    }

    .career-slider-card p:nth-child(4) {
        font-size: 18px;
    }

    .mw-about,
    .hr-boxing {
        margin: 20px 0;
        margin-bottom: 30px;
    }

    .hr-boxing {
        margin-top: 100px;
    }

    .mob-career-im {
        display: block;
    }

    .mob-career-im img {
        position: relative;
        top: 60px;
        width: 100%;
    }
}

@media (max-width:600px) {
    .career-top-content .left-top {
        position: absolute;
        top: -10px;
        left: 5%;
    }
    .career-section {
        background-image: url('../../Icons/mobile-aboutUs.webp');
        background-position: top;
        background-size: 100% 340px;
    }

    .join-us-content {
        flex-direction: column;
        gap: 30px;
    }

    .mob-reasons img {
        width: 52px;
    }

    .mob-reasons {
        height: 56px;
    }

    .manage-Width {
        width: 90%;
    }

    .career-slider-main button {
        position: absolute;
        top: 105%;
        margin: 0 20px;
    }

    .career-slider {
        margin: auto;
        margin-top: 90px;
    }

    .mob-reasons-display>img {
        right: 0;
    }

    .mw-cont {
        text-align: center;
        position: relative;
    }

    .career-top-content {
        margin-top: 14px;
        padding-top: 25px;
    }

    .mob-career-im img {
        top: 40px;
    }
}