.nominee {
    margin-top: 64px;
}

.customer-box {
    background: #FAFFFB;
    border: 1px solid #0FA82E;
    border-radius: 10px;
    padding: 10px 12px;
    margin-top: 25px;
}
.nominee-rmrl{
  margin-top: 15px;
  cursor: pointer;
}
.nominee-rmrl p{
    display: initial;
    font-size: 10px;
    line-height: 16px;
}
.nominee-read-more{
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: #4d3ecc;
    text-decoration: underline;
}
.customer-box p {
    margin: 0;
    font-size: 14px;
    line-height: 140%;
    color: #011149;
}
.manage-nomine-bottom{
    margin-bottom: 40px;
}