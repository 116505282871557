.right-side-partner-form .form-box .form-body {
  margin: 5% 7%;
}

.right-side-partner-form .form-box .form-body .tag-for-input {
  margin-top: 0px;
}

.right-side-partner-form .form-box .form-body .form-head {
  padding-top: 5%;
}

.partner-form-document p {
  margin-top: 20px;
  font-family: 'poppinsmedium';
  font-size: 14px;

}

.partner-form-table th {
  font-size: 14px;
}

.partner-form-table tr td {
  font-size: 12px;
  padding: 4px;
}

.partner-options-confirm {
  display: flex;
  justify-content: space-between;
}

.partner-options-confirm input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #8A91A1;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin: 0px;
}

.partner-options-confirm input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #0FA82E;
  /* border: 0.15em solid #fff; */
  outline: max(2px, 0.15em) solid #0FA82E;
  outline-offset: max(2px, 0.15em);
}

.partner-options-confirm input[type="radio"]:checked::before {
  transform: scale(1);
}

.partner-options-confirm label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.partner-options-confirm label:first-child {
  padding-left: 0px;
  padding-right: 0px;
}

.partner-res-question {
  margin: 10px 0;
  font-size: 16px;
  line-height: 140%;
  font-family: 'poppinsmedium';
  color: #011149;
  font-weight: bold;
  line-height: 140%;
  padding-top: 5px;
}

.partner-res-question span {
  font-size: 14px;
}

.partner-options-confirm label {
  font-size: 14px;

}

.partner-options-confirm:first-child {
  padding-top: 20px;
}

.partner-proof-row-p {
  font-size: 12px;
  color: #011149;
  font-weight: 400;
  margin: 3% 0;
}

.err-text-partner {
  color: #1476e6 !important;
  font-size: 10px;
  margin-top: 3px;
  margin-bottom: -10px;
}

/* partner upload css */

.upload-main {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.upload-right-input {
  border-bottom: 1px solid #D5D7DD;
  display: flex;
  margin-top: 20px;
}

.upload-right-input input {
  margin-left: 10px;
}


.upload-right-input input,
.upload-left-input input {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.upload-left-input input {
  /* width: 50px; */
  opacity: 0;
  z-index: 9;
  width: 125px;
}

.upload-left-input img {
  width: 24px;
  height: 24px;
}

.upload-left-input {
  display: flex;
  align-items: center;
  position: relative;
  /* width: 114px; */
}

.upload-left-input .upload-file-partner {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload-left-input .upload-file-partner p {
  color: #263238;
  font-size: 12px;
}

.doc-partner-pop {
  height: auto;
}

.partner-popup-check {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}

.partner-popup-check input[type="checkbox"] {
  accent-color: #0fa82e;
  cursor: pointer;
}

.ovdpopup-content .partner-popup-check:last-child {
  margin-bottom: 0px;
}

.partner-popup-check span {
  font-size: 14px;
  color: #011149;
}

.partner-doc-btn {
  text-align: center;
  padding-bottom: 30px;
}

.partner-doc-btn button {
  border: none;
  background-color: transparent;
  color: #015EF7;
  font-size: 16px;
  cursor: pointer;
}

.partner-doc .proof-row-p {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.proof-row.doc-case {
  margin: 3px 12px;
}

.upload-the-photo {
  display: none;
}