.thank-cont{
    /* width: 374px;
    padding:30px; */
    border-radius: 25px;
    background-color:#fff; 
    margin: auto;
    padding-bottom: 20px;
}
.thank-container{
    width: 374px;
    padding:30px;
    border-radius: 25px;
    background-color:#fff; 
    margin: auto;
    padding-bottom: 40px;
}
.thank-popup-lottie{
    width: 45%;
    margin: auto; 
    align-items: center;
    padding-top: 20px;
}
.thank-p{
    font-size: 24px;
    font-weight: bold;
    color:#011149;
    text-align: center;
    line-height: 36px;
}
.thank-p1{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color:#011149; 
    line-height: 22px;
    padding: 0px 20px;
    margin-top: -12px;
}
.thank-lotie{
    width: 180px;
    text-align: center;
    margin: 10px auto;
}
.thank-regards{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #4D3ECC;
    line-height: 140%;
    /* margin-bottom: -10px; */
}
@media screen and (max-width:1366px) {
    .thank-p{
        font-size: 22px;
        line-height: 30px;
    }
}
@media only screen and (max-width:500px){
    .thank-cont{
        width: 84%;
        padding:20px;
        padding-bottom: 40px;
    }
    .thank-container{
        width: 84%;
        padding:20px;
        padding-bottom: 40px;
    }
    .thank-p{
        font-size: 20px;
    }
    .thank-p1{
        font-size: 16px;
        padding: 0px;
    }
    .thank-lotie{
        margin: 20px auto;
    }
}