.featuresbenefits{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 40px;
}
.featurebenefit-main{
    display: flex;
    justify-content: center;
    position: relative;
    
}
.fandb-sides-new{
    background-color: #FFF;
    border-radius: 14px;
    box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
    width: 236px;
    height: 50px;
    position: absolute;
    display: flex;
    gap: 18px;
    padding: 14px 0px;
    padding-left: 32px;
    align-items: center;
    border-left: 4px solid #4D3ECC;
    }

.fandb-sides{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
    width: 271px;
    height: 72px;
    position: absolute;
    display: flex;
    gap: 18px;
    padding: 14px 0px;
    padding-left: 32px;
    align-items: center;
}
.inner-fb{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.featuresbenefits h2{
    color: #011149;
    font-size: 36px;
    font-weight:100;
    text-align: center;
    margin: 40px 0;
}
.cetnred::before{
    left: 44%;
    bottom: -10px;
}
.inner-fb p, .inner-fb span{
    margin: 0px;
    color: #000000;
    font-size: 16px;
    font-family: 'poppinsmedium';
}
.fb1{
    left: 5%;
    visibility: hidden;
}
.fb2{
    left: 0px;
    top: 40%;
    visibility: hidden;
}
.fb3{
    left: 5%;
    bottom: 0px;
    visibility: hidden;
}
.fb4{
    right: 12%;
    visibility: hidden;
}
.fb5{
    right: 0;
    top: 40%;
    visibility: hidden;
}
.fb6{
    right: 2%;
    bottom: 0px;
    visibility: hidden;
}
@keyframes zoom {
	0%{
		transform: scale(0);
	}
	100%{
		transform: scale(1);
	}
}
.fb-inr-img{
    width: 36px;
    height: 36px;
}
@media screen and (min-width:1900px) {
    .fb1{
        left: 12%;
    }
    .fb2{
        left: 8%;
    }
    .fb3{
        left: 12%;
    }
    .fb4{
        right: 16%;
    }
    .fb5{
        right: 8%;
    }
    .fb6 {
        right: 10%;
    }
}
@media screen and (max-width:1440px) {
    .fb1{
        left: 0%;
    }
    .fb2{
        left: -4%;
    }
    .fb3{
        left: 0%;
    }
    .fb4{
        right: 8%;
    }
    .fb5{
        right: -5%;
    }
    .fb6 {
        right: -4%;
    }
}
@media screen and (max-width:1000px) {
    .featurebenefit-main .fbimg{
        width: 100%;
    }
    .featuresbenefits{
        width: 90%;
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .all-bubble{
        display: flex;
        justify-content: space-between;
    }
    .featurebenefit-main{
        flex-direction: column;
    }
    .fandb-sides{
        position: unset;
        width: 180px;
        margin: 20px 0px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .fb-inr-img{
        width: 24px;
        height: 24px;
    }
}
@media screen and (max-width:460px) {
    .cetnred::before{
        left: 34%;
    }
    .featurebenefit-main{
        margin-top: 14px;
    }
    .featuresbenefits{
        padding-top: 0;
        margin: 30px auto;
        margin-top: 40px;
    }
    .featuresbenefits h2{
        font-size: 22px;
        margin: 0;
    }
    .fandb-sides{
        width: 160px;
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 0px;
        padding-top: 0px;
        gap: 10px;
    }
    .inner-fb{
        gap: 4px;
    }
    .inner-fb p, .inner-fb span{
        font-size: 11px;
    }
}
@media  screen and (max-width:380px) {
    .fandb-sides{
        width: 150px;
    }
}
@media  screen and (max-width:368px) {
    .fandb-sides{
        width: 144px;
    }
}