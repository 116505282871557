.main-cal-of-exp{
    position: absolute;
    background-color: #fff;
    width: 94%;
    bottom:4px;
    box-shadow: 0 0 6px 0 #c5c5c5;
    border-radius: 20px;
    z-index: 98;
}
.calendar-content{
    margin: 20px;
}
.show-year{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;        
}
.calendar-btn{
    border: none;
    margin: 0 3px;
    color: #525660;
    cursor: pointer;
    border-radius:10px;
}
.calendar-content button:focus{
    outline:1px solid;
    border:1px solid;
}
.calendar-btn:hover{
    background-color: #D5D7DD;
}
.calendar-btn-disable img{
    opacity: 0.2;
}
.btn-fast{
    background-color:  #F6F6F8;
}
.btn-slow{
    background-color:#F6F6F8;
}
.calendar-head{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
    text-align: center;
    height: 30px;
}
.calendar-body{
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 5px 0;
}
.cal-box button{
    background:none;
    border:0px;
}
.box-content-cal{
    margin: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width:100%;
}
.box-content-cal.disable{
    opacity:0.3;
}

.box-content-cal:hover{
    background-color: #F6F6F8;
    font-weight: 600;
}