 .sachloan_1{
    list-style: none;
    margin: 20px 0;
}
.sachloan_1 .sachloan_2::before{
    content: "\2022";
    color:orangered;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}
.sachloantable{
    border: 1px solid  rgb(208, 196, 196);
    border-collapse: collapse;
    word-break:break-word;
}
.sachloantable_1{
    background-color:#1a89ff;
    border: 1px solid  rgb(229, 219, 219);
    color: white;
    padding: 20px;
}
.sachloantable_2{
    border: 1px solid  rgb(229, 219, 219);
    padding: 15px;
}
.sachloantable_3{
    padding:10px;
    border: 1px solid  rgb(229, 219, 219);
}
.sachloantable_4{
    background-color: rgb(241, 238, 238);
}
.policycontDesk{
    width: 80%;
    margin: auto;
    display: flex;
    background-color: #FAF9FF;
}
.policycont h2{
    font-size: 26px;
    line-height: 30px;
}
.policyholder{
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
}
.policycontmenu{
    margin-top: 0px;
    min-width: 250px;
    line-height: 140%;
}
.policyname {
    color: #0e88f5;
    text-decoration: none;
    padding: 20px;
    font-weight: bold;
    transition: 0.5s;
    background-color: white;
}
.policyname-unselected {
    color: black;
    text-decoration: none;
    padding: 20px;
    font-weight: bold;
    transition: 0.5s;
}
.policy-img{
    margin-top: 80px;
    width: 100%;
    position: relative;
    background: url("../../Icons/Moneywidepolicy.svg") no-repeat;
    height: 295px;
    background-color: #FAF9FF;
    background-size: cover;
}
.policy-img-mobile{
    width: 100%;
    position: relative;
    background: url("../../Icons/Moneywidepolicy.svg") no-repeat;
    height: 275px;
}
.policy-img img{
    width: 100%;
}
.policy-head{
    top: 16px;
    left: 33%;
    position: absolute;
    color: white;
    font-size: 48px;
    line-height: 140%;
}

 .policycontPhone{
    display: none;
}
.policycontent-show{
    background-color: #FFF;
    padding: 20px;
}
.policymenu-header-navi{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.policyholder-mob-select{
    display: none;
}
.policyname-unselected:hover{
    color: #0e88f5;
    cursor: pointer;
}
.policyname:hover{
    cursor: pointer;
}
@media only screen and (max-width:1050px){
    .policy-head {
        top: 0px;
        left: 19%;
    }
}
@media only screen and (max-width:850px){
    .policy-head {
        left: 23%;
        font-size: 36px;
        top: 50px;
    }
}
@media only screen and (max-width:800px){
    .policyholder{ 
        grid-template-columns: auto; 
    }
}
@media only screen and (max-width:750px){
    .policycontPhone{
        display: none;
    }
    .policycontDesk{
        display: flex;
     }
     .policymain-container-head h1 {
        font-size: 18px;
        margin-top: 20px;
    }
}
@media only screen and (max-width:500px){
    .policycontDesk{
        display: block;
     }
     .policyholder{
        display: none;
     }
     .policyholder-mob-select{
        display: block;
        position: relative;
     }
    .dropdown-display{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        list-style-type:none;
        background: #FFFFFF;
        box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
        border-radius: 10px;  
    }
    .dropdown-display-selected{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        list-style-type:none;
        background: #FFFFFF;
        box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
        border-radius: 10px 10px 0px 0px;  
    }
    .dropdown-menu{
        list-style: none;
        background-color: #FFFFFF;
        box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 20px 20px;
        margin-top: 0px;
        overflow: hidden;
        border-top: 1px solid #F6F6F8;
    }
    .dropdown-menu li{
        margin-left: -30px;
        border-bottom: 1px solid #F6F6F8;
        padding: 10px;
    }
    .policycontent-show{
        margin-top: 20px;
        border-radius: 10px;
    }
    .policycontDesk{
        width: 90%;
    }
    .toggle-button-arrow{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .policy-img{
        background: url("../../../public/assets/images/PolicyMobileImg.webp")no-repeat;
        width: 100%;
        background-size: cover;
        height: 270px;
        margin-top: 62px;
    }
    .policy-head{
        font-size: 32px;
        left: 5%;
        text-align: center;
    }
    .policy-head-site{
        left: 20%;
    }
}
@media only screen and (max-width:414px){
    .policycont h2{
        font-size: 22px;
    }
}