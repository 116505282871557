.faq-page-main{
    margin-top: 72px;
    margin-bottom: 40px;
}
.faqpage{
    background-image: linear-gradient(#4D3ECC1A, #4D3ECC05);
}
.infograph-heading{
    text-align:center;
    margin-top: 50px;
    margin-bottom: 30px;
}
.infograph-heading .graphhead1{
    font-size:40px;
    color:#011149;
}
.infograph-heading .graphhead2{
    color:#4D3ECC;
    font-size:80px;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 4%;
}
.left-top.infographics,
.left-top.infographics a,
.left-top.infographics span {
    color: #015EF7;
    font-family: 'poppinsmedium';
}

.infograph-heading p{
    color: #525660;
    font-size: 20px;
    font-family: 'poppinsmedium'!important;
}
.infograph-inner-search-box{
    position: relative;
    background-color: #fff;
    box-shadow: 0px 8px 20px #1A39600D;
    width: 60%;
    border-radius: 10px;
    margin: 0 auto;
    height: 56px;
    display: flex;
    align-items: center;
}
.infograph-search-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
    position: relative;
    
}
.infograph-inner-search-box .infoinput{
    width: 68%;
}
.infograph-inner-search-box input[type=text] {
    border: none;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    border-radius: 10px;
    width: 100%;
}
.search-keywords-div {
    position: absolute;
    top: 62px;
    width: 60%;
    background-color: #fff;
    border-radius: 15px;
    left: 20%;
    line-height: 140%;
    /* margin: 0 auto; */
}
.search-keywords{
    padding: 0 10px;
    cursor: pointer;
}
.infograph-inner-search-box .search-btn{
    background-color: #0FA82E;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    border: none;
    padding: 9px 25px;
    position: absolute;
    top: 12px;
    right: 4%;
    cursor: pointer;
}
.infograph-cards-main{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 50px;
}

.infographcard{
    min-height: 390px;
    border: 2px solid #E4E2F8;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}
.read-more-class{
    font-size: 14px !important;
    font-weight: 600;
    text-decoration: underline;
    color: #4D3ECC !important;
}
.info-img {
    width: 100%;
    overflow: hidden;
    height: 200px;
}
.info-img img {
    width: 100%;
    height: 200px;
    background: #c5d2d9 no-repeat 50%;
    -o-object-fit: cover;
    object-fit: cover;
}
.infographcard:hover{
    border: 2px solid #e4e2f800;
    box-shadow: 0px 10px 20px #6D57DF33;
}
.infograph-cont-main{
    padding: 14px 20px 30px 20px;
}
.infograph-cont-main .info-cont span{
    color: #011149;
    font-size: 18px;
    font-family: 'poppinsmedium'!important;
}
.infograph-cont-main .info-cont p{
    font-size: 14px;
    color: #525660;
}
.infograph-cont-main .info-view-date{
    display: flex;
    justify-content: space-between;
}
.infograph-cont-main .info-view-date span{
    color: #015EF7;
    font-size: 14px;
    font-family: 'poppinsmedium'!important;
}
.infograph-cont-main .info-view-date p{
    font-size: 14px;
    color: #8A91A1;
    margin: 0px;
}
.infosection{
    margin-top: 2%;
}
.info-readmore{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.info-readmore button{
    color: #fff;
    background-color: #4D3ECC;
    border-radius: 10px;
    font-family: "poppinsmedium"!important;
    padding: 14px 18px 15px 19px;
    border: none;
    cursor: pointer;
}


@media screen and (max-width:768px) {
.infograph-inner-search-box input[type=text]{

font-size: 14px;
}
.infograph-inner-search-box .search-btn{
font-size: 14px;
}
.infograph-cards-main{
grid-template-columns: repeat(1,1fr);
}
.infograph-heading{
margin-top: 20px;
}
.infograph-heading .graphhead1{
font-size: 24px;
}
.infograph-heading .graphhead2{
font-size: 50px;
margin-top: 3%;
}
.infograph-heading p{
font-size: 16px;
margin-top: 7%;
}
.infosection {
   margin-top: 8%;
}
.info-readmore{
margin-top: 4%;
margin-bottom: 4%;
}
@media screen and (max-width:512px) {
    .infograph-inner-search-box{
        width: 92%;
        margin: 0 auto;
        margin-left: 0px;
    }
}
}
@media screen and (max-width:550px){
    .search-keywords-div {
        position: absolute;
        top: 62px;
        width: 95%;
        background-color: #fff;
        border-radius: 15px;
        left: 4%;
        line-height: 140%;
        /* margin: 0 auto; */
    }
}
@media screen and (max-width:380px) {
.infograph-inner-search-box input[type=text]{
padding-left: 10px;
}	
.infograph-inner-search-box .search-btn{
    right: 8%;
}
}