.pp-app-head{
    width: 80%;
    margin: 0 auto;
    margin-top: 140px;
    margin-bottom: 20px;
}
.pp-head-app{
    margin-top: 30px !important;
}
.pp-app-head span{
    color: #2917a4;
    font-size: 22px;
    font-family: 'poppinssemibold';
}
.privacyapp-bg{
    width: 76%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 6%), 0 0 5px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    margin-bottom: 60px;
}
.pp-intro span{
    font-size: 18px;
    color: #2917a4;
    font-family: 'poppinssemibold';
}
.pp-intro p{
    color: #455a64;
    font-size: 14px;
    font-family: 'poppinsregular';
    line-height: 140%;
}
.pp-data span{
    font-size: 16px;
    font-family: 'poppinssemibold';
    color: #455a64;
    line-height: 140%;
}
.pp-contact{
    margin: 20px 0px;
}
.pp-contact span:first-child{
    color: #455a64;
    font-size: 14px;
    font-family: 'poppinssemibold';
}
.pp-contact span:last-child{
    color: #455a64;
    font-size: 14px;
    font-family: 'poppinsregular';
    line-height: 140%;
}
.pp-data p{
    font-size: 14px;
    font-family: 'poppinsregular';
    color: #455a64;
    line-height: 140%;
}
.pp-methods p{
    font-size: 14px;
    font-family: 'poppinsregular';
    color: #455a64;
    line-height: 140%;
}
.pp-order-list ol li{
    font-size: 14px;
    font-family: 'poppinsregular';
    color: #455a64;
    line-height: 140%;
    margin: 20px 0px;
}
.pp-how-to-use span{
    font-size: 16px;
    font-family: 'poppinssemibold';
    line-height: 140%;
    margin: 20px 0px;
    color: #455a64;
}
.pp-how-to-use p{
    font-size: 14px;
    font-family: 'poppinsregular';
    color: #455a64;
    line-height: 140%;
}
.pp-unorder-list ul li{
    font-size: 14px;
    font-family: 'poppinsregular';
    color: #455a64;
    line-height: 140%;
    margin: 20px 0px;
    list-style-type: none;
}
.pp-unorder-list ul li::before{
    content: "\2022";
    color: #eb5f32;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.pp-please-note p{
    font-family: 'poppinssemibold';
    font-size: 16px;
    color: #455a64;
    line-height: 140%;
}
.pp-data table{
    border-collapse: collapse;
    width: 100%;
    margin: 20px 0px;
}
.pp-data table th{
    background: #1a89ff!important;
    color: #fff;
    border-right: 1px solid #fff;
    padding: 20px;
}
.pp-data table td{
    padding: 20px;
}