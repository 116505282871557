.login-cont {
  background-image: url(https://www.moneywide.com/assets/images/moneywide/pl/downloadapp-bgmask.webp), linear-gradient(to right, #301bc0, #d73749);
  background-size: cover;
  margin-top: 81px;
  padding: 35px;
}

.login-main-cont {
  width: 80vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 350px;
}

.login-left {
  color: white;
}

.login-left-svg {
  display: grid;
  grid-template-columns: auto auto;
}

.login-left-svg>p {
  display: flex;
}

.breadcrump {
  color: #FFF;
  font-size: 12px;
  margin-bottom: 42px;
}

.breadcrump a {
  color: white;
  text-decoration: none;
}

.login-right {
  width: 100%;
  position: absolute;
  background-color: #fff;
  border-radius: 20px;
}

.div-pos {
  position: absolute;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 20px #11357026;
}

.login-form-body {
  margin: 0 8%;
  margin-top: 8%;
}

.login-heading {
  font-size: 22px;
  color: #011149;
  text-align: center;
  font-weight: bold;
  line-height: 25px;
}

.login-head-p {
  font-size: 16px;
  color: #525660;
  margin: 10px 0;
  text-align: center;
  line-height: 22px;
}

.login-form-part {
  margin: 6% 0 0 0;
  /* margin-bottom: 10px; */
}

.login-form-border {
  display: flex;
  padding-bottom: 7px;
  border-bottom: 3px solid #6D57DF;
  position: relative;
  align-items: center;
}

.login-form-border input {
  width: 100%;
  margin-left: 15px;
  border: none;
  font-family: 'poppinsmedium';
  font-size: 20px;
  color: #32343A;
  letter-spacing: 0.14em;
}


.login-form-border input::placeholder {
  color: #525660;
  font-size: 18px;
  font-family: 'poppinsregular';
  letter-spacing: 0;
}

.login-form-border input:focus {
  outline: none;
}

.get-login-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4% 0;
  margin-bottom: 0px;
}

.left-tag-whatsaap {
  display: flex;
  align-items: center;
}

.left-tag-whatsaap img {
  width: 24px;
}

.left-tag-whatsaap span {
  font-size: 14px;
  color: #011149;
  margin-left: 7px;
}

.login-auth-text {
  text-align: left;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  /* border: 2px solid #0FA82E; */
  transition: .4s;
}

input:checked+.slider {
  background-color: #0FA82E;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.login-form-content-under-whatsapp {
  font-size: 11px;
  text-align: center;
  color: #525660;
  line-height: 16px;
  margin: 30px 0;
  margin-bottom: 10px;
  cursor: pointer;
}

.login-form-content-under-whatsapp input {
  accent-color: #0FA82E;
  /* margin-right: 10px; */
}

.login-whatsapp-check {
  display: flex;
  align-items: start;
  gap: 10px;
}

.TandC-link {
  color: #015EF7;
  text-decoration: none;
  font-size: 11px;
  font-family: 'poppinsmedium';
}

.login-button {
  width: 100%;
  height: 56px;
  background-color: #D5D7DD;
  border-radius: 10px;
  border: none;
  color: #7D85A1;
  font-size: 18px;
  margin-top: 8px;
  /* margin-bottom: 1%; */
  font-family: 'poppinssemibold';
  cursor: pointer;
  position: relative;
}

.login-btn-active {
  background-color: #0FA82E;
  color: #fff;
  outline: none;
}

.red-error-border {
  border-bottom: 3px solid #D0342C;
}

.number-login-err {
  color: #D0342C;
  font-size: 12px;
  position: absolute;
  bottom: -22px;
  left: 0;
}

@media screen and (max-width:1160px) {
  .login-left {
    margin-top: 10px;
  }
}

@media screen and (max-width:900px) {
  .login-right {
    /* width: 360px */
    /* max-width: 500px; */
  }

  .login-main-cont {
    width: 100%;
  }

  .login-cont {
    margin-top: 100px;
  }

  .login-button {
    margin: 10px 0 0 0;
  }
}

@media screen and (max-width:750px) {
  .login-cont {
    margin-top: 50px;
  }

  .login-left {
    display: none;
  }

  .login-right {
    width: auto !important;
  }

  .login-main-cont {
    display: flex;
    height: auto;
  }

  .login-heading {
    font-size: 20px;
  }

  .login-cont {
    padding: 3%;
  }
}

@media screen and (max-width:520px) {
  .login-form-body {
    margin: 5%;
  }

  .login-form-border {
    padding-bottom: 5px;
  }
}