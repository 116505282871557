.tag {
    text-align: center;
}


.slick-slide img {
    margin: auto;
    width: 96%;
    /* height: 50%; */
}


.slider-main{
    display: flex;
    justify-content: space-around;
    width: 73%;
    height: auto;
    margin: auto;
    margin-top: 130px;
    border-radius: 30px;
    background: url("../assests/slider_back.svg"), linear-gradient(to right,#d73749,#301bc0) no-repeat;
    background-size: cover;
    /* background-image: url("../Images/slider_back.svg"), linear-gradient(to right,#d73749,#301bc0) no-repeat; */
    /* background-image: url("https://www.moneywide.com/assets/images/moneywide/pl/downloadapp-bgmask.png"),linear-gradient(to right,#d73749,#301bc0); */
    position: relative;
    padding: 3%;
    font-family: poppinsregular;
}

.slider-left{
    width: 40%;
}

.slider-frame{
    position: relative;
    margin-top: -25%;
    margin-bottom: -25%;
    z-index: 10;
}

.slider-frame img{
    width: 59.2%;
}

.slider-img{
    width: 56%;
    position: relative;
    margin-top: -123.5%;
    margin-bottom: -15%;
    margin-left: 1.5%;
    z-index: 1;
    background-color: white;
}

.slider-right{
    /* padding-right: 8%; */
    /* padding-top: 1%; */
    color: white;
    word-break: break-word;
}

.slider-right h2{
    font-size: 30px;
    margin-bottom: 3%;
    font-family: 'poppinssemibold';
    text-align: left;
}

.slider-right p{
    font-size: 20px;
    line-height: 36px;
    margin-top: 0%;
    text-align: left;
}

.slider-right ul{
    margin-left: -10%;
    font-size: 18px;
    text-align: left;
}

.slider-right li{
    list-style-type: none;
    line-height: 48px;
}
.slider-right li span{
line-height: inherit;
}
.slider-right li::before{
    content: "\2713";
    background: #21bf26;
    margin-right: 15px;
    float: left;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin-top: 15px;
}
.slide-right-second{
    margin-left: 15px;
}

.imgslider{
    overflow: hidden;
    border-radius: 11%;
}

@media only screen and (max-width: 1180px) {
    .slide-right-second{
        margin-left: 8px;
    }
}
@media only screen and (max-width: 990px) {
    .slide-right-second{
        margin-left: 4px;
    }
}
 

@media only screen and (max-width: 750px) {
    .slider-main{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 85%;
        margin-top: 20px;
    }

    .slider-left{
        width: 100%;
    }

    .slider-img{
        margin-left: 22.7%;
        margin-top: -124.5%;
    }

    .slider-frame{
        position: relative;
        margin-top: 0%;
        margin-left: 0%;
        /* margin-bottom: -25%; */
        z-index: 10;
    }

    .slider-frame img{
        width: 60%;
        margin-left: 20.5%;
    }

    .slide-right-second{
        margin-left: 4px;
    }

    .slider-right h2{
        font-size: 22px;
        text-align: left;
    }
    
    .slider-right p{
        font-size: 16px;
        line-height: 32px;
        text-align: left;
    }
    
    .slider-right ul{
        font-size: 14px;
        text-align: left;
    }
    
    .slider-right li{
        line-height: 36px;
    }
    .slider-right li::before{
        margin-top: 7px; 
    }
}

@media only screen and (max-width: 400px){
    .slider img{
        width: 55%;
        margin-left: 22.5%;
        margin-top: 125.5%;
    }
    .slider-img {
        margin-left: 22.5%;
        margin-top: -125.2%;
        border-radius: 20px;
    }
}