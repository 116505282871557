.mobile-footer-cont{
    display: flex; 
    justify-content:space-around;
    background-color: #12235F;
    color: white;
    font-size: 12px;
    text-align: center; 
}
.mobile-indian-flag{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-footer-p{
    line-height: 16px;
    font-size: 12px;
}
@media only screen and (max-width:740px){
    .mobile-indian-flag{
        display: none;
    }
    .mobile-footer-p{
        font-size: 11px;
    }
}
@media only screen and (max-width:590px){
    .mobile-footer-p{
        padding: 0px 20px;
    }
}