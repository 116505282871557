.pa-offer-main {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
}

.pa-offer-head {
    width: 100%;
}

.pa-offer-head-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pa-offer-head-first p {
    font-weight: 500;
    font-size: 14px;
}

.pa-offer-head-second input {
    width: 100%;
    margin-top: 5%;
}

.urban-range-slider {
    width: 100%;
    -webkit-appearance: none;
    background: #D5D7DD;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: inset #D5D7DD;
}

.urban-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #4D3ECC;
    cursor: pointer;
    border: 3px solid #fff;
    box-shadow: -407px 0 0 400px #4D3ECC;
}

.pa-offer-head-input-minmax {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
}

.pa-offer-head-input-minmax p {
    margin-top: 0px;
}

.pa-offer-head-first-input-div {
    width: 33%;
    display: flex;
    gap: 5%;
    border-bottom: 1px solid #D5D7DD;
}

.pa-offer-head-first-input-div span {
    font-weight: 600;
    font-size: 22px;
}

.pa-offer-head-first input {
    width: 100%;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    border: none;
    outline: none;
}

.pa-offer-body-first table {
    width: 100%;
    background: #F6F6F8;
}

.pa-offer-body-first table thead tr th:first-child {
    width: 10%;
}

.pa-offer-body-first table thead tr th {
    width: 30%;
    font-size: 16px;
}

.pa-offer-body-first table thead tr th span {
    font-size: 12px;
}

.pa-offer-body-first table tbody tr td {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

.pa-offer-body-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pa-offer-body-second p {
    font-weight: 600;
    font-size: 14px;
}

.pa-offer-body-third {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.margin-percentage {
    margin-bottom: 5%;
}

.pa-offer-body-third span {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
}
@media (max-width:468px){
    .pa-offer-head-first-input-div{
        width:50%;
    }
}