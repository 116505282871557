.partners-all-main {
    background-image: url('../assests/partners/new-partners-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* min-height: 436px;
    max-height: 536px; */
    height: 30vw;
}

.partners-sub-div {
    width: 80%;
    margin: 0 auto;

}

.partner-new-offer{
    margin-left: 57px;
    margin-top: -29px;
    /* font-size: 16px; */
}

.partner-new-offer p{
    font-size: 16px;
    font-weight: bold;
    color: #011149;
}

.mw-muthot-logo {
    padding-top: 40px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

/* .mw-muthot-logo img:first-child {
    border-right: 1px solid #fff;
    padding-right: 20px;
} */

.partners-form-div {
    display: flex;
    justify-content: space-between;
    height: 40vw;
    /* align-items: center; */
    /* margin-top: 80px; */
}

.partners-left-cont {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.login-whatsapp-check {
    display: flex;
    align-items: start;
    gap: 10px;
}

.login-auth-text {
    text-align: left;
}

.partners-left-cont-head span {
    font-size: 32px;
    font-family: 'poppinssemibold';
    color: #fff;
    line-height: 140%;
}

.partners-right-form {
    background-color: #fff;
    border-radius: 25px;
    width: 434px;
    /* height: 455px; */
    box-shadow: 15px 15px 15px rgba(17, 53, 112, 0.1);
}

/* .partners-keyfeatures{
    width: 80%;
    margin: 0 auto;
} */
.partners-keyfeatures-heading span {
    font-size: 36px;
    font-family: 'poppinsregular';
}

.partner-sub-key {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
}

.partner-sub-new {
    display: flex;
    gap: 10px;
    align-items: center;
}

.partner-sub-new p {
    color: #263238;
    font-family: "poppinsmedium";
    font-size: 20px;
    margin: 0px;
}

.partners-right-form-heading p {
    font-size: 24px;
    font-family: 'poppinssemibold';
    margin: 0px;
}

.partners-right-form-heading p span {
    color: #6D57DF;
}

.partners-right-form-sub {
    padding: 10% 0%;
    /* width: 368px; */
}

.partner-input-number {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
}

.partner-input-number input {
    width: 100%;
    border: none;
}

.partner-input-number input:focus {
    outline: none;
}

.partners-checkbox {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    margin-bottom: 24px;
}

.partners-check-cont p {
    color: #525660;
    font-size: 11px;
    margin: 0px;
    line-height: 140%;
}

.partner-otp-btn button {
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'poppinssemibold';
    background-color: #0FA82E;
    width: 100%;
    border: none;
    padding: 14px 0px;
    border-radius: 10px;
}

.pre-aprove-partner-offer {
    background-color: #EEF0FF;
    border: 2px solid #B4ADFB;
    border-radius: 20px;
    margin-top: 30px;
    background-image: url("../assests/partners/PA-Offer-BG.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.pre-aprove-space {
    padding: 14px 14px 0px 20px;
}

.login-form-content-under-whatsapp input {
    accent-color: #0fa82e;
}

.pre-apprv-head p {
    color: #011149;
    font-size: 14px;
    margin: 0px;
    line-height: 140%;
    font-family: 'poppinsmedium';
}

.pre-apprve-sub {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.pre-aprv-left {
    margin-top: 6px;
}

.pre-apprv-inner-head p {
    color: #6D57DF;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 12px;
}

.pre-apprv-inner-head p span {
    font-size: 14px;
    font-family: 'poppinsmedium';
    color: #011149;
}

.pre-aprv-cont-main {
    display: flex;
    gap: 28px;
}

.pre-aprv-cont span {
    color: #525660;
    font-size: 12px;
}

.pre-aprv-cont p {
    color: #011149;
    font-family: 'poppinssemibold';
    margin: 0px;
    font-size: 14px;
    margin-top: 6px;
}

.partner-key-mob {
    display: none;
}

.pa-congrats-main-div {
    margin-top: 30px;
    padding-bottom: 24px;
}

.pa-congrats-one {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
}

.pa-congrats-one input {
    height: 30px;
    width: 16px;
}

.pa-congrats-main-div-one {
    /* background: #F2F7FF; */
    border: 2px solid #0FA82E;
    border-radius: 15px;
    cursor: pointer;
    /* padding: 16px; */

}

.pa-congrats-one-main {
    display: flex;
    gap: 48px;
    padding-left: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pa-congrats-p {
    color: #525660;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 11px;
}

.pa-congrats-ptwo {
    font-weight: 600;
    color: #011149;
    margin-top: 8px;
    font-size: 14px;
}

.partner-pre-btn-unlck button{
    background-color: #4D3ECC;
    color: white;
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}

/* .pa-congrats-main-div-one ul {
    background: #F1FFF4;
    border-radius: 0px 0px 15px 15px;
    margin-bottom: 0px;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-top: 0px;
    font-size: 11px;
    display: flex;
    justify-content: space-around;
    align-items: center;
} */

/* .pa-congrats-main-div-one button{
    background-color: #0FA82E;
    color: white;
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
} */

.partner-pre-btn {
    background-color: #F1FFF4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding-right: 12px;
}
.partner-pre-btn p, .partner-pre-btn-unlck p{
    margin: 0px;
    font-size: 12px;
    position: relative;
}
.partner-pre-btn p::after{
    content: '';
    position: absolute;
    background-color: #0FA82E;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    left: -7px;
    top: 7px;
}
.partner-pre-btn-unlck p::after{
    content: '';
    position: absolute;
    background-color: #4D3ECC;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    left: -7px;
    top: 7px;
}


.partner-pre-btn button{
    background-color: #0FA82E;
    color: white;
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 8px 10px;
    cursor: pointer;
}

.partner-pre-btn-unlck{
    background-color: #D5D7DD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding-right: 12px;
}

/* .pa-congrats-main-div-two ul {
    background-color: #D5D7DD;
    border-radius: 0px 0px 15px 15px;
    margin-bottom: 0px;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-top: 0px;
    font-size: 11px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #011149;
} */

/* .pa-congrats-main-div-two button{
    background-color: #4D3ECC;
    color: white;
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
} */

.pre-approved-prystine-p{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 8px;
}

.pa-congrats-main-div-two {
    /* background: #F5F3FF; */
    background-color: #F6F6F8;
    border: 1px solid #D5D7DD;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 24px;
    cursor: pointer;
}

.pa-congrats-div-main {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 30px;
}

.pa-congrats-div-main p {
    font-weight: 600;
    font-size: 11px;
    line-height: 140%;
    color: #011149;
    width: 100%;
}

.pa-congrats-div-main b{
    font-size: 16px;
}

.pa-offer-note-imp {
    font-size: 11px;
    font-weight: 400;
    color: #263238;
    line-height: 140%;
    margin-top: 18px;
    text-align: center;
}

.mw-logo-right img:first-child {
    max-width: 100px;
    height: 62px;
    /* border-right: 1px solid #8A91A1; */
    padding-right: 36px;
    padding-left: 36px;
    position: relative;
}

/* .mw-logo-right img:first-child::after{
    content: '';
    color: #8A91A1;
    height:36px;
    width: 2px;
} */

.mw-logo-right img:nth-child(2) {
    width: 200px;
}

.partner-logo-name {
    padding: 0px 20px;
}

.mw-logo-right {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    align-items: center;
}

.mw-logo-right p {
    padding: 0px 14px;
}

.mw-logo-right.mw-logo-right-none::after {
    display: none;
}

.mw-logo-right::after {
    content: '';
    position: absolute;
    background-color: #8A91A1;
    height: 36px;
    width: 1px;
    left: 168px;
    top: 12px;
}

@media screen and (max-width:1028px) {
    .partner-key-web {
        display: none;
    }

    .partner-key-mob {
        display: block;
    }

    .mw-muthot-logo {
        justify-content: center;
    }

    .partners-form-div {
        flex-direction: column;
    }

    .partners-left-cont {
        text-align: center;
        margin-bottom: 30px;
    }

    .partners-left-cont span {
        font-size: 28px;
    }

    /* .partners-keyfeatures{
        margin-top: 40%;
        position: absolute;
        top: 196%;
        left: 8%;

    } */
    .partner-input-number {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .partners-right-form {
        width: 100%;
        margin-bottom: 30px;
    }

    /* .partners-right-form-sub{
        width: 100%;
    } */
    .partners-all-main {
        height: 60vh;
    }
}

@media screen and (max-width:986px) {
    .partners-keyfeatures {
        /* margin-top: 50%; */
        /* top: 190%; */

    }
}

@media screen and (max-width:768px) {
    .partners-sub-div {
        width: 90%;
    }

    .partners-left-cont span {
        font-size: 20px;
    }

    .partners-keyfeatures {
        /* margin-top: 60%; */
        /* top: 210%; */
    }

    .partners-keyfeatures-heading span {
        font-size: 28px;
        line-height: 140%;
    }

    .partner-sub-new p {
        font-size: 16px;
        line-height: 140%;
    }
}

@media screen and (max-width:600px) {
    /* .partners-keyfeatures{
        margin-top: 70%;
    } */
}

@media screen and (max-width:468px) {
    .pre-aprove-space {
        padding: 14px 14px 14px 20px;
    }

    .pa-congrats-one-main {
        gap: 30px;
    }

    .partners-left-cont span {
        font-size: 18px;
    }

    .partners-keyfeatures {
        /* margin-top: 90%; */
        /* top: 170%; */
    }

    .partners-keyfeatures-heading span {
        font-size: 20px;
    }

    .partner-sub-new p {
        font-size: 14px;
        line-height: 140%;
    }

    .pre-apprv-inner-head p {
        font-size: 14px;
    }

    .pre-apprve-right img {
        width: 90px;
        height: 76px;
    }

    .pre-aprv-cont-main {
        gap: 18px;
    }

    .mw-muthot-logo {
        padding-top: 20px;
    }

    .mw-logo-right {
        width: 100%;
        justify-content: space-around;
    }

    .mw-logo-right img:first-child {
        width: 92px;
    }

    .mw-logo-right img:nth-child(2) {
        width: 150px;
    }

    .mw-logo-right::after {
        left: 50%;
    }

    .partners-left-cont {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .partners-right-form-heading p {
        font-size: 20px;
    }

    .partner-input-number .login-form-content-under-whatsapp input {
        margin-left: 0px;
    }

    .pa-congrats-div-main p{
        font-size: 12px;
    }

    .pa-congrats-div-main b{
        font-size: 14px;
    }
    .partner-pre-btn p{
        font-size: 11px;
    }
    .partner-pre-btn button{
        font-size: 11px;
    }

}

@media screen and (max-width:438px) {
    .partners-keyfeatures {
        /* margin-top: 90%; */
        /* top: 140%; */
    }
}

@media screen and (max-width:400px) {
    .partners-keyfeatures {
        /* margin-top: 86%; */
        /* top: 168%; */
    }

    .partners-left-cont span {
        font-size: 16px;
    }
}

@media screen and (max-width:396px) {
    .partners-keyfeatures {
        /* top: 150%; */
    }
}

@media screen and (max-width:380px) {
    .partners-keyfeatures {
        /* top: 160%; */
    }
}