.scrollToTopButton {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    font-size: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
    transition: opacity 0.4s ease-in-out;
    z-index: 10;
  }
  .scrollToTopButton img{
    width: 55px;
  }
  
  .scrollToTopButton.show {
    display: block;
    opacity: 0.5;
  }
  
  .scrollToTopButton:hover {
    opacity: 1;
  }
  @media only screen and (max-width:500px){
    .scrollToTopButton img {
        width: 45px;
    }
    .scrollToTopButton {
        transition: opacity 1s ease-in-out;
    }
    .scrollToTopButton.show {
        display: block;
        opacity: 1;
      }
  }