.NewFooter-bg {
    background-color: #011149;
    border-bottom: 1px solid #A3AFBF;
}

.foot-first {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
}

.non_submenu {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.foot-head {
    width: 100%;
}

.foot-head a {
    color: #D5D7DD;
    font-size: 16px;
    text-decoration: none;
    font-family: 'poppinsmedium';
}

.foot-head a:hover {
    color: #fff;
}

.foot-mid-bg {
    background-color: #011149;
    border-bottom: 1px solid #A3AFBF;
    padding-top: 20px;
}

.mwplay-store {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mwplay-store p {
    color: #fff;
    font-size: 16px;
    margin: 0px;
    font-family: 'poppinsmedium';
}

.mwplay-store img {
    cursor: pointer;
}

.foot-app-mid {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.foot-mid {
    width: 80%;
    margin: 0 auto;
}

.foot-app-calc {
    margin: 0;
}

.foot-mid span {
    color: #FFFFFF;
    font-size: 18px;
    margin: 20px 0px;
    position: relative;
    font-family: 'poppinsmedium';

}

.foot-mid span::after {
    content: '';
    background-color: #fff;
    height: 2px;
    position: absolute;
    bottom: -7px;
    right: 0;
    left: 0px;
    border-radius: 10px;
    width: 32%;
}

.foot-mid ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 14px;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0px;
    padding: 6px 0px;
    /* padding-left: 2%; */
}

.foot-mid ul li a {
    font-size: 14px;
    color: #D5D7DD;
    position: relative;
    text-decoration: none;
    font-family: 'poppinsregular';
    /* padding: 0px 10px; */
    /* border-right: 1px solid #fff; */

}

.foot-mid ul li a:hover {
    color: #fff;
}

/* .foot-mid ul li a::after{
    content: '';
    background-color: #fff;
    height: 16px;
    position: absolute;
    bottom: 2px;
    right: -12px;
    width: 0.1em;
} */
.foot-app-head {
    font-size: 16px;
    color: #FFFFFF;
}

.foot-base-bg {
    background-color: #011149;
}

.foot-base {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    padding-bottom: 10px;
    gap: 10px;
}

.foot-mw-cntct {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.foot-mw-adres p {
    color: #fff;
    font-size: 14px;
    margin: 10px 0px;
    line-height: 140%;
    /* font-family: 'poppinsmedium'; */
}

.foot-mw-adres span {
    color: #fff;
    font-size: 14px;
    margin: 10px 0px;
}

.foot-mw-adres2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.foot-ctct-head {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-family: "poppinsmedium";
}

.cntct-area-main {
    display: flex;
    gap: 10px;
    align-items: end;
}

.cntct-area-main span {
    font-size: 14px;
    color: #fff;
}
.cntct-timing span{
    font-size: 12px;
}


.foot-cntct-area {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.foot-mw-follow {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mw-follow-head span {
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'poppinsmedium';
}

.foot-copyright-bg {
    background-color: #12235F;
    padding: 10px 0px;
}

.foot-copyright {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.foot-copy-txt span {
    font-size: 12px;
    color: #fff;
}

.submenufooter .foterdownArow {
    display: none;
}

.foot-flag-copy {
    display: flex;
    gap: 6px;
    align-items: center;
}

.foot-india-love {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.foot-india-love p {
    font-size: 12px;
    color: #fff;
    margin: 0px;
}

.foot-flag-copy img {
    width: 40px;
    height: 26px;
}

.foterdownArow {
    color: #fff;
}
.sub_footer span{
    color: #D5D7DD;
    font-size: 16px;
    font-family: 'poppinssemibold';
    line-height: 140%;
}
@media screen and (max-width: 1400px) and (min-width: 768px) {
    .foot-mid ul li a, .foot-mw-adres p, .cntct-area-main span{
      font-size: 12px;
    }
    .foot-head a, .sub_footer span, .mwplay-store p, .mw-follow-head span, .foot-ctct-head{
        font-size: 14px;
    }
    
  }
@media screen and (max-width:1000px) {
    .NewFooter-bg {
        border-bottom: none;
    }

    .non_submenu {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        row-gap: 20px;
    }
    .cntct-timing>span{
        font-size: 10px;
    }

}

@media screen and (max-width:768px) {

    .foot-mid,
    .foot-first,
    .foot-base {
        width: 90%;
    }

    .foot-head a {
        font-size: 14px;
    }

    .non_submenu {
        column-gap: 20px;
        justify-content: flex-start;
    }

    .foot-first {
        padding-top: 30px;
    }

    .sub_footer .subhead {
        padding-top: 30px;
        padding-bottom: 0px;
        border-bottom: 1px solid #fff;
    }

    .foot-mid span {
        color: #D5D7DD;
        margin: 10px 0px;
        margin-bottom: 4px;
        padding-left: 2%;
    }

    .foot-mid ul li a::after {
        display: none;
    }

    /* .sub_footer .submenufooter{
        padding: 10px 0px;
        border-bottom: 1px solid #fff;
    } */
    .sub_footer .submenufooter {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
    }

    .submenufooter .foterdownArow {
        display: block;
    }

    .foot-mid span::after {
        display: none;
    }

    .foot-mid ul {
        display: none;
        padding: 0px;
        padding-left: 2%;
        margin: 0px;
    }

    .subhead ul li {
        margin: 14px 0px;
    }

    .foot-mw-adres2 {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .foot-base {
        flex-direction: column;
    }

    .foot-mw-adres {
        text-align: center;
    }

    .foot-mw-adres p br:nth-child(2) {
        display: none;
    }

    .foot-mw-cntct {
        margin: 20px 0px;
        align-items: center;
    }

    .cntct-area-main {
        align-items: end;
        gap: 6px;
    }

    .foot-cntct-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .foot-mid-bg {
        padding-top: 0px;
        padding-bottom: 20px;
        border-bottom: none;
    }
    .mwplay-store{
        align-items: center;
        margin-bottom: 20px;
    }
    .mwplay-store-mob p {
        font-family: 'poppinsmedium';
        margin-top: 0px;
        color: #fff;
        font-size: 14px;
    }

    .foot-mw-follow {
        align-items: center;
    }

    .foot-copy-txt {
        text-align: center;
    }

    .foot-flag-copy {
        display: none;
    }

    .footblankspace {
        display: none;
    }
}