.Eligibility-cont{
    width:73vw;
    line-height: 25px;
    margin: auto;
    margin-top: 50px;
    color: #455a64;
    margin-top: 100px;
    background: #fff;
    box-shadow: 1px 1px 4px #ddd;
    border: 1px solid #e5f2ff;
    padding: 50px 40px;
    border-radius: 12px;
}
.eligibility-criteria {
    margin: 80px auto;
    background-color: #F9FAFE;
}
.eligibility-criteria-box {
    /* background: #fff; */
    /* padding: 40px; */
    padding-top: 50px;
    /* border-radius: 12px; */
    /* border: 1px solid #e5f2ff; */
    /* box-shadow: 1px 1px 4px #ddd; */
}
.heading-h2 {
    font-size: 36px;
    line-height: 30px;
    color: #011149;
    margin-bottom: 0px;
}
.ploan-row-criteria {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.colm-1 {
    /* width: 100%;
    margin: auto; */
}
.list-check {
    list-style: none;
    padding: 0;
}
.gtm_lefttable{
text-decoration: none;
color: #3d5fe6;
}
@media screen and (max-width:1000px) {
    /* .colm-2 img {
        width: 280px;
    } */

    .colm-2 {
        margin-right: -50px;
    }
}

@media screen and (max-width:720px) {
    .heading-p {
        font-size: 10px;
    }
    .Eligibility-cont{
        margin-top: 70px;
    }

    .heading-h2 {
        font-size: 20px;
    }

    .ploan-row {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
    }
    .ploan-row-criteria {
        display: flex;
        flex-direction: column;
    }
    .colm-1 {
        width: 100%;
        margin: auto;
    }

    .colm-2 {
        margin: 0 0;
        width: 100%;
    }
    .eligibility-criteria{
        margin-top: 30px;
        margin-bottom: 0px;
    }
    .eligibility-criteria-box{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    /* .colm-2 img {
        width: 60vw;
    } */

    .ploan-container {
        padding: 0;
    }

}