/* First */
.bann {
  margin: 0 auto;
  /* margin-top: 120px; */
  margin-top: 82px;
  /* background-image: url("/src/Icons/homebannerbg.svg"); */
  background-size: cover;
}

.bann .slick-dots li.slick-active button:before {
  font-size: 12px;
  color: #4d3ecc;
  opacity: 1;
}

.bann .slick-dots {
  right: 37%;
  top: 88%;
}

.bann .slick-dots li button {
  color: #ddd;
}

.bann .slick-dots li {
  margin: 0 0px;
}

.bann .slick-dots li button:before {
  color: #4d3ecc;
  font-size: 10px;
}

.homebanner {
  width: 80%;
  display: flex !important;
  justify-content: space-evenly;
  margin: auto;
}

.right-content {
  margin-top: 30px;
  /* margin: auto; */
}

.right-content img {
  /* margin-top: 30px !important; */
  width: 100%;
}

.left-content {
  /* width: 60%; */
  max-width: 650px;
  min-width: 480px;
  padding: 8% 0;
  display: flex;
  flex-direction: column;
}

.left-content h1 {
  font-size: 42px;
  color: #011149;
  position: relative;
  line-height: 62px;
  font-family: "poppinsregular";
  font-weight: 100;
}

@media (max-width:1372px) {
  .left-content h1 {
    font-size: 38px;
  }
}

.left-content h1 span {
  /* background: -webkit-linear-gradient(#fd4b1a,#ff8c2b); */
  /* -webkit-background-clip:text; */
  /* -webkit-text-fill-color:transparent; */
  color: #4D3ECC;
  font-family: 'poppinssemibold';
}

/* .left-content hr {
  width: 127px;
  height: 4px;
  background: #fd4c1b;
  border: none;
  margin-left: 0;
  margin-top: -20px;
  margin-bottom: 30px;

} */

.home-words {
  margin-top: 3px;
  margin-bottom: 45px;
  /* width: 80%; */
  overflow: hidden;
  height: 35px;
}

/* .home-words span {
  display: block;
  animation: move1 12s infinite linear;
  background: -webkit-linear-gradient(#FD4B1A, #FF8C2B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'poppinssemibold';
  font-size: 36px;
  height: 35px;
  white-space: nowrap;
  opacity: 1;
} */
.home-words span {
  position: absolute;
  opacity: 0;
  background: -webkit-linear-gradient(#FD4B1A, #FF8C2B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'poppinssemibold';
  font-size: 36px;
  white-space: nowrap;
  animation: newlyAni 12s linear infinite 0s;
}

.home-words span:nth-child(2) {
  animation-delay: 2s;
}

.home-words span:nth-child(3) {
  animation-delay: 4s;
}

.home-words span:nth-child(4) {
  animation-delay: 6s;
}

.home-words span:nth-child(5) {
  animation-delay: 8s;
}

.home-words span:nth-child(6) {
  animation-delay: 10s;
}

@keyframes newlyAni {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    transform: translateY(-30px);
  }

  5% {
    opacity: 1;
    transform: translateY(0px);
  }

  17% {
    opacity: 1;
    transform: translateY(0px);
  }

  20% {
    opacity: 0;
    transform: translateY(30px);
  }

  98% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

/* new css for homepage parvesh */
.offering-loan {
  margin-bottom: 30px;
}

.offering-loan span {
  color: #525660;
  font-size: 26px;
  font-family: 'poppinsregular';
}

/* new css for homepage parvesh */
/* right banner bubble start */

.right-content {
  position: relative;
}

.right-content a {
  text-decoration: none;
}

.bubble-1,
.bubble-4,
.bubble-3,
.bubble-2 {
  position: absolute;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(202, 204, 222, 0.5);
  padding: 16px 0px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 162px;
  border: none;
  transition: 0.3s;
  /* transition: border 2s linear;
	transition: box-shadow 2s linear; */
  /* transition: background-color 2s linear; */
  color: #000 !important;
}

.bubble-1 span a,
.bubble-4 span a,
.bubble-3 span a,
.bubble-2 span a {
  color: #000 !important;
}

.bubble-1:hover,
.bubble-4:hover,
.bubble-3:hover,
.bubble-2:hover {
  border: 2px solid #B4ADFB;
  background-color: #F8F8FF;
  box-shadow: none;
  cursor: pointer;
  transform: scale(1.1);
}
.btn{
  transition: transform .4s;
}
.btn:hover{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* .b-1, .bb-4{
	visibility: visible;
} */

.bubble-1 {
  top: 0px;
  visibility: hidden;
}

.bubble-2 {
  top: 142px;
  left: 20px;
  visibility: hidden;
}

.bubble-3 {
  top: 108px;
  right: 26px;
  visibility: hidden;
}

.bubble-4 {
  bottom: 218px;
  right: 16px;
  visibility: hidden;
}

@keyframes zoom {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.cont2::before {
  content: "\a";
  white-space: pre;
}

/* .career-section {


  background-position: right;
 

  background-size: 100% 340px;
} */
.budget-slide {
  /* background-image: url('../../Icons/budgetbg.webp'); */
  background-repeat: no-repeat;
  background-position-y: top;
  /* background-color: #f9fafe; */
  background-size: cover;
  width: 100%;
  margin: 0 auto;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}

.budgetloti {
  width: 550px;
  height: 423px;
}

.budgetslide-left span {
  font-size: 60px;
  color: #011149;
  font-family: 'poppinssemibold';
  line-height: 140%;
  margin: 0px;
}
.budgetslide-left p:first-child{
  margin: 0px;
  font-size: 34px;
  font-family: 'poppinsmedium';
  /* font-weight: 600; */
  line-height: 140%;
}
.budgetslide-left p{
  font-size: 60px;
  color: #4D3ECC;
  font-family: 'poppinssemibold';
  margin: 0px;
  line-height: 140%;
}

/* .budgetslide-left a {
  background: #0FA82E;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 14px;
  text-decoration: none;
} */

/* .budgetslide-right{
  position: relative;
} */
/* .budget-btn{
  position: absolute;
  top: 44%;
  right: 22%;
  /* overflow: hidden;
} */
.budgetslide-right {
  margin-top: 32px;
}
.budgetslide-right img{
  width:40vw !important;
}

/* .budget-btn{
  display: -webkit-box;
  height: 160px;
  width: 374px;
  position: absolute;
  overflow: hidden;
  top: 41%;
  left: 22%;
} */
/* .budget-btn img{
  position: absolute;
} */
/* .budget-btn::after{
  content: "";
  z-index: 10;
  position: absolute;
  height: 100%;
  width: 100%;
  top: -120%;
  left: -120%;
  background: linear-gradient(transparent 0%, #ddd 45%, transparent 50%, #ddd 55%, transparent 100%);
  transform: rotate(238deg);
  animation: shine 2s ease-in infinite;
} */
@keyframes shine {
  0% {
    top: -120%;
    left: -120%;
  }

  20% {
    left: 100%;
    top: 100%;
  }

  40% {
    left: 100%;
    top: 100%;
  }

  100% {
    left: 100%;
    top: 100%;
  }
}
.text-slider-marq{
  display: flex;
  justify-content: space-between;
}
/* .text-strip {
  background-color: #6D57DF;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 0px;
}

.text-strip .text-strip-inner {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  animation: marquee 20s linear 0s infinite normal none running;
}
.text-strip-inner ul{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.text-strip-inner ul li{
  color: #fff;
  
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
} */
/* .text-strip-bg-main{
  width: 100%;
  background-color: #8A6AFD;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 21px;
}
.text-strip-bg{
  overflow: hidden;
}
.text-strip-bg::before, .text-strip-bg::after{
  position: absolute;
    content: "";
    z-index: 2;
    pointer-events: none;
}
.text-main-new{
  width: calc(2100px);
  animation: 20s linear infinite 0 normal none running slideq;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.text-to-slide{
  height: 40px;
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.5s ease 0s;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}
.text-main-new{
  color: #fff;
}
@keyframes slideq{
  0%{
  transform: translateX(0px);
}

  100%{
  transform: translateX(calc(-1000px));
}
} */
@media screen and (max-width:990px) {

  .bubble-1,
  .bubble-4,
  .bubble-3,
  .bubble-2 {
    width: 98px;
    font-size: 9px;
    gap: 5px;
    font-family: 'poppinsregular' !important;
    padding: 6px 5px;
    text-align: left;
    box-shadow: 0px 0px 6px 0px #d1d7df;
    padding: 12px;
  }

  .cl-logo img,
  .pl-logo img,
  .dl-logo img,
  .sl-logo img {
    width: 20px;
  }

  .bubble-1 {
    top: 0px;
  }

  .bubble-2 {
    left: -12px;

  }

  .bubble-4 {
    right: 7px;
  }

  .bubble-3 {
    right: 4px;
  }

  .offering-loan span {
    font-size: 24px;
  }

  .offering-loan {
    text-align: center;
  }

  .budget-slide {
    flex-direction: column;
  }
  .budgetslide-right img{
    width:80% !important;
  }
}

@media screen and (max-width:768px) {
  .budget-slide {
    /* background-image: url('../../Icons/budgetbgmob.webp'); */
    margin-top: 85px;
    min-height: 440px;
    justify-content: space-between;
    background-size: contain;
  }

  .bubblerow {
    flex-direction: column-reverse;
  }

  .bubble-1 {
    top: 65px;
    left: 28px;
  }

  .bubble-2 {
    left: 32px;
    top: 40%;
  }

  .bubble-4 {
    bottom: 190px;
    right: 38px;
  }

  .bubble-3 {
    right: 46px;
  }

  .offering-loan span {
    font-size: 16px;
  }

  .budgetloti {
    width: 334px;
    height: 236px;
  }

  .budgetslide-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .budgetslide-left span {
    font-size: 30px;
  }

  .budgetslide-left p {
    font-size: 30px;
    margin-bottom: 16px;
  }

  .budgetslide-left a {
    padding: 14px;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 100;
  }

  .bann .slick-dots {
    right: 0%;
    top: 111%;
  }
  .bann .slick-dots li{
    margin: 0px;
  }
}

@media screen and (max-width:480px) {

  .bubble-1,
  .bubble-4,
  .bubble-3,
  .bubble-2 {
    padding: 8px;
  }

  .bubble-1 img,
  .bubble-2 img,
  .bubble-3 img,
  .bubble-4 img {
    width: 20px !important;
  }

  .bubble-1 {
    top: 18px;
  }

  .bubble-2 {
    left: 14px;
    top: 36%;
  }

  .bubble-4 {
    bottom: 132px;
    right: 8px;
  }

  .bubble-3 {
    top: 88px;
    right: 20px;
  }
  .budgetslide-left p:first-child{
    font-size: 24px;
  }
}

@media screen and (max-width:370px) {
  .offering-loan span {
    font-size: 14px;
  }
}

/* right banner bubble end */

.btn {
  width: 132px;
  white-space: nowrap;
  border: none;
  background: #0FA82E;
  border-radius: 5px;
  font-size: 14px;
  line-height: 42px;
  cursor: pointer;
}

.btn a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-family: 'poppinsmedium';
}

@media screen and (Max-width:960px) {
  .bann {
    width: 100vw;
    margin: auto;
  }

  .homebanner {
    display: flex;
    flex-direction: column-reverse;
    margin: 10% 0;
    padding: 0 8%;
  }

  .right-content img,
  .btn {
    width: 100%;
    margin: 0 auto;
  }

  .left-content h1 {
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    margin-top: -10px;
  }

  .left-content hr {
    margin: auto;
    margin-bottom: 20px;
    margin-top: -5px;
  }

  .left-content {
    max-width: 100%;
    padding: 0px;
  }

  .home-words {
    width: auto;
    display: flex;
    justify-content: center;
    height: 28px;
  }

  .home-words span {
    font-size: 20px;
    height: 28px;
  }

  .right-content {
    margin-top: 16px;
  }

  .left-content,
  .right-content {
    width: 100%;
  }

  .offering-loan {
    display: none;
  }

  .left-content hr {
    display: none;
  }

  .right-content img {
    margin-top: 10%;
  }

  .bann .slick-slide img {
    width: 80%;
  }

  .btn {
    width: 117px;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 16px;
    margin: 0 auto;
  }
 
}

/* ABOUT MONEYWIDE START */
.about-mw {
  margin: 20px 0;
  margin-top: 30px;
}

.about-mw .right-content {
  margin-top: 0;
}

.about-mwide .question {
  align-items: center;
  padding: 15px 0;
}

.about-mwide .FAQ-Question-Content {
  padding: 0;
  padding-bottom: 20px;
}

.about-mwide .question-box {
  border-bottom: 1px solid #D5D7DD;
  margin: 0;
}

.about-mwide .question-left {
  font-size: 30px !important;
  line-height: 140%;
  color: inherit;
  font-family: 'poppinssemibold';
  padding: 0;
}


.about-mwide .question-right {
  transform: rotate(0deg);
  width: auto;
  padding: 0;
  margin: 0;
}

.about-mwide .question .rotate {
  transform: rotate(180deg);
}

.about-mw .left-content {
  width: 100%;
  padding-top: 0;
  margin-top: 20px;
  padding-bottom: 0;
}

.active .question-left {
  color: #4D3ECC;
}

.notactive .question-left {
  color: #011149;
}

@media (max-width:500px) {
  .about-mwide .question-left {
    font-size: 20px !important;
  }

  .about-mwide .question {
    padding: 10px 0px;
  }

  .about-mwide {
    width: 90%;
    margin: auto;
  }

  .about-mw .right-content img {
    display: flex;
    width: 90% !important;
  }

  .about-mw .left-content {
    margin-top: 30px;
  }

  .about-mw,
  .about-mw .homebanner,
  .about-mw .right-content,
  .about-mw .right-content img {
    margin-top: 0;
  }
}

/* ABOUT MONEYWIDE END */


@media screen and (max-width:500px) {
  .homebanner {
    margin-top: 60px;
  }

  .left-content h1::before {
    margin: 0 35%;
  }
  .left-content{
    min-width: fit-content;
  }

  .homebanner {
    width: 100%;
    padding: 0 0%;
  }
}

/* Group Brands css start */
.groupbrands-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10%;
  width: 80%;
  margin: 20px auto;
}

.groupbrand-heading {
  display: flex;
  justify-content: center;
}

.groupbrand-heading div {
  font-size: 30px;
  font-family: "poppinssemibold";
  margin: 20px 0;
  color: #011149;
}

.groupbrand-logos img {
  width: 160px;
  transition: transform .4s;
}
.groupbrand-logos img:hover{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.groupbrand-logos {
  display: flex;
  gap: 50px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 400px) {
  .groupbrand-heading div {
    font-size: 22px;
  }

  .groupbrand-logos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .groupbrand-logos img {
    width: 140px;
  }

  .groupbrands-main {
    margin-bottom: 10%;
  }
}

/* Group Brands css end */

/* Partner css Start */
.partner-blog {
  width: 80%;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 15px 30px #eff2ff;
  border-radius: 25px;
  padding: 30px;
  margin-top: 20px;
}

.partner-heading {
  font-family: 'poppinssemibold';
  font-size: 30px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 0;
}

@media only screen and (max-width:550px) {
  .partner-blog {
    width: 85%;
    box-shadow: none;
    padding: 0px;
  }

  .partner-heading {
    font-size: 22px;
  }
}

/* Partner css end */