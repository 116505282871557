.chat-bot-logo {
  background-color: #fff;
}

.chat-popup-open {
  /* display: block; */
  /* opacity: 1; */
  animation: fadeInFromBottom 0.6s ease;
  position: fixed;
  bottom: 18px;
  right: 35px;
  /* height: 61%;
  min-height: 48%; */
  z-index: 9999;
}

@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* .closing-chat-poup{
  opacity: 0;
  position: fixed;
  bottom: 0px;
  right: 35px;
  animation: fadeOutToBottom 0.6s ease;
}


@keyframes fadeOutToBottom {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
} */

/* Apply the fade-in animation to the chat-popup-open class */


.chat-bot-icon {
  width: 80px;
  height: 80px;
  padding: 5px 10px;
  border-radius: 20px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  font-size: 20px;
  cursor: pointer;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgba(17, 53, 112, 0.16);
}

.mw-chat-bot {
  /* height: 60vh; */
  /* height: 100%; 11-08*/
  /* min-height: 68%; 11-08 */
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  height: 450px;
  /* position: fixed; */
  /* bottom: 100px;
  right: 36px; */
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 10px 20px 0px rgba(109, 87, 223, 0.20);
}

.chat-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 12px;
  z-index: 999;
}

.chat-head-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-bot-icon-main-div{
  height: 40px;
  width: 40px;
}

.chat-head-left img {
  width: 30px;
  height: 26px;
}

.chat-head-left span {
  font-family: 'poppinssemibold';
  font-size: 12px;
}

.chat-bottom {
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.08);
}

.chat-bottom button {
  background-color: transparent;
  border: none;
}

.close-chat-popup {
  background-color: #4D3ECC;
  padding: 14px 26px;
  box-shadow: 0px 10px 30px 0px rgba(17, 53, 112, 0.16);
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  border-radius: 40px;
  z-index: 99;
  display: flex;
  align-items: center;
}

.chat-bottom form {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 10px 20px;
  /* padding-top: 10px; */

}

.chat-bottom input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  color: #011149;
  background-color: transparent;
}

.chat-bottom input::placeholder {
  color: #D5D7DD;
  font-size: 'poppinsregular';
  font-size: 16px;
}

.chat-mid-area {
  height: 427px;
  /* height: 86%; 11-08 */
  /* min-height: 68%; */
  background-image: url("../../Icons/chatBg.webp");
  background-size: cover;
  overflow-y: scroll;
}

.chat-mid-area::-webkit-scrollbar {
  width: 4px;
  height: 320px;
}

.chat-mid-area::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px rgb(0 0 0 / 30%);
  background-color: #f1f1f1;
}

.chat-mid-area::-webkit-scrollbar-thumb {
  background-color: #525660;
  outline: #525660;
  border-radius: 10px;
}

.error-chat {
  border: 1px solid #D0342C;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  color: #D0342C;
  background-color: #FFF0EF;
  margin: 14px 14px 0px 14px;
  width: fit-content;
  animation: fadeInFromBottom 0.6s ease;

}

.chat-head-right {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
}

.chat-head-right img {
  width: 25px;
  height: 20px;
}

.chatbot-dropdown-ul {
  display: flex;
  flex-direction: column-reverse;
}


.chatbot-loader-cta {
  /* width: 60px;
    position: absolute;
    top: 353px; */
  /* padding-top: 14px; */
  background-image: url("../../Icons/chatBg.webp");
  padding-left: 14px;
  padding-bottom: 14px;
  background-size: cover;
}

.chatbot-loader-cta div {
  /* position: absolute;
    bottom: 39px; */
  width: 38px;
}

.rotate-360 {
  animation: rotate 3s linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ChatBot.css */

/* Style for the chat message container */
.chat-messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  /* justify-content: end; */
}

/* Style for the user message */
.user-message {
  background-color: #4D3ECC;
  padding: 8px 12px;
  margin: 14px 14px 0px 14px;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  animation: fadeInFromBottom 0.6s ease;
}

/* Style for the bot message */
.bot-message {
  background-color: #fff;
  padding: 8px 12px;
  margin: 14px 14px 0px 14px;
  border-radius: 8px;
  align-self: flex-end;
  font-size: 12px;
  color: #011149;
  width: fit-content;
  animation: fadeInFromBottom 0.6s ease;

}

.bot-message:first-child {
  margin: 14px 14px 0px 14px;
}

/* .bot-message:nth-child(2){
  display: none;
  animation: fadeInFromBottom 0.6s ease 5s;
} */
/* .bot-message:nth-child(3){
  display: none;
  animation: fadeInFromBottom 0.6s ease 5s;
  
} */


.usermsgdiv {
  width: 100%;
  display: flex;
  justify-content: end;
}

.usermsgdiv div {
  margin: 6px 6px 6px 6px;
  /* margin: 0px 14px; */
  margin-bottom: 0px;
}

.usermsgdiv div button {
  border: 1px solid #4D3ECC;
  border-radius: 6px;
  background-color: #fff;
  color: #4D3ECC;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 8px;
  font-size: 12px;
  animation: fadeInFromBottom 0.6s ease;
}

.botmsgdiv div {
  margin: 6px 6px 6px 6px;
  /* margin: 0px 14px; */
  margin-bottom: 0px;
}

.botmsgdiv div button {
  border: 1px solid #4D3ECC;
  border-radius: 6px;
  background-color: #4D3ECC;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 8px;
  font-size: 12px;
  animation: fadeInFromBottom 0.6s ease;
}

.botmsgdiv div button {
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
  transform: translateY(10px);
}

.botmsgdiv div button:nth-child(n) {
  animation-delay: 1.5s;
}

.box-hover {
  color: #fff !important;
  background-color: #4D3ECC !important;
}

/* .botmsgdiv div button:hover {
  background-color: #4D3ECC !important;
  color: #fff !important;
} */

.botmsgdiv div button:nth-child(odd) {
  margin-right: 0px;
  margin-left: 8px;
}

.botmsgdiv div button:nth-child(even) {
  margin-left: 8px;
  margin-top: 8px;
}

.pincode-dropdown {
  overflow: hidden;
  background: rgb(0 0 0 / 58%);
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 12%;
  display: flex;
  align-items: end;
  justify-content: end;
  }

  .pincode-dropdown ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    background-color: #FFF;
    width: 100%;
    height: fit-content;
    position: absolute;
    border-radius: 20px 20px 0px 0px;
    }

    .pincode-dropdown ul li:last-child{
      padding-top: 20px;
      position: relative;
      }
      .pincode-dropdown ul li:last-child::before {
      content: '';
      position: absolute;
      height: 4px;
      width: 40px;
      background-color: #E3E3E3;
      top: 14px;
      right: 44%;
      border-radius: 10px;
      }

.pincode-dropdown ul li {
  border: none;
  text-align: center;
  border-bottom: 1px solid #DDD;
}

.pincode-dropdown ul li button {
  border: none;
  background: none;
  text-align: center;
  padding: 0px;
  margin: 8px 0px;
  width: 100%;
}

.chat-bottom .cwo-chatbot .react-datepicker {
  position: absolute;
  top: -189px;
  width: 237px;
}

.chat-bottom .cwo-chatbot .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 158px;
  transform: rotate(179deg) !important;
  left: 46px;
}

.chat-bottom .cwo-chatbot .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -70px;
}

/* 00000....... */
/* .message-container {
  display: flex;
}

.user-message {
  align-self: flex-start;
  background-color: #cceeff;
  margin-right: auto;
}

.bot-message {
  align-self: flex-end;
  background-color: #f0f0f0;
  margin-left: auto; 
}

.message {
  margin: 5px;
}
button {
  margin-right: 5px;
}


.conversation-container {
  display: flex;
  flex-direction: column;
}

.message-container {
  display: flex;
  justify-content: flex-start;
  margin: 5px;
}

.user-message {
  background-color: #cceeff;
  border-radius: 10px;
  padding: 10px;
  margin-right: auto;
  max-width: 70%;
}

.bot-message {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  margin-left:250px;
  max-width: 70%;
}

button {
  margin-right: 5px;
} */
.chat-disburse-loan-main {
  margin: 14px 14px 0px 14px !important;
  background-color: transparent;
  padding: 0px;
}

.chat-disburse-loan-main .chat-disburse-loan-sub {
  border: 1px solid #4D3ECC;
  border-radius: 10px;
  background-color: #fff;
  padding: 6px 12px;
  margin: 0px;
  width: fit-content;
  cursor: pointer;
  text-align: left;
}

/* .chat-disburse-loan-main .chat-disburse-loan-sub:hover{
  border: 1px solid #4D3ECC;
  background-color: #4D3ECC;
  color: #fff;
} */

.botmsgdiv .chat-disburse-loan-main button:nth-child(odd) {
  margin-left: 0px;
  margin-top: 8px;
}
.botmsgdiv .chat-disburse-loan-main button:first-child{
  margin-top: 0px;
}
.botmsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub-hover {
  border: 1px solid #4D3ECC;
  background-color: #4D3ECC;
  color: #fff;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 10px;
  margin: 0px !important;
  /* margin-bottom: 6px !important; */
  font-size: 12px;
  cursor: pointer;
  text-align: left;
}
.botmsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub-hover:nth-child(even){
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}
.botmsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub-hover:nth-child(odd){
  margin-top: 8px !important;
}
.botmsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub-hover:first-child{
  margin-top: 0px !important;
}
.botmsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub-hover p {
  margin: 0px;
  font-family: 'poppinssemibold';
}

.botmsgdiv .chat-disburse-loan-sub-hover p span {
  margin: 0px;
  font-family: 'poppinsregular';
}

.botmsgdiv .chat-disburse-loan-sub-hover p:nth-child(3) span {
  color: #fff;
}

.usermsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub,
.usermsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub-hover {
  border: 1px solid #4D3ECC;
  background-color: #4D3ECC;
  color: #fff;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 10px;
  margin: 0px !important;
  font-size: 12px;
  cursor: pointer;
}

.usermsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub p,
.usermsgdiv .chat-disburse-loan-main .chat-disburse-loan-sub-hover p {
  margin: 0px;
  font-family: 'poppinssemibold';
  color: #fff;
}

.usermsgdiv .chat-disburse-loan-sub p span,
.usermsgdiv .chat-disburse-loan-sub-hover p span {
  margin: 0px;
  font-family: 'poppinsregular';
  color: #fff;
}

.usermsgdiv .chat-disburse-loan-sub p:nth-child(3) span {
  color: #fff;
}

.chat-disburse-loan-sub p {
  margin: 0px;
  font-size: 12px;
  color: #011149;
  font-family: 'poppinssemibold';
}

/* .chat-disburse-loan-sub:hover p{
  color: #fff;
} */
.chat-disburse-loan-sub p:nth-child(3) span {
  color: #0FA82E;
}

.chat-disburse-loan-sub p span {
  font-size: 12px;
  font-family: 'poppinsregular';
}

/* .chat-disburse-loan-sub:hover p span{
  color: #fff;
} */
/* Add a CSS class for the fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timer-bot .bot-message{
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
  transform: translateY(10px);
  animation-delay: 1s;
}

/* Apply the animation to the bot messages */
.bot-message {
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
  transform: translateY(10px);
}

/* Add a delay to subsequent bot messages using nth-child */
.bot-message:nth-child(2) {
  animation-delay: 0.8s;
 
}

.bot-message:nth-child(3) {
  animation-delay: 1s;

}

.bot-message:nth-child(4) {
  animation-delay: 1.5s;

}

@media screen and (max-width:480px) {
  .chat-popup-open {
    width: 100%;
    height: 87%;
    min-height: 77%;
    /* height: 90%;
    min-height: 80%; 11-08 */
    bottom: 34px;
    right: 0px;
  }

  .mw-chat-bot {
    width: 100%;
    bottom: 60px;
    right: 0px;
    height: 100%;
  }

  .close-chat-popup {
    bottom: 6px;
  }

  .chat-mid-area {
    height: 72vh;
    /* height: 82%;
    min-height: 72%; */
  }

  .chat-bot-icon {
    width: 54px;
    height: 54px;
    bottom: 60px;
  }

  .pincode-dropdown{
    height: 84%;
    bottom: 9%;
    }
}