body {
  margin: 0;
  padding: 0;
}

.App {
  position: relative;
}

span,
div,
h1,
h2,
h3,
li,
a,
p,
button {
  line-height: 140%;
}

ul.sachterm_1 {
  padding-left: 14px;
}

ul.sach {
  padding-left: 16px;
}

@font-face {
  font-family: 'mograregular';
  src: url('./Font/mogra-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsregular';
  src: url('./Font/poppins-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinssemibold';
  src: url('./Font/poppins-semibold-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsmedium';
  src: url('./Font/poppins-medium-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}

* {
  font-family: 'poppinsregular';
}