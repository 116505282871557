.blog-main-head {
  background: linear-gradient(90deg, #3427a3 0%, #4d3ecc 100%);
  margin-top: 80px;
}

.blog-main-head-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(to right, #6956ed, #ff6274);
}

.blog-main-head-2 {
  width: 80%;
  text-align: start;
  margin-left: 8%;
}

.blog-main-img {
  width: 100%;
}
.blogdescption h2{
  font-size: 20px;
  color: #3427A3;
}
.blog-main-head-2 h2 {
  font-size: 44px;
  line-height: 140%;
  color: white;
  margin-bottom: 0px;
  /* text-align: center; */
}

.blog-main-head-2 p {
  margin-top: 0px;
  font-size: 22px;
  color: white;
  line-height: 140%;
  /* text-align: center; */
}

.blog-search-new-main {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  /* border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; */
  box-shadow: 0px 8px 20px rgb(26 57 96 / 5%);
  position: sticky;
  top: 76px;
  z-index: 98;
  margin-top: -3px;
}

.slider-main-head {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* background-color: #4D3ECC 100%; */
  background: linear-gradient(90deg, #3427A3 0%, #4D3ECC 100%);
  /* background-image:url('../../../public/assets/images/Rectangle_17662.svg'); */
  /* background-size: 100%; */
  /* background-position: 100%; */
  /* background-position: right center;
  height: 420px;
  width: 100%;
  background-repeat: no-repeat; */
}

.slider-main-head-first {
  /* width: 50%; */
  padding-left: 10%;
  padding-right: 3%;
  background-image: url('../../../public/assets/images/Rectangle_17662.svg');
  background-size: 52%;
  /* background-position: 100%; */
  background-repeat: no-repeat;
  background-position: right;
  min-height: 342px;
  max-height: 580px;
}
.left-slider-blog-cont{
  padding-top: 40px;
  width: 42%;
}

/* .slider-main-head-second {
  background-image: url('../../../public/assets/images/Rectangle_17662.svg');
  background-size: 100%;
  background-position: 100%;
  background-position-y: top;
  height: 420px;
  width: 100%;
  background-repeat: no-repeat;
} */

.slider-main-head-first h3 {
  font-size: 44px;
  /* line-height: 140%; */
  margin-bottom: 0%;
  color: #ffffff;
}

.slider-main-head-first p {
  font-size: 18px;
  line-height: 140%;
  color: #ffffff;
}

.blog-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.blog-category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.blog-category p {
  font-size: 18px;
  color: #4D3ECC;
  padding: 8px 14px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  background: 0 0;
  margin: 8px 0px;
}

.blog-category p:hover {
  border: 1px solid #4D3ECC;
  background: #4D3ECC;
  color: #fff;
}

.blog-search-body {
  position: relative;
  border: 2px solid #F6F6F8;
  border-radius: 10px;
  background-color: #fff;
  /* overflow: hidden; */
}

.blog-search-div {
  min-height: fit-content;
  max-height: 400px;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  background-color: #fff;
  scrollbar-width: thin;
}

.blog-search-div-inner-main {
  padding: 0px 8px;
  border: 1px solid #D9DFFF;
  margin-bottom: 0PX;
}

.blog-search-div-inner-main p {
  line-height: 16px;
  margin-top: 6px;
}

.blog-search-div-inner-main:hover {
  cursor: pointer;
  background-color: rgb(40, 134, 216);
}

.blog-search-div::-webkit-scrollbar {
  width: 2px;
}

.blog-search-div::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); */
}

.blog-search-div::-webkit-scrollbar-thumb {
  background-color: #9A9999;
  outline: #9A9999 solid 1px;
  border-radius: 10px;
}

/* .blog-search-div p {
    border-bottom: 1px solid black;
    line-height: 16px;
    padding-bottom: 6px;
} */

/* .blog-search-div-inner-main p:hover {
   
} */

.blog-search-main input {
  border: none;
  background: none;
  font-size: 14px;
  outline: none;
  height: 40px;
  padding-left: 14px;
  padding-right: 68px;
  padding-bottom: 0px;
  /* font-weight: 600; */
}

.additional-css-blog {
  background-color: rgb(40, 134, 216);
}

.blog-search-main {
  /* border: 1px solid #c1c1c1;
    border-radius: 10px; 
    background-color: white;*/
  display: flex;
  align-items: center;
  /* flex-direction: row-reverse; */
}

.blog-search-main img {
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 0px;
  width: 24px;
  height: 24px;
}

.blog-description {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  /* margin-top: 50px; */
  gap: 60px;
}

.blog-card-main {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 20px;
  margin-top: 62px;
}

.blogimage {
  min-width: 400px;
}

.blogimage img {
  width: 400px;
  border-radius: 20px;
}

.blogtrack {
  display: flex;
  margin-top: 16px;
}

.blogtrack p {
  border-right: 1px solid #ddd;
  margin-right: 12px;
  padding-right: 16px;
  font-size: 12px;
}

.read-btn {
  display: inline;
  border: 1px solid #005df6;
  background: #f0f6ff;
  color: #005df6;
  padding: 6px 14px;
  border-radius: 4px;
  margin: 8px 0;
  cursor: pointer;
}

.blogdescption p {
  line-height: 140%;
  color: #455a64;
  margin-bottom: 24px;
}

.blogdescption a {
  text-decoration: none;
}

.active-class {
  border: 1px solid #4d3ecc !important;
  background: #4d3ecc !important;
  color: #fff !important;
}

.blog-loan {
  background-color: #baaedb;
  border-radius: 20px;
  padding: 30px 20px;
  position: relative;
  width: 352px;
  margin-left:20px;
  /* margin-right:20px ; */
  margin-top: 62px;
}

.blog-card-blog {
  width: 440px;
}

.blog-card-blog-heading {
  font-size: 20px;
  font-weight: bold;
  font-family: "poppinssemibold";
  margin-bottom: 0px;
  margin-top: 46px;
  margin-left: 20px ;
}

.blog-displaying-row{
  justify-content: space-between;
}

.main-blog-card {
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #e4e2f8;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  /* min-height: 340px;
  max-height: 340px; */
  overflow: hidden;
  margin-bottom: 130px;
  box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
  /* width: 375px; */
}

.blog-card-blog .slick-slide img {
  margin: auto;
  height: 165px;
  background-color: #f8f8fe;
  object-fit: cover;
}

/* .main-blog-card:hover {
  box-shadow: 0px 25px 50px #eaeeff;
} */

.main-blog-img {
  margin-top: 15px !important;
  width: 90% !important;
  border-radius: 10px !important;
}

.main-blog-p {
  color: #011149;
  font-size: 18px;
  line-height: 140%;
  font-weight: bold;
  margin-bottom: -7px;
  padding: 11px;
  height: 44px;
  overflow: hidden;
}

.main-blog-card button {
  margin: 20px;
  padding: 10px 14px;
  background: #f6f6f8;
  border: 1px solid #4d3ecc;
  border-radius: 10px;
  color: #4d3ecc;
  font-size: 13px;
}

.main-blog-card a {
  text-decoration: none;
  color: #4d3ecc;
}

.blog-card-blog .slick-arrow {
  position: absolute;
  top: 76%;
  transform: translate(-50%);
  cursor: pointer;
  z-index: 1;
  width: 40px;
  height: 40px;
  border: 1px solid #E4E2F8;
  border-radius: 5px;
  background-color: #fff;
  /* box-shadow: 0 0 4px 0 #adadad; */;
  color: #0d0d0d;
  transition: all 0.3s ease-out 0s;
}

.blog-card-blog .slick-arrow:hover {
  /* background-color: #4D3ECC; */
  box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
}

.blog-card-blog .slick-arrow::before {
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 99;
  position: absolute;
  top: 31%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.blog-card-blog .slick-arrow.slick-prev {
  left: 11%;
}

.blog-card-blog .slick-arrow.slick-next:hover::before {
  color: #4D3ECC;
}

.blog-card-blog .slick-arrow.slick-prev:hover::before {
  color: #4D3ECC;
  /* box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16); */
}

.blog-card-blog .slick-arrow.slick-next {
  right: 4px;
}

.blog-card-blog .slick-arrow.slick-prev::before {
  content: "\276F";
  color: #4D3ECC;
  transform: rotate(180deg);
  /* background-image: url("../../Icons/ReviewArrow.svg"); */
}

.blog-card-blog .slick-arrow.slick-next::before {
  content: "\276F";
  /* background-image: url("../../Icons/ReviewArrow.svg"); */
  color: #4D3ECC;
  position: absolute;
  left: 54%;
  top: 44%;
}
.blog-main-form-box{
  box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
}
.blog-main-form-box .rating-star-1{
  margin-top: -8px;
}
.blog-main-container-two .form-box{
  width: 400px;
  margin-left: 20px;
}
.blog-main-form-box .input-tag{
  margin-bottom: 0px;
}
.blog-main-form-box .rate:not(:checked) > label{
  position: relative;
}
.blog-main-form-box .rate:not(:checked) > label:before{
  position: absolute;
  top: 8px;
}
.subscriber-list {
  background-color: #fef6f4;
  border-radius: 30px;
  width: 320px;
  padding: 25px;
  margin-top: -35px;
}

.subs-p {
  font-size: 16px;
  line-height: 140%;
  color: #f85532;
  margin-bottom: 4px;
  margin-top: 0px;
  font-family: "poppinsmedium";
}

.subs-p-1 {
  color: #011149;
  font-size: 24px;
  line-height: 140%;
  margin-top: 0px;
  margin-bottom: 4px;
  font-weight: bold;
}

.subscriber-list span {
  color: #011149;
  font-size: 12px;
  line-height: 140%;
  margin-top: 10px;
}

.subs-subs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.subs-subs input {
  border: none;
  outline: none;
}

.subs-subs button {
  border: none;
  background-color: #0fa82e;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
}

/* pagination css  start */
.pagination-main {
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.pagination .pagi-btn {
  border: 1px solid #fff0;
  background-color: #fff0;
  margin-left: 4px;
  margin-right: 4px;
  padding: 6px 12px;
  transition: background-color 0.3s;
  cursor: pointer;
  float: left;
}

.pagination .pagi-btn:hover {
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 10px;
  /* background-color: #f85532; */
  background-color: #4D3ECC;
  color: #fff;
  /* border: 1px solid #f85532; */
  border: 1px solid #4D3ECC;
}

.active-pagination {
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 10px;
  /* background-color: #f85532; */
  background-color: #4D3ECC;
  color: #fff;
  /* border: 1px solid #f85532; */
  border: 1px solid #4D3ECC;
  padding: 6px 12px;
}

.feed-error {
  color: red;
  position: absolute;
  left: 50%;
  font-size: 12px;
  margin-top: 0px;
}

.Blog-main-slider {
  display: flex;
  position: relative;
  background: linear-gradient(to right, #6956ed, #ff6274);
  background-size: cover;
  padding-left: 10%;
  padding-top: 80px;
  max-height: 600px;
  min-height: 300px;
  overflow: hidden;
}

.Blog-main-slider .left-top {
  position: absolute;
  top: 0;
  color: #fff;
}
.blog-main-container-one{
  position: sticky;
  top: 0;
}
.Blog-main-slider .left-top a,
.Blog-main-slider .left-top span {
  font-family: 'poppinsmedium';
  color: #fff;
}

.sliderImage {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 46%;
}

.sliderMain {
  margin-top: 60px;
}

.sliderMain .slick-arrow {
  position: absolute;
  top: 86%;
  transform: translate(-50%);
  cursor: pointer;
  z-index: 9;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4d3ecc;
  box-shadow: 0 0 4px 0 #adadad;
  color: #0d0d0d;
  transition: all 0.3s ease-out 0s;
  ;
}

.sliderMain .slick-arrow::before {
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 99;
  position: absolute;
  top: 31%;
  left: 30%;
}

.sliderMain .slick-arrow.slick-prev::before {
  content: "\276F";
  color: #fff;
  transform: rotate(180deg);
  opacity: 1;
}

.sliderMain .slick-arrow.slick-next::before {
  content: "\276F";
  /* background-image: url(../../Icons/ReviewArrow.svg); */
  color: #fff;
  position: absolute;
  left: 32%;
  top: 20%;
  opacity: 1;
}

.sliderMain .slick-prev {
  left: 82%;
}

.sliderMain .slick-next {
  right: 12%;
}

.sliderContainer h2 {
  font-size: 26px;
  color: white;
  line-height: 140%;
  padding-right: 66px;
}

.sliderContainer p {
  font-size: 14px;
  color: white;
  line-height: 140%;
}

.sliderContainer a {
  border: 1px solid #fff;
  background: 0 0;
  color: #fff;
  padding: 6px 14px;
  display: inline-block;
  border-radius: 4px;
  margin: 20px 0;
  text-decoration: none;
}

.sliderContainer a:hover {
  background: #fff;
  color: #18375f;
  transition: all ease .5s;
}

.sliderImage img {
  background-size: cover;
  min-height: 378px;
  margin-right: 0px;
}

.BlogLeft {
  position: absolute;
  top: 320px;
  right: 160px;
  z-index: 99;
}

.BlogLeft:active {
  transform: scale(.8);
  transition: all ease-in-out .5s;
}

.BlogRightArr {
  position: absolute;
  top: 320px;
  z-index: 99;
  right: 100px;
}

.BlogRightArr:active {
  transform: scale(.8);
  transition: all ease-in-out .5s;
}
.pagination-main #prev-btn{
  background-color: #F6F6F8;
  border: 1px solid #8A91A1;
  border-radius: 8px;
}
.pagination-main #prev-btn .pagi-btn:hover {
  background-color: #f6f6f800;
  border: 1px solid #8a91a100;
  border-radius: 8px;
  color: #000;
}
.pagination-main #next-btn{
  background-color: #4D3ECC;
  border: 1px solid #4D3ECC;
  color: #fff;
  border-radius: 8px;
}

/* pagination css  end */

/* blog inner card css start */


@media screen and (max-width:1400px) and (min-width:1220px) {
  .sliderContainerLeft {
    width: 48%;
  }

  .blog-description {
    gap: 32px;
  }

  .blogdescption h2 {
    font-size: 20px;
  }

  .blogdescption p {
    font-size: 12px;
  }
  
}

@media only screen and (max-width:1400px) {
  .blog-main-head-2 {
    width: 65%;
  }

  .sliderMain .slick-prev {
    left: 78%;
  }
  .slider-main-head-first{
    min-height: 308px;
  }
}

@media only screen and (max-width: 1270px) {
  .blog-main-head-2 {
    width: 48%;
  }
}

@media only screen and (max-width: 1150px) {
  .blog-main-container-one{
    position: inherit;
  }
  .blog-main-head-1 {
    background: url(../../Icons/BlogMobile.svg) no-repeat;
    background-position: right top;
  }

  .blog-main-img {
    width: 0px;
  }

  .blog-main-head-2 {
    width: 95%;
  }

  .blog-card-main {
    display: grid;
  }
}

@media screen and (max-width:998px) {
  .blog-search-new-main {
    top: 62px;
    padding: 20px 0px;
    height: 80px;
  }
  .slider-main-head{
    background: linear-gradient(90deg, #fff 0%, #fff 100%);
  }
  .slider-main-head-first{
      background-image: url('../../../public/assets/images/blog/blog-mob-banner.svg');
      background-size: cover;
  }
  .left-slider-blog-cont{
    width: 98%;
  }
  .blog-main-form-box{
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .blog-main-form-box .input-tag textarea{
    margin-top: 20px;
  }
  
  .blog-description{
    gap: 40px;
  }
  .blog-main-container-two .form-box{
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 900px) {
  .blog-loan {
    width: 30vw;
  }

  .blog-card-blog {
    width: 35vw;
  }

  .subscriber-list {
    width: 30vw;
  }
}

@media only screen and (max-width: 800px) {
  .blog-description {
    display: grid;
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  .blog-card-blog {
    position: absolute;
    top: 1%;
  }

  .sliderMain .slick-prev {
    left: 76%;
  }

  .blog-card-blog-header-main {
    position: absolute;
    top: 5%;
  }

  .blog-display-main {
    margin-top: 450px;
  }

  .blog-loan {
    width: 50vw;
  }

  .subscriber-list {
    width: 50vw;
  }

  .blog-search {
    width: 90%;
    flex-direction: column;
    margin-bottom: 10px;
    top: 60px;
    display:unset;
  }

  .blog-category {
    flex-wrap: wrap;
  }

  .blog-main-head-2 h2 {
    font-size: 24px;
  }

  .blog-main-head-2 p {
    font-size: 12px;
  }

  .main-blog-card {
    margin: 10px;
    margin-bottom: 130px;
  }

  .blog-card-blog {
    width: 85vw;
  }

  .pagination-main {
    flex-wrap: wrap;
  }

  .blog-card-blog .slick-arrow.slick-next {
    right: 0px;
  }
  .blog-main-container-one{
    display: flex;
    flex-direction: column-reverse;
  }

}

@media only screen and (max-width:750px) {
  .blog-loan {
    margin-top: 0px;

  }

  /* .blog-card-blog {
    position: absolute;
    top: 9%;
  } */
  .Blog-main-slider {
    flex-direction: column;
    position: relative;
  }

  .sliderImage {
    left: 203px;
  }

  .sliderImage img {
    display: none;
    width: 50%;
    min-height: 100px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .sliderContainer {
    position: relative;
    z-index: 1;
  }

  .BlogLeft {
    top: 235px;
    transition: all ease 1s;

  }

  .BlogRightArr {
    top: 235px;
    transition: all ease 1s;

  }
  .blog-display-main{
    width: 100%;
    margin: 0 auto;
    margin-top: 460px;
  }
  .blog-card-main-mobile-vw{
    width: 90%;
    margin: 0 auto;
  }
  .blog-card-main{
    /* width: 100%; */
    /* margin: 0 auto; */
    background: #FFFFFF;
    box-shadow: 0px 9.8421px 19.6842px rgba(109, 87, 223, 0.2);
    border-radius:20px;
    padding: 20px;
    margin-top: 40px;
  }
  .blogimage{
    width: 100%;
    min-width: 0;
    margin: 0 auto;
  }
  .blogdescption{
    width: 100%;
    margin: 0 auto;
  }
  .blogimage img{
    width: 100%;
    margin: 0 auto;
  }
  .slider-main-head-first{
    padding: 0px;
  }
}

@media screen and (max-width:694px) {
  .sliderMain .slick-prev {
    left: 68%;
  }

  .sliderMain .slick-arrow {
    top: 82%;
  }
  .slider-main-head-first h3{
    font-size: 28px;
  }
  .slider-main-head-first p{
    font-size: 16px;
    margin-top: 6px;
  }
  .slider-main-head-first{
    background-size: cover;
    border-radius: 0px 0px 0px 100px;
  }
}

@media screen and (max-width:460px) {
  .blog-search-scroll{
    overflow: scroll;
  }
  .blog-search-scroll::-webkit-scrollbar{
    display: none;
  }
  .blog-category {
    column-gap: 20px;
    width: 400px;
    margin-bottom: 10px;
  }

  .blog-card-blog .slick-arrow {
    top: 78%
  }

  .blog-card-blog .slick-arrow.slick-prev {
    left: 76%;
  }
}

@media only screen and (max-width: 450px) {
  .blog-card-blog {
    width: 94vw;
    margin: 0 auto;
    left: 17px;
  }
  .blog-search-main img{
    margin-left: 0px;
  }
  .blog-search-main input{
    padding-left: 0px;
  }

  /* .blog-display-main {
    width: 77vw;
  }

  .blogimage {
    min-width: 300px;
  }

  .blogimage img {
    width: 350px;
  } */

  .blogdescption a {
    font-size: 18px;
  }

  .blogdescption p {
    font-size: 14px;
  }

  .read-btn a {
    font-size: 14px;
  }

  .blogdescption p {
    font-size: 12px;
    color: #8a91a1;
  }

  .blog-loan {
    width: 80vw;
    margin-top: 0px;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .subscriber-list {
    width: 70vw;
  }

  .blog-card-blog-heading {
    font-size: 18px;
    margin-top: 0px;
  }

  .blog-main-head-1 {
    padding: 20px;
  }

  .BlogLeft {
    top: 290px;
    right: 100px;
    transition: all ease 1s;

  }

  .BlogRightArr {
    top: 290px;
    right: 50px;
    transition: all ease 1s;
  }
}

@media screen and (max-width:414px) {
  /* .blog-category {
    column-gap: 0px;
  } */

  .blog-card-blog .slick-arrow.slick-prev {
    left: 10%;
  }
  .left-slider-blog-cont{
    padding-top: 6px;
  }
  .slider-main-head-first{
    min-height: 252px;
    padding-right:4%;
    padding-left: 7%;
  }
  .blog-search-main{
    justify-content: space-evenly;
    flex-direction: inherit;
  }
  .blog-category p{
    font-size: 16px;
  }
}

@media screen and (max-width:396px) {
  .sliderMain .slick-prev {
    left: 64%;
  }
  
}