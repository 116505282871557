.journey-part {
    margin-top: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 104px);
    position: relative;
    background: url('../../../Icons/bg-shape.svg') no-repeat;
    background-position: right top;
    position: relative;
}
.journey-part-mobile{
    margin-top:0px;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 104px);
    position: relative;
    background: url('../../../Icons/bg-shape.svg') no-repeat;
    background-position: right top;
    position: relative;
}
.journey-content {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
}

.left-side {
    width: 45%;
}

.journey-heading {
    margin: 0;
    font-family: 'poppinssemibold';
    font-size: 36px;
    line-height: 54px;
    color: #011149;
    position: relative;
}

.journey-heading::after{
    content: '';
    position: absolute;
    width: 110px;
    height: 5px;
    background-color: #E8572F;
    bottom: -10px;
    left: 0;
}

.journey-con {
    font-family: 'poppinsmedium';
    margin: 40px 0;
    font-size: 18px;
    line-height: 140%;
    color: #011149;

}
.left-img{
    width: 100%;
    position: relative;
}
.left-img img{
    width: 65%;
}
@media screen and (max-width:1366px) {
    .journey-heading{
        font-size: 32px;
        line-height: 48px;
    }
    .journey-con{
        margin: 30px 0;
    }
}
@media screen and (max-width:890px) {
    .journey-part{
        margin-top: 56px;
        min-height: calc(99vh - (150px ));
        background-image: none;
    }
    .journey-part::before{
        content: "";
        position: absolute;
        background: url('../../../Icons/bg-shape.svg');
        width: 100%;
        height: 100%;
        z-index: -1;

    }
    .journey-content{
        flex-direction: column;
        width: 90%;
    }
    .left-side{
        display: none;
    }
    .right-side{
        width: 100%;
        margin-top: 20px;
    }
}