.react-datepicker__header{
    background-color: #fff !important;
    border-bottom: 0px !important;
    padding-bottom: 5px;
}
.react-datepicker__header select{
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border: 0px solid;
    background-color: #F6F6F8;
    padding: 5px;
    height: auto;
    width: 50%;
    border-radius: 4px;
}
.react-datepicker__header select option{
    background-color: #626263;
    color: #fff;
    cursor: pointer;
}
.react-datepicker__header select option:hover{
    background-color: #578CDF;
}
.react-datepicker__header select option{
    padding: 10px;
}
.react-datepicker__day-names{
    font-family: 'poppinsmedium';
    font-size: 14px;
    font-weight: 600;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    margin: 1px 3px;
    padding: 1px 3px;
}
.react-datepicker-popper{
    z-index: 100;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: #301bc0;
    color: #fff;
}

.react-datepicker{
    background-color: #fff;
    box-shadow: 0px 0px 15px 5px rgb(1 17 73 / 10%);
    border-radius: 20px;
    border: 0px solid;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select){
    border-radius: 20px;
}
.react-datepicker__month-container{
    padding: 10px;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: #fff;
    color:#000;
}
.react-datepicker__day:hover{
    background-color: #e4e2f8;
    color: #000;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border-bottom-color: #fff;
}
.react-datepicker-popper[data-placement^=bottom]{
    padding-top: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before{
    border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{

}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border-bottom-color:#fff;
}
.react-datepicker-popper[data-placement^=top]{
    margin-bottom: 0px !important;
}
.react-datepicker__month{
    margin: 0 0.4rem;
}