.disclaimer-box {
    width: 80%;
    margin: 0 auto;
    margin-top: 130px;
}

.disclaimer-box .privacyapp-bg {
    width: 94%;
}

.sachmain {
    margin: auto;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 20px;
    font-family: 'poppinsregular';
    color: #455a64;
    word-break: break-word;
}

.marriage-loan-a {
    font-size: 16px;
    color: #1592E6;
    text-decoration: none;
    font-family: 'poppinssemibold';
}

@media screen and (max-width:500px) {
    .marriage-loan-a {
        font-size: 11px;
    }

    .disclaimer-box {
        width: 90%;
    }

    .disclaimer-box .privacyapp-bg {
        width: 84%;
    }

    .disclaimer-box {
        margin-top: 80px;
    }

    .disclaimer-box .sachhead {
        font-size: 22px;
    }
}