
.main-mob-slider-div{
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
}
/* .mob-all-main-heading{
    width: 80%;
    margin: 0 auto;
} */
/* .mob-work-slider-div{
    width: 90%;
    margin: 0 auto;
} */
/* .mob-work-slider-main{
    width: 90%;
    margin: 0 auto;
} */
/* .mob-work-slider-div img{
    width: 100%;
} */
.slider-inner-div-mob{
}
.mob-all-main-heading{
  text-align: center;
}
.mob-all-main-heading span{
    font-size: 22px ;
}
.mob-all-main-heading p{
    font-size: 14px;
    line-height: 140%;
}
/* .slider-inner-div-mob .slick-slide img{
    width: 100%;
} */
.mob-work-slider-div p{
    color: #4D3ECC;
    font-size: 18px;
    font-family: 'poppinssemibold';
    margin-left: 12px;
    line-height: 140%;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 70%;
}
/* .mob-work-slider-div p:nth-child(2){
  margin-top: 20px;
} */
.mob-count-head{
  display: flex;
  gap: 10px;
  margin-left: 16px;
  margin-bottom: 20px;
  margin-top: 10px;
  justify-content: center;
  width: 100%;
  line-height: 140%;
}
.mob-count-head span {
  /* background-color: #4D3ECC; */
  /* border-radius: 50%; */
  color: #fff;
  font-family: 'poppinssemibold';
  /* height: 24px;
  width: 36px; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  font-size: 16px;
}
/* .mob-work-slider-div p:nth-child(1)::after{
  bottom: 10px;
}
.mob-work-slider-div p:nth-child(2)::after{
  bottom: -2px;
}
.mob-work-slider-div p:nth-child(3)::after{
  bottom: 10px;
}
.mob-work-slider-div p:nth-child(4):after{
  bottom: 10px;
} */
.mob-work-slider-div p::after {
    content: "";
    background-color: #4d3ecc;
    border-radius: 50%;
    bottom: 10px;
    height: 32px;
    left: -42px;
    position: absolute;
    right: 0;
    width: 32px;
}



.mob-work-slider-div img{
    width: 300px;
    /* box-shadow:  0px 80px 80px rgba(202, 204, 222, 0.5); */
}
.slider-inner-div-mob .slick-arrow {
    position: absolute;
    /* top: -27px; */
    transform: translate(-50%);
    cursor: pointer;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #E4E2F8;
    color: #0d0d0d;
    transition: all 0.3s ease-out 0s;
  }
  
  .slider-inner-div-mob .slick-arrow:hover {
     box-shadow: 0 0 4px 0 #adadad;
  }
  
  .slider-inner-div-mob .slick-arrow::before {
    width: 18px;
    height: 18px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 99;
    position: absolute;
    top: 31%;
    left: 30%;
    transform: translate(-50%, -50%);
  }
  
  .slider-inner-div-mob .slick-arrow.slick-prev {
    left: 6%;
  }
  
  /* .slider-inner-div-mob .slick-arrow.slick-next:hover::before {
    color: #ffffff;
  } */
  
  .slider-inner-div-mob.slick-arrow.slick-prev:hover::before {
    color: #fff;
  }
  
  .slider-inner-div-mob .slick-arrow.slick-next {
    right: -22px;
  }
  
  .slider-inner-div-mob .slick-arrow.slick-prev::before {
    content: "\276F";
    color: #4D3ECC;
    transform: rotate(180deg);
    /* background-image: url("../../Icons/ReviewArrow.svg"); */
  }
  
  .slider-inner-div-mob .slick-arrow.slick-next::before {
    content: "\276F";
    /* background-image: url("../../Icons/ReviewArrow.svg"); */
    color: #4D3ECC;
    position: absolute;
    left: 54%;
    top: 44%;
  }