.rating-star-1{
    border-radius: 10px;
    display: flex;
    margin-top: -20px;
}
.rating-star-1 span{
	color: #1C1939;
	font-size: 15px;
}
/* .givestar-rating{
	position: absolute;
    top: 26px;
    right: 37px;
} */
.rate:not(:checked) > input {
    position:absolute;
    visibility: hidden;
}
.rate:not(:checked) > label {
    float: right;
    width: 40px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 27px;
    color: #fff;
    background: #ddd;
    border: 1px solid #fff;
    height: 40px;
}
.rate:not(:checked) > label:before {
    content: '\2605';
	margin-left: 7px;
}
.rate > input:checked ~ label {
    color: #ffffff;
    background: #4ad721;
	border: 1px solid #fff;  
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #ffffff;
    background: #4ad721;
	border: 1px solid #fff;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #ffffff;
    background: #4ad721;
	border: 1px solid #fff;
}