.pancardpopup {
    margin: 12% auto;
    max-width: 424px;
    /* padding:5px; */
    border-radius: 35px;
    background-color: #fff;
}

.pancardpopup h2 {
    text-align: center;
    color: #0FA82E;
    line-height: 36px;
    font-size: 24px;
    margin: 0;
    font-family: 'poppinssemibold';
}

.pancarddata {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 6px 0px;
    margin-left: 15px;
}

.pancarddata .pan-name {
    margin: auto 0;
    color: #525660;
    font-size: 16px;
}

.pancardcontent {
    margin: 30px;
    padding: 30px 0;
}
.pan-content{
    display: flex;
    align-items: center;
    font-size: 16px;
    background: none;
    color: #011149;
    line-height: 30px;
    font-family: 'poppinsmedium';
}
.pan-calender{
    position: absolute;
    background-color: #fff;
    z-index: 1;
    right: 0;
    bottom:0;
}
.pancard-button {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.pancard-button button{
    font-family: 'poppinssemibold';
}
.pancard-button .contbuton {
    height: 52px;
    width: 100%;
    font-size: 18px;
    color: #8A91A1;
    background-color: #D5D7DD;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}
.contbuton.activate-ctn-btn{    
    color: #fff;
    background-color: #0FA82E;
}

.pancard-button .modifybuton {
    width: 100%;
    font-size: 18px;
    color: #fff;
    background-color: #525660;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}

.remove-block {
    display: none;
}
.drop-option-box {
    position: absolute;
    top:105%;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 5px;
    box-shadow: 0 0 6px 0 #c5c5c5;
}
.drop-option{
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
}
.drop-option:hover{
    background-color: #D5D7DD;
    font-family: 'poppinsmedium';
}
.pan-input{
    margin:0;
    width:90%;
    cursor: pointer;
    position: relative;
}

@media screen and (max-width:545px) {
    .pancardpopup {
        width: 90%;
        border-radius: 25px;
    }

    .pancard-button {
        margin-top: 34px;
    }
    .pancardcontent{
    margin: 20px;
    padding: 20px 0;   
    }
}

@media screen and (max-width:382px) {
    .pancardpopup h2 {
        font-size: 20px;
        line-height: 30px;
    }

    .pancarddata .pan-name {
        font-size: 14px;
    }

    .pancarddata .pan-content {
        font-size: 16px;
    }
}