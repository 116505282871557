.loginform {
    width: 100%;
    position: relative;
    top: 64px;
    /* margin-bottom: 90px; */
    background-image: url('../../../Icons/abstract_shapenew.svg');
    background-position:right;
    background-repeat: no-repeat;
    background-position-y: top;
    background-size: cover;
    width: 100%;
    height: 420px;
}


.displaying-row {
    display: flex;
    justify-content: space-between;
}

.manage-loginform {
    margin: 0 10%;
}

.left-content-login-form {
    padding: 3.5% 0;
    width: 64%;
    margin-top: 0px;
}


.left-top {
    font-size: 14px;
    color: #fff;
    padding: 2% 0;
    padding-top: 40px;
    padding-bottom: 0px;
    line-height: 140%;
}

.left-top a {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
}
.left-top a,.left-top span{
    font-family: 'poppinsmedium';
    color: #fff;
}

.pl-heading {
    font-family: 'poppinssemibold';
    font-size: 36px;
    line-height: 140%;
    color: #fff;
}

.words {
    font-family: 'poppinssemibold';
    margin-top: 3%;
    margin-bottom: 7%;
    /* overflow: hidden; */
    height: 31px;
    position: relative;
}

.words span {
    color:#FFC035;
    font-family: 'poppinssemibold';
    font-size:28px;
    position: absolute;
    opacity: 0;  
    animation: animating 18s linear infinite 0s;
}
.words span:nth-child(2){
    animation-delay: 3s;
}

.words span:nth-child(3){
    animation-delay: 6s;
}

.words span:nth-child(4){
    animation-delay: 9s;
}


.words span:nth-child(5){
    animation-delay: 12s;
}


.words span:nth-child(6){
    animation-delay: 15s;
}

@keyframes animating {
    0%{
        opacity: 0;
      }
      2%{
        opacity: 0;
        transform: translateY(-30px);
      }
      5%{
        opacity: 1;
        transform: translateY(0px);
      }
      17%{
        opacity: 1;
        transform: translateY(0px);
      }
      
      20%{
        opacity: 0;
        transform: translateY(30px);
      }
      98%{
        opacity: 0;
      }
      100%{
        opacity: 0;
      }
}

@keyframes move {
    0%{
        transform:translateY(-400%);
        /* opacity:1; */
    }
    20%{
        transform:translateY(-400%);
        /* opacity:0.5; */
    }

    24% {
        transform: translateY(-298%);
        /* opacity:0.5;         */
    }
    44% {
        transform: translateY(-298%);
    }

    48% {
        transform: translateY(-198%);
    }
    68% {
        transform: translateY(-198%);
    }

    72%{
        transform:translateY(-98%)
    }
    92%{
        transform:translateY(-98%)
    }
    96%{
        transform:translateY(0%)
    }
    100% {
        transform: translateY(0%);
    }
}

.get-app {
    background-color: #0FA82E;
    border: 0px;
    border-radius: 10px;
    outline: none;
    font-family: 'poppinsmedium';
    font-size: 16px;
    color: #fff;
    padding: 14px 28px;
    cursor: pointer;
    text-decoration: none;
}

.right-content-login-form {
    width: 36%;
    position: relative;

}

.log-form {
    padding: 20px;
    position: absolute;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
    left: 0px;
}

.login-form-head {
    font-family: 'poppinssemibold';
    font-size: 20px;
    color: #011149;
}

.form-subHead {
    font-size: 14px;
    color: #525660;
    margin: 8px 0;
    white-space: nowrap;
}
.form-subHead.logoni{
    margin-top: 10px;
}
@media screen and (max-width:1920px) and (min-width:1713px) {
    .loginform{
        background-size: cover;
        min-height: 490px;
        max-height: 748px;
    }
    .left-top{
        font-size: 16px;
    }
    .left-top a{
        font-size: 16px;
    }
    .left-content-login-form{
        width: 62%;
    }
}
@media screen and (max-width:1713px) and (min-width:1400px) {
    .loginform{
        background-size: cover;
        min-height: 440px;
        max-height: 690px;
    }
}
@media screen and (max-width:1400px) and (min-width:1220px){
    .left-content-login-form{
        width: 62%;
    }
}
@media screen and (max-width:1000px) {
    .displaying-row {
        display: grid;
        grid-template-columns: 1fr;
    }

    .left-content-login-form {
        width: 100%;
        text-align: center;
        position: relative;
    }

    .right-content-login-form {
        width: 100%;
        top: 50%;
    }

    .pl-heading,
    .words {
        text-align: center;
        /* color: #fff; */
    }

    .pl-heading {
        font-size: 22px;
         color: #fff;
    }

    .words span{
        font-size: 20px;
        white-space: nowrap;
        display: flex;
        width:100%;
        justify-content: center;
    }

    .login-form-head {
        font-size: 18px;
        white-space: normal;
    }
    .form-subHead .logoni{
        font-size: 12px;
    }

    .form-subHead {
        font-size: 14px;
        white-space: normal;
    }

    .log-form {
        width: 90%;
        padding: 20px;
        box-shadow: 0px 10px 15px #1135701A;
    }

    .get-app {
        display: none;
    }
    .form-subHead.logoni{
        margin-top: 12px;
        font-size: 12px;
    }
}
@media screen and (max-width:890px) {
    .loginform{
        top: 45px;
        background-size: cover;
        /* height: 330px; */
    }
    .manage-loginform{
        margin: 0 6%;
    }
    .left-top{
        font-size: 12px;
        color: #fff;
        text-align: left;
        padding-top: 30px;
    }
    .left-top a{
        font-size: 12px;
        color: #fff;
    }
}
@media screen and (max-width:414px) {
    .left-content-login-form{
        margin-top: 14px;
    }
    .words{
        margin-top:7% ;
    }
    .loginform{
        background-image: url('../../../Icons/abstractmobile-bg.svg');
        top: 62px;
        
    }
}
@media screen and (max-width:380px) {
    .words span{
        font-size:18px;
    }
    .loginform{
        top: 62px;
    }
}