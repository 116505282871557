.cont{
    position: relative;
} 
.main-loader-lottie{ 
    margin: auto;  
    height:100px;
}
.loader-text-main{
    color: #fff;
    font-size: 20px;
    text-align: center; 
    margin:auto;
    line-height: 140%;
    font-family: 'poppinsmedium';
} 