.partner-section {
    background-image: url('../../Icons/partner-img.webp');
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: top;
    background-color: #f9fafe;
}

.partner-boxing .left-top {
    position: absolute;
    left: 10%;
    top: 0;
    font-family: 'poppinsmedium';
    color: #015EF7;
}
.partner-boxing .left-top a,.partner-boxing .left-top span{    
    font-family: 'poppinsmedium';
    color: #015EF7;
}

.partner-banner {
    position: relative;
    margin-top: 64px;
}

.partner-boxing {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.LContent {
    margin-top: 70px;
    width: 80%;
}

.section-heading {
    font-size: 36px;
    color: #011149;
    font-family: 'poppinssemibold';
}

.LContent span {
    font-size: 36px;
    color: #6d57df;
    font-family: 'poppinssemibold';
}

.LContent p {
    line-height: 22px;
    font-size: 20px;
    color: #011149;
    font-family: 'poppinsmedium';
    margin-top: 30px;
    line-height: 140%;
}

.lending-partner {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
}

.lending-content {
    width: 80%;
    margin-top: 30px;
    position: relative;
}

.partners-boxes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.partners-boxes img {
    width: 85%;
}

.partner-box {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 8px 20px #1a39600d;
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 20px 8px;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    gap: 20px;
}

.partner-box button {
    text-decoration: none;
    color: #015ef7;
    font-family: 'poppinsmedium';
    cursor: pointer;
    outline: none;
    border: 0px solid;
    font-size: 18px;
    background: none;
}

.sourcing-boxes {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-top: 30px;

}

.hidden-cls {
    height: 850px;
    overflow: hidden;
}

.sourcing-box {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 20px #1a39600d;
    padding: 15px 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    cursor: pointer;
}

.sourcing-boxes:hover .sourcing-box {
    opacity: 0.25;
}

.sourcing-box:hover {
    opacity: 1 !important;
}

.sourcing-box span {
    color: #4d3ecc;
    font-size: 16px;
    font-family: 'poppinsmedium';
    position: relative;
    line-height: 120%;
}

.sourcing-box span::after {
    height: 0.2em;
    border-radius: 10px;
    width: 100px;
    content: '';
    background-color: #4d3ecc;
    position: absolute;
    bottom: -10px;
    right: 0;
    left: 0;

}

.sourcing-box p {
    line-height: 22px;
    color: #455a64;
}

.sourcing-box div {
    line-height: 22px;
    color: #455a64;
}

.more-open-btn {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    height: 120px;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    backdrop-filter: blur(2.5px);
    z-index: 10;
}

.more-open-btn button {
    background-color: #4d3ecc;
    border-radius: 10px;
    color: #fff;
    font-family: 'poppinsmedium';
    font-size: 16px;
    padding: 14px 28px;
    cursor: pointer;
    outline: none;
    border: 0px solid;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .4s;
}
.more-open-btn button:hover{    
    transform: scale(1.1);
}

.blur-background {
    position: fixed;
    background: rgb(0 0 0 / 58%);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 0;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.partner-d {
    padding: 30px;
    width: min(534px, 80%);
    background-color: #fff;
    box-shadow: 0px 0px 9.15094px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    z-index: 999;
}

.partner-top-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100px;
    margin-bottom: 40px;
}

.partner-top-content img:nth-child(1) {
    max-width: 270px;
}

.partner-top-content img:nth-child(2) {
    cursor: pointer;
    max-width: 25px;
}

.pdetail-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.pdetail-box p,
.pdetail-box-address p,
.partner-top-content>div>p {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.pdetail-box-address p span:nth-child(1),
.pdetail-box p span:nth-child(1) {
    color: #525660;
    font-size: 14px;
    line-height: 22px;
}

.manageflex {
    display: flex;
    flex-direction: column;
}

.pdetail-box-address p span:nth-child(2),
.pdetail-box p span:nth-child(2) {
    color: #011149;
    font-size: 16px;
    font-family: 'poppinsmedium' !important;
    line-height: 22px;
}

.p-btns {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.p-btns a {
    font-size: 16px;
    color: #015ef7;
    border-radius: 8px;
    border: 1px solid #4d3ecc;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 20px;
    background-color: #fff;
    text-decoration: none;
}

.p-btns a:hover {
    background-color: #e4e2f8;
    border-color: #e4e2f8;
}

@media (max-width:900px) {
    .partner-banner {
        margin-top: 56px;
    }

    .sourcing-boxes {
        grid-template-columns: repeat(2, 1fr);
    }

    .partners-boxes {
        grid-template-columns: repeat(2, 1fr);
    }

    .partners-boxes {
        row-gap: 30px;
    }
}

@media (max-width:500px) {
    .partner-boxing .left-top {
        left: 5%;
    }
    .partner-section .LContent p br {
        display: none;
    }

    .partners-boxes img {
        width: 76%;
    }

    .hidden-cls {
        height: 920px;
    }

    .more-open-btn {
        bottom: 0;
    }

    .sourcing-boxes {
        grid-template-columns: auto;
    }

    .partners-boxes {
        grid-template-columns: auto;
    }

    .section-heading,
    .LContent span {
        font-size: 22px;
    }

    .LContent p {
        font-size: 18px;
    }

    .LContent {
        width: 90%;
    }

    .partner-section {
        background-image: url('../../Icons/mob-img.webp');
    }

    .lending-content {
        width: 90%;
    }

    .sourcing-box {
        height: auto;
    }

    .pdetail-box {
        grid-template-columns: auto;
    }

    .pdetail-box-address p span:nth-child(1),
    .pdetail-box p span:nth-child(1) {
        font-size: 14px;
    }

    .pdetail-box-address p span:nth-child(2),
    .pdetail-box p span:nth-child(2) {
        font-size: 16px;
        line-height: 120%;
    }


    .pdetail-box p,
    .pdetail-box-address p {
        gap: 0;
    }

    .pdetail-box p,
    .pdetail-box-address p {
        margin: 10px 0;
    }

    .partner-d {
        padding: 15px 20px 20px 20px;
    }

    .more-open-btn button {
        font-size: 14px;
        padding: 14px 28px;
    }
}

@media only screen and (max-width:480px) {
    .lending-partner .slick-arrow {
        top: 100%;
    }
}