.right-side-popUp {
    position: fixed;
    top: 50%;
    right:0;
    display: flex;
    z-index: 99;
    white-space: nowrap;
    width:0px;
    transition: 0.5s;
}

.close-loan-option {
    position: relative;
    background-color: #000;
}

.get-loan {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #F46632;
    box-shadow: -5px 0px 10px #F4663236;
    border-radius: 0px 0px 15px 15px;
    position: absolute;
    cursor: pointer;
    width: max-content;
    top: 40%;
    left: -62px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'poppinssemibold';
    padding: 8px 12px;
    z-index: 100;
}

.open-get-loan {
    background: transparent linear-gradient(270deg, #89ACF4 0%, #3B77E1 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 30px #0000004D;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    padding: 30px 20px;
    position: relative;
    z-index: 99;
    width: 320px;
    transition: 10s;
}

.open-get-loan-heading {
    font-size: 18px;
    font-family: 'poppinssemibold';
    color: #fff;
    margin-bottom: 15px;
}

.left-content-1 ul {
    padding-left: 15px;
    color: #fff;
    line-height: 140%;
}

.open-get-loan-btn a {
    text-decoration: none;
    font-size: 14px;
    font-family: 'poppinsmedium';
    background-color: #F46632;
    border-radius: 8px;
    padding: 3%;
    color: #fff;
    border: 2px solid #fff;
}

.open-loan-option {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #F46632;
    box-shadow: -5px 0px 10px #F4663236;
    border-radius: 0px 0px 15px 15px;
    cursor: pointer;
    width: max-content;
    color: #fff;
    font-family: 'poppinssemibold';
    text-transform: uppercase;
    padding: 10px 12px;
    z-index: 9;
    transition: filter 1ms;
}

.right-side-popUp-open {
    position: fixed;
    top: 60%;
    right: -40px;
    z-index: 99999;
}
.open-loan-option:hover{
    filter:brightness(0.7);
}

.arrow-btn {
    border: solid #525660;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 8px;
    border-right: solid #fff;
    border-bottom: solid #fff;
}

.right-arr {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: -2px;
}

.left-arr {
    transform: rotate(45deg);
    margin-bottom: 2px;
    -webkit-transform: rotate(45deg);
}
.right-popUp-image img{
    width:100px;
    margin-top:5%;
}
.get-loan-displaying-row{
    display:flex;
}

@media screen and (max-width:500px) {
    .loaderbackground-right {
        position: fixed;
        background: rgb(0 0 0 / 58%);
        width: 100%;
        height: 100%;
        z-index: 999;
        left: 0;
        top: 0;
        backdrop-filter: blur(2px);
    }

    .loadertransparent-right {
        background: 0 0;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-radius: 20px;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .open-get-loan{
        max-width: 280px;
        height: auto;
        right:0;
    }
    .get-loan{
        right:-32px;
    }
    .open-get-loan-heading{
        font-size:16px;
    }
    .right-popUp-image img{
        width:55px;
        margin-top: 50%;
    }
}