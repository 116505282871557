.blur-background {
    position: fixed;
    background: rgb(0 0 0 / 58%);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 0;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop-content {
    background-color: #fff;
    /* padding: 40px 25px; */
    box-shadow: 0px 0px 9.15094px rgba(0, 0, 0, 0.16);
    border-radius: 18px;
    width: 434px;
    z-index: 999;
}

.login-area-popup {
    animation: comes 0.4s linear 1 0s;
}

.login-area-login-err {
    color: #D0342C;
    font-size: 12px;
    position: absolute;
    bottom: -18px;
    left: 0;
}

@keyframes comes {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.err-head {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}

.err-heading {
    font-family: 'poppinssemibold';
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #011149;
}

.err-content {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #525660;
}

.refresh {
    width: 100%;
    color: #fff;
    background-color: #525660;
    height: 56px;
    font-size: 16px;
    font-family: 'poppinssemibold';
    border: 0px solid;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
}

.capcha-section-new{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;   
}
.capcha-new{
    display: flex;
    gap: 30px;
}
.capcha-new img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.canvas-new{
    width: 140px;
    height:60px;
    background-color: #F6F6F8;
    letter-spacing: 6px;
    line-height: 140%;
}

.main-popUP-form {
    margin: 30px;
}

.main-popUP-form .content {
    margin-top: 20px;
    margin-bottom: 40px;
}

.main-popUP-form .content p {
    font-size: 18px;
    font-family: 'poppinsmedium';
    text-align: center;
    color: #525660;
    line-height: 140%;
    margin: 0;
}

.ckyc-loader {
    height: 150px;
    width: 150px;
    margin: 0 auto;
}

.logo-img {
    display: flex;
    justify-content: center;
}

.popup-form-head {
    margin-top: 30px;
    position: relative;
    text-align: center;
}

.popup-form-head span {
    font-weight: 600;
    font-size: 21.9623px;
    line-height: 33px;
    color: #000;
}

.popup-form-head span::after {
    position: absolute;
    content: "";
    width: 75px;
    height: 4px;
    background: #4D3ECC;
    bottom: -45%;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 4px;
}

.popUp-input-tag {
    border-bottom: 2px inset;
    display: flex;
    /* border:1px solid black; */
    padding-left: 2px;
}

.make-payment-form .popUp-input-tag {
    margin: 24px 0;
}

.popUp-red-error-border {
    border-bottom: 2px solid #ff0000;
    position: relative;
}

.popUp-add-border {
    border-bottom: 2px solid #6D57DF;
}

.popUp-input-tag img {
    margin-right: 21px;
    margin-left: 5px;
}

.popUp-input-tag input {
    width: 100%;
    /* min-width: 350px; */
    border: 0px solid;
    outline: none;
    font-weight: 600;
    font-size: 18.3019px;
    line-height: 27px;
    color: #525660;
    letter-spacing: 0.14rem;
}

.popUp-input-tag input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: #8A91A1;
    letter-spacing: 0;
}

.popUp-checkbox {
    display: flex;
    align-items: baseline;
    margin-top: 40px;
    margin-bottom: 10px;
}

.main {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    color: #525660;
}

.popUp-checkbox input[type=checkbox] {
    visibility: hidden;
    height: 1px;
}

.geekmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #D5D7DD;
    border-radius: 4px;
}

.main input:checked~.geekmark {
    background-color: #0FA82E;
}

.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

.main input:checked~.geekmark:after {
    display: block;
}

.main .geekmark:after {
    left: 5px;
    bottom: 4px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.popUp-btn button,
.popUp-otp-btn button {
    width: 100%;
    text-align: center;
    background-color: #D5D7DD;
    border: 0px solid;
    outline: none;
    padding: 15px 0;
    color: #8A91A1;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    border-radius: 10px;
    cursor: pointer;
    height: 56px;
    position: relative;
}

.popUp-btn.active-btn button,
.popUp-otp-btn.active button {
    background-color: #0FA82E;
    color: #fff;
}

.popUp-lnk {
    color: #015EF7;
    text-decoration: none;
}

.change-opt {
    text-align: center;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #011149;
}

.change-opt span {
    color: #6D57DF;
    cursor: pointer;
}

.resend-opt {
    text-align: center;
    margin-top: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #011149;
}

.resend-opt span {
    color: #6D57DF;
    font-size: 14px;
    font-family: 'poppinsmedium';
    cursor: pointer;
}

.popUp-otp-boxes {
    margin: 0 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.popUp-otp-boxes input {
    width: 52.52px;
    height: 52.13px;
    background: #FFF;
    box-shadow: 0px 4px 2px rgba(64, 64, 64, 0.2);
    border-radius: 10px;
    border: 0px solid;
    outline: none;
    font-weight: 600;
    font-size: 24.2728px;
    line-height: 36px;
    text-align: center;
    color: #011149;
}

.popUp-otp-boxes.err input {
    box-shadow: 0px 4px 2px rgba(168, 15, 15, 0.6);
}

.popUp-invalid {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #D0342C;
    margin-bottom: 20px;
}

.capcha-section {
    margin: 20px 30px;
    display: flex;
    justify-content: space-between;
}

canvas {
    height: 50px;
    width: 150px;
    border-radius: 10px;
    position: relative;
}

.capcha-code-box a {
    position: absolute;
    right: 10px;
    font-size: 25px;
    top: -10px;
    color: #6D57DF !important;
}

.capcha-code-box {
    margin-top: 5px;
}

.capcha-code-box input {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.2em;
    color: #000000;
    background-color: #F6F6F8;
    border-radius: 10px;
    border: 0px solid;
    outline: none;
    padding: 14px;
    width: 90px;
    text-align: center;
    margin-top: 2px;
    font-family: 'mograregular';
}

.capcha-enter-box input {
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    border: 0px solid;
    border-bottom: 3px solid #6D57DF;
    outline: none;
    padding: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #011149;
    width: 90px;
    margin-top: 2px;
}

.capcha-box {
    position: relative;
}

.capcha-box span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #525660;
    display: flex;
    justify-content: space-between;
}

.capcha-box span img {
    transition: transform 0.5s;
    cursor: pointer;
}

.capcha-box span img.clicked {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.master-popUp-content {
    background-color: #fff;
    padding: 25px 30px;
    border-radius: 20px;
    max-width: 350px;
}

.master-popUp-heading {
    color: #0FA82E;
    font-size: 22px;
    font-family: 'poppinssemibold';
    text-align: center;
    line-height: 30px;
}

.master-popUp-options {
    margin-top: 30px;
}

.master-content {
    text-align: center;
    font-size: 16px;
    color: #011149;
    padding: 20px 0;
    cursor: pointer;
    text-transform: capitalize;
}

.master-content:hover {
    background-color: #F6F6F8;
}

@media (max-width:700px) {
    .popUp-checkbox {
        margin-top: 30px;
    }
}

@media (max-width:1566px) {
    .capcha-code-box a {
        font-weight: 600;
        font-size: 20px;
        top: 0;
    }
}

@media screen and (max-width:500px) {
    .pop-content {
        width: 90%
    }

    .capcha-code-box a {
        top: -10px;
        font-weight: 900;
        font-size: 24px;
        font-family: 'poppinssemibold';
    }

    .popUp-otp-boxes {
        margin-left: 5px;
        margin-right: 5px;
    }

    .popUp-btn button {
        padding: 15px 0;
    }


    .master-popUp-content {
        max-width: 80%;
        padding: 25px;
    }

    .master-popUp-heading {
        font-size: 20px;
        line-height: 26px;
    }

    .master-content {
        padding: 15px 0;
        font-size: 14px;
    }

    .err-heading {
        font-size: 20px;
    }

    .err {
        font-size: 16px;
    }

    .refresh {
        font-size: 18px;
    }
}