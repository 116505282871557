.sdk{
    margin-top: 80px;  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.vkyc-class{
    width: 100%;
    height: 700px;
}
.sdk-mobile{
    margin-top: 40px;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.sdk-boxing{
    width: 80%;
}
.sdk-pre-box{
    padding: 0 30px;
    border: 1px solid #1ed308;
    background-color: #e6ffe6;
    border-radius: 6px;
}
.sdk-pre-box p{
    font-size:14px;
    line-height: 22px;
    color:#455a64;
}
.sdk-box{
    width: 66%;
    margin-top: 60px;
}
.remain-down{
    min-height: 85vh;
}
#aa-client-frame{
    width:685px;
    overflow: hidden;
    height: 100%;
}
.load-loading{
    width:100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:700px) {
    #aa-client-frame{
        width: 100%;
    }
}