.eligibale-area{
    width: 80%;
    margin: 0 auto;
}
.benefits-content,.GetInstantLoanIm,.FAQ{
    /* width: 95vw; */
    margin: 0 auto;
}
.you-video{
    width:550px;
    height: 300px;
}
.pl-gap{
    gap: 140px;
}
.you-tube{
    display:flex;
    justify-content:center;
}
#IPL-section{
    width: 90%;
    margin: 0 auto;
    margin-top: 6%;
    
}
@media screen and (max-width:720px) {
    #IPL-section{   
        margin-left: auto;
        margin-top: 60%;
    }
    .pl-gap{
        gap: 10px;
    }
    .eligibale-area{
        width: 90%;
    }
}
@media screen and (max-width:500px) {
    /* .eligibility-criteria-box{
        padding: 15px;
    } */
}