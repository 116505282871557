

@font-face {
    font-family: 'poppinsregular';
    src: url('../../Font/poppins-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}



.footer-main {
    background: #011149 !important;
    /* extra width while using vw */
    width: 100%;
    font-size: 11px;
    color: #fff;
}

.footer-container {
    width: 78%;
    margin: auto;
    padding-top: 4%;
    /* padding-left: 2%; */
}

.footer-row {
    display: flex;
    justify-content: space-between;
}

.footer-box {
    padding: 0 2%;
}

.footer-box span {
    font-size: 20px;
    color: #fff;
    font-family:"poppinssemibold";
}

.footer-p {
    font-size: 12px;
    line-height: 20px;
}

.footer-box ul {
    list-style: none;
    padding: 0;
}

.footer-box ul li {
    line-height: 15px;
    margin-bottom: 14px;
    font-size: 12px;
}

.footer-box ul li a {
    text-decoration: none;
    color: #fff;
}

.footer-box ul li a:hover {
    text-decoration: underline;
}

.footer-address {
    width: 30%;
}

.copy-right {
    width: 100%;
    background-color: #12235f;
    padding: 10px 0;
}

.copyright-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    padding: 1px 0;
}

.copyright-container {
    width: 100%;
}

.col-1 {
    width: 80%;
}

.col-2 img {
    width: 40px;
    height: 26px;
}

.copy-right span {

    font-size: 10px;
    display: inline-block;
    margin-left: 6px;
    line-height: 14px;
}

.link-li li:hover {
    text-decoration: underline;
}

.form-footer-main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #12235F;
    color: white;
    margin-top: 1.5%;
    font-size: 12px;
}

.form-footer-main span {
    font-size: 10px;
    display: inline-block;
    margin-left: 10px;
    line-height: 14px;
}

.form-footer-main img {
    width: 40px;
    height: 26px;
    display: inline;
}

.google-play {
    margin-bottom: 20px;
    margin-top: 15px;
}

.social-media {
    margin-bottom: 20px;
}

.social-media a {
    display: inline-block;
    background: url('../../Icons/social-media.webp') no-repeat;
    height: 35px;
    width: 35px;
    margin-right: 2px;
}

.social-media a.facebook {
    background-position: 0 0;
}

.social-media a.twitter {
    background-position: -42px 0;
}

.social-media a.linkedin {
    background-position: -84px 0;
}

.social-media a.instagram {
    background-position: -126px 0;
}

.social-media a.youtube {
    background-position: -168px 0;
}

@media screen and (max-width:720px) {
    .footer-row {
        flex-wrap: wrap;
        margin: auto;
    }

    .footer-address,
    .footer-box {
        width: 100%;
        text-align: center;
    }

    .footer-box ul li {
        display: inline;
        padding: 6px;
        line-height: 30px;
    }

    .footer-box {
        border-bottom: 1px solid #e7ecff;
    }

    .copyright-row {
        margin: auto;
    }

    .col-1,
    .col-2 {
        margin: 10px 0;
        width: 100%;
        text-align: center;
    }

    .col-1 {
        border-bottom: 1px solid #3657c5;
    }
}
@media screen and (max-width:500px) {
    .footer-container{
        width: 90%;
    }
}