.typesOfLoan,
.eduaction-loan,
.home-loan,
.travel-loan,
.wedding-loan,
.pension-loan{
    margin: 50px auto;
    width:92%;
}

.heading-h1 {
    color: #2917a4;
}
.heading-h{
    color: #2917a4;
    font-size: 26px;
    line-height: 30px;
}

.heading-h3 {
    color: #2917a4;
}

.send-lnk {
    color: #1592e6;
    text-decoration: none;
}

b {
    font-weight: bold;
}
@media screen and (max-width:500px) {
    .heading-h {
        font-size: 20px;
    }
}