.ploan-container {
    /* width: 95%; */
    margin: 0 auto;
    padding: 0 5px;

}

.pl-cont-new {
    background-color: #F9FAFE;
}

.ploan-content1 {
    width: 80%;
    margin: auto;
    text-align: center;
}

.manage-top-margin {
    margin-top: 60px;
}

#about-pl-after-form {
    /* margin-top: 55px; */
}

.ploan-content2 {
    width: 85%;
}

.heading-h2 {
    font-size: 36px;
    line-height: 26px;
    color: #011149;
    margin-bottom: 0px;
}

.heading-p {
    line-height: 22px;
    color: #455a64;
}

.benefits-content,
.about-content {
    padding: 1% 0;
}

.about-content {
    margin-top: 100px;
    ;
}

.about-content .heading-h2 {
    margin: 0;
}

.about-content .heading-p {
    margin-top: 10px;
}

.ploan-row {
    display: flex;
    justify-content: space-between;
}

.web-margin-top {
    margin: 30px auto;
    margin-top: 120px;
}

.box-1 {
    width: 15%;
    margin-top: 30px;
    text-align: center;
    padding: 0 3%;
}

.box-sh {
    width: 80%;
    margin: auto;
}

.add-btom-line {
    position: relative;
}

.add-btom-line::before {
    content: '';
    background-color: #4d3ecc;
    height: 6px;
    position: absolute;
    bottom: -16px;
    right: 0;
    left: 0;
    border-radius: 10px;
    width: 100px;
    z-index: 1;
}

.eligibility-criteria-box {
    padding-top: 20px !important;
    /* background: #fff; */
    /* padding: 20px 30px; */
    /* border-radius: 12px;
    border: 1px solid #e5f2ff;
    box-shadow: 1px 1px 4px #ddd; */
}

.eligibility-criteria-box .underline-heading {
    position: relative;
}

.eligibility-criteria-box .underline-heading:first-child::before {
    content: '';
    background-color: #4d3ecc;
    height: 6px;
    position: absolute;
    bottom: -16px;
    right: 0;
    left: 0;
    border-radius: 10px;
    width: 100px;
}

.ploan-table {
    width: 100%;
    border-collapse: collapse;
    color: #455a64;
    table-layout: fixed;
}

.ploan-table td {
    font-size: 14px;
    height: 45px;
    padding: 2px 10px;
    border: 1px solid #bdbdbd;
}

.colm-1 img {
    width: 400px;
    height: 320px;
}

.list-check {
    list-style: none;
    padding: 0;
}

.list-check li {
    margin-bottom: 60px;
    line-height: 22px;
    color: #455a64;
    font-size: 20px;
}

/* .list-check li::before {
    content: "\2713";
    background: #21bf26;
    margin-right: 14px;
    float: left;
    margin-top: -2px;
    color: #fff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
} */

b {
    font-weight: bold;
}

.ploan-APR {
    margin-bottom: 40px;
}

.ploan-APR {
    margin-top: 0px;
}
.plpage.mlc-pl-cost-main{
    margin-top: 30px;
}

.mlc-pl-cost-main {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
}

.mlc-pl-head h2 {
    color: #000;
    font-size: 24px;
    font-family: 'poppinsmedium';
}

.mlc-pl-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.mlc-pl-mrgin-top {
    margin-top: 30px;
}

.mlc-pl-list-cont {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
}

.mlc-pl-list-cont span {
    font-family: 'poppinsregular';
    font-size: 16px;
    color: #272727;
    line-height: 140%;
    margin-top: 4px;
}

.wd-font span {
    font-size: 16px;

}

.apr-table {
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
}

.apr-table td {
    border: 1px solid #c4d3e0;
    width: 50%;
    padding: 10px 10px;
}

.apr-list li {
    line-height: 26px;
    list-style: none;
}

.apr-list li::before {
    content: "\2022";
    color: #eb5f32;
    margin-left: -30px;
    font-weight: bold;
    padding-right: 10px;
}

/* -----------------------------Without Document CSS--------------------------------- */
.what-is,.what-is-extra {
    /* margin-top: 70px; */
    /* margin-bottom:40px; */
    line-height: 22px;
}

.salary-slip-KYC-documents,
.tips,
.benefits-without-doc,
.faqs-without-docs {
    margin: 50px 0;
}
.benefits-without-doc .heading-h2{
    padding-bottom: 26px;
}
.benefits-without-doc .eligibility-criteria-box .underline-heading:first-child::before{
    bottom: 24px;
}

.salary-slip-KYC-documents {
    margin-top: 0px;
    margin-bottom: 0px;
}

.b-it {
    font-weight: 600;
}

.re-sh-box {
    width: 80%;
    margin: auto;
}

.martop-faq {
    margin: 80px 0;
}

/* @media screen and (max-width:20px) and (min-width) {
    
} */
/* @media screen and (min-width:1900px) {
    #about-pl-after-form{
        margin-top: -4%;
    }  
    .web-margin-top{
        margin-top: -4%;
    }
} */
@media screen and (max-width:1000px) {
    #about-pl-after-form {
        margin-top: 42%;
    }

    .colm-1 img {
        width: 280px;
    }

    .colm-2 {
        margin-right: -50px;
    }

    .what-is,.what-is-extra {
        margin-top: 70px;
    }

    .mlc-pl-right {
        display: none;
    }

    .manage-top-margin {
        margin-top: 40px;
    }
}

@media screen and (max-width:720px) {
    .mob-margin-top {
        margin-top: 60%;
        margin-bottom: 20px;
    }

    .heading-p {
        font-size: 12px;
    }

    .heading-h2 {
        font-size: 20px;
    }

    .re-sh-box {
        width: 80%;
    }

    .ploan-row {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
    }

    .box-1 {
        width: 100%;
        margin-top: 15px;
    }

    .ploan-row-criteria {
        display: flex;
        flex-direction: column;
    }

    .ploan-table td {
        font-size: 10px;
    }

    .colm-1 {
        width: 100%;
        margin: auto;
    }

    .colm-2 {
        margin: 0 0;
        width: 100%;
    }

    /* .colm-2 img {
        width: 60vw;
    } */

    .ploan-container {
        padding: 0;
    }

    .apr-list li {
        line-height: 140%;
    }

    .apr-list li::before {
        content: "\2022";
        color: #eb5f32;
        font-weight: bold;
        padding-right: 10px;
    }

    .apr-table td {
        padding: 4px;
        width: auto;
        font-size: 10px;
    }

    .eligibility-criteria-box .underline-heading:first-child::before {
        /* bottom: 6px; */
        width: 60px;
        bottom: -10px;
    }

    .mlc-pl-list {
        gap: 20px;
    }

    .about-content .heading-p {
        margin-top: 6px;
    }

    .heading-p {
        line-height: 140%;
    }

}

@media (max-width:450px) {
    .ol-list{
        margin-bottom: -4px;
    }
.benefits-without-doc .eligibility-criteria-box .underline-heading:first-child::before{
    bottom: 14px;
}
    .re-sh-box{
        width: 90%;
    }
    .add-btom-line::before {
        bottom: -10px;
    }

    .eligibility-criteria-box {
        padding-top: 0px !important;
    }

    .heading-h2 {
        font-size: 18px;
    }

    #about-pl-after-form {
        margin-top: 240px;
    }

    .ploan-content1 {
        width: 90%;
        padding-bottom: 20px;
    }

    .mlc-pl-list-cont span {
        font-size: 14px;
        line-height: 140%;
    }
}

@media (max-width:430px) {
    .web-margin-top{
        margin: 30px auto;
    }
    #about-pl-after-form {
        margin-top: 250px;
    }

    .what-is {
        margin-top: 220px !important;
    }
    .what-is-extra {
        margin-top: 280px !important;
    }
    .marr.what-is-extra{
        margin-top: 240px !important;
    }
    .top-pad{
        padding-top: 20px !important;
    }
}

