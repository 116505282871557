#review-cust-star {
  display: flex;
  gap: 6px;
}

.review-slider-main {
  background-color: #f8f8fe;
  margin: 30px 0;
}

#review-card {
  width: 80%;
  margin: auto;
  margin-top: 0px;
  padding-bottom: 4px;
}

.review-card .slick-slider img {
  height: 20px;
  background-color: transparent;
}


.review-card .slick-arrow {
  position: absolute;
  top: 42%;
  transform: translate(-50%);
  cursor: pointer;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #fff;
  /* box-shadow: 0px 0px 20px 0px rgb(4 7 47 / 10%); */
  color: #0d0d0d;
  transition: all 0.3s ease-out 0s;
  border: 1px solid #E4E2F8;
  /* right: -8%; */
}

.review-card .slick-arrow:hover {
  border: none;
  box-shadow: 0px 0px 20px 0px rgb(4 7 47 / 10%);
  transition: none;

}

.review-card .slick-arrow.slick-prev::before {
  content: "\276F";
  position: absolute;
  background-image: none;
  color: #E4E2F8;
  transform: rotate(180deg);
  top: 15px;
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}

.review-card .slick-arrow.slick-next:hover::before {
  content: "\276F";
  background-image: none;
  transform: rotate(0deg);
  color: #fff;
  position: absolute;
  top: 14px;
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}

.review-card .slick-arrow.slick-next::before {
  content: "\276F";
  position: absolute;
  background-image: none;
  color: #E4E2F8;
  transform: rotate(0deg);
  top: 14px;
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}

.review-card .slick-arrow.slick-prev:hover::before {
  content: "\276F";
  background-image: none;
  transform: rotate(180deg);
  color: #fff;
  position: absolute;
  /* top: 18px; */
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}

.review-first {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.review-buuton-all {
  background-color: #0fa82e;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  line-height: 140%;
  transition: transform .4s;
}

.review-buuton-all:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.review-button-div {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding-bottom: 30px;
}

.review-first p {
  font-size: 26px;
  font-weight: bold;
  line-height: 140%;
}

.reviewcard {
  background-color: #ffffff;
  border: 1px solid #d9dfff;
  border-radius: 20px;
  margin: 10px;
  border: none;
  margin-bottom: 60px;
}

.review-card .blogcard:hover {
  box-shadow: 0px 25px 50px #EAEEFF;
}

.review-card .blogcard {
  min-height: 178px;
  max-height: 178px;
  margin: 50px 10px;
}

.review-card .blogcard:hover {
  border: 1px solid #d9dfff00;
}

.reviewcard:hover {
  box-shadow: 0 8px 20px #4d3ecc26;

}

.review-h2 {
  font-size: 30px;
  font-family: 'poppinssemibold';
  line-height: 140%;
  color: #011149;
  margin: 0;
  margin-top: 30px;
}

.review-first img {
  margin-top: 20px;
}

.review-f-n {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
}

.review-f-n-1 {
  background-color: #717be1;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-f-n-1 span {
  color: white;
  font-size: 30px;
  font-weight: 600;
}

.review-f-n-2 {
  display: flex;
  justify-content: space-between;
  margin: 5% 0%;
  align-items: center;

}

.review-name span {
  font-size: 18px;
  color: #011149;
  font-family: poppinsmedium !important;
}

.review-name {
  margin-top: 0%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.review-name p {
  margin: 0px;
  font-size: 14px;
  color: #8a91a1;
}

.review-feedback p {
  font-size: 14px;
  color: #011149;
  font-weight: 100;
  height: 78px;
  overflow-y: auto;
  line-height: 140%;
  padding: 0px 2px;
}

.review-feedback p::-webkit-scrollbar {
  width: 2px;
}

.review-feedback p::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgb(0 0 0 / 30%); */
}

.review-feedback p::-webkit-scrollbar-thumb {
  background-color: #4d3ecc;
  outline: #4d3ecc solid 1px;
  border-radius: 10px;
}

.review-date p {
  margin: 0px;
  font-size: 14px;
  color: #8a91a1;
}

.review-feedback p::-webkit-scrollbar-thumb {
  background-color: #4d3ecc;
  outline: #4d3ecc solid 1px;
  border-radius: 10px;
}

.review-feedback p::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}

.slick-active.slick-current,.slick-active{
  outline: 0px solid;
  border:0px solid;
}
.slick-active.slick-current + .slick-slide .blogcard,
.slick-active.slick-current + .slick-slide .faq-slide-card
.slick-active.slick-current + .slick-slide .main-blog-card
.slick-active.slick-current + .slick-slide .career-slider-card
.slick-active.slick-current + .slick-slide .partner-slider-card{
  box-shadow: 0px 25px 50px #EAEEFF;
  border: 0px solid;  
  outline: none;
}

@media only screen and (max-width: 750px) {
  .review-first img {
    display: none;
  }
}

@media only screen and (max-width:480px) {
  .slick-active.slick-current + .slick-slide .blogcard,
.slick-active.slick-current + .slick-slide .faq-slide-card
.slick-active.slick-current + .slick-slide .main-blog-card
.slick-active.slick-current + .slick-slide .career-slider-card
.slick-active.slick-current + .slick-slide .partner-slider-card{
  box-shadow:none;
  border: 0px solid;  
  outline: none;
}

  .slick-active.slick-current .slick-slide .blogcard,
.slick-active.slick-current .slick-slide .faq-slide-card
.slick-active.slick-current .slick-slide .main-blog-card
.slick-active.slick-current .slick-slide .career-slider-card
.slick-active.slick-current .slick-slide .partner-slider-card{
  box-shadow: 0px 25px 50px #EAEEFF;
  -webit-box-shadow: 0px 25px 50px #EAEEFF;
  border: 0px solid;  
  outline: none;
  z-index: 1;
}
  .review-card .blogcard {
    margin: 20px 0;
  }

  .review-card .mw-announce-arrow-desscription {
    position: absolute;
    top: 106%;
    left: 4%;
    cursor: pointer;
  }

  .review-card .mw-announce-arrow-des {
    position: absolute;
    top: 104%;
    right: 3%;
    cursor: pointer;
  }

  .review-button-div {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 30px;
  }

  #review-card {
    width: 90%;
  }

  .review-card .slick-arrow {
    width: 48px;
    height: 48px;
    top: 108%;
  }

  .review-card .slick-arrow.slick-next::before {
    top: 26%;
    left: 3%;
  }

  /* .review-card .slick-arrow.slick-next:hover::before {
  top: 20%;
  left: 15%;
}

.review-card .slick-arrow.slick-prev:hover::before {
  top: 21%;
} */

  .review-card .slick-arrow.slick-next {
    right: -4%;
  }

  .review-card .slick-arrow.slick-prev {
    left: 8%;
  }
}

@media only screen and (max-width: 550px) {
  .review-h2 {
    font-size: 22px;
  }

  .review-first img {
    margin-top: 11px;
    height: 25px;
  }

  .review-first p {
    font-size: 18px;
  }

  /* .review-card .slick-arrow {
    height: 25px;
    width: 25px;
  }

  .review-card .slick-arrow.slick-next::before {
    top: 16%;
  }

  .review-card .slick-arrow.slick-next {
    right: -35px;
  } */
}