.text-slide-bg{
    background-color: #6D57DF;
    padding: 14px 0px;
    width: 100%; 
}

.text-slides{
    /* border: 1px solid; */
    /* width: fit-content !important; */
    /* margin: 0 100px; */
}
.text-slides{
    color: #fff;
    font-size: 16px;
    position: relative;
    /* padding: 0px 20px; */
    transition: transform 0.5s linear;
    display: flex !important;
    align-items: center;
    gap: 5px;
    margin-right:20px;
}
.text-slides.slick-active {
    transform: scale(1.1);
  }
/* .text-slides::after {
    content: "";
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    left: -12px;
    top: 8px;
} */
.text-slides span:first-child{
    line-height: 140%;
    margin: 0px;
    font-size: 24px;
}
/* .text-slider{
    display: flex;
    gap: 20px;
} */
/* .text-slider .slick-slide{
    width: 380px !important;
} */
@media screen and (max-width:990px) {
    .text-slides span{
        font-size: 14px;
    }
    .text-slides::after{
        top: 5px;
    }
}
@media screen and (max-width:420px){
    .text-slides span{
        font-size: 12px;
    }
    .text-slide-bg{
        padding: 4px 0px;
        margin-bottom: 80px;
    }
}

  
