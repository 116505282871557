.form-box {
    border-radius: 25px;
    height: auto;
    width: 440px;
    background-color: #fff;
    margin-top: -10px;
    box-shadow: 15px 15px 20px rgba(1, 17, 73, 10%);
    z-index: 10;
    /* border: 1px solid black; */
}

.form-body {
    margin: 5% 10%;
}

.pointer-cur-one {
    color: #8A91A1;
}

.form-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8%;
    /* margin-bottom: 20px; */
}

.form-sub-head {
    color: #011149;
    font-size: 14px;
    margin-top: 1%;
    line-height: 18px;
    font-family: 'poppinsmedium';
}

.Left-head {
    display: flex;
    align-items: center;
}

.Left-head img {
    margin-right: 10px;
}

.heading-2 {
    font-size: 24px;
    font-family: 'poppinssemibold';
    font-weight: 600;
    color: #011149;
    line-height: 140%;
}

.icons-img {
    display: flex;
}

.icons-img img {
    margin: 0 10px;
    cursor: pointer;
}

.tag-for-input {
    margin-top: 12%;
}

.input-tag {
    margin: 8% 0;
    display: flex;
    width: 100%;
    /* height: 30px; */
    justify-content: center;
    align-items: center;
    border-bottom: 1px inset;
    position: relative;
    /* padding-bottom: 4px; */
}




.input-tag input,
.input-tag-dob {
    border: 0 solid;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    /* margin-left: 3%; */
    font-family: 'poppinsmedium';
    text-align: left;
    /* padding-bottom: 3px; */
    /* height: 25px; */
    /* margin-top: 5px; */
    /* border: 1px solid black; */
}

.input-tag-dob {
    background: none;
}

.input-tag textarea {
    border: none;
    outline: none;
    width: 100%;
    font-size: 18px;
    line-height: 120%;
    /* margin-left: 3%; */
    font-family: 'poppinsmedium';
    /* padding-bottom: 3px; */
    /* height: 25px; */
    /* margin-top: 5px; */
    /* border: 1px solid black; */
}

.input-tag img {
    margin-right: 10px;
}

.rupee-symbol {
    width: 12px;
    margin-right: 5px !important;
}

.input-tag input::placeholder {
    font-size: 16px;
    font-family: 'poppinsregular';
}

.input-tag textarea::placeholder {
    font-size: 16px;
    font-family: 'poppinsregular';
}

/* .input-tag-show{
    padding-bottom: 3px;
} */
.this-is-calender {
    position: absolute;
    width: 93%;
    /* left:0; */
    top: 35px;
    background-color: #fff;
    box-shadow: 0px 0px 15px 5px rgb(1, 17, 73, 10%);
    border-radius: 20px;
    padding: 13px;
    z-index: 1;
}

.this-has-calendar {
    position: relative;
}

.input-tag input:focus,
.input-tag select:focus {
    outline: none;
    color: #32343A;
}

.add-border {
    border-bottom: 1px solid #6D57DF;
}

.small-the-icon {
    width: 20px;
    height: 20px;
}

.error-border {
    border-bottom: 1px solid #D0342C !important;
}

.error-border.pan-case {
    border-bottom-color: #4D3ECC !important;
}

.err-text {
    color: #D0342C !important;
    font-size: 11px;
    margin-top: 3px;
    margin-bottom: -10px;
    /* font-family:'poppinsmedium'; */
}

.err-text.pan-text {
    color: #4D3ECC !important;
}

/* .error-img-show {
    padding-bottom: 5px;
} */
button {
    outline: none;
}

.footer-btn {
    margin: 4% 0 8% 0;
    position: relative;
    width: 100%;
    height: 56px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-family: 'poppinssemibold';
    cursor: pointer;
    color: #7D85A1;
    background-color: #D5D7DD;
    /* margin-bottom: 10%; */
}

.continue-lottie {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ctn-loader {
    height: 56px;
}

.footer-btn.Tmargin {
    margin-top: 50px;
}

.Bmargin {
    margin-bottom: 25px;
}

.PadBottom {
    padding-bottom: 30px;
}

.manage-top {
    margin-top: 4%;
}

.form-sub-heading {
    font-size: 18px;
    font-family: 'poppinssemibold';
    color: #011149;
}

.pointer-cur {
    cursor: pointer;
}

.img-rotateing {
    transform: rotate(180deg);
}

.Ind-rupee {
    margin-bottom: 2px;
    margin-right: 2px
}

#back-arr {
    cursor: pointer;
}

.footer-btn-activate {
    background-color: #0FA82E;
    color: #fff;
    outline: none;
}

.calendar-clas {
    width: 70% !important;
    background: #32343A;
}

.proof-div {
    margin-bottom: 7%;
    margin-top: -3%;
}

.proof-border {
    border: 2px dashed #D5D7DD;
    border-radius: 5px;
}

.proof-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.proof-row label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 4px 15px;
}

#proof-row-id {
    margin: 1% 4%;
}

.specific-width-height {
    width: 30px;
    height: 30px;
}

.proof-row-p {
    font-size: 16px;
    color: #011149;
    font-weight: 400;
    margin: 3% 0;
}

.proof-row-content-photo {
    font-size: 14px;
    color: #455A64;
}

.proof-row a {
    /* width: 30px;
    height: 30px;
    border-radius: 50%; */
    text-align: center;
    font-size: 12px;
    /* background-color: #015EF7; */
    border-radius: 6px;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

#upload-photo,
#upload-the-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.front-remove,
.back-remove {
    width: 90px;
    height: 30px;
    border-radius: 6px;
    background-color: #525660;
    color: #fff;
    border: none;
    font-size: 14px;
}

.proof-row-error {
    font-size: 16px;
    color: #ff0000;
}

.without-input p {
    font-size: 16px;
    color: #525660;
    margin: 2px 0;
    line-height: 25px;
}

.without-input span {
    color: #011149;
    font-family: 'poppinsmedium';
    font-size: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.entire-reader {
    position: relative;
    height: 470px;
    overflow: hidden;
    border: 1px solid #D5D7DD;
}

.pdf-reader {
    border: 1px solid #D5D7DD;
    /* height: 420px; */
    /* overflow: auto; */
    position: relative;
    margin-top: 30px;
}

#pdf-viewer-user {
    height: 76vh;
    width: 76%;
    padding: 30px;
    overflow-y: auto;
    background-color: #fff;
}

.loan-agreement-open {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
    top: 12px;
}

.loan-agreement-open>div {
    height: 80vh;
    width: 100%;
    padding: 15px;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.loan-agree-back {
    width: 80%;
}

.loan-agree-back span {
    display: flex;
    align-items: center;
    font-size: 22px;
    gap: 10px;
    cursor: pointer;
}

.loan-btn {
    width: 356px;
    height: 60px;
    margin: 10px 0;
}

@media (max-width:450px) {
    #pdf-viewer-user {
        overflow-x: hidden;
        padding: 10px;
        width: 92%;
    }

    .loan-agreement-open>div {
        height: 86vh;
    }

    .loan-btn {
        width: 90%;
    }
}

#pdf-viewer-user canvas {
    width: 98% !important;
    height: auto;
}


#pdf-viewer-user div {
    width: 100%;
}

#pdf-viewer-user table {
    width: 100%;
}

.not-for-displaying {
    display: none;
}

.download-size {
    width: 780px;
}

#pdf-viewer-user::-webkit-scrollbar,
.dropDownBox::-webkit-scrollbar {
    height: 0px;
    width: 4px;
}

/* Track */
#pdf-viewer-user::-webkit-scrollbar-track,
.dropDownBox::-webkit-scrollbar-track {
    background: #D5D7DD;
}

/* Handle */
#pdf-viewer-user::-webkit-scrollbar-thumb,
.dropDownBox::-webkit-scrollbar-thumb {
    background: #525660;
    border-radius: 4px;
}

/* Handle on hover */
#pdf-viewer-user::-webkit-scrollbar-thumb:hover,
.dropDownBox::-webkit-scrollbar-thumb:hover {
    background: #525660;
}


.download-btn {
    background-color: #015EF7;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 10px #015EF758;
    outline: none;
    border: 0px solid;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: 4px;
}

.bank-error {
    border: 1px solid #D0342C;
    border-radius: 10px;
    padding: 8px 10px;
    color: #D0342C;
    background-color: #FFF2F2;
    margin: 70px 0 -30px 0;
    font-size: 11px;
    line-height: 14px;
}


.email-verification-img {
    text-align: center;
}

.email-verification-img img {
    margin-top: 8%;
    width: 100%;
}

.email-verification-content {
    text-align: center;
}

.email-verification-content>span {
    color: #011149;
    font-family: 'poppinssemibold';
    font-size: 22px;
}

.email-verification-content p {
    color: #011149;
    font-size: 14px;
    line-height: 21px;
}

.email-verification-content p span {
    font-family: 'poppinsmedium';
}

.email-otp {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.email-otp input {
    height: 52px;
    width: 52px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #E4E2F8;
    border-radius: 10px;
    border: 0px solid;
    outline: none;
    font-size: 18px;
    font-family: 'poppinssemibold';
    line-height: 27px;
    text-align: center;
}

.err-email input {
    box-shadow: 0px 4px 4px #D0342C !important;
}

.resend-otp {
    margin-top: 6%;
    text-align: center;
}

.resend-otp span {
    font-size: 14px;
}


abbr {
    position: relative;
    margin-bottom: -5px;
}

.api-text {
    font-size: 11px;
    line-height: 140%;
    color: #525660;
    margin-top: 2px;
    margin-bottom: -15px;
    /* font-family: 'poppinsmedium'; */
}

.remove-bottom {
    margin-bottom: 0 !important;
}

.lotie-div {
    width: 100%;
}

.sry-heading {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    font-family: 'poppinssemibold';
    color: #011149;
    margin-top: 10xp;
}

.sry-text {
    font-family: 'poppinsmedium';
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #525660;
    margin: 20px 0;
}

.sry-timer {
    font-family: 'poppinssemibold';
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #011149;
    padding-bottom: 30px;
}

.email-resend {
    text-align: center;
    text-transform: uppercase;
    margin: 30px 0;
    font-size: 14px;
    line-height: 21px;
    font-family: 'poppinsmedium';
    color: #7064D8;
}

.loan-box-TandC {
    margin-top: 40px;
    font-size: 18px;
    line-height: 140%;
    font-family: 'poppinsmedium';
    color: #011149;
}

.T-C-heading {
    font-size: 16px;
    line-height: 140%;
    font-family: 'poppinssemibold';
    color: #011149;
    margin-bottom: 15px;
}

.T-C-Box {
    border: 1px solid #D5D7DD;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto;
    padding: 0 14px;
    gap: 10px;
}

.T-C-subBoxes {
    margin: 10px 0;
}

.TCHead {
    font-size: 12px;
    line-height: 140%;
    color: #525660;
}

.TCContent {
    font-size: 14px;
    line-height: 140%;
    font-family: 'poppinsmedium';
    color: #011149;
}

.send-lnk {
    text-decoration: underline !important;
    font-weight: 600;
}

.TCLinks {
    margin: 30px 0;
}

.TCLinks div:nth-child(1) {
    margin-bottom: 15px;
}

.TCLinks i {
    width: 2px;
    height: 2px;
}

.TC-confirmText {
    font-size: 11px;
    line-height: 140%;
    color: #525660;
}

.dropDownBox {
    position: absolute;
    background-color: #fff;
    width: 100%;
    max-height: 190px;
    overflow: auto;
    left: 0;
    top: 30px;
    z-index: 10;
    box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 20px 20px;
}

.dropDownValue {
    padding: 10px;
    padding-left: 30px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #011149;
}

.dropDownValue:hover,
.dropDownBox .select {
    background-color: #E4E2F8;
}

.pdf-loader {
    height: 470px;
    border: 1px solid #F6F6F8;
    background-color: rgb(82, 86, 89);
    display: flex;
    align-items: center;
}

.email-options {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.email-input-box {
    margin-top: -20px;
    position: relative;
}

.edit-email {
    /* position:absolute; */
    /* top:0; */
    /* right:20px; */
    font-size: 12px;
    font-family: 'poppinsmedium';
    cursor: pointer;
    color: #015EF7;
    /* margin-right: 0 !important; */
}

.email-disable {
    opacity: 0.5;
}

.account-type-option {
    margin-top: 20px;
    margin-bottom: -10px;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-width:1366px) {
    .heading-2 {
        font-size: 22px;
    }

    .form-body {
        margin: 7%;
    }

    .form-sub-head {
        font-size: 12px;
        margin-top: 2%;
    }


    .form-box {
        width: 380px;
    }

    .input-tag input::placeholder,
    .input-tag textarea::placeholder {
        font-size: 14px;
    }

    .input-tag input,
    .input-tag-dob {
        font-size: 16px;
    }

    .sry-heading {
        font-size: 22px;
        line-height: 30px;
    }

    .sry-text {
        font-size: 16px;
    }

    .sry-timer {
        font-size: 18px;
    }
}

.capLetter {
    text-transform: uppercase;
}

@media screen and (max-width:890px) {

    .form-box {
        width: 100%;
        box-shadow: 15px 15px 20px 20px #11357026;
        margin-bottom: 30px;
    }
}


@media screen and (max-width:740px) {

    .heading-2 {
        font-size: 20px;
    }

    .input-tag input,
    .input-tag select,
    .input-tag-dob {
        height: 22px;
        font-size: 16px;
    }

    .form-body {
        margin: 5%;
    }

    .error-img-show {
        padding-bottom: 3px;
    }


    #pdf-viewer-user canvas {
        width: 90% !important;
        height: auto;
    }
}

@media screen and (max-width:700px){
    #pdf-viewer-user canvas {
        width: 80% !important;
        height: auto;
    }
}

@media screen and (max-width:590px){
    #pdf-viewer-user canvas {
        width: 70% !important;
        height: auto;
    }
}

@media screen and (max-width:590px){
    #pdf-viewer-user canvas {
        width: 60% !important;
        height: auto;
    }
}

@media screen and (max-width:500px) {
    .form-body {
        margin: 5% 7%;
    }

    .this-is-calender {
        padding: 8px;
        width: 96%;
    }

    .form-box {
        width: 90vw;
    }

    .form-head {
        padding-top: 10%;
    }


    .form-sub-head {
        font-size: 12px;
        line-height: 14px;
    }

    .api-text,
    .err-text {
        font-size: 10px;
    }

    .form-box {
        /* width: 100%; */
    }

    .email-otp {
        gap: 10px;
    }

    .input-tag {
        margin-top: 10%;
    }

    .manage-sry-screen {
        margin-top: 50px;
    }

    .TCHead {
        font-size: 10.2995px;
    }

    .TCContent {

        font-size: 12.016px;
    }

    .TCLinks {
        font-size: 14px;
    }

    .TCLinks i {
        width: 1px;
        height: 1px;
    }
}

@media screen and (max-width:400px) {
    #pdf-viewer-user canvas {
        width: 50% !important;
        height: auto;
    }
}

@media screen and (max-width:350px) {
    .input-tag input {
        margin: 0;
    }
}

.numberVerification-main {
    font-family: 'Poppins';
    font-style: normal;
}

.numberVerification-head {
    
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 30px;
    background: #F9FBFF;
    border: 1px solid #015EF7;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    padding-right: 2px;
}

.numberVerification-head p {
    font-size: 12px;
}

.numberVerification-head p span {
    color: #015EF7;
    font-weight: 600;
    font-size: 12px;
}

.numberVerification-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #4D3ECC;
}

.numberVerification-body input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    padding-left: 16px;
}

.numberVerification-body input::placeholder {
    font-weight: 400;
}


.numberVerification-body-error p {
    font-size: 11px;
    color: #D0342C;
    margin-top: 5px;
}

.numberVerification-body-error-border {
    border-bottom: 1px solid #D0342C !important;
}