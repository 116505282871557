.main-faq-bg{
    background-image: linear-gradient(#4d3ECC1A,#4d3ECC05);
    margin-top: 80px;
    padding-bottom: 40px;
}
.main-faq-cont{
    width: 80%;
    margin: auto;
    text-align: center;
}
.main-faq-cont .left-top {
    position: absolute;
    top: -24px;
    color: #015EF7;
}

.main-faq-cont .left-top a,.main-faq-cont .left-top span {
    color: #015EF7;
    font-family: 'poppinsmedium';
}
.main-faq-greet{
    display: flex;
    font-size: 36px;
    color: #011149;
    font-weight: bold;
    line-height: 140%;
    text-align: center;
    justify-content: center;
}
.main-faq-greet p{
    margin-bottom: 10px;
}
.catergory-content {
    color: #011149;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin-top: 0;
    line-height: 140%;
}
.faq-slide-img{
    width: 15%!important;
}
.faq-slide-card{
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    margin-right: 20px;
    height: 90px;
}
.faq-slide-card p{
    color: #011149;
    font-size: 18px;
    font-weight: bold;
}
.faq-container-main{
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 40px;
}
.faq-container-main p{
    font-size: 24px;
    color: #000000;
    font-weight: bold;
}
.faq-container-main hr{
    width: 100px;
    height: 6px;
    background-color: #4d3ecc;
    border-radius: 10px;
    margin: 0px;
    margin-top: -14px;
    border: none;
}
.faq-container-main .box-shape{
    width: 100%;
}
.faq-container-main .question-box{
    background: none;
    box-shadow: none;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #D5D7DD;
    padding: 0px;
}
.faq-container-main .box-shape .heading-h2{
    display: none;
}
@media only screen and (max-width:550px){
    .main-faq-cont .left-top{
        top: 0;
        position: relative;
    }
    .main-faq-greet{
        font-size: 24px;
    }
    .catergory-content{
        font-size: 16px;
    }
    .faq-slide-card{
        height: 80px;
    }
    .faq-slide-card p{
        font-size: 14px;
    }
    .faq-container-main p{
        font-size: 18px;
    }
    .faq-slide-img {
        width: 25%!important;
    }
    .faq-container-main {
        width: 90%;
    }
    .question-left {
        font-size: 16px !important;
    }
    .main-faq-bg {
        margin-top: 60px;
    }
    .faq-container-main hr {
        margin-top: -10px;
    }
    /* .question-right {
        width: none;
    } */
}