.blog-cont {
  width: 80%;
  margin: 80px auto;
  text-align: center;
  margin-top: 30px;
}

.blog-header {
  margin: auto;
}

.blog-header p {
  color: #011149;
  font-size: 30px;
  font-family: 'poppinssemibold';
  margin-bottom: 20px;
}

.blog-header span {
  font-size: 22px;
  color: #455A64;
}

.blog-card {
  margin: auto;
  margin-top: 30px;
}

.blogcard {
  background-color: #FFFFFF;
  border: 1px solid #D9DFFF;
  border-radius: 20px;
  margin: 10px;
  min-height: 340px;
  max-height: 340px;
  overflow: hidden;
  margin-bottom: 50px;
}

.blog-card .slick-slide img {
  margin: auto;
  height: 165px;
  background-color: #f8f8fe;
  object-fit: cover;
}

.blogcard:hover {
  box-shadow: 0px 25px 50px #EAEEFF;
  border: 0px solid;
}

.blogimg {
  margin-top: 15px !important;
  width: 90% !important;
  border-radius: 10px !important;
}

.blogp {
  color: #011149;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: -7px;
  padding: 11px;
  height: 44px;
  overflow: hidden;
}

.blogcard button {
  margin: 20px;
  padding: 10px 20px;
  background: #E8572F;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;
  transition: transform .4s;
}
.blogcard button:hover{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.blogcard a {
  text-decoration: none;
  color: white;
}
.blog-card .mw-announce-arrow-desscription {
  position: absolute;
  top: 40%;
  left: -7%;
  cursor: pointer;
}

.blog-card .mw-announce-arrow-des {
  position: absolute;
  top: 44%;
  right: -7%;
  cursor: pointer;
  transform: rotate(360deg);
}

.blog-card .slick-arrow{
  position: absolute;
  top: 40%;
  transform: translate(-50%);
  cursor: pointer;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #fff;
  /* box-shadow: 0px 0px 20px 0px rgb(4 7 47 / 10%); */
  color: #0d0d0d;
  transition: all 0.3s ease-out 0s;
  border: 1px solid #E4E2F8;
  /* right: -8%; */
}
.blog-card .slick-arrow:hover{
  border: none;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 20px 0px rgb(4 7 47 / 10%);
  transition: none;

}
.blog-card .slick-next{
  right: -76px;
}
.blog-card .slick-arrow.slick-prev::before {
  content: "\276F";
  position: absolute;
  background-image: none;
  color: #E4E2F8;
  transform: rotate(180deg);
  top: 15px;
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}
.blog-card .slick-arrow.slick-next:hover::before {
  content: "\276F";
  background-image: none;
  transform: rotate(0deg);
  color: #ddd;
  position: absolute;
  top: 14px;
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}
.blog-card .slick-arrow.slick-next::before{
  content: "\276F";
  position: absolute;
  background-image: none;
  color: #E4E2F8;
  transform: rotate(0deg);
  top: 14px;
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}
.blog-card .slick-arrow.slick-prev:hover::before {
  content: "\276F";
  background-image: none;
  transform: rotate(180deg);
  color: #ddd;
  position: absolute;
  /* top: 18px; */
  left: 0px;
  right: 0px;
  color: #4d3ecc;
}

@media only screen and (max-width:1100px) {
  .blogp {
    font-size: 16px;
  }
  .blog-card .mw-announce-arrow-des {
    position: absolute;
    top: 33%;
    right: -6%;
    cursor: pointer;
}
.blog-card .mw-announce-arrow-desscription {
  position: absolute;
  top: 40%;
  left: -6%;
  cursor: pointer;
}
/* .blog-card .slick-arrow{
  right: -11%;
} */
}
@media only screen and (max-width:900px){
  .blog-card .mw-announce-arrow-des {
    position: absolute;
    top: 33%;
    right: -9%;
    cursor: pointer;
}
.blog-card .mw-announce-arrow-desscription {
  position: absolute;
  top: 40%;
  left: -8%;
  cursor: pointer;
}
}

@media only screen and (max-width:750px) {

  /* .blog-card .slick-next{
    height: 35px;
    width: 35px;
}
.blog-card .slick-prev{
    height: 35px;
    width: 35px;
} */
  .blog-card .slick-prev {
    margin-left: -16px;
  }

  .blog-card .slick-next {
    margin-right: -30px;
  }

  .blog-card .slick-arrow.slick-prev {
    left: -4px;
  }

  .blog-card .slick-arrow.slick-next {
    right: -43px;
  }
  .blog-card .mw-announce-arrow-des {
    position: absolute;
    top: 33%;
    right: -9%;
    cursor: pointer;
}
.blog-card .mw-announce-arrow-desscription {
  position: absolute;
  top: 40%;
  left: -10%;
  cursor: pointer;
}
}

@media only screen and (max-width:650px){
  .blog-card .mw-announce-arrow-des {
    position: absolute;
    top: 33%;
    right: -10%;
    cursor: pointer;
}
}

@media only screen and (max-width:550px) {
  
  .blog-header p {
    font-size: 22px;
    margin-bottom: 0;
  }
  .blogcard{
    margin: 26px 0;
  }
  .blog-card{
    margin-top: 0px;
  }
  .blogcard button {
    font-size: 14px;
  }
  .blog-card .mw-announce-arrow-des {
    position: absolute;
    top: 33%;
    right: -12%;
    cursor: pointer;
}
.blog-card .mw-announce-arrow-desscription {
  position: absolute;
  top: 40%;
  left: -12%;
  cursor: pointer;
}
}


@media only screen and (max-width:480px) {
  .blogp {
    font-size: 18px;
  }

  .blog-card .slick-next {
    margin-right: -15px;
  }

  .blog-card .slick-prev {
    margin-left: -7px;
  }

  .blog-card .slick-arrow {
    width: 48px;
    height: 48px;
    top: 95%;
  }

  .blog-card .slick-arrow.slick-next::before {
    top: 26%;
    left: 3%;
  }

  /* .blog-card .slick-arrow.slick-next:hover::before {
    top: 20%;
    left: 15%;
  }

  .blog-card .slick-arrow.slick-prev:hover::before {
    top: 21%;
  } */

  .blog-card .slick-arrow.slick-next {
    right: -4%;
  }

  .blog-card .slick-arrow.slick-prev {
    left: 8%;
  }
  .blog-card .mw-announce-arrow-desscription {
    position: absolute;
    top: 96%;
    left: 3%;
    cursor: pointer;
}
.blog-card .mw-announce-arrow-des {
  position: absolute;
  top: 95%;
  right: 2%;
  cursor: pointer;
}
.blog-cont{
  width: 90%;
}
}

@media only screen and (max-width:480px) {

  .blog-header span {
    font-size: 16px;
  }

  .blog-card .slick-next {
    margin-right: -10px;
  }

  .blog-card .slick-prev {
    margin-left: -6px;
  }
}