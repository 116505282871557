.desktop-header-section {
    width: 100%;
    height: 76px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
.shadow-give{    
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.1);
}

.header-content {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-header {
    display: flex;
}

.header-logo {
    max-width: 132px;
    height: 62px;
}

.header-right-content {
    display: flex;
}

/* .header-right-content ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    padding: 0;
    margin: 0;
} */

.header-right-content li {
    list-style-type: none;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 6px;
}
/* .header-right-content li:last-child{
    padding-right: 0px;
} */
.header-right-content li:hover {
    background-color: #E4E2F8;
    
}
.desktop-header-section .header-right-content .menu li:hover a{
    color: #4D3ECC;
}
.desktop-header-section .header-content .header-right-content .menu li.special-menu-item-29:hover a{
    color: #fff;
}
.desktop-header-section .header-content .header-right-content .menu li .submenu li p a{
    color: #011149;
}
.desktop-header-section .header-content .header-right-content .menu li .submenu li:hover p a{
    color: #4D3ECC;
}
.header-right-content .header-dropdown:hover a img{
    transform: rotate(180deg);
}
.header-right-content ul li a,
.header-right-content ul li span {
    font-family: 'poppinsmedium';
    font-size: 14px;
    line-height: 140%;
    text-decoration: none;
    color: #011149;
    position: relative;
    margin: 2px 0;
}
.header-right-content ul li a svg{
    margin:-5px 6px -7px -7px;
}

/* .header-right-content>ul>li:not(:last-child):hover>a,
.header-right-content ul li a:hover {
    color: #4D3ECC;
    background-color: #E4E2F8;
    border-radius: 6px;
} */


.activated-head {
    background-color: #E4E2F8;
}

.activated-head a {
    color: #4D3ECC !important;
}

.header-download-btn {
    background-color: #4D3ECC;
    border-radius: 6px;
    max-width: 189px;
    padding: 4px 12px;
    color: #fff !important;
    /* height: 40px; */
    display: flex;
    gap: 10px;
    align-items: center;
}

.header-download-btn svg{
    margin: 0 !important;
}

.header-download-btn:hover {
    background-color: #4D3ECC !important;
}

.header-login-btn {
    padding: 8px 16px;
    width: 75px;
    height: 40px;
    border: 1.5px solid #4D3ECC;
    border-radius: 6px;
    font-size: 16px;
    line-height: 140%;
    color: #4D3ECC !important;
}

.header-login-btn:hover {
    color: #fff !important;
    background-color: #4D3ECC !important;
}

.dropbtn {
    border: none;
}

.rev {
    padding: 2px 10px !important;
}

.rev:hover>a>img {
    transform: rotate(180deg);
}

.dropbtn>span::before {
    content: '>';
    font-size: 24px;
}



.header-nasted-content>div {
    margin: 0 10px;
}

.header-nasted-content>div:hover {
    background-color: #E4E2F8;
    border-radius: 5px;
}

.header-dropdown {
    position: relative;
    display: inline-block;
    padding: 15px 0;
}

.header-dropdown a {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* .header-dropdown a img {
    width: 24px;
    height: 24px;
} */

.header-nasted-content {
    background-color: #fff;
    min-width: 250px;
    box-shadow: 0px 10px 20px rgba(38, 50, 56, 0.2);
    z-index: 1;
    border-radius: 0 0 10px 10px;
    padding: 8px 0;
}

.header-nasted-side-content {
    background-color: #fff;
    min-width: 250px;
    box-shadow: 0px 10px 20px rgba(38, 50, 56, 0.2);
    z-index: 1;
    border-radius: 0px 10px 10px 10px;
    padding: 8px 10px;
}

.header-nasted-side-content a:hover {
    background-color: #E4E2F8;
    border-radius: 6px;
}
.header-side-dropdown a{
    display: flex;
}
.header-dropdown:hover .header-dropdown-content{
    display: block;
    opacity: 1;
    -webkit-transform: translate(0);
    transform: rotateX(0);
    -o-transform: rotateX(0);
    -moz-transform: rotateX(0);
    -webkit-transform: rotateX(0);
}

.header-side-dropdown:hover .header-side-dropdown-content {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: rotateX(0);
    -o-transform: rotateX(0);
    -moz-transform: rotateX(0);
    -webkit-transform: rotateX(0);
}
.header-dropdown-content {
    position: absolute;
    top: 2px;
    left: 5px;
    padding: 50px 0;
    opacity: 0;
    -o-transform-origin: 0 20%;
    -ms-transform-origin: 0 20%;
    -moz-transform-origin: 0 20%;
    -webkit-transform-origin: 0 20%;
    -o-transition: -o-transform .5s, opacity .4s;
    -ms-transition: -ms-transform .5s, opacity .4s;
    -moz-transition: -moz-transform .5s, opacity .4s;
    -webkit-transition: -webkit-transform .5s, opacity .4s;
    transform-style: preserve-3d;
    -o-transform: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotateX(92deg);
    -o-transform: rotateX(92deg);
    -moz-transform: rotateX(92deg);
    -webkit-transform: rotateX(92deg);
}

.header-side-dropdown-content{    
    position: absolute;
    top: 0px;
    left: 100%;
    padding: 50px 0;
    opacity: 0;
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -o-transition: -o-transform .2s, opacity .2s;
    -ms-transition: -ms-transform .2s, opacity .2s;
    -moz-transition: -moz-transform .2s, opacity .2s;
    -webkit-transition: -webkit-transform .2s, opacity .2s;
    transform-style: preserve-3d;
    -o-transform: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotateY(92deg);
    -o-transform: rotateY(92deg);
    -moz-transform: rotateY(92deg);
    -webkit-transform: rotateY(92deg);
}


.header-dropdown-content a {
    padding: 10px 13px;
    text-decoration: none;
    display: block;
}

.RArr::after {
    content: '\276F';
    position: absolute;
    width: 100%;
    left: 90%;
    font-size: 16px;
}

.mobile-header-section {
    display: none;
    width: 100%;
    height: 62px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.nav-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.open-mobile-header {
    position: absolute;
    min-width: 320px;
    /* animation: Arrive 1s 1 ease; */
}


.mobile-header-content {
    background: #FFFFFF;
    border-top: 1px solid #E9E9E9;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 0px 0px 50px 0px;
    width: 80%;
    max-height: calc(100vh - 62px);
    padding: 5px 10px;
    overflow-y: scroll;
    margin-bottom: 10px;
    min-width: 320px;
}

.mobile-header-content .name {
    font-family: 'poppinssemibold';
    font-size: 18px;
    line-height: 27px;
    color: #011149;
    margin: 25px 0 14px;
    padding-left: 10px;
}

.mobile-header-content::-webkit-scrollbar {
    width: 0px;
}

.pl-span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #000000;
    font-family: 'poppinsmedium';
    font-size: 14px;
}

li>.pl-span~ul>li>ul>li::before {
    position: absolute;
    content: "";
    left: -24px;
    top: 23px;
    width: 41px;
    border: 1px solid #E4E2F8;
    transform: rotate(90deg);
}

.header-blur-background {
    position: fixed;
    background: rgb(0 0 0 / 58%);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 62px;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.rotateImg {
    transform: rotate(180deg);
}

.rotateRight {
    transform: rotate(-90deg);
    width: 30px;
    height: 30px;
}

.rotateLeft {
    transform: rotate(90deg);
    width: 30px;
    height: 30px;
}

.mobile-header-content ul li {
    list-style-type: none;
}

.mobile-header-content ul li:not(:last-child) a:hover {
    background-color: #E4E2F8;
    color: #4D3ECC;
}

.main-backbtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #4D3ECC;
    cursor: pointer;
}

.main-backbtn img {
    margin-right: 30px;
}

.mobile-header-content ul li a,
.mobile-header-content ul li span {
    font-family: 'poppinsmedium';
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px;
    border-radius: 6px;
}


.mobile-header-content ul li span,
.header-right-content ul li span {
    cursor: pointer;
}

/* .mobile-header-content ul li:not(:last-child) a:hover,
.pl-span:hover,
.header-right-content ul li:hover span {
    color: #4D3ECC !important;
} */

.act-header,
.act-header a {
    color: #4D3ECC !important;
}

.act-my-header a {
    color: #4D3ECC !important;
    background-color: #E4E2F8;
    border-radius: 6px;

}


/* .mobile-header-content>ul img {
    width: 24px;
    height: 24px;
} */

.mobile-header-content>ul {
    padding-left: 0px;
    margin-top: 0;
}

/* .mobile-header-content>ul ul {
    padding-left: 15px;
} */

.mobile-header-content>ul ul>li {
    padding-right: 0px;
}



.header-bottom-content {
    margin: 20px 0;
    padding: 0 10px;
}

.header-bottom-content span {
    font-size: 18px;
    line-height: 27px;
    font-family: 'poppinssemibold';
    background: linear-gradient(90deg, #103E7A 0%, #205CA9 98.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.header-bottom-content p {
    font-size: 14px;
    line-height: 140%;
    color: #3D3D3D;
}

.header-bottom-content p a {
    font-size: 16px;
    font-family: 'poppinssemibold';
    color: #015EF7;
    text-decoration: none;
}
.header-right-content  .menu .submenu p{
    margin: 0px;
    display: flex;
}
.desktop-header-section .header-content .header-right-content .menu {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0;
  }
  
  .desktop-header-section .header-content .header-right-content .menu li {
    display: inline-block;
    margin-right: 10px;
    position: relative;
  }
  
  .desktop-header-section .header-content .header-right-content .menu li .submenu {
    /* display: none; */
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 8px;
    /* position: absolute;
    top: 2px;
    left: 5px;
    padding: 50px 0; */
    opacity: 0;
    -o-transform-origin: 0 20%;
    -ms-transform-origin: 0 20%;
    -moz-transform-origin: 0 20%;
    -webkit-transform-origin: 0 20%;
    -o-transition: -o-transform .5s, opacity .4s;
    -ms-transition: -ms-transform .5s, opacity .4s;
    -moz-transition: -moz-transform .5s, opacity .4s;
    -webkit-transition: -webkit-transform .5s, opacity .4s;
    transform-style: preserve-3d;
    -o-transform: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotateX(92deg);
    -o-transform: rotateX(92deg);
    -moz-transform: rotateX(92deg);
    -webkit-transform: rotateX(92deg);
  }
  .desktop-header-section .header-content .header-right-content .menu li .submenu li .submenu-second-level{
    /* display: none; */
    position: absolute;
    top: 0%;
    left: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius:6px;
    opacity: 0;
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -o-transition: -o-transform .2s, opacity .2s;
    -ms-transition: -ms-transform .2s, opacity .2s;
    -moz-transition: -moz-transform .2s, opacity .2s;
    -webkit-transition: -webkit-transform .2s, opacity .2s;
    transform-style: preserve-3d;
    -o-transform: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotateY(92deg);
    -o-transform: rotateY(92deg);
    -moz-transform: rotateY(92deg);
    -webkit-transform: rotateY(92deg);
    
  }
  /* .menu li ul li .submenu-second-level{
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  } */
  .desktop-header-section .header-content .header-right-content .menu li:hover .submenu{
    display: block;
    opacity: 1;
    -webkit-transform: translate(0);
    transform: rotateX(0);
    -o-transform: rotateX(0);
    -moz-transform: rotateX(0);
    -webkit-transform: rotateX(0);
  }
  .desktop-header-section .header-content .header-right-content .menu li .submenu li:hover .submenu-second-level{
    display: block;
    opacity: 1;
    -webkit-transform: translate(0);
    transform: rotateX(0);
    -o-transform: rotateX(0);
    -moz-transform: rotateX(0);
    -webkit-transform: rotateX(0);
  }
  .desktop-header-section .header-content .header-right-content .menu li .submenu li:hover .submenu-second-level li p a{
    color: #011149;
  }
  .desktop-header-section .header-content .header-right-content .menu li .submenu li:hover .submenu-second-level li:hover p a{
    color: #4D3ECC;
  }
  /* .menu li ul li:hover .submenu-second-level{
    display: block;
  } */
  /* .submenu li {
    display: block;
  } */
  .desktop-header-section .header-content .header-right-content .menu .submenu li a{
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .desktop-header-section .header-content .header-right-content .menu .submenu li a span{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .special-menu-item-29 {
    padding: 8px 16px;
    border: 1.5px solid #4D3ECC;
    border-radius: 6px;
    font-size: 16px;
    line-height: 140%;
    color: #4D3ECC ;
  }
  .special-menu-item-29:hover{
    background-color: #4D3ECC !important;
    color: #fff !important;
  }
  .special-menu-item-29:hover a{
    color: #fff;
  }
  .special-menu-item-30:hover{
    background-color: #4D3ECC !important;
  }
  .special-menu-item-30{
    background-color: #4D3ECC;
    margin-left: 14px;
    /* border-radius: 6px !important;  
    padding: 0px !important;
    margin-right: 0px !important; */
  }
  .special-menu-item-30>a{
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0px 2px;
    margin: 0 !important;
    color: #fff !important;
  }
  .active-header-menu{
    background-color: #E4E2F8;
    
  }
  .active-header-menu a{
    color: #4D3ECC !important;
  }
  /* .mobile-header-content .menu{
    flex-direction: column;
    align-items: start;
  }
  .mobile-header-content .menu{
    width: 100%;
  }
  .mobile-header-content .menu li{
    width: 100%;
  }
  .mobile-header-content .special-menu-item-30{
    margin-left: 0px;
  }
  .mobile-header-content .menu li .submenu{
    display: block;
    position: relative;
    background-color: transparent;
    box-shadow: none;
  }
  .mobile-header-content .menu .menu-item-3 .submenu{
    position: relative;
    background-color: transparent;
    box-shadow: none;
  }
  
.mobile-header-content .menu li .submenu li .submenu-second-level{
    display: none;
    position: relative;
    left: 0%;
    background-color: transparent;
    box-shadow: none;
}
.mobile-header-content .menu li ul li img:first-child{
    display: none;
}
.mobile-header-content .menu .submenu li a{
    width: 96%;
}
.mobile-header-content .special-menu-item-29{
    display: none;
}
.mobile-header-content .menu li.special-menu-item-30{
    width: fit-content;
    border-radius: 6px;
}
.mobile-header-content .menu .header-dropdown{
    padding: 0px;
}
.mobile-login-menu ul{
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}
.mobile-login-menu ul li .pl-span{
    color:#4D3ECC !important;
} */
@media (max-width:1280px) {
    .header-right-content ul{
        gap: 4px;
    }
}

@media (max-width:1000px) {
    .mobile-header-content .menu  .active-header-menu{
        background: none;
    }
    .mobile-header-content .menu{
        flex-direction: column;
        align-items: start;
      }
      .mobile-header-content .menu{
        width: 100%;
        padding-left: 0px;
      }
      .mobile-header-content .menu li{
        width: 100%;
      }
      .mobile-header-content .special-menu-item-30{
        margin-left: 0px;
      }
      .mobile-header-content .menu li .submenu{
        display: block;
        position: relative;
        background-color: transparent;
        box-shadow: none;
        padding-left: 20px;
      }
      .mobile-header-content .menu .menu-item-3 .submenu{
        display: none;
        position: relative;
        background-color: transparent;
        box-shadow: none;
      }
      
    .mobile-header-content .menu li .submenu li .submenu-second-level{
        display: none;
        position: relative;
        left: 0%;
        background-color: transparent;
        box-shadow: none;
    }
    .mobile-header-content .menu li ul li img:first-child{
        display: none;
    }
    .mobile-header-content .menu .submenu li a{
        width: fit-content;
    }
    .mobile-header-content .special-menu-item-29{
        display: none;
    }
    .mobile-header-content .menu li.special-menu-item-30{
        width: fit-content;
        border-radius: 6px;
    }
    .mobile-header-content .menu .header-dropdown{
        padding: 0px;
    }
    .mobile-header-content .menu .submenu p{
        margin: 0px;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
    }
    .desktop-header-section {
        background-color: transparent;
        box-shadow: none;
    }

    .header-content {
        width: 90%;
    }

    .mobile-header-section {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
    }

    .header-logo {
        width: 110px;
        height: 46px;
    }

    .header-left-content {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .header-login-btn {
        text-align: center;
        text-decoration: none;
        padding: 8px 20px;
    }

    .header-download-btn {
        max-width: 140px;
        padding: 5px 10px !important;
        margin-top: 8px;
    }
    .header-right-content li:last-child{
        padding: 0px;
    }
    .mobile-header-content>ul>li ul li {
        position: relative;
    }

    .header-download-btn svg{
        margin: 0px -2px 0px 0px !important;
    }
    .mobile-header-content .mobile-login-menu ul{
        padding-left: 0px;
    }
    .mobile-header-content .menu .active-header-menu{
        border-radius: 8px;
    }
    .mobile-header-content .menu .menu-item-26 a:hover{
        background-color: transparent;
        color: #000;
    }
}