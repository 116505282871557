

.mplec{
    width: 94%;
    margin: 80px auto;
}
.FeatureAndBenefits {
    width: 100%;
    margin: 80px 0;
}

.container {
    width: 80%;
    margin: auto;
    margin-top: 30px;
}

.heading-h2 {
    font-size: 36px;
    line-height: 140%;
    color: #011149;
    font-weight: 100;
    margin-bottom: 0px;
}

.heading-p {
    line-height: 140%;
    color: #455a64;
}

.box-sh {
    width: 80%;
    margin: auto;
}


.eligibility-criteria-box {
    /* background: #fff; */
    /* padding: 40px; */
    /* padding-top: 50px;
    padding-bottom: 50px; */
    /* border-radius: 12px; */
    /* border: 1px solid #e5f2ff; */
    /* box-shadow: 1px 1px 4px #ddd; */
}

.ploan-row-criteria {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.list-check {
    list-style: none;
    padding: 0;
}

.list-check li {
    margin-bottom: 40px;
    line-height: 140%;
    color: #455a64;
    font-size: 18px;
}

/* .list-check li::before {
    content: "\2713";
    background: #21bf26;
    margin-right: 14px;
    float: left;
    margin-top: 4px;
    color: #fff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
} */

@media screen and (max-width:1000px) {
    .box-sh {
        width:90%;
    }
    .colm-1 img {
        width: 280px;
        height: 223px;
    }

    .colm-2 {
        margin-right: -50px;
    }
}

@media screen and (max-width:720px) {
    .heading-p {
        font-size: 14px;
        margin-top: 0px;
    }

    .heading-h2 {
        font-size: 22px;
        padding: 20px 0;
        padding-bottom: 0;
    }

    .ploan-row-criteria {
        display: flex;
        flex-direction: column;
    }

    .colm-1 {
        width: 100%;
        margin: auto;
    }

    .colm-2 {
        margin: 0;
        width: 100%;
    }

    /* .colm-2 img {
        width: 60vw;
    } */

    .ploan-container {
        padding: 0;
    }
    .list-check li{
        font-size: 14px;
        margin-bottom: 20px;
    }
    .eligibility-criteria-box{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .container {
        width: 100%;
        margin: auto;
        margin-top: 30px;
    }
}

@media screen and (max-width:414px) {
    .eligibility-criteria-box{
        padding-top: 0px;
    }
}