.SelfieVerificationPopup{
    margin:12% auto;
    width:414px;
    border-radius: 35px;
    background-color: #fff;
}
.SelfieVerificationPopup-main{
    margin: 0px 38px;
}
.boxcircle{
    border: 1px solid black;
    width: 219px;
    height: 219px;
    margin: 0 auto;
}
.SelfieVerificationPopup-main h2{
    color:#011149;
    font-size: 18px;
    text-align: center;
    margin-top: -22px;
}
.SelfieVerificationPopup-main p{
    text-align: center;
    color: #525660;
    font-size: 14px;
    font-family: 'poppinsregular';
    line-height: 140%;
    margin-bottom: 0px;
    margin-top: -2%;
}
.SelfieVerificationPopup-main button{
    width: 100%;
    background-color: #0FA82E;
    color: #fff;
    font-size: 18px;
    font-family: 'poppinssemibold';
    border: none;
    height: 56px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 9%;
    margin-bottom: 9%;
}

.pa-img-sorry {
    margin: auto;
    display: flex;
    padding: 40px;
    width: 52%;
}

@media screen and (max-width:440px) {
    .SelfieVerificationPopup{
        width:94%;
    }
    .SelfieVerificationPopup .SelfieVerificationPopup-main h2{
        font-size: 20px;
    }
}