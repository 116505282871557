.complain-service-request-main {
    width: 100%;
}

.complain-service-request-all-products {
    width: auto;
    border: 1px solid #8A91A1;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0 5%;
    padding-bottom: 4%;
    cursor: pointer;
}

.complain-service-request-product {
    margin-bottom: 10px;
    margin-top: 20px;
}

.complain-service-request-product p {
    /* font-weight: 600; */
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    font-family: 'poppinsmedium';
    margin: 0;


    color: #011149;
}

.complain-service-request-all-products-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.complain-service-request-all-products-type p {
    /* font-weight: 600; */
    font-size: 14px;
    line-height: 21px;
    color: #011149;
    font-family: 'poppinsmedium';
    margin: 14px 0;
}

.complain-service-request-all-products-type input[type=radio] {
    accent-color: #0FA82E;
    cursor: pointer;
}

.complain-service-request-all-products hr {
    width: 32px;
    height: 2px;
    background-color: #6D57DF;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0px;
    border: 0px solid;
}

.complain-service-request-all-products-date-amount {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.complain-service-request-all-products-date p,
.complain-service-request-all-products-amount p,
.complain-service-request-all-products-status p {
    /* font-weight: 500; */
    font-size: 12px;
    line-height: 140%;
    color: #011149;
    margin-bottom: 0px;
    margin-top: 10px;
}

.complain-service-request-all-products-date p:nth-child(2),
.complain-service-request-all-products-amount p:nth-child(2),
.complain-service-request-all-products-status p:nth-child(2) {
    font-size: 14px;
    margin-top: 0px;
    font-family: 'poppinsmedium';
}

.input-dropdown {
    position: absolute;
    max-height:154px;
    width: 100%;
    left: 0;
    top: 25px;
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 9px 10px rgba(17, 53, 112, 0.1);
    border-radius: 0px 0px 20px 20px;
    z-index: 1;
}

.dropdown-content {
    padding: 14px 0;
    text-align: center;
    border-bottom: 1px solid #F3F7FF;
    cursor: pointer;
}

.input-dropdown::-webkit-scrollbar {
    height: 0px;
    width: 4px;
}

/* Track */
.input-dropdown::-webkit-scrollbar-track {
    background: #D5D7DD;
}

/* Handle */
.input-dropdown::-webkit-scrollbar-thumb {
    background: #525660;
    border-radius: 4px;
}

/* Handle on hover */
.input-dropdown::-webkit-scrollbar-thumb:hover {
    background: #525660;
}