.setting-back-bg {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px 30px;
}

.add-number-head {
    margin-top: 30px;
}

.add-number-head span {
    color: #525660;
    font-size: 12px;
}

.add-number-selection {
    display: flex;
    border-bottom: 2px solid #4D3ECC;
    margin-top: 14px;
    padding-bottom: 4px;
    align-items: center;
    width: fit-content;
}

.add-number-selection select {
    border: none;
    width: 100%;
    appearance: none;
    font-size: 14px;
    color: #011149;
    font-family: 'poppinsmedium';
    padding-left: 10px;
    cursor: pointer;
    line-height: 140%;
}

.add-number-selection select option {
    font-size: 14px;
}

.add-number-selection select:focus {
    outline: none;
}

.add-new-no-mail {
    display: flex;
    align-items: end;
    gap: 14px;
    margin-top: 24px;
    cursor: pointer;
}

.account-state-button.preferences .activate {
    background-color: #4D3ECC;
    width: 154px;
    height: 40px;
    padding: 0px;
    font-size: 14px;
    line-height: 140%;
    font-family: 'poppinsregular';
}

.preferences {
    position: relative;
}

.add-new-no-mail p {
    color: #4D3ECC;
    font-size: 14px;
    font-family: 'poppinsmedium';
    margin: 0px;
}

.prefrence-no {
    margin-top: 30px;
    margin-bottom: 20px;
}

.prefrence-no img {
    width: 16px;
    height: 16px;
}

.prefrence-no p {
    font-size: 14px;
    color: #525660;
    font-family: 'poppinsregular';
    margin: 0px;
    line-height: 140%;
}

.prefrence-no p span {
    color: #011149;
    font-family: 'poppinssemibold';
    line-height: 140%;
    display: inline-flex;
    gap: 6px;
}

.toggle-approvel {
    width: 100%;
    margin-top: 20px;
}

.toggle-approve-sub {
    width: 100%;
}

.myAccountContent .Tmargin {
    margin-top: 24px;
}

.setting-header-span span {
    font-size: 16px;
    font-family: 'poppinsregular';
    line-height: 140%;
}

.toggle-approve-label-main {
    display: flex;
    background-color: #F6F6F8;
    padding: 8px 20px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
}

.toggle-approve-label {
    width: 50%;
}

.toggle-approve-right-main-sms {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.toggle-approve-right {
    padding: 0px 20px;
}

.toggle-approve-sms span {
    color: #525660;
    font-family: 'poppinsmedium';
    font-size: 14px;
}

.toggle-approve-label span,
.toggle-tags span {
    color: #525660;
    font-family: 'poppinsmedium';
    font-size: 14px;
}

.toggle-cont-all {
    margin-top: 30px;
}

.toggle-cont-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.toggle-left-btn-main {
    display: flex;
}

.toggle-cont-sub span {
    color: #011149;
    font-size: 16px;
    font-family: 'poppinssemibold';
}

.toggle-cont-sub .subdesc {
    font-size: 12px;
    color: #015EF7;
}

.pref-success-msg {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    bottom: 100%;
}

.pref-success-msg p {
    margin: 0%;
    background-color: #263238;
    color: #fff;
    font-size: 12px;
    line-height: 140%;
    padding: 8px 16px;
    border-radius: 10px;
}

.toggle-cont-sub p {
    color: #525660;
    font-size: 14px;
    margin: 0px;
    line-height: 140%;
    margin-top: 6px;
}

.toggle-approve-cont-main {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toggle-buttons-main {
    display: flex;
    gap: 50px;
    flex-direction: column;
}

.toggle-butn-flex {
    display: flex;
    gap: 50px;
}

.toggle-table {
    width: 100%;
    border-collapse: collapse;
}

.toggle-header {
    background-color: #F6F6F8;
}

.toggle-header th:first-child {
    width: 50%;
    text-align: left;
    padding: 10px 20px;
}

.toggle-header th {
    color: #525660;
    font-size: 14px;
    width: 10%;
    font-weight: 100;
    font-family: 'poppinsmedium';
}

.toggle-sub-cont td:first-child {
    padding-left: 20px;
    padding-top: 30px;
    text-align: left;
}

.toggle-sub-cont td:first-child span {
    color: #011149;
    font-family: 'poppinssemibold';
    font-size: 16px;
}

.toggle-sub-cont td:first-child p {
    color: #525660;
    font-family: 'poppinsregular';
    font-size: 14px;
    margin: 0px;
    line-height: 140%;
    margin-top: 6px;
}

.toggle-table>tr:not(:first-child),
.toggle-table>tr:nth-child(n+2):nth-child(-n+4)>td:not(:first-child) {
    text-align: center;
}

.communication-msg {
    padding: 0px 20px;
    margin-top: 6px;
}

.communication-msg span {
    color: #015EF7;
    font-size: 12px;
    font-family: 'poppinsmedium';
}

.unsubscribe-toggle-cont-all {
    margin-top: 14px;
}

.unsubscribe-toggle {
    display: inline;
}

.toggle-left-btn p {
    display: none;
}

/*shailza css */


.unsubscribeform {
    padding: 0px 40px 40px 40px;
}

.unsubscribe-content p {
    line-height: 140%;
    font-size: 16px;
    color: #525660;
    text-align: center;

}

.make-payment-form,
.main-options {
    margin-top: 40px;
}

.make-payment-form .footer-btn {
    margin-bottom: 0px;
}

.main-options .make-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin: 10px 0;
    cursor: pointer;
}

.main-options input[type="radio"] {
    accent-color: #0FA82E;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.make-box span {
    font-size: 16px;
    line-height: 140%;
    color: #8A91A1;
}

.main-options p {
    color: #011149;
    font-size: 16px;
    font-family: 'poppinsmedium';
    margin: 0;
}

.option-box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    gap: 20px;
}

.option-box .option {
    font-size: 12px;
    line-height: 140%;
    padding: 8px 16px;
    background-color: rgba(208, 52, 44, 0.1);
    border-radius: 10px;
    border: 0px solid;
    color: #263238;
    background-color: #F6F6F8;
    cursor: pointer;
    font-family: 'poppinsmedium';
}

.option-box .option.red-option {
    color: #D0342C;
}

.option.red-option.option-red-selected {
    border: 1px solid #D0342C;
}

.option.selected-option {
    border: 1px solid #4D3ECC;
    color: #4D3ECC;
}

.make-payment-btn {
    display: flex;
    justify-content: center;
    margin: 30px 0;
}

.make-payment-btn button {
    background-color: #4D3ECC;
    border-radius: 6px;
    padding: 8px 16px;
    color: #fff;
    font-size: 16px;
    border: 0px solid;
    outline: none;
    cursor: pointer;
}

.make-payment-option .over-amt {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 30px 0;
}

.over-amt span {
    color: #D0342C;
    font-size: inherit;
    font-family: 'poppinssemibold';
}

.make-pay .popup-form-head span::after {
    bottom: -25%;
}

.make-pay .thank-popup-lottie {
    padding-top: 0px;
}

/* data deletion */

/* Delete form css starts */

.data-deletion p {
    margin: 10px 0;
    color: #000000;
    font-size: 16px;
    line-height: 140%;
}

.data-deletionform {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
}

.data-deletionform .preferences {
    justify-content: flex-start;
    margin-top: 20px;
}

.data-deletionform .account-state-button button {
    margin: 0 0 20px;
}

.data-deletionform input {
    border: none;
    outline: none;
    padding: 4px 10px 10px;
    border-bottom: 1px solid #D5D7DD;
    font-size: 16px;
    line-height: 140%;
    color: #011149;

}

.data-deletionform input::placeholder {
    color: #525660;
}

.data-deletionform label {
    font-size: 16px;
    line-height: 140%;
    font-family: 'poppinsmedium';
    color: #011149;
}

.delete-opt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.delete-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.delete-box span {
    font-family: 'poppinsmedium';
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #011149;
}

.delete-box p {
    margin: 0;
    font-size: 16px;
    line-height: 140%;
}

.center {
    text-align: center;
}

.delete-btns {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.delete-btns button {
    color: #fff;
    border-radius: 8px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.delete-btns .cancel {
    background-color: #0FA82E;
}

.delete-btns .delete {
    background-color: #D0342C;
}

.wid-180 {
    width: 180px;
}

/* Delete form css ends */

@media screen and (max-width:604px) {
    .add-number-selection {
        width: 100%;
    }

    .preferences {
        margin-top: 60px;
    }

    .add-number-head {
        width: 100%;
    }

    .toggle-approve-label-main {
        display: none;
    }

    .toggle-cont-sub {
        flex-direction: column;
    }

    .toggle-approve-label {
        width: 100%;
    }

    .toggle-approve-right-main-sms {
        width: 100%;
    }

    .toggle-cont-sub {
        gap: 10px;
    }

    .toggle-approve-right-main-sms {
        border: 1px solid #E4E2F8;
        border-radius: 10px;
        padding: 15px 0px 14px 0px;
        ;
    }

    .toggle-left-btn .switch {
        width: 28px;
        height: 16px;
    }

    .toggle-left-btn .slider:before {
        width: 14px;
        height: 14px;
        bottom: 1px;
    }

    .toggle-left-btn input:checked+.slider:before {
        transform: translateX(10px);
    }

    .toggle-left-btn {
        text-align: center;
    }

    .toggle-left-btn p {
        display: block;
        font-size: 12px;
        font-family: 'poppinsmedium';
    }

    .toggle-approve-right {
        padding: 0px;
    }

    .toggle-cont-sub p {
        margin-top: 6px;
    }

    .unsubscribe-toggle-cont-all {
        margin-top: 0px;
    }

    .toggle-approve-cont-main {
        padding: 0px;
    }

    .unsubscribe-toggle span {
        font-size: 14px;
    }

    .unsubscribe-toggle p {
        font-size: 12px;
    }

    .tgle-btn-sub .switch {
        width: 28px;
        height: 16px;
    }

    .tgle-btn-sub .slider:before {
        width: 14px;
        height: 14px;
        bottom: 1px;
    }

    .tgle-btn-sub input:checked+.slider:before {
        transform: translateX(10px);
    }

    .communication-msg {
        padding: 0px;
    }

    .option-box .option {
        padding: 5px 10px;
    }
}

@media screen and (max-width:726px) {
    .toggle-table tr:not(:first-child) {
        display: flex;
        flex-wrap: wrap;
    }

    .toggle-table tr:not(:first-child) td {
        flex-basis: 16.666%;
        text-align: center;
        padding: 10px;
    }

    .setting-header-span span {
        font-size: 14px;
    }

    .setting-back-bg {
        box-shadow: 0px 8px 8px rgba(26, 57, 96, 0.05);
    }

    /* delete css start*/
    .data-deletion p {
        text-align: center;
        font-size: 14px;
    }

    .data-deletionform label,
    .delete-box span {
        text-align: center;
        font-size: 16px;
    }

    .data-deletionform .preferences {
        justify-content: center;
    }

    .data-deletionform input {
        font-size: 14px;
    }

    /* delete css end*/

}