.main-about {
    margin-top: 76px;
    background-image: url('../../Icons/about-back.svg');
    background-repeat: no-repeat;
    background-size: 100%;
}

.main-about .eligibility-criteria-box {
    padding-top: 20px;
    position: relative;
}

.main-about .left-top,
.main-about .left-top a,
.main-about .left-top span {
    color: #8A91A1;
}

.main-about .left-top {
    padding: 0;
}

.about-heading {
    font-size: 36px;
    line-height: 140%;
    font-family: 'poppinssemibold';
    margin: 0;
    color: #011149;
    margin-top: 10px;
}

.about-heading span {
    color: #4D3ECC;
}

.main-about .eligibility-criteria-box p,
.we-do-para {
    margin: 0;
    font-size: 16px;
    line-height: 140%;
    color: #011149;
}

.about-p {
    padding-bottom: 40px;
}

.main-about .eligibility-criteria-box p {
    margin: 16px 0;
}


.what-we-do .product-box {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    gap: 26px;
}

.what-we-do .eligibility-criteria-box {
    padding-top: 40px;
}

a.p-box {
    text-decoration: none;
    padding: 40px 26px;
    border-radius: 25px;
    color: #fff;
    background-image: url('../../Icons/about-box-back.svg');
    background-repeat: no-repeat;
    background-position: right;
    transition: 0.5s all;
    width: 38vw;
}

a.p-box:hover {
    transform: scale(1.1);
}

.p-box .head {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    color: inherit;
    font-size: 16px;
}

.p-box p {
    margin: 0;
    font-size: 16px;
    line-height: 140%;
    color: inherit;
}

.head .right-head h3 {
    margin: 0;
    color: inherit;
    font-size: 24px;
}

.first-head {
    padding: 5px 12px;
    background-color: #F8F8FF;
    border-radius: 10px;
    width: fit-content;
    color: #011149;
    font-family: 'poppinsmedium';
    font-size: 20px;
    line-height: 140%;
}

.second-head {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin: 30px 0 20px;
    margin: 0 auto;
    margin-top: 30px;
    width: 75%;
    margin-bottom: 20px;
}

.left-slider {
    width: 100%;
}

.conventional-slider {
    display: flex;
    justify-content: center;
    align-items: center;
}

.conv-slider {
    text-align: center;
    margin: 15px 0;
    transition: all 0.5s;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
}

.conv-slider .left-slide,
.conv-slider .right-slide {
    font-size: 18px;
    line-height: 140%;
    border: 1px solid #D5D7DD;
    padding: 20px;
    border-radius: 15.8559px;
    color: #8A91A1;
    min-width: 350px;
    z-index: 1;
}

.slider-img {
    width: 80px !important;
    background-color: transparent;
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
}

.just-img {
    position: relative;
}

.circle-back {
    background-color: #F8F8FF;
    border-radius: 50%;
    height: 180px;
    width: 180px;
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.circle-back span {
    position: relative;
    top: -30px;
    line-height: 140%;
    text-overflow: ellipsis;
    padding: 0 30px;
}


.slick-slide.slick-active.slick-current+.slick-slide.slick-active+.slick-slide.slick-active .conv-slider .circle-back {
    display: flex !important;
    flex-direction: column;
}

.slick-slide.slick-active.slick-current+.slick-slide.slick-active+.slick-slide.slick-active .conv-slider {

    justify-content: space-around;
}

.slick-slide.slick-active.slick-current+.slick-slide.slick-active+.slick-slide.slick-active .conv-slider .right-slide,
.slick-slide.slick-active.slick-current+.slick-slide.slick-active+.slick-slide.slick-active .conv-slider .left-slide {
    background: #FFFFFF;
    box-shadow: 0 25px 50px #eaeeff;
    border: 0px solid;
    transform: scale(1.2, 1.15);
    box-shadow: 0px 0px 20px rgba(109, 87, 223, 0.2);
    color: #011149;
    font-family: 'poppinssemibold';
    transition: all 0.2s;
}

.about-grp,
.about-slider {
    margin: 80px 0;
}

.about-slide-show .fullstep-heading {
    margin-top: 20px;
}

.about-slide-show .fullstep-heading span {
    font-size: 32px;
    font-family: 'poppinssemibold';
    line-height: 140%;
    color: #4D3ECC;
}

.about-slide-show .fullstep-heading span::after {
    width: 0px;
}

.about-slide-show .fullslider-bg {
    background-image: none;
}

.about-slide-show .fullstep-heading p {
    font-size: 20px;
    line-height: 140%;
    color: #525660;
    margin-top: 20px;
}

.full-slider-list {
    list-style: none;
    position: relative;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    margin: 20px 0;
}

ul.slider-list {
    padding-left: 40px;
    margin-top: 0px;
}

.full-slider-list::before {
    content: "\2713";
    position: absolute;
    background-color: #0FA82E;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: -40px;
    top: 50%;
    color: #fff;
    transform: translate(0, -50%);
    display: flex;
    align-items: end;
    font-size: 21px;
    justify-content: center;
}

.about-slide-show .slider-link {
    text-decoration: none;
    background-color: #0FA82E;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;
    padding: 14px 28px;
    transition: all 0.5s ease-in-out;
    position: relative;
    top: 20px;
}

.about-slide-show .slider-link:hover {
    transform: scale(1.1);
}

.about-slider-img {
    position: relative;
    top: 50px;
}

.main-cont {
    font-size: 28px;
    line-height: 140%;
    color: #4D3ECC;
    font-family: 'poppinssemibold';
    margin-top: 10px;
}

.about-slide p {
    font-size: 14px;
    line-height: 140%;
    color: #525660;
}

.about-slide {
    margin-bottom: 40px;
}

.about-slide-show .fullpageslide-main {
    flex-direction: row;
}

.about-slide-show .full-page-sticky {
    min-height: 100vh;
}

.about-slide-show .Fullpage-App-header {
    align-items: self-start;
    justify-content: center;
}

.about-slide-show .fullpage-contnt {
    padding-top: 0%;
}

.about-slide-show .full-right-img img {
    height: 70vh;
    margin-top: 160px;
}

/* 
.about-slide-show .Fullpage-App-header{
    min-height: 146vh;
} */

.top-slider,
.bottom-slider {
    margin: 30px 0;
}

.about-conv-slides {
    margin: 4px 0;
}

.about-conv-slides div {
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #8A91A1;
    padding: 14px 0;

}


.center-box .img-div {

    width: 160px;
    height: 160px;
    padding: 0;
    margin: auto;
    background-color: #F8F8FF;
    border-radius: 50%;
    position: relative;
}

.center-box .img-div .img-b {
    /* width: 100px; */
    position: absolute;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.center-box .img-div .img-b img {
    width: 60%;
}

.center-box .img-div span {
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #000000;
}

.bottom-slider .slick-slide.slick-active.slick-current,
.top-slider .slick-slide.slick-active.slick-current {
    background: #FFFFFF;
    box-shadow: 0 25px 50px #eaeeff;
    border: 0px solid;
    transform: scale(0.94, 1.1);
    box-shadow: 0px 0px 20px rgba(109, 87, 223, 0.2);
    transition: all 0.2s;
    border-radius: 10px;
}

.bottom-slider .slick-slide.slick-active.slick-current {
    margin-top: 10px;
}

.top-slider .about-heading {
    margin-top: 20px;
}

.bottom-slider .slick-slide.slick-active.slick-current div,
.top-slider .slick-slide.slick-active.slick-current div {
    color: #011149 !important;
    font-family: 'poppinsmedium';
}

.top-slider .slick-list {
    height: 360px !important;
}

.tenet-heading p {
    color: #011149;
    font-size: 44px;
    font-family: 'poppinssemibold';
}

.convvsmw br {
    display: none;
}

@media (max-width:900px) {
    .main-about {
        margin-top: 60px;
    }

    .main-about .left-top {
        text-align: left;
    }

    .about-heading {
        text-align: center;
        font-size: 28px;

    }

    .top-slider .about-heading {
        margin-top: 0px;
    }

    .main-about .eligibility-criteria-box p,
    .we-do-para,
    .p-box .head,
    .p-box p {
        font-size: 14px;
    }

    .what-we-do .product-box {
        flex-direction: column;
    }

    .head .right-head h3 {
        font-size: 22px;
    }

    a.p-box:hover {
        transform: scale(1);
    }

    .what-we-do .eligibility-criteria-box {
        padding-top: 0;
    }

    .main-about .eligibility-criteria-box {
        padding-top: 20px !important;
        padding-bottom: 0;
    }

    ul.slider-list {
        margin: 0;
    }

    .about-grp,
    .about-slider {
        margin: 40px 0;
    }

    .full-slider-list {
        font-size: 16px;
    }

    .full-slider-list img {
        width: 30px;
    }

    .about-slide-show .slider-link {
        top: 10px;
        font-size: 14px;
        padding: 14px 20px;
        left: 2px;
    }

    .about-slide-show .slick-prev,
    .about-slide-show .slick-next {
        top: 106%;
        background: #FFFFFF;
        border: 1px solid #E4E2F8;
        border-radius: 10px;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .about-slide-show .slick-prev {
        left: 0;
    }

    .about-slide-show .slick-next {
        right: 0;
    }

    .about-slide-show .slick-prev:before,
    .slick-next:before,
    .about-slide-show .slick-prev:before,
    .slick-next:before {
        color: #4D3ECC;
    }

    .mobile-about-slider {
        margin-top: 20px;
    }

    .first-head {
        font-size: 16px;
    }

    .main-cont {
        font-size: 22px;
    }

    a.p-box {
        width: fit-content;
        padding: 20px;
    }

    .convvsmw br {
        display: block;
    }

    .mobile-about-slider .mw-announce-arrow-desscription {
        top: 98.1%;
    }
}

@media screen and (max-width:420px) {
    .about-heading {
        font-size: 26px;
    }

    .full-slider-list::before {
        width: 20px;
        height: 20px;
        left: -36px;
        font-size: 14px;
        align-items: center;
    }
}