.ovdpopup{
    margin:12% auto;
    width:392px;
    height:251px;
    border-radius: 20px;
    background-color: #fff;
}
.ovdpopup-content{
    margin: 35px 35px;
}
.ovdpopup h2{
    font-size: 24px;
    text-align: center;
    color:#0FA82E;
    padding-top:25px;
}
.ovdpopup .ovdpopup-content ul{
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin-top:-3px;
}
.ovdpopup .ovdpopup-content ul li{
    padding: 18px 0px;
    font-family: 'poppinsregular'!important;
    font-size: 16px;
    color:#011149;
}
.ovdpopup .ovdpopup-content ul li:hover{
    background-color: #F6F6F8;
    cursor: pointer;
}
@media screen and (max-width:410px) {
    .ovdpopup{
        width:95%;
    }
}

