.IncorrectBankDocumentPopUp{
    width:414px;
    background-color: #fff;
    border-radius: 25px;
    margin:12% auto;
}
.IncorrectBankDocumentPopUp .wrongcontent{
    text-align: center;
    margin: 30px;
}
.IncorrectBankDocumentPopUp .wrongcontent img{
    margin-top: 40px;
    width: 100px;
    height: 100px;
}
.IncorrectBankDocumentPopUp .wrongcontent h2{
    margin-top: 30px;
    color: #011149;
    font-family: 'poppinssemibold';
    font-size: 24px;
}
.IncorrectBankDocumentPopUp .wrongcontent h3{
    color: #011149;
    font-family: 'poppinssemibold';
    font-size: 20px;
    text-align: center;
    margin: 16px 0;
}
.IncorrectBankDocumentPopUp .wrongcontent p{
    color: #525660;
    font-family: 'poppinsregular';
    font-size: 16px;
    margin: 16px 0;
    line-height: 140%;
}
.IncorrectBankDocumentPopUp .wrongcontent button{
    width: 100%;
    height: 56px;
    background-color: #0FA82E;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    font-family: 'poppinssemibold';
    cursor: pointer;
    margin-bottom: 30px;

}

@media screen and (max-width:1366px) {
    .IncorrectBankDocumentPopUp .wrongcontent h3{
        font-size: 18px;
    }
}

@media screen and (max-width:440px) {
    .IncorrectBankDocumentPopUp{
        width:94%;
    }
    .IncorrectBankDocumentPopUp .wrongcontent h2{
        font-size: 20px;
    }
    .IncorrectBankDocumentPopUp .wrongcontent{
        margin: 0 20px;
    }
    .IncorrectBankDocumentPopUp .wrongcontent button{
        margin-bottom: 20px;
    }
}
