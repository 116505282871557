@font-face {
    font-family: 'poppinssemibold';
    src: url('../../Font/poppins-semibold-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.FAQ-Question-Content a {
    text-decoration: none;
    font-family: 'poppinsmedium';
    color: #015EF7;
    font-size: inherit;
    }

.FAQs {
    margin: 80px 0;
}

.FAQ-container {
    margin: 0 auto;
    padding: 0 5px;
}

.FAQ-container .heading-h2 {
    font-size: 36px;
    line-height: 30px;
    color: #011149;
    position: relative;
}

.FAQ-container .heading-h2::after {
    content: '';
    background-color: #4d3ecc;
    height: 6px;
    position: absolute;
    bottom: -18px;
    right: 0;
    left: 0;
    border-radius: 10px;
    width: 100px;
}

.heading-p {
    line-height: 22px;
    color: #455a64;
}

.box-shape {
    width: 80%;
    margin: auto;
}

.faq-arr {
    width: 18px;
    transform: rotate(-90deg);
    margin-right: 4px;
}
.FAQ-container .bot-6::before{
    bottom:-20px;
}
.question {
    color: #18375f;
    font-family: 'poppinssemibold';
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    align-items: center;
}

.question:hover {
    color: #301bc0;
    cursor: pointer;
}

.question-left {
    padding: 10px 0;
    font-size: 18px !important;
    line-height: 22px;
    font-weight: 600;
}

.question-right {
    width: 5%;
    font-size: 30px;
    padding-top: 0;
    margin-top: 0;
    margin-right: 0;
    padding-left: 8px;
    transform: rotate(270deg);
    font-weight: 100;
}

.question-box {
    background: #fff;
    /* border-radius: 12px;
border: 1px solid #e5f2ff;
box-shadow: 1px 1px 4px #ddd; */
    margin: 10px 0;
    max-height: 70px;
    /* transition: max-height 1s ease-in-out; */
    overflow: hidden;
    /* height: 80px;
overflow: auto; */
    border-bottom: 1px solid #D5D7DD;
}

.question-box.active {
    color: #301bc0 !important;
    max-height: 500px;
    transition: max-height 0.5s ease-in-out;
    /* height: 200px; */
}

.FAQ-Question-Content {
    color: #455a64;
    padding: 0;
    font-family: 'poppinsregular';
    padding-bottom: 20px;
    margin-top: -14px;
}

.normal-list {
    list-style: none;
}

.normal-list li {
    margin-bottom: 10px;
}

.normal-list li::before {
    content: "\2022";
    color: #eb5f32;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.manage-div-limit {
    max-height: 430px;
    position: relative;
    overflow: hidden;
    margin-bottom: 60px;
}

.manage-div-limit.open-div {
    max-height: fit-content;
    transition: max-height 0.5s ease-in-out;
}




@media screen and (max-width:720px) {
    .FAQ-Question-Content .heading-p {
        font-size: 14px;
    }

    .heading-h2 {
        font-size: 22px;
    }

    .ploan-container {
        padding: 0;
    }

}

@media screen and (max-width:500px) {
    .FAQ-container .bot-6::before{
        bottom:-10px;
    }
    .FAQs {
        margin: 20px 0;
    }

    .FAQ-container .heading-h2 {
        font-size: 22px;
        padding-bottom: 0px !important;
    }

    .FAQ-container {
        padding: 0;
    }

    .box-shape {
        width: 90%;
    }
}