.service-checkbox {
    display: flex;
    align-items: flex-start;
}

.service-checkbox label {
    font-size: 11px;
    line-height: 16px;
    color: #455A64;
    margin-left: 10px;
}
.service-class{
    font-size: 20px !important;
}