.step-main{
    width: 90%;
    height: 30px;
    margin:  6% auto;
    position: relative;
    background-color: #fff;
}

.step-bar-main{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 30px; */
}

.steps-circle-span{
    font-size: 12px;
    color: #fff;
}

.steps-circle-gray{
    width: 24px;
    height: 16px;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D5D7DD;
    z-index: 9;
}

.steps-circle-orange{
    width: 24px;
    height: 16px;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFC035;
    z-index: 9;
    border: 2px solid #FFEDC4;
}

.steps-circle-green{
    width: 24px;
    height: 16px;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #43E763;
    background-color: #0FA82E;
    z-index: 9;
}
.steps-circle-span img{
    /* margin-bottom: 1px; */
}

#steps-circle1::after, #steps-circle2::after, #steps-circle3::after, #steps-circle4::after{
    position: absolute;
    top: 25px;
    font-size: 15px;
    /* font-weight: 600; */
    color:#011149;
}

#steps-circle1::after{
    content: "Profile";
}

#steps-circle2::after{
    content: "Offer";
}

#steps-circle3::after{
    content: "eKYC";
}

#steps-circle4::after{
    content: "Disbursal";
}

/* .steps-circle-span{
    color: white;
} */

.steps-line-gray{
    width: 38%;
    height: 3px;
    background-color: #D5D7DD;
    z-index: 9;
}

.steps-line-orange{
    width: 38%;
    height: 3px;
    background-color: #FFC035;
    z-index: 9;
}

.steps-line-green{
    width: 38%;
    height: 3px;
    background-color: #0FA82E;
    z-index: 9;
}

.step-bar-baseline{
    width: 100%;
    height: 3px;
    background-color: gray;
    position: absolute;
    top: 46%;
}
@media screen and (max-width:500px) {
    #steps-circle1::after, #steps-circle2::after, #steps-circle3::after, #steps-circle4::after{
        top: 24px;
        font-size:14px;
    }
}

@media screen and (max-width:400px){
    #steps-circle1::after, #steps-circle2::after, #steps-circle3::after, #steps-circle4::after{
        font-size: 12px;
    }
}

@media screen and (max-width:300px){
    #steps-circle1::after, #steps-circle2::after, #steps-circle3::after, #steps-circle4::after{
        font-size: 10px;
    }
}