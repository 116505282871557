 
.sachterm_1{
    list-style: none;
    margin: 20px 0;
    color:#455a64;
}
.sachterm_1 .sachterm_2::before{
    content: "\2022";
    color:orangered;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}
.sachinb{
    font-weight: bold;
    color:#3b4549;
}
.gtm_interlinking{
    color: #1569d5;
    text-decoration: underline;
    font-family: poppinssemibold!important;
}
