.loan-details-heading {
    font-family: 'poppinsmedium';
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 20px;
}

.contentHead {
    font-size: 12px;
    line-height: 140%;
    color: #525660;
}

.contentData {
    font-size: 14px;
    line-height: 140%;
    color: #011149;
    font-family: 'poppinsmedium';
    margin-top: 5px;
}


.loan-details-box {
    background: #FFFFFF;
    margin: 0;
    border: 1px solid #F6F6F8;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 10px;
    padding: 25px 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
}

.trasaction-head {
    display: flex;
    align-items: center;
    gap: 10px;
}

.trasaction-head img {
    /* filter: grayscale(100%); */
    cursor: pointer;
}

.trasaction-head span {
    font-size: 20px;
    color: #011149;
    font-family: "poppinssemibold";
}

.repayment-table-heaing {
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    font-family: 'poppinsmedium';
    margin-top: 20px;
    margin-bottom: 10px;
}


.myacc-repayment-table table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 10px;
    padding: 0 2px;
    margin-bottom: 20px;
}

.myacc-repayment-table table tr {
    height: 56px;
}

.myacc-repayment-table table thead {
    background: #4D3ECC;
}

.myacc-repayment-table table tr th {
    height: 56px;
    text-align: center;
    font-size: 18px;
    line-height: 140%;
    font-family: 'poppinsmedium';
    color: #FFFFFF;
}

.myacc-repayment-table table tr th:first-child {
    border-top-left-radius: 10px;
}

.myacc-repayment-table table tr th:last-child {
    border-top-right-radius: 10px;
}

.myacc-repayment-table table tr td {
    height: 56px;
    text-align: center;
    font-size: 16px;
    line-height: 140%;
    color: #011149;
}

.myacc-repayment-table table tr:nth-child(2n) {
    background-color: #F6F6F8;
}

.myacc-repayment-btns {
    display: none;
}


.myacc-repayment-table table::-webkit-scrollbar {
    height: 4px;
}

.myacc-repayment-table table::-webkit-scrollbar-track {
    background: #D5D7DD;
}

.myacc-repayment-table table::-webkit-scrollbar-thumb {
    background: #8A91A1;
    border-radius: 4px;
}

.myacc-repayment-table table::-webkit-scrollbar-thumb:hover {
    background: #8A91A1;
}

.managing-res-address {
    margin: 10px 0px 0px;
    padding: 20px 0px;
    padding-right: 20px;
}

.myc-back-btn {
    cursor: pointer;
    display: flex;
    justify-content: end;
    position: absolute;
    right: 0;
}

.foreclosure-back {
    position: absolute;
    right: 0;
}

@media screen and (max-width:1115px) {
    .loan-details-box {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:944px) {
    .loan-details-box {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width:900px) {
    .as-period-content {
        flex-direction: column;
        align-items: center;
    }
}

.manage-myaccount-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
}

@media screen and (max-width:500px) {
    .myAccountBoxContent {
        padding: 6px;
        padding-bottom: 15px;
    }

    .loan-details-box.myAccountBoxContent {
        padding: 25px;
    }

    .myacc-repayment-table table tr th:first-child {
        border-radius: 0px;
    }

    .myacc-repayment-table table {
        display: flex;
        height: 390px;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
    }

    .myacc-repayment-table table tr {
        display: flex;
        flex-direction: column;
    }

    .myacc-repayment-table table tbody {
        display: flex;
    }

    .myacc-repayment-table table thead {
        background-color: #fff;
        position: sticky;
        left: 0;
        z-index: 1;
    }

    .myacc-repayment-table table tr:first-child {
        background-color: #fff;
    }

    .myacc-repayment-table table tr th {
        background-color: #fff;
        font-size: 16px;
        line-height: 140%;
        align-items: center;
        color: #011149;
        min-width: 150px;
        min-height: 33px;
        padding: 16px 0;
        border-bottom: 1px solid #D5D7DD;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
    }

    .myacc-repayment-table table tr td {
        min-width: 110px;
        min-height: 33px;
        font-size: 14px;
        border-bottom: 1px solid #D5D7DD;
        display: flex;
        justify-content: center;
        padding: 16px 0;
        align-items: center;
    }

    .myacc-repayment-table table tr:nth-child(2n) td {
        background-color: #F6F6F8;
    }

    .myacc-repayment-table table tr th:last-child {
        border-radius: 0 0 0 10px;
    }

    .myacc-repayment-btns {
        display: block;
        padding-left: 150px;
        display: flex;
        justify-content: space-between;
        height: 30px;
        background-color: #fff;
        border: 1px solid #D5D7DD;
        border-radius: 10px 10px 0 0;
    }

    .myacc-repayment-btns button {
        border: 0px solid;
        background: none;
        font-size: 11px;
        color: #015EF7;
        cursor: pointer;
    }

    .my-acc-dis {
        line-height: 140%;
        color: #8A91A1 !important;
    }

    .left {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-45deg);
        margin-bottom: -2px;
    }

    .right {
        transform: rotate(45deg);
        -webkit-transform: rotate(135deg);
        margin-bottom: -2px;
    }


}

/* transaction history repay box css  */
.recenttransaction-main-box {
    background: #FFFFFF;
    margin: 0;
    border: 1px solid #F6F6F8;
    box-shadow: 0px 8px 20px rgb(26 57 96 / 5%);
    border-radius: 10px;
    padding: 25px 30px;
    margin-bottom: 20px;
}

.recenttrans-boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.recenttrans-left {
    display: flex;
    gap: 14px;
}

.recenttrans-box-heading span {
    color: #000;
    font-size: 16px;
    font-family: "poppinsmedium";
}

.recenttrans-box-heading p {
    font-size: 14px;
    color: #525660;
    font-family: "poppinsmedium";
    margin: 0px;
    margin-top: 6px;
}

.recenttrans-right {
    display: flex;
    gap: 10px;
}

.recenttrans-right span {
    color: #0FA82E;
    font-size: 16px;
    font-family: "poppinsmedium";
}

.break-head {
    margin-top: 10px;
}

.break-head span {
    font-size: 16px;
    color: #011149;
    font-family: 'poppinsmedium';
}

.breakdown-main-content {
    display: flex;
    gap: 10%;
    margin-top: 14px;
}

.bdcont span {
    font-size: 12px;
    color: #525660;
    font-family: "poppinsmedium";
}

.bdcont p {
    font-size: 14px;
    color: #011149;
    font-family: "poppinsmedium";
}

.reapyborder {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

@media screen and (max-width:750px) {
    .breakdown-main-content {
        flex-direction: column;
    }

    .bdcont {
        display: flex;
        align-items: center;
        gap: 60px;
        justify-content: space-between;
    }

    .recenttrans-bottom-dwn {
        padding-left: 10px;
    }
}

/* transaction history repay box css  */
/*account statement css  */
.as-head {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.as-head button {
    background-color: #015EF7;
    color: #fff;
    padding: 7px 10px;
    outline: none;
    border: 0px solid;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.send-success {
    display: flex;
    align-items: center;
    gap: 1px;
    color: #FFFFFF;
    background: #0FA82E !important;
}

.as-head button img {
    width: 18px;
    height: 18px;
}

.as-head button:first-child {
    min-width: 103px;
}

.as-head button:last-child {
    min-width: 171px;
}

.download-ctn-loader {
    height: 32px;
}

.as-head span {
    color: #000000;
    font-size: 16px;
    font-family: "poppinsmedium";
    line-height: 140%;
}

.as-period {
    background: #FFFFFF;
    margin: 0;
    border: 1px solid #F6F6F8;
    box-shadow: 0px 8px 20px rgb(26 57 96 / 5%);
    border-radius: 10px;
    padding: 25px 30px;
    margin: 20px 0;
}

.as-period-content {
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
}

.date-box {
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    position: relative;
}

.date-box input {
    border: none;
    font-size: 16px;
    color: #525660;
    margin-left: 10px;
}

.forebox input {
    width: 224px;
    position: relative;
    color: #32343A;
}

.date-box input:focus,
.date-box textarea:focus,
.date-box select:focus {
    outline: none;
}

.date-box input::placeholder {
    font-size: 14px;
}

.add-border {
    border-bottom: 1px solid #6D57DF !important;
}

.account-state-button {
    display: flex;
    justify-content: center;
    margin-top: 42px;
}

.account-state-button button {
    border: none;
    background-color: #D5D7DD;
    color: #8A91A1;
    font-size: 18px;
    font-family: "poppinssemibold";
    width: 334px;
    border-radius: 10px;
    padding: 20px 0px;
    cursor: pointer;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;
}
.account-state-button.partial1 button{
    margin-top: -10px;
}

.account-state-button.partial1{
    margin-top: 0px;
}

.account-state-button .activate {
    background-color: #0FA82E;
    color: #fff;
}
.account-state-button.partial .activate{
    background-color: #0FA82E;
}
.account-state-button.partial1 .activate{
    background-color: #525660;
}
.small-the-icon {
    width: 20px;
    height: 20px;
}

.myc-err-text {
    color: #D0342C !important;
    font-size: 11px;
    margin-top: 3px;
    margin-bottom: -60px;
    line-height: 140%;
    position: absolute;
}

/* foreclose yellow box */
.foreclose-yellow-box {
    border: 1px solid #FFCC00;
    border-radius: 10px;
    padding: 16px 14px;
    margin: 20px 0px;
    background-color: #FFFCEF;
    display: flex;
    align-items: center;
    gap: 12px;
}

.yellow-box-spacing {
    width: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.foreclose-yellow-box span {
    font-size: 14px;
    color: #011149;
    line-height: 140%;
}

.has-download-btn {
    display: flex;
    gap: 10px;
}

@media screen and (max-width:750px) {
    .as-head {
        flex-direction: column;
        gap: 10px;
    }

    .has-download-btn {
        justify-content: flex-end;
    }

    .send-success {
        width: 50%;
    }

    .send-success {
        gap: 1px !important;
    }

    .foreclose-yellow-box {
        align-items: flex-start;
    }

    .acc-btn {
        margin-top: 20px;
    }
}

/* foreclose yellow box end */

/*foreclose green box  */

.forclosegreenbox {
    border: 1px solid #0FA82E;
    border-radius: 10px;
    padding: 16px 14px;
    margin: 20px 0px;
    background-color: #FFF;
    display: flex;
    align-items: start;
    gap: 12px;
}

.forclosegreenbox span {
    font-size: 11px;
    color: #0FA82E;
    line-height: 140%;
}

.forclose-proceedbox {
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 30px;
    box-shadow: 0px 8px 20px rgb(26 57 96 / 5%);
}

.forclose-amnts span {
    color: #525660;
    font-size: 12px;
}

.forclose-amnts p {
    color: #011149;
    font-size: 14px;
}

.forclose-all-amnts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 50px;
}

.forclose-proceed-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;
}

.forclose-proceed-btn .fc-proceed-green {
    border: none;
    background-color: #0FA82E;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    font-family: "poppinssemibold";
    width: 334px;
    padding: 20px 0px;
    cursor: pointer;
    height: 56px;
}

.proceed-popup-content span {
    color: #011149;
    font-size: 20px;
    font-family: 'poppinssemibold';
    display: flex;
    justify-content: center;
}

.proceed-popup-content p {
    color: #525660;
    font-size: 16px;
    text-align: center;
    line-height: 140%;

}

@media screen and (max-width:1052px) {

    /* .forclosegreenbox{
        width: 100%;
    } */
    .forclose-all-amnts {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 74px;
    }
}

@media screen and (max-width:602px) {
    .forclose-all-amnts {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:354px) {
    .forclose-all-amnts {
        column-gap: 14px;
    }

    .proceed-popup-content span {
        text-align: center;
    }
}

/*foreclose green box  css end*/

/* overduepayment css */
.overdue-payment-box {
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 30px;
    box-shadow: 0px 8px 20px rgb(26 57 96 / 5%);
    margin: 20px 0px;
    position: relative;
}

.od-main {
    text-align: center;
    margin: 0px 118px;
}

.make-pay-overdue {
    padding: 0;
    margin: 0;
    box-shadow: none;
    position: relative;
}

.make-pay-overdue .od-main {
    margin: 0;
    margin-top: 10px;
}

.od-head span {
    font-size: 20px;
    font-family: 'poppinssemibold';
    color: #D0342C;
}

.od-name span {
    font-size: 20px;
    color: #011149;
    font-family: 'poppinssemibold';
}

.od-money {
    margin-top: 10px;
}

.od-money span {
    font-size: 24px;
    color: #D0342C;
    font-family: 'poppinssemibold';
    margin-top: 10px;
}

.od-content p {
    color: #525660;
    font-size: 16px;
    line-height: 140%;
    margin: 10px 0;
}

.foreclosue-err {
    font-size: 11px;
    color: #D0342C !important;
    position: absolute;
    left: 0;
    bottom: -16px;
}


.od-tn-footer {
    margin-bottom: 0%;
}

.od-popup-lottie {
    width: 200px !important;
}

.od-head-blue span {
    font-size: 20px;
    font-family: 'poppinssemibold';
    color: #011149;
}

@media screen and (max-width:750px) {
    .od-main {
        margin: 0px;
    }
}

/* partpayment boxes start */
.pp-boxes-main {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.pp-first-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 80px;
}

.pp-web {
    display: none;
}

.pp-web-2 {
    display: block;
}

@media screen and (max-width:902px) {
    .pp-boxes-main {
        flex-direction: column;
        align-items: center;
    }

    .pp-second-box input {
        width: 242px;
    }

    .pp-web {
        display: block;
    }

    .pp-web-2 {
        display: none;
    }

    .pp-mob-btn {
        margin-top: 50px;
    }

    .pp-first-box {
        grid-template-columns: 1fr;
    }
}