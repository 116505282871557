.login-area-background {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.login-area-content {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(109, 87, 223, 0.2);
    border-radius: 25px;
    display: flex;
    width: 900px;
}

.myacc{
    margin-bottom: 20px;

}
.popUp-checkbox-myacc{
    margin-top: 0px !important;
}
.get-login-tag-myacc{
    align-items: self-start;
}

.main-login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    max-width: 374px;
    padding-bottom: 40px;
}

.main-login-content,
.login-area-imgbox {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.Left-loginArea-con {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Left-loginArea-con h2 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    margin: 20px 0;
}

.Left-loginArea-con h2 span {
    font-family: 'poppinsemibold';
}

.Left-loginArea-con h2 img {
    position: absolute;
}

.Left-loginArea-con p {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    margin-bottom: 8px;
}

.Left-loginArea-con img {
    text-align: center;
    margin-left: 5px;
}

.login-area-imgbox {
    position: relative;
    background: url('../../Icons/background.svg');
    border-radius: 25px 0 0 25px;
    background-size: cover;
    background-position: -15px 0;
    display: flex;
    justify-content:space-between;
}


@media (max-width:700px) {
    canvas{
        width: 130px;
    }
    .login-area-content {
        flex-direction: column-reverse;
        width: 90%;
        max-width: 374px;
        gap:30px;
    }

    .login-area-imgbox .mob {
        display: none;
    }

    .login-area-imgbox {
        width: 100%;
        border-radius: 0 0 25px 25px;
        padding-bottom: 40px;
        background-position: 0;
    }
    .main-login-content{
        width: auto;
        padding-top: 30px;
    }
    .main-login-content{
        padding: 0 20px;
        gap: 0;
    }
    .login-area-background{
        margin-top: 100px;
        height: auto;
    }
}   