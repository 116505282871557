.form-main-cont{
    /* width: 98vw; */
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    margin: auto;
    height: fit-content;
}
.form-main{
    width: 100%;
    margin: auto; 
    /* min-height: 89vh; */
    margin-top: 64px;
    line-height: 20px;
    font-family: 'Poppins', sans-serif;
    display: flex; 
    justify-content: space-evenly;
    height: 100vh;
}
.form-left{
   flex: 1;
    display: flex; 
    justify-content: center;
    margin-left:2.5%;
}

.form-left-content{
    width: 65%;
}
.form-right{
    flex: 1;
    display: flex;
    /* align-items: center; */
    align-items: flex-start;
    justify-content: center;
    /* background: url("../../assests/bg-shape.webp"); */
    background-size:cover;
    background-repeat: no-repeat; 
} 
.form-left h1{
    font-family: 'poppinssemibold';
    color: #011149;
    font-size: 34px;
    font-weight:bolder;
    line-height: 119%;
    word-spacing:2px;
}
.form-left hr{
    width: 109px;
    height:5px;
    background: #e8572f;
    border: none;    
    margin-left: 0;
    margin-top: -2%;
    margin-bottom: 6%;
}
.form-left p{
    font-size: 16px;
    line-height: 170%;
    color: #011149;
    margin-top: -14px;
}

.form-left-svg{
  width: 100%; 
}
@media only screen and (max-width:740px){
  .form-main{
    align-items: center;
    justify-content: space-around;
    /* margin-top: 56px; */
  }
  .form-footer-span{
    display: none;
  }
  .form-left{
    display: none;
  }
}
@media screen and (max-width:500px) {
  .form-main{
    width: 100%;
    height: auto;
    min-height: auto;
    margin-top:56px;
  }
  .form-main-cont{
    width: 100vw;
  }
  .form-right{
    min-height: 79vh;
    margin-top:0;
  }
}