.range-main {
  color: #455a64;
  /* padding-top: 40px; */
}

.range {
  display: flex;
  justify-content: space-between;
  padding: 0px 14px;
}

.range span {
  color: #011149 !important;
  font-size: 12px !important;
}

.range-main h3 {
  padding-left: 10px;
  margin: 0px;
  color: #525660;
  font-size: 18px;
}

.rangeslider-main {
  padding: 1%;
  /* padding-top: 40px; */
  font-weight: bold;
}

.rangesslide-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.rangesslide-new p {
  color: #011149;
  font-size: 20px;
}

.rangesslide-new span {
  color: #011149 !important;
  font-size: 20px !important;
}

.rangeslider-main p {
  padding-left: 2%;
  margin: 0px;
}

 /* parvesh slide css */
  .newrange {
    width: 100%;
    height: 15px;
    -webkit-appearance: none;
    background: #D5D7DD;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: inset #D5D7DD;
}

.newrange::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4D3ECC;
    cursor: pointer;
    border: 4px solid #fff;
    box-shadow: -407px 0 0 400px #4D3ECC;
}
  /* parvesh slide css */



.percentage-box {
  width: 90%;
  margin: 0 auto;
  height: 10px;
  border-radius: 10px;
}

.calculation-main {
  /* padding-top: 5%; */
  display: flex;
  justify-content: space-between;
}

.calculation-main hr {
  width: 70%;
  margin: auto;
  border: 1px solid #ddd;
}

.calculation-first-half {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.calculation-second-half {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.calculator-main {
  width: 80%;
  margin: 0 auto;
  /* padding: 2%; */
  margin-top: 80px;
  /* background: #fff;
    border-radius: 25px;
    box-shadow: 1px 1px 4px #ddd; */
}

.calculation-main p {
  color: #8A91A1;
  font-size: 18px;
  font-family: 'poppinsmedium';
  text-align:left ;
  margin-top: 0px;
  margin-bottom: 8px;
}

.calculator-head {
  /* padding-left: 5%; */
  /* margin-left: 4.5%; */
  padding: 0px 0px 4px;
}

.calculator-head h3 {
  color: #011149;
  font-size: 36px;
  font-family: 'poppinsregular';
  font-weight: 100;
  text-align: center;
  position: relative;
  margin-bottom: 18px;
}

.calculator-head h3::after {
  content: '';
  background-color: #4d3ecc;
  height: 6px;
  position: absolute;
  bottom: -10px;
  right: 0;
  left: 46%;
  border-radius: 10px;
  width: 100px;
}

.calculator-head p {
  color: #525660;
  line-height: 140%;
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.calculation-main h1 {
  color: #011149;
  font-size: 30px;
  text-align: start;
  margin-top: 0px;
  margin-bottom: 30px;
}

.calculator-body {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  color: #455a64;
  position: relative;
  margin-top: 60px;
}

.calc-bg-first {
  z-index: -1;
  position: absolute;
  left: -13%;
}
.calc-bg-first img{
  width: 100%;
}

.calc-bg-second {
  z-index: -1;
  position: absolute;
  right: 2%;
  top: -18%;
}
.calc-bg-second img{
  height: 580px;
}

.calculator-body-left {
  /* flex: 1; */
  /* display: flex;
  flex-direction: column;
  gap: 20px; */
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0px 8.59375px 25.7812px rgba(17, 53, 112, 0.16);
  padding: 40px 40px;
  padding-right: 116px;
  padding-bottom: 40px;
  width: 100%;
}

.calculator-body-left span:first-child {
  color: #8A91A1;
  font-size: 18px;
  font-family: 'poppinsmedium';
}

.calculator-body-left h2 {
  font-size: 30px !important;
  color: #011149 !important;
  font-family: 'poppinssemibold' !important;
  margin: 0px 0px 14px 0px;

}

.calculator-body-right {
    /* flex: 1 1; */
    text-align: center;
    border: 5px solid transparent;
    /* border-image: linear-gradient(45deg, #3f5efb, #fc466b); */
    background: #FFFFFF;
    box-shadow: 0px 8.51393px 25.5418px rgb(17 53 112 / 16%);
    border-radius: 25px;
    border-image-slice: 1;
    width: 100%;
    margin-left: -8%;
    /* padding: 40px; */
    padding: 2% 2% 2% 2%;
}
.calculator-body-right h3{
  color: #011149;
  font-size: 30px;
  font-family: 'poppinsmedium';
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
}

.calculator-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  flex-direction: column-reverse;
  padding-bottom: 10px;
  padding-top: 30px;
  gap: 14px;
}

.repayment-schedule-btn {
  font-size: 18px;
  /* padding: 2.5% 4% 2.5% 4%; */
  border-radius: 10px;
  border: none;
  background: #fff;
  color: #015EF7;
  cursor: pointer;
}

.apply-now-btn {
  font-size: 18px;
  font-weight: bold;
  padding: 4.5% 4% 4.5% 4%;
  border-radius: 10px;
  border: none;
  background-color: #0FA82E;
  cursor: pointer;
  color: white;
  width: 70%;

}
.left-content-login-form a{
  transition: transform .4s;
}
.left-content-login-form a:hover{
  -webkit-transform: scale(1.1);
  transform: scaleX(1.1);
}
/* @keyframes transform {
  0% {
      transform: scale(0);
  }
  
  100% {
      transform: scale(1);
  }
  }  */
.calculator-footer {
  width: 100%;
  margin: auto;
  margin-top: 3%;
  overflow-y: scroll;
}

.giving-height {
  height: 450px;
}

.calc-table {
  width: 100%;
  /* margin: auto; */
  /* margin-top: 50px; */
  border-collapse: collapse;
  text-align: center;
}

.calc-table thead {
  background-color: blue;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.calc-table thead tr td {
  padding: 1% 0 1% 0;
}

.calc-table tr td {
  border: 1px solid #ddd;
  padding: 1% 0 1% 0;
}

.calc-td {
  color: #18375f;
}
.repay-heading{
  text-align: center;
  margin-top: 100px;
}
.repay-heading span{
  font-size: 36px;
  color: #011149;
  position: relative;
}
.repay-heading span::after{
    content: '';
    background-color: #4d3ecc;
    height: 6px;
    position: absolute;
    bottom: -14px;
    left: 34%;
    border-radius: 10px;
    width: 100px;
}
.princip-cont {
  background-color: #E4E2F8;
  border: 1px solid #6D57DF;
  border-radius: 10px;
  padding: 20px;
  width:45%;
  text-align: center;
}
.princip-monthly{
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  margin-top: 60px;
}
.princip-cont span{
  color: #011149;
  font-size: 24px;
  font-family: 'poppinsmedium';
  line-height: 140%;
}
.repaytab-cont{
  border-collapse: collapse;
  width: 85%;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #D5D7DD;
  margin-top: 20px;
}
.repay-table{
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.repaytab-cont thead tr td{
  color: #011149;
  font-size: 18px;
  font-family: 'poppinsmedium';
  background-color: #F6F6F8;
}
.repaytab-cont tbody tr td{
  color:#263238;
  font-size: 16px;
}
.repaytab-cont thead tr td, .repaytab-cont tbody tr td {
  border: 1px solid #D5D7DD;
  padding: 14px 0px;  
}
.repaytab-cont tr:nth-child(even){
  background-color: #F6F6F8;
}
.repaybtn{
  border: none;
  background-color: #fff0ff00;
  color: #4D3ECC;
  font-size: 18px;
  position: relative;
  text-align: center;
  font-family: 'poppinsmedium';
  margin-top: 20px;
  cursor: pointer;
}
.repaybtn::after{
  text-decoration: none;
  content: "\276F";
  position: absolute;
  margin-left: -18px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(90deg);
  right:-14px;
  top: -2px;
  color: #4D3ECC;
}

.repaybtn1{
  border: none;
  background-color: #fff0ff00;
  color: #4D3ECC;
  font-size: 18px;
  position: relative;
  text-align: center;
  font-family: 'poppinsmedium';
  margin-top: 20px;
  cursor: pointer;
}

.repaybtn1::after{
  text-decoration: none;
  content: "\276F";
  position: absolute;
  margin-left: -18px;
  -webkit-transform: rotate(90deg);
  transform: rotate(-90deg);
  right:-14px;
  top: -2px;
  color: #4D3ECC;
}

/* forclose css parvesh raghav */
.foreclose-emi-paid{
  display: flex;
  align-items: self-end;
  border-bottom: 1px solid #D5D7DD;
  gap: 10px;
  margin-top: 20px;
  padding-bottom: 6px;
}

.foreclose-emi-paid input{
  border: none;
  color: #525660;
  font-size: 16px;
  font-family: "poppinsregular";
  outline: none;
  background-color: #fff0;
  width: 100%;
  padding-bottom: 4px;
}
.party-pay-radio{
  margin-top: 40px;
}
.partpay-radio-head span{
  color: #011149 !important;
  font-family: 'poppinsmedium';
  font-size: 24px;
  font-weight: 600;
}
.party-pay-radio{
  flex-direction: column;
}
/* .party-pay-radio input{
  margin-right: 34px;
} */
.part-pay-radio-box{
  display: flex;
  justify-content: space-around;
}
.part-pay-radio-box input[type="radio"]{
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #8A91A1;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin: 0px;
}
.part-pay-radio-box input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #0FA82E;
  /* border: 0.15em solid #fff; */
  outline: max(2px, 0.15em) solid #0FA82E;
  outline-offset: max(2px, 0.15em);
}
.part-pay-radio-box input[type="radio"]:checked::before {
  transform: scale(1);
}
.part-pay-radio-box label{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
@media screen and (max-width:1350px) {
  .princip-cont{
    width: 42%;
  }
}
@media only screen and (max-width: 1000px) {
  .calc-bg-first {
    position: absolute;
    top: -18%;
  }

  .calculator-body {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .calculator-body-left h2 {
    font-size: 24px !important;
  }

  .calculator-body-left {
    width: 100%;
    /* margin-bottom: 40px; */
    padding: 20px 20px;
    /* padding-bottom: 10px; */
    gap: 0px;
  }

  .calculator-head h3 {
    font-size: 22px;
  }

  .calculator-head p {
    width: 90%;
    margin: 0 auto;
  }
  .princip-cont span{
    font-size: 18px;
  }
  .calculator-body-right{
    width: 100%;
    margin-left: 0%;
    padding: 4% 4% 4% 4%;
  }
}
@media screen and (max-width:768px) {
  .princip-cont {
    /* width: 38%; */
}
  .princip-cont span{
    font-size: 16px;
  }
  .repay-heading span{
    font-size: 30px;
  }
  .repaytab-cont, .princip-monthly{
    width: 100%;
  }
  .repaytab-cont tbody tr td{
    font-size: 14px;
  }
}
@media only screen and (max-width: 500px) {
  /* .calculation-main{
    padding: 0px 18px;
  } */
  .calculation-main h1 {
    font-size: 24px;
    margin-bottom: 26px;
  }

  .calculator-main {
    width: 90%;
    margin-top: 60px;
  }
/* 
  .calculator-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  } */

  .calculation-main p {
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 4px;
  }

  .calculation-third p span {
    margin-bottom: 10px;
  }

  .calculation-fourth p span {
    margin-bottom: 10px;
  }

  .calculator-btns {
    gap: 20px;
    padding:10% 0% ;
    padding-bottom: 10px;
  }

  .repayment-schedule-btn {
    width: 90%;
    /* margin-bottom: 15px; */
    font-size: 16px;
    font-family: 'poppinsmedium';
  }

  .apply-now-btn {
    width: 100%;
    font-size: 16px;
  }
  .calculator-head h3::after{
    width: 100px;
    left: 34%;
    bottom: -10px;
  }
  .calculator-head p{
    width: 100%;
    font-size: 14px;
  }
  .calc-bg-second{
    top: 46%;
    right: -5%;
  }
  .calc-bg-second img{
    height: 400px;
  }
  .calculator-body-left span:first-child{
    font-size: 14px;
  }
  .calculator-body-left h2{
    margin-bottom: 10px;
  }
  .range-main h3{
    font-size: 14px;
    /* font-family: 'poppinsmedium'; */
  }
  .range-main p{
    font-size: 12px;
  }
  .rangesslide-new span{
    font-size: 14px !important;
  }
  .rangeslider-main{
    margin: 0px 0px;
    margin-bottom: 0px;
  }
  .rangesslide-new{
    margin-bottom: 6px;
  }
  .range-main{
    margin: 10px 0px;
  }
  .calc-left-top{
    width: 90%;
    margin: 0 auto;
  }
  .calculation-first-half{
    justify-content: space-between;
  }
  .calculation-second-half{
    justify-content: space-between;
  }
  /* .calculation-first, .calculation-second, .calculation-third, .calculation-fourth{
    width: 160px;
  } */
  .calculation-main p{
    text-align:left ;
  }
  .calculation-main h1{
    text-align: left;
  }
  .repaytab-cont thead tr td{
    font-size: 16px;
  }
  .calculation-main{
    justify-content: space-around;
  }
  .calculator-body-right h3{
    font-size: 24px;
  }
  .percentage-box{
    width: 100%;
  }
  .princip-cont{
    padding: 10px;
  }
}
@media screen and (max-width:400px) {
  .calculation-main p{
    font-size: 12px;
  }
}
@media screen  and (max-width:380px){
  .calculator-body-left h2 {
    font-size: 22px !important;
  }
  .princip-cont{
    padding: 10px;
  }
  .princip-cont span {
    font-size: 12px;
  }
  .repaytab-cont thead tr td{
    font-size: 12px;
  }
}