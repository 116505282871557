.myAccountSection {
    width: 80%;
    margin-top: 64px;
    padding-top: 45px;
}

.myAccountName {
    font-size: 24px;
    line-height: 36px;
    color: #011149;
    font-family: 'poppinssemibold';
    position: relative;
}

.myAccountName::after {
    content: url('../../Icons/snack.svg');
    position: absolute;
    right: -100px;
}

.myAccountContent {
    margin-top: 20px;
    display: flex;
    gap: 30px;
}

.MyAccountLeftSide {
    margin: 0;
    width: 265px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 10px;
    padding: 10px;
    height: fit-content;
    display: grid;
}

.myAccountSectionName {
    margin: 1px 0;
    border-radius: 5px;
    width: auto;
    padding: 8px 15px;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    color: #011149;
    font-weight: 500;
    text-decoration: none;
}

.myAccountSectionName:hover,
.myAccountActivateSection {
    background-color: #F7F7FF;
    color: #4D3ECC !important;
    font-family: 'poppinsmedium';
}

.myProfileRightSide {
    margin: 0;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.myAccountHeading {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #011149;
    font-family: 'poppinssemibold';
    margin: 0;
}

.myProfileHeading {
    display: flex;
    gap: 20px;
}

.myProfileImg {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.myProfileImg img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000;
}

.myProfileIntroPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.personName {
    font-family: 'poppinssemibold';
    font-size: 18px;
    color: #011149;
    line-height: 27px;
}

.personNumber {
    font-size: 18px;
    line-height: 140%;
    color: #525660;
}

.myAccountBoxes {
    margin: 20px 0;
}

.myAccountBox {
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 10px;
    margin: 20px 0;
}

.myAccountBox .boxheading {
    padding: 13px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.activateBox .boxheading::after {
    content: "";
    position: absolute;
    width: 94%;
    height: 1px;
    background-color: #D5D7DD;
    bottom: 0px;
    left: 30px;
}

.myAccountBox .boxheading span {
    font-family: 'poppinsmedium';
    font-size: 18px;
    line-height: 140%;
    color: #011149;
}

.arrow {
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 8px;
    width: 1px;
    height: 1px;
    margin-top: 3px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: -2px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 10px;
}

.myAccountBox:hover .boxheading span,
.activateBox .boxheading span {
    font-family: 'poppinsmedium';
    color: #4D3ECC;
}

.myAccountBox:hover .boxheading i,
.activateBox .boxheading i {
    border-color: #4D3ECC;
}


.myAccountBoxContent {
    margin: 15px 30px;
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
    display: flex;
    flex-wrap: wrap;
    gap: 25px 80px;
    padding-bottom: 28px;
}

.res-address {
    padding-top: 10px;
    margin: 0 30px;
}

.subDataHead {
    font-family: 'poppinsmedium';
    font-size: 18px;
    line-height: 140%;
    color: #011149;
    margin-top: 10px;
}

.updateSection {
    margin-top: 30px;
}

.updateSection p {
    font-size: 14px;
    line-height: 21px;
    color: #525660;
}

.send-lnk {
    color: #015EF7;
}

.myLoanBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    border-radius: 10px;
    flex-direction: column;
    margin: 10px 0;
}

.myLoanBox img {
    width: 100%;
    margin-top: 10px;
}

.myLoanBox p {
    font-family: 'poppinssemibold';
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #011149;
    padding:  0 10px;
}

.userData {
    display: flex;
    flex-direction: column;
    min-width:200px ;

}

.userData .dataHead {
    font-size: 12px;
    line-height: 140%;
    color: #525660;
}

.userData .dataContent {
    font-size: 16px;
    line-height: 140%;
    color: #011149;
}

.account-menu {
    background-color: #F9FAFE;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.mainContent-box {
    padding: 0 30px;
    padding-top: 30px;
}

.looping-class {
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 30px;
}

.border-box {
    border: 1px solid #D5D7DD;
    border-radius: 10px;
}

.track-row {
    margin: 30px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.contentHead {
    font-size: 12px;
    line-height: 140%;
    color: #525660;
}

.contentData {
    font-size: 14px;
    line-height: 140%;
    color: #011149;
    font-family: 'poppinsmedium';
    margin-top: 5px;
}

/* parvesh raghav css for show more in my account */
.show-more-main {
    background-color: #E4E2F8;
    border-radius: 0px 0px 10px 10px;
}
.show-more-main.hdb-case{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px 20px;
    background-color: #E4E2F8;
    border-radius: 0px 0px 10px 10px;
}
.show-more-main.hdb-case h3,.show-more-main.hdb-case p{
    margin: 0;
    color: #011149;
}
.show-more-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: #E4E2F8;
    border-radius: 0 0 10px 10px;
}

.show-more-btn span {
    display: flex;
    justify-content: center;
    color: #011149;
    font-family: "poppinsmedium";
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}

.myloanshowmore {
    display: flex;
    padding-top: 12px;
    padding-bottom: 20px;
    gap: 20px;
    margin: 0 20px;
    justify-content: center;
}

.myloanboxes {
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.11);
    display: flex;
    align-items: center;
    padding: 10px 13px;
    gap: 10px;
    background-color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.myloanboxes span {
    color: #011149;
    font-family: "poppinsregular";
    font-size: 12px;
    line-height: 140%;
}

.myloanredbox {
    border: 1px solid #D0342C;
    background-color: #FFF5F5;
}

@media screen and (max-width:1340px) {
    .myloanshowmore {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:928px) {
    .myloanboxes {
        border-radius: 10px;
    }

    .myloanshowmore {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:750px) {
    .myloan-track {
        padding-top: 25px;
    }

    .personName {
        text-align: center;
    }

    .myloancontent-box {
        background-color: #fff0;
        border-radius: 0px;
        padding: 0px;
    }

    .myloan-borderbox {
        background-color: #fff;
        border: 0px;
        box-shadow: 0px 10px 20px rgb(26 57 96 / 5%);
    }
}

@media screen and (max-width:650px) {
    .myloanshowmore {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* parvesh raghav css for show more in my account */

.track-btn {
    margin: 25px 0;
    display: flex;
    justify-content: center;
}

.track-btn button {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    font-family: 'poppinsmedium';
    background-color: #0FA82E;
    padding: 7px 10px;
    border: 0px solid;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

.track-btn a {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    font-family: 'poppinsmedium';
    background-color: #0FA82E;
    padding: 7px 10px;
    border: 0px solid;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}


.yellow-btn,
.red-btn,
.green-btn,
.grey-btn {
    padding: 2px 10px;
    border-radius: 5px;
    border: 1px solid;
    width: fit-content;
}

.yellow-btn {
    border-color: #FFCC00;
    color: #DEB200 !important;
    background-color: rgba(255, 204, 0, 0.1);
}

.red-btn {
    color: #D0342C !important;
    border-color: #D0342C;
    background-color: rgba(208, 52, 44, 0.1);
}

.green-btn {
    color: #4BB543 !important;
    border-color: #4BB543;
    background-color: rgba(15, 168, 46, 0.15);
}

.grey-btn {
    color: #8A91A1 !important;
    border-color: #8A91A1;
    background-color: #fff;
}

.myc-mobile,
.contentData-mob {
    display: none;
}

.myacc-header a {
    text-decoration: none;
    cursor: pointer;
}

.myacc-head {
    width: 90%;
    position: fixed;
    top: 0;
    background-color: #fff;
    margin: 0;
    padding: 6px 5%;
    z-index: 100;
}

.myacc-head-activate {
    list-style-type: none;
    text-align: left;
    display: contents;
    font-size: 15px;
    color: #182040;
    white-space: nowrap;
    display: contents;
    font-weight: 300;
}

@media (max-width:1250px) {
    .track-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px 10px;
    }
}

@media (max-width:750px) {
    
.content-area.myc-mobile{
    display: flex;
    justify-content: right;
    align-items: start;
}

    .myAccountName,
    .MyAccountLeftSide {
        display: none;
    }

    .myProfile h3 {
        text-align: center;
    }

    .myProfileHeading {
        flex-direction: column;
        align-items: center;
    }

    .myAccountSection {
        margin-top: 56px;
        padding-top: 10px;
    }

    .MyAccountLeftSide,
    .myAccountSection {
        width: 90%;
    }

    .myAccountBoxContent {
        display: flex;
        flex-wrap: wrap;
        margin: 0 13px;
        gap: 30px;
        padding: 20px 10px;
    }

    .myAccountBox .boxheading {
        padding: 13px 20px;
    }

    .myAccountHeading {
        text-align: center;
        margin: 15px 0;
    }

    .myAccountContent {
        margin-top: 0px;
    }

    .track-row {
        grid-template-columns: repeat(2, 1fr);
    }

    .myc-desktop,
    .content-area-mob {
        display: none;
    }

    .myc-mobile,
    .contentData-mob {
        display: block;
    }

    .track-row {
        margin: 20px;
    }

    .track-btn button {
        width: 100%;
        margin: 0;
    }

    .track-btn a {
        width: 100%;
        margin: 0;
    }

    .track-btn {
        margin: 20px;
    }
}

@media (max-width:450px) {
    .userData{
        min-width: auto;
    }

    .myAccountBoxContent {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin: 10px 14px;
    }

    .updateSection {
        text-align: center;
    }

    .activateBox .boxheading::after {
        left: 0px;
        width: 100%;
    }



}

@media (max-width:400px) {
    /* .track-row{
        grid-template-columns: 1fr;
    }
    .content-area{
        text-align: center;
    } */
}