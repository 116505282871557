
.choose-residential-address {
    border: 1px solid #0FA82E;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 10px;
}
.res-question{
    margin: 25px 0;
    font-size: 16px;
    line-height: 140%;
    font-family: 'poppinsmedium';
    color: #011149;
    font-weight: bold;
    line-height: 140%;
    padding-top: 5px;
}
.options-confirm{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
.yes-option-confirm,.no-option-confirm{
    width: 100%;
    height: 36px;
    border: 0px solid;
    outline: none;
    font-size: 16px;
    font-family: 'poppinsmedium';
    border-bottom: 1px solid #8A91A1;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    color: #8A91A1;
}
.yes-option-confirm input,.no-option-confirm input{
    margin-right: 10px;
    cursor: pointer;
}
.yes-option-confirm:hover,.no-option-confirm:hover,.activated-option{
    border-bottom-color: #0FA82E;
    color: #0FA82E;
    accent-color: #0FA82E;
}
.residential-cont-info {
    display: flex;
    justify-content: center;
    text-align: left;
}
.add-border-address{    
border: 1px solid #21C943;
border-radius: 10px;
}
.residential-radio {
    height: 20px;
    margin-top: 3.5%;
    margin-left: 2%;
}

.residential-option-1-info {
    width: 90%;
    margin: 10px 8px;
    line-height: 21px;
    font-size: 14px;
    color: #011149;
}

.residential-option-p-2 {
    margin: 0;
}

.add-resident-address {
    color: #015EF7;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 12px;
    cursor: pointer;
}

.residentbutton {
    width: 100%;
    color: #7D85A1;
    margin: 20px 0px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    padding: 14px;
}
.marginT60{
    margin-top: 60px;
}

.residentbutton-active {
    background: #0FA82E;
    color: white;
    width: 100%;
    margin: 20px 0px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    padding: 14px;
}

.residential-radio input[type=radio] {
    height: 20px;
    width: 20px;
    accent-color: #0FA82E;
}

@media only screen and (max-width:414px) {
    .residentialp {
        font-size: 14px;
    }
}