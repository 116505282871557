.intro-section {
    border: 1px solid #6D57DF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(#FFFFFF, #E5E3FF);
}

.image-section-offer {
    width: 40%;
    height: 106px;
    /* border: 1px solid; */
}

.user-detail {
    color: #000000;
    font-size: 16px;
    font-family: 'poppinssemibold';
}

.about-user {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-user p {
    text-align: center;
    color: #011149;
    font-size: 14px;
    margin: 15px 20px;
}

.internal-heading {
    font-family: 'poppinssemibold';
    color: #011149;

}

.sub-head {
    font-family: 'poppinssemibold';
    font-size: 16px;
}

.check-box-option {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
}

.check-box-option p {
    font-size: 11px;
    margin: 0 10px;
    line-height: 120%;
}

.check-box-option p a {
    text-decoration: none;
    color: #015EF7;
}

.arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px !important;
    margin-left: 8px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: -2px;
}

.down {
    transform: rotate(45deg);
    margin-bottom: 2px;
    -webkit-transform: rotate(45deg);
}
.left {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-bottom: -2px;
}


.hidden-contents {
    margin: 3% 0;
}
.kfs-subhead{
    color:#011149;
    font-size: 11px;
    line-height: 140%;
    padding:5px 11px ;
    border-right:1px solid #D5D7DD ;
    border-left: 1px solid #D5D7DD;
    background-color: #E4E2F8;
    /* margin: -5px 0; */
}
.subHead-content{
    margin:6px 0;
}
.subHead-content span:nth-child(1){
    font-family: 'poppinsmedium'
}

.nested-heading{
    font-size: 16px;
    font-family: 'poppinsmedium';
    margin-left: 10px;
}

.option-boxing {
    background-color: #4D3ECC;
    color: #fff;
    padding: 2% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 34px;
}

.option-head {
    font-size: 16px;
    font-family: 'poppinssemibold';
    display: flex;
    align-items: center;
}

.option-head img {
    margin-left: 5px;
    width: 25px;
    height: 25px;
}

.key-statements{
    /* padding: 3% 3% 0 3%; */
    border-left: 1px solid #D5D7DD;
    border-right: 1px solid #D5D7DD;
    height: 250px;
    overflow: auto;
}
.statements{
    padding: 3%;
    font-size: 14px;
    color:#011149;
    line-height: 19px;

}
.statement-head{
    font-family: 'poppinssemibold';
    margin: 7px 0;
}
.statements:nth-child(even){
    background-color: #F5F5F5;
}

.key-statements::-webkit-scrollbar {
    width: 4px;
}
.key-statements::-webkit-scrollbar-track {
    background: #D5D7DD;
}
.key-statements::-webkit-scrollbar-thumb {
    background: #8A91A1;
    border-radius: 4px;
}
.key-statements::-webkit-scrollbar-thumb:hover {
    background: #8A91A1;
}
.repayment-section{
    
    border-left: 1px solid #D5D7DD;
    border-right: 1px solid #D5D7DD;
    border-bottom: 1px solid #D5D7DD;
    border-radius: 0 0 10px 10px;
}
.btns-colm{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D5D7DD;
}
.btns-colm .left-move button,.btns-colm .right-move button{
    background: none;
    border: 0px solid;
    outline: none;
    font-size: 11px;
    cursor: pointer;
}
.btns-colm .left-move button img,.btns-colm .right-move button img{
    margin-bottom: -2px;
}
.left-move{
    margin-left: 100px;
    border-left: 1px solid #D5D7DD;
}
.left-move,.right-move{
    padding: 2%;
}
.colored{
    color:#155BF1;
}
.repayment-table{
    min-width: 100%;
    display: flex;
    overflow-x: auto;
}
.fixed-col{
    min-width: 116px;
    max-width: 116px;
    box-shadow: 3px 2px 6px #0000001C;
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 9;
    font-size: 11px;
    line-height: 140%;
}
.data-part{
    min-width: 80px;
    max-width: 100px;
    z-index: 1;
}
.fixed-col .row-heading{
    height: 58px;
    padding:3% 10%;
    font-family: 'poppinsmedium';
    font-weight: 600;
}
.data-part:nth-child(even){
    background-color: #F5F5F5;
}

.row-content{
    height: 60px;
    padding:3% ;
    border-right: 1px solid #D5D7DD;
    font-size: 12px;
}
.row-content:not(:last-child),.row-heading:not(:last-child){
    border-bottom: 1px solid #D5D7DD;
}
.row-content,.row-heading{
    color:#011149;    
    display: flex;
    justify-content: center;
    align-items: center;
}
.data-part .row-content:first-child{
    font-family: 'poppinsmedium';
    font-weight: 600;
}

.repayment-table::-webkit-scrollbar {
    height: 4px;
}
.repayment-table::-webkit-scrollbar-track {
    background: #D5D7DD;
}
.repayment-table::-webkit-scrollbar-thumb {
    background: #8A91A1;
    border-radius: 4px;
}
.repayment-table::-webkit-scrollbar-thumb:hover {
    background: #8A91A1;
}
.instruction-mitc{
    color:#011149;
    font-size: 10px;
    line-height: 16px;
    padding: 0 11px;
}

@media screen and (max-width:740px) {
    .user-detail {
        font-size: 14px;
    }

    .user-detail p {
        font-size: 13px;
    }

    .internal-heading {
        font-size: 13px;
    }


}