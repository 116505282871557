.approved-box {
    position: relative;
    border-radius: 20px;
    background: url('../../Icons/Ellipse\ 300.svg')no-repeat right bottom, linear-gradient(90deg, #7064D8 0%, #4D3ECC 100%);
    margin: 10px 0;
}

.approved-box .content span {
    color: #fff;
    font-family: 'poppinssemibold';
    font-size: 18px;
    line-height: 140%;
}

.approved-box .content p {
    font-size: 14px;
    color: #fff;
    font-family: 'poppinsregular';
    line-height: 140%;
    margin: 2px 0;
}

.content-boxes {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    padding: 10px 16px;
    margin: 14px 0;

}

.box-head {
    display: flex;
    align-items: flex-start;
}

.box-head span {
    font-size: 18px;
    color: #011149;
    font-family: 'poppinsmedium';
    line-height: 140%;
}

.box-head img {
    margin-right: 4%;
}

.box-content {
    line-height: 140%;
    color: #525660;
    margin-top: 0px;
    font-size: 14px;
}

.subheading-status {
    margin: 10px 0;
}

.subheading-status .heading-2 {
    font-size: 18px;
}

.con-btn {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

.con-btn a {
    border-radius: 10px;
    background-color: #0FA82E;
    border: 0px;
    outline: none;
    padding: 8px 14px;
    color: #fff;
    font-size: 14px;
    font-family: 'poppinsmedium';
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 140%;
    text-decoration: none;
}

.con-btn a button {
    background-color: transparent;
    border: 0px;
    outline: none;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
    font-family: inherit;
    line-height: inherit;
}

.dashboard-sorry-message {
    font-family: 'poppinsmedium';
    font-size: 12px;
    line-height: 140%;
    color: #015EF7;
}

.content {
    padding: 14px 20px;
}
.show-disabled{
    opacity: 0.5;
}
.offer-data>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #000;
    margin: 10px 0;
}

.offer-data>div span {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
}

@media screen and (max-width:456px) {

    .subheading-status .heading-2 {
        font-size: 16px;
    }

    .approved-box .content span,
    .box-head span {
        font-size: 14px;
    }

    .box-content,
    .approved-box .content p {
        font-size: 12px;
    }

    .application-status-box {
        /* width: 370px;
        height: 722px; */
    }

    .approved-box {
        width: 100%;
    }

    .profile-box {
        width: 100%;
    }

    .offer-box {
        width: 100%;
    }

    .verification-box {
        width: 100%;
    }

    .Disbursal-box {
        width: 100%;
    }

}