.loaderbackground{
    position: fixed;
    background: rgb(0 0 0 / 58%);
    width: 100%;
    height: 100%;
    z-index: 999;
    left: 0;
    top: 0;
    backdrop-filter: blur(2px);
    
    /* display:block; */
}
.loadertransparent{
    background: 0 0;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-radius: 20px;
    /* padding: 40px; */
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
}

.loader{
    text-align: center;
    width: 340px;
    margin: 0 auto;
    border-radius: 25px;
    height: 397px;
    opacity: 1;
    background-color: #fff;
    z-index: 99;
}
.loader h2{
    color:#011149;
    margin-top:-35px;
}
.loader p{
    color:#525660;
    padding:0px 35px;
    font-size:16px;
}
.loader span{
    color:#525660;
    font-size:16px;
}
@media screen and (max-width:420px) {
    .loader{
        width: 85vw;margin: auto;
    }
    .loadertransparent{
        padding: 0;
    }
}