.emandate-cont {
    text-align: center;
}

.emandate-cont-info {
    display: flex;
    justify-content: center;
    /* margin-top: 40px; */
}

.emandate-cont-info-1 img {
    width: 50px;
    height: 52px;
}

.emandate-cont-info-2 {
    text-align: left;
    margin-left: 4%;
    width: 90%;
    margin-top: 1.5%;
}

.emandate-p {
    margin-top: 5px;
    color: #525660;
    font-size: 14px;
    font-weight: lighter;
    margin-left: -10px;
    font-family: 'poppinsmedium';
}

.emandate-p-1 {
    color: #011149;
    font-size: 16px;
    font-weight: bold;
    margin-top: -10px;
    margin-left: -9px;
}

.emandate-line {
    width: 100%;
    border: 0.5px solid #F6F6F8;
    background-color: #F6F6F8;
    margin-top: -5px;
}

.emandate-loan-info {
    display: flex;
    justify-content: space-evenly;
    margin-top: -8px;
}

.emandate-loan-p {
    color: #525660;
    font-size: 12px;
    font-weight: lighter;
    font-family: 'poppinsmedium';
    margin-left: -43px;
}

.emandate-loan-p-1 {
    color: #011149;
    font-size: 18px;
    font-weight: bold;
    margin-top: -7px;
    margin-left: -36px;
    letter-spacing: 1px;
}

.emandate-loan-p-2 {
    margin-top: -7px;
    margin-left: 9px;
    color: #011149;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
}

.analyzer-recomended {
    display: flex;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    color: green;
    font-family: 'poppinsmedium';
    margin: 10px 4px 0 0;
}
.emandate-option-1-info.recommend-emandate{
    display: flex;
    justify-content: space-between;
}

.emandate-setup {
    color: #011149;
    font-size: 16px;
    line-height: 40px;
    font-weight: bold;
    /* margin-top: -4px; */
}

.emandate-option {
    border: 1px solid #D5D7DD;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 0px 2px;
    margin-top: -10px;
}

.emandate-option .emandate-line {
    border: 0.5px solid #D5D7DD;
    width: 100%;
}

.emandate-option-1 {
    display: flex;
    justify-content: center;
    text-align: left;
    cursor: pointer;
}

.emandate-radio {
    height: 20px;
    margin-top: 3%;
    margin-left: 2%;
}

.emandate-option-1-info {
    width: 90%;
    margin-left: 2%;
}

.emandate-option-p-1 {
    color: #011149;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.emandate-option-p-2 {
    color: #525660;
    font-size: 11px;
    line-height: 14px;
    font-weight: lighter;
    margin-top: -10px;
}

.emandate-other {
    color: #525660;
    font-size: 11px;
    line-height: 15px;
    font-weight: lighter;
}

.emandate-btn {
    background-color: #0FA82E;
    font-size: 18px;
    color: white;
    width: 100%;
    border: none;
    border-radius: 10px;
    margin: 20px 0;
    height: 56px;
}

.npci-logo {
    height: 13px;
    margin-top: 14px;
    margin-left: 5px;
}

@media screen and (max-width:400px) {
    .emandate-loan-p-1 {
        font-size: 16px;
    }

    .emandate-loan-p-2 {
        font-size: 16px;
    }

    .emandate-setup {
        font-size: 14px;
    }
}