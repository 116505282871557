.ckycverification-popup {
    margin: auto;
    /* width: 374px; */
    border-radius: 25px;
    background-color: #fff;
    padding: 25px 0;
}

.ckycverification-popup h2,.kyc-h2 {
    text-align: center;
    color: #0FA82E;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    padding: 0;
}

.ckycverification-popup p {
    font-size: 14px;
    line-height: 140%;
    color: #525660;
    margin: 0;
}

.CKYC-subhead {
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #011149;
    margin-top: 6px;
}

.ckyc-customer-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
}

.title-name {
    margin: auto 0;
    color: #525660;
    font-size: 14px;
}

.title-content {
    font-size: 16px;
    color: #011149;
    line-height: 140%;
}

.address>.title-name {
    margin: 10px 0;
}

.verifiction-data {
    margin: 10px 0;
}

.ckycverification-checkbox {
    margin: 0px auto;
    width: 92%;
    padding-top: 6px;
}
.address{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap:3px;
}
.address-confirm{
    margin: 10px 0;
}
.address-asking{
    font-size: 16px;
    font-family: 'poppinsmedium';
    line-height: 140%;
    color:#011149;
    margin-bottom: 8px;
}
.options-confirm{
    display: flex;
    gap: 20px;
}
.yes-option-confirm,.no-option-confirm{
    width: 100%;
    height: 36px;
    border: 0px solid;
    outline: none;
    font-size: 16px;
    font-family: 'poppinsmedium';
    border-bottom: 1px solid #8A91A1;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    color: #8A91A1;
}
.yes-option-confirm input,.no-option-confirm input{
    margin-right: 10px;
    cursor: pointer;
}
.yes-option-confirm:hover,.no-option-confirm:hover,.activated-option{
    border-bottom-color: #0FA82E;
    color: #0FA82E;
    accent-color: #0FA82E;
}

.ckyc-confirm-button {
    text-align: center;
    margin-top: 20px;
    /* padding-bottom: 30px; */
}

.ckyc-confirm-button button {
    padding: 20px;
    width: 100%;
    font-size: 18px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'poppinssemibold';
    background-color: #D5D7DD;
    color: #7D85A1;
}

.activte-ckyc button{
    color: #fff;
    background-color: #0FA82E;
}
.allData {
    width: 100%;
}


.remove-block {
    display: none;
}

@media screen and (max-width:1366px){
    .ckycverification-popup h2{
        font-size: 22px;
        line-height: 30px;
    }
}


@media screen and (max-width:490px) {
    /* .ckycverification-popup {
        width: 80%;
        padding: 20px;
    } */

    .ckycverification-popup h2 {
        font-size: 20px;
        line-height: 25px;
        margin: 0;
    }
    .CKYC-subhead{
        font-size: 14px;
        line-height: 120%;
    }

    .title-name {
        font-size: 14px;
    }

    .title-content {
        font-size: 16px;
        line-height: 120%;
    }

}