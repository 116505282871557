.review-breadcrum {
  width: 80%;
  margin: 0 auto;
  margin-top: 120px;
}

.bred-cont {
  color: #8a91a1;
  font-family: "poppinsmedium";
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
  margin-top: -12px;
  color: #015EF7;
}

.bred-cont a,
.bred-cont span {
  text-decoration: none;
  color: #015EF7;
  font-family: 'poppinsmedium';
}

.customer-review {
  display: flex;
  justify-content: space-between;
}

.revie-left h2 {
  font-size: 36px;
  color: #011149;
  position: relative;
  font-family: 'poppinsmedium';
}

.revie-left h2::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 60px;
  background: #f85532;
  bottom: -3px;
  left: 0px;
  border-radius: 4px;
}

.revie-left p {
  color: #011149;
  font-size: 20px;
  font-family: "poppinsregular";
  line-height: 140%;
  position: relative;
}

.revie-left p::before {
  content: '\201C';
  font-size: 40px;
  position: absolute;
  font-family: Georgia, 'Times New Roman', Times, serif;
  left: -18px;
}

.revie-left p::after {
  content: '\201D';
  font-size: 40px;
  position: absolute;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.rating-cust {
  align-items: center;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  width: fit-content;
  padding: 10px;
  justify-content: space-around;
  box-shadow: 0 10px 20px #1135700d;
  margin-top: 40px;
}

.rating-cust span {
  color: #015ef7;
  font-size: 20px;
}

.all-review-bg {
  background-color: #f9fafe;
  padding-bottom: 25px;
}

.all-reviews {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.all-reviews h2 {
  font-size: 36px;
  font-family: "poppinsmedium";
  text-align: center;
  color: #011149;
  padding-top: 30px;
}

.re-views-cards {
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px 20px;
  margin-bottom: 30px;
}

.single-review-card {
  /* min-height: 200px; */
  padding: 30px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
  transition: opacity ease 0.2s;
}

.opacityChanger {
  /* min-height: 200px; */
  padding: 30px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
  opacity: 0.3;
  transition: opacity ease 0.2s;
}

.head-main-review {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.head-part-review {
  background-color: #015ef7;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-part-review span {
  color: #fff;
  font-family: "poppinsmedium";
  font-size: 30px;
}

.head-cont-area p {
  margin-top: -5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8A91A1;
}

.head-cont-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.head-star-img {
  display: flex;
}

.head-star-img img {
  width: 20px;
}

.head-cont-area span {
  font-size: 20px;
  color: #011149;
  font-family: 'poppinssemibold';
}

.feeback-review p {
  font-size: 16px;
  font-family: "poppinsregular";
  color: #525660;
  line-height: 140%;
}

.review-date-area {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
}

.review-left-date span {
  font-size: 14px;
  color: #8a91a1;
}

.review-right-date {
  display: flex;
  position: relative;
}

.review-right-date span {
  font-size: 14px;
  color: #015ef7;
  font-family: "poppinsmedium";
}

.review-right-date img {
  width: 16px;
}

.share-review-div {
  opacity: 0;
  position: absolute;
  display: none;
  flex-direction: column;
  right: -42px;
  top: -48px;
  gap: 15px;
  background-color: #fff;
  padding: 6px;
  border-radius: 5px;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  z-index: 1;
  animation: opacityhandler .2s 1 ease forwards;
  height: 87px;
}

.review-button-main {
  background: rgba(77, 62, 204, 0.05);
  border: 2px solid #4D3ECC;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  margin: auto;
}

@keyframes opacityhandler {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width:1000px) {
  .re-views-cards {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width:750px) {
  .customer-review {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .revie-left h2::after {
    content: "";
    position: absolute;
    height: 6px;
    width: 86px;
    background: #4D3ECC;
    bottom: -3px;
    left: 44%;
    border-radius: 4px;
  }

  .all-reviews h2 {
    font-size: 36px;
  }

  .revie-right img {
    width: 100%;
  }

  .revie-left p::before {
    left: 0;
  }
}

@media only screen and (max-width:600px) {
  .re-views-cards {
    grid-template-columns: auto;
  }

  .all-reviews h2 {
    font-size: 24px;
  }

  .revie-left h2 {
    font-size: 24px;
  }

  .revie-left p {
    font-size: 16px;
  }

  .revie-left h2::after {
    left: 34%;
  }

  .review-breadcrum {
    margin-top: 90px;
  }
}

@media only screen and (max-width:500px) {
  .revie-left p::before {
    left: -8px;
  }
}

@media only screen and (max-width:390px) {
  .revie-left p::before {
    left: 28px;
  }
}