.esign-cont{
    margin:12% auto;
    width:374px;
    border-radius: 25px;
    background-color: #fff;
    padding: 30px;
    text-align: center;
}
.esign-p1{
    color: #011149;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    line-height: 140%;
    margin-top: 0px;
    margin-bottom: 0px;
}
.esign-span{ 
    font-size: 16px;
    line-height: 140%;
    color:#011149;
    padding: 0px 70px;
}
.margin-b2{
    margin-bottom: 2px !important;
}
@media screen and (max-width:500px) {
    .esign-cont{
        width: auto;
        margin: 20px;
        padding: 20px;
    }
    .esign-p1{
        font-size: 20px;
    } 
}