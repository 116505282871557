.budget-banner {
    margin-top: 84px;
    background-image: url('../../Icons/budgetbg.webp');
    background-repeat: no-repeat;
    background-position-y: top;
    background-size: cover;
    width: 100%;
}

.budget-main-content {
    width: 80%;
    margin: 0 auto;
}

.budget-main-banner {
    width: 80%;
    margin: 0 auto;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
}

.budget-card {
    margin: 40px 0px;
}

.budgethead span {
    color: #011149;
    font-size: 36px;
}

.budgethead p {
    font-size: 22px;
    color: #011149;
}

.budgetinnercont p {
    color: #011149;
    font-size: 18px;
    line-height: 140%;
}

.budgetrangeslider {
    width: 100%;
}
.xyz ul{
    display: grid;
    grid-template-rows: 300px 1fr 2fr;
}
.contact-right-content {
    margin: auto;
  }
  
  .contact-right-content img {
    width: 100%;
    margin-top: 20px;
  }

  .contact-service-embedded a {
    font-size: 16px;
    color: #fff;
    background-color: #0FA82E;
    border-radius: 10px;
    padding: 14px 24px;
    text-decoration: none;
    font-family: "Poppinsmedium";
    cursor: pointer;
  }
  
  .main-div-embedded {
    background: #F9FCFF;
    padding: 40px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 25px;
    border: 2px solid white
  }
 .heading-embedded{
    margin-top: 0;
    font-size: 30px;
    color: #000;
    font-family: 'poppinssemibold';
}
  
  
  .main-div-embedded .heading-p {
    line-height: 150%;
    color: #000;
    margin-bottom: 0;
}

.mlc-pl-list-cont-embedded span {
    font-family: 'Poppinsmedium';
    font-size: 18px;
    color: #000;
    line-height: 140%;
    margin-top: 4px;
}
.mlc-pl-list-cont-embedded{
    display: flex;
    align-items: center;
    gap: 10px;
}
.harnessed-embedded{
color: #000;
font-size: 30px;
font-weight: 100;
text-align: center;
margin: 40px 0;
margin-top: 60px;
font-family: "poppinssemibold";
}
.harnessed-embedded p{
    font-size: 18px;
    text-align: center;
    font-family: "poppinsregular";}

.right-harnessed{
background: #F9FCFF;
padding: 40px;
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
border-radius: 35px;
text-align: left;
padding-bottom: 20px;
border: 2px solid white;
}
.harnessed-main{
    display:flex;
    gap: 40px;  
    margin-top: 32px;
}
.harnessed-heading h1{
    font-family: "Poppinssemibold";
    font-size: 30px;
    color: #000;
}
.right-harnessed-heading{
    font-size: 28px;
    font-family: "Poppinssemibold";
}
.right-harnessed p{
    font-size: 16px;
    text-align: left;
    line-height: 150%;
}
.harnessed-main-second{
    display: flex;
    gap:40px
}
.main-start-div{
background-color: #F5F5F5;
background-image: url('../../Icons/embedded-background.webp');
background-repeat: repeat-y;
background-position: center top;
background-size: 100% auto;
}
.contact-div{
background: #F9FCFF;
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
text-align: left;
display: flex;
justify-content: space-around;
padding: 10px;
margin-top: 10px;
flex-direction: column;
padding: 40px;
border-radius:20px;
border: 2px solid white;
}
.contact-div-call{
text-align: left;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding-bottom: 10px;
}

.contact-heading {
    margin-top: 40px;
    text-align: center;
    color: #000;
    font-size: 30px;
    font-family: "Poppinssemibold";
}
.choose-heading h1{
    font-family: "Poppinssemibold";
    font-size: 30px;
    color: #000;
}
.contact-person {
    display: flex;
    align-items: end;
    gap: 10px;
    padding-top: 10px;
    position: relative;
}
.contact-person::after{
    content: '';
   
}

.contact-person span{
    font-size: 20px;
    color: #000;
    font-family: "Poppinssemibold";
}
.contact-person p{
    font-size: 16px;
    color: #000;
    margin: 0px;
    font-family: "Poppinsmedium";
}
.contact-person img{
    height: 36px;
    width: 36px;
}
.eligibility-criteria-box-embedded{
    display: flex;
    justify-content: space-between;
    padding: 40px;
}
.why-choose-us-left{
    font-size: 30px;
    color: #000;
    display: flex;
    flex-direction: column;
    gap: 35px;
}
.choose-points{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.why-choose-us-right img{
    height: 400px
}
.left-harnessed img{
    height: 200px
}

@media screen and (max-width:1000px) {
    .eligibility-criteria-box-embedded{
        flex-direction: column-reverse;
        padding: 0px;
        
    }
    .heading-embedded{
        font-size: 22px;
        margin-bottom: 20px;
    }
    .main-div-embedded{
        padding: 20px;
    }
    .choose-heading h1, .harnessed-heading h1 {
        font-size: 24px;
    }
    .why-choose-us-right img{
        width: 100%;
    }
    .harnessed-main{
        flex-direction: column;
    }
  
    .harnessed-main-second{
        flex-direction: column-reverse;
    }
    .contact-person{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        margin-top: 10px;
    }
    .contact-div-call{
        flex-direction: column;
    }
    .contact-div-address{
        padding-bottom: 10px;
    }
    .contact-div-address .contact-person img{
        
        margin-bottom: 40px;
    }
    .contact-person span{
        font-size: 18px;

    }
    .contact-person p{
        font-size: 14px;

    }
    
    .contact-div{
        padding: 20px;
    }
    .left-harnessed{
        display: flex;
        align-self: center;
    }
    .why-choose-us-left{
        gap: 0px;
    }
    .choose-points{
        gap: 10px;
    }
    .mlc-pl-list-cont-embedded span{
        font-size: 14px;
    }
    .embedhome-banner .contact-home-words p{
        font-size: 18px;
        font-family: "Poppinsmedium";
    }
    .left-harnessed img {
        height: 160px;
    }
    .main-start-div{
        background-size: cover;
    }
    .embed-top-link{
        padding-top: 10px;

    }
    .journey-stop.embedded{
        margin-top: 58px;

    }
    
}
