.LRotate {
    transform: rotate(90deg);
}

.back-to-all {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 0;
    top: 100px;
}

.backBTN {
    border: 1.5px solid #4D3ECC;
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #4D3ECC;
    gap: 5px;
    width: 142px;
    cursor: pointer;
}

.applyBTN {
    background: #0FA82E;
    border-radius: 6px;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 8px 16px;
    width: 118px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.carrer-form-career-heading {
    margin: 0;
    font-family: 'poppinssemibold';
    font-size: 44px;
    line-height: 140%;
    margin-top: 30px;
    color: #fff;
}

.carrer-form-head p:first-of-type {
    font-size: 30px;
    line-height: 140%;
    margin: 0;
    padding: 0;
}

.carrer-form-head p:last-of-type {
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    margin-top: 8px;
}

.job-desc {
    margin-top: 30px;
}

.jd {
    margin: 40px 0;
}
.jd-top{
    margin-top: 100px;
}
.jd ul {
    padding-left: 30px;
    margin-top: 30px;
}

.jd-heading {
    font-size: 36px;
    line-height: 140%;
    color: #011149;
}

.jd ul li {
    font-size: 18px;
    line-height: 140%;
    color: #525660;
    margin-bottom: 10px;
}

.joining-form {
    padding-bottom: 80px;
    margin-top: 10px;
}

.joining-box {
    display: flex;
}

.work-together {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.work-together span {
    font-size: 44px;
    line-height: 140%;
    font-family: 'poppinssemibold';
    color: #3427A3;
}

.work-together p {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
}

.joining-form-box {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
    border-radius: 25px;
}

.career-form-content {
    padding: 34px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.career-form-content span {
    font-family: 'poppinssemibold';
    font-size: 22px;
    line-height: 33px;
    color: #011149;
}

.career-form-row {
    display: flex;
    gap: 30px;
    margin-bottom: 40px;
    justify-content: center;
}

.career-input {
    width: 100%;
    border-bottom: 1px inset #D5D7DD;
    display: flex;
    gap: 10px;
    position: relative;
}

.career-input input {
    border: 0 solid;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    font-family: 'poppinsmedium';
    outline: none;
    color: #011149;
}

.career-input input::placeholder {
    font-size: 16px;
    color: #525660;
    font-family: 'poppinsregular';
}

.manage-btn {
    display: flex;
    justify-content: center;
}

.carrer-btn {
    margin: 0 auto;
    width: 356px;
}

.career-border-err {
    border-bottom: 1px solid #D0342C !important;
}

.career-border {
    border-bottom: 1px solid #6D57DF !important;
}

.career-err {
    margin: 0;
    position: absolute;
    color: #D0342C;
    font-size: 11px;
    top: 32px;
    left: 0;
}

.career-form-row .proof-row label {
    gap: 10px;
    height: 53px;
    align-items: center;
    padding: 0 15px;
}

.career-form-row .proof-border {
    max-width: 356px;
    width: 100%;
    height: 53px;
    font-size: 18px;
    border: 1.5px dashed #D5D7DD;
}

.career-form-row #proof-row-id {
    height: 56px;
    margin: 0 10px;
}

.career-form-row .specific-width-height {
    height: 26px;
}

.career-form-row .proof-border span {
    font-size: 16px;
    color: #011149;
    font-family: 'poppinsmedium';
}

.career-form-row .proof-row-p {
    margin: 0;
    color: #525660;
}

@media (max-width:1000px) {
    .back-to-all {
        left: 0;
    }

    .jd-top {
        margin-top: 165px;
    }

    .career-top-content {
        width: 100%;
        text-align: center;
    }

    .joining-box {
        flex-direction: column;
        gap: 20px;
    }

    .work-together {
        width: 100%;
        align-items: center;
    }
}

@media (max-width:600px) {
    .back-to-all {
        font-size: 14px;
        top: 80px;
    }

    .carrer-form-career-heading,
    .work-together span {
        font-size: 32px;
    }

    .carrer-form-career-heading {
        margin-top: 28px;
    }

    .carrer-form-head p:first-of-type,
    .jd-heading {
        font-size: 18px;
    }

    .carrer-form-head p:first-of-type,
    .jd ul li,
    .work-together p {
        font-size: 16px;
    }

    .career-form-content span {
        font-size: 22px;
    }

    .career-form-row {
        flex-direction: column;
        margin-bottom: 40px;
        gap: 40px;
    }

    .career-input {
        max-width: none;
    }

    .career-form-row .proof-border {
        width: 100%;
        max-width: none;
    }

    .career-form-row .proof-border span {
        font-size: 14px;
    }
}