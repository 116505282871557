.blog-inner{
    width: 100%;
}
.blog-inner h1{
    font-size: 26px;
}
.blog-inner-header{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.blog-inner-header p{
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 20px;
}
.blog-inner-header p a{
    color: #196df7;
    text-decoration: none;
    font-size: 14px;
    font-family: 'poppinsmedium';
}

.blog-inner-header p span{
    margin: 0px 8px;
}
h3{
    color: #2917a4;
    line-height: 140%;
}

/* .blog-inner-header p span:last-child{
    margin-right: 5px;

} */
.blog-inner-content-main p{
    color: #455a64;
    line-height: 140%;
    font-size: 14px;
}
.blog-inner-content-main h2{
    position: relative;
}
.blog-inner-content-main h2::after{
    content: "";
    position: absolute;
    height: 4px;
    width: 60px;
    background: #4D3ECC;
    bottom: -3px;
    left: 0px;
    border-radius: 4px;
}
.blog-inner-content-main h3{
    position: relative;
}
.blog-inner-content-main h3::after{
    content: "";
    position: absolute;
    height: 4px;
    width: 60px;
    background: #4D3ECC;
    bottom: -3px;
    left: 0px;
    border-radius: 4px;
}
.blog-inner-info{
    display: flex;
}
.blog-inner-info p{
    flex: 1;
    font-size: 12px;
}
.blog-inner-image-main{
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}
.blog-inner-image-main img {
    border-radius: 25px;
    width: 80%;
    height: 340px;
}
.blog-inner-author{
    background: url(./abthor-icon.svg) no-repeat;
    background-position: right center;
    background-color: #f5f6fb;
    padding: 20px 40px;
    border-radius: 20px;
    margin: 30px 0;
}
.blog-inner-author h2{
    color: #011149;
    font-size: 18px;
}
.blog-inner-author p{
    line-height: 140%;
    color: #455a64;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 0px;
}
.blog-inner-feedback{
    background: url(./comment-icon.svg) no-repeat;
    background-position: right bottom;
    background-color: #f5f6fb;
    padding: 20px 40px;
    border-radius: 20px;
    margin: 30px 0;
}

.blog-inner-feedback h2{
    color: #011149;
    font-size: 18px;
}
.blog-inner-feed-box{
    display: flex;
    flex-direction: column;
    width: 70%;
}
.blog-inner-feed-box label{
    font-size: 12px;
    color: #18375f;
}
.blog-inner-feed-box input, .blog-inner-feed-box textarea{
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0 20px;
    margin-bottom: 20px;
    outline: none;
}
.blog-inner-feed-box textarea{
    height: 80px;
    padding-top: 10px;
}
.blog-inner-feedback .read-btn{
    border: 1px solid #005df6;
    background: #f0f6ff;
    color: #005df6;
    padding: 10px 16px;
    border-radius: 4px;
    margin: 8px 0;
    cursor: pointer;
    font-weight: 600;
}
.blog-inner-feedback .read-btn:hover{
    background: #005df6;
    color: #fff;
}
.blog-inner-rating{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog-inner-rating .rate:not(:checked) > input {
    position:absolute;
    visibility: hidden;
}
.blog-inner-rating .rate:not(:checked) > label {
    float: right;
    width: 30px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #D6D6D6;
    background: none;
    border: none;

}
.blog-inner-rating .rate:not(:checked) > label:before {
    content: '\2605';
}
.blog-inner-rating .rate > input:checked ~ label {
    color: #FFC107;    
}
.blog-inner-rating .rate:not(:checked) > label:hover,
.blog-inner-rating .rate:not(:checked) > label:hover ~ label {
    color: #FFC107;  
    background:none;
    border: none;
}
.blog-inner-rating .rate > input:checked + label:hover,
.blog-inner-rating .rate > input:checked + label:hover ~ label,
.blog-inner-rating .rate > input:checked ~ label:hover,
.blog-inner-rating .rate > input:checked ~ label:hover ~ label,
.blog-inner-rating .rate > label:hover ~ input:checked ~ label {
    color: #FFC107;
}
.blog-inner-image-main{
    position: relative;
}
.blog-inner-image-main .social-media{
    position: absolute;
    top: 16px;
    display: flex;
    flex-direction: column;
    left: 16px;
    gap: 6px;
    
}
.blog-inner-image-main .social-media a.facebook, .blog-inner-image-main .social-media a.twitter, .blog-inner-image-main .social-media a.linkedin{
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s,-webkit-transform .4s;
}
.blog-inner-image-main .social-media a.facebook:hover, .blog-inner-image-main .social-media a.twitter:hover, .blog-inner-image-main .social-media a.linkedin:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
.blog-inner-content-main ul li{
    line-height: 22px;
    color: #455a64;
    margin-bottom: 10px;
    list-style-type: none;
}
.blog-inner-content-main ul li::before{
    content: "\2022";
    color: #eb5f32;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.blog-inner-content-main table{
    width: 100%;
    border-collapse: collapse;
}
.blog-inner-content-main table td{
    padding: 0.7rem;
    vertical-align: top;
    border: 1px solid #bdbdbd;
    font-size: 13px;
}
@media screen and (max-width:1100px) {
    .blog-inner-image-main img{
        height: 100%;
    }
}
@media screen and (max-width:768px) {
    .blog-inner-image-main img{
        width: 100%;
    }
    .blog-inner h1{
        font-size: 22px;
    }
    .blog-inner-author{
       padding: 20px 20px;
       background-size: 62% 22%;
       margin-bottom:0px ;
       background-position: top;
    }
    .blog-inner-feedback{
        padding: 20px 20px;
        background-size: 62% 22%;
    }
    .blog-inner-author p{
        width: 100%;
    }

    .givestar-rating{
        position: absolute;
    }
    .newratingblog{
        top: 34px;
        right: 8px;
    }
    .blog-inner-rating{
        flex-direction: column;
    }
    .blog-inner-feed-box{
        width: 100%;
    }
    .blog-inner-content-main h2{
        font-size: 18px;
    }
    .blog-inner-content-main h2::after {
        height: 3px;
    }
    .blog-inner{
        width: 90%;
        margin: 0 auto;
    }
    .blog-new-header{
        width: 90%;
        margin: 0 auto;
    }
    .blog-inner-info{
        flex-direction: column;
    }
    /* .blog-card-blog{
        position: inherit;
    } */
    .blog-inner-image-main{
        display: flex;
        flex-direction: column-reverse;
    }
    /* .blog-inner-image-main .social-media{
        position: relative;
        flex-direction: row;
        
    } */
    .blog-inner-info{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .blog-inner-feed-box input, .blog-inner-feed-box textarea{
        margin-top: 21px;
    }
    .feed-error {
        margin-top: 17px;
        left: 60%;
    }
}
@media only screen and (max-width:480px){
    .blog-inner-header p{
        margin-top: 0px;
    }
    .blog-inner h1{
        margin-bottom: 0px;
    }
    .blog-inner-image-main{
        margin-top: -6px;
    }
}