.ourproducts-main{
    background-color: #F9FAFE;
    padding: 50px 0px; 
    /* padding-bottom: 0px; */
    position: relative;
}
.product-bg-image{
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: fit-content;
}
.ourproducts-sub{
    width: 80%;
    margin: 0 auto;
    display: flex;
}
/* .orproducts-cont-main{
    display: flex;
    flex-direction: row;
    gap: 60px;
} */
.product-heading{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-self: end;
    margin-right: 50px;
    margin-bottom: 68px;
}
.product-heading p:first-child{
    font-size: 40px;
    color: #011149;
    font-family: 'poppinsregular';
    font-weight: 400;
    margin: 0px;
    line-height: inherit;
}
.product-heading span{
    color: #3427A3;
    font-size: 80px;
    font-family: 'poppinssemibold';
    position: relative;
}

.product-heading span::before{
    content: '';
    background-color: #4d3ecc;
    height: 6px;
    position: absolute;
    bottom: 6px;
    right: 0;
    left: 0;
    border-radius: 10px;
    width: 100px;
}
.product-heading p:last-child{
    color: #525660;
    font-size:18px;
    line-height: 140%;
}
.instant-box{
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(17, 53, 112, 0.16);
    border-radius: 25px;
    /* width: 400px; */
    height: fit-content;
    overflow: hidden;
}
.green-instant-box{
    margin-top: 80px;
    z-index: 9;
    margin-left: 40px;
}
.instant-head-cont{
    padding: 20px;
    margin-top: 20px;
    /* min-height: 242px;
    max-height: 350px; */
}
.instant-head span{
    color: #525660;
    font-size: 24px;
    font-family: 'poppinssemibold';
    position: relative;
}
.instant-head span::before{
    content: '';
    background-color: #4d3ecc;
    height: 6px;
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 102px;
    border-radius: 10px;
    width: 54%;

}
.green-instant-box .instant-head span::before{
    background-color: #0FA82E;
    width: 46%;
}
.instant-contnt{
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 20px;
}
.instant-inner{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.instant-inner span{
    color: #525660;
    font-size: 16px;
    font-family: 'poppinssemibold';
}
.instant-inner p{
    color: #525660;
    font-size: 14px;
    margin: 0px;
    font-family: 'poppinsregular';
}
.instant-bottom{
    background-color: #4D3ECC;
    text-align: center;
    padding: 14px;
    cursor: pointer;
}
.green-instant-box .instant-bottom{
    background-color:#0FA82E;
}
.instant-bottom a{
    color:#fff;
    font-size: 20px;
    font-family: 'poppinsregular';
    text-decoration: none;
}
@media screen and (max-width:1900px) and (min-width:1713px) {
    .instant-head span{
        font-size: 20px;
    }
    /* .instant-box{
       min-height: 350px;
       max-height: 430px;
    } */
    .instant-contnt{
        gap: 20px;
    }
    .product-heading p:first-child{
        margin-bottom: 0px;
    }
    .product-heading p:last-child{
        margin-top: 4px;
    }
    .product-heading span::before{
        bottom: 8px;
    }
    .eligibility-criteria-box.top-pad .bot-6::before{
        bottom: -4px;
    }
}
@media screen and (max-width:1514px) and (min-width:1400px) {
    /* .instant-box{
        min-height: 450px;
        max-height: 450px;
    } */
    .instant-head span{
        font-size: 20px;
    }
}

@media screen and (max-width:1440px) {
    .instant-head span{
        font-size: 20px;
    }
}
@media screen and (max-width:1400px) and (min-width:1220px){
    .instant-head-cont{
        /* min-height: 242px;
        max-height: 242px; */
    }
}
@media screen and (max-width:1300px) and (min-width:1200px) {
    .instant-head span{
        font-size: 16px;
    }
    .product-heading span{
        font-size: 60px;
    }
    .product-heading p:last-child{
        font-size: 14px;
    }
}
@media screen and (max-width:1200px) and (min-width:1000px){

    .product-heading span{
        font-size: 58px;
    }
    .instant-head span{
        font-size: 14px;
    }
    .instant-inner span{
        font-size: 12px;
    }
    .instant-contnt{
        gap: 14px;
    }
    .instant-head span::before{
        bottom: 32px;
        right: 0;
        left: 66px;
    }
    .product-heading p:last-child{
        font-size: 14px;
    }
}
@media screen and (max-width:1000px) {
    .ourproducts-sub{
        flex-direction: column;
        width: 90%;
    }
    .product-heading{
        width: auto;
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .green-instant-box{
        margin-left: 0px;
    }
    .instant-box{
        z-index: 9;
    }
    .ourproducts-main{
        overflow: hidden;
        padding: 10px 0px;
    }
    .product-bg-image{
        top: 72%;
    }
}
@media screen and (max-width:460px) {
    .product-heading span::before{
        bottom: -2px;
    }
    .ourproducts-main{
        padding-bottom: 40px;
    }
    .product-heading{
        padding-top: 20px;
    }
    .product-heading p:first-child{
        font-size: 30px;
    }
    .product-heading p:last-child{
        font-size: 14px;
        margin: 20px 0px;

    }
    .product-heading span{
        font-size: 40px;
    }
    .instant-box{
        width: 360px;
        margin-top: 30px;
    }
    .instant-head span{
        font-size: 22px;
    }
    .instant-inner span{
        font-size: 16px;
    }
    .green-instant-box .instant-bottom{
        margin-top: 0px;
    }
    .instant-head span::before{
        left: 84px;
    }
}
@media screen and (max-width:382px) {
    .instant-box{
        width: 328px;
    }
    .instant-bottom{
        margin-top: 2px;
    }
    .instant-head span{
        font-size: 20px;
    }
    .green-instant-box .instant-bottom{
        margin-top: 2px;
    }
}