.journey-part .left-top {
    position: relative;
    top: -30px;
    font-family: 'poppinsmedium';
    color: #015EF7;
}

.journey-part .left-top a,.journey-part .left-top span {
    color: #015EF7;
    font-family: 'poppinsmedium';
}
/* .form{
    position:relative;
    margin-bottom: 30px;

    }
    .svgicons{
        position: absolute;
        top: 25%;
        left: 4%;
    }

    .new-form{
        margin-top: 10%;
    }    */
    
    /* span.top-label, span.inputselect-label{
        position: absolute;
        font-size: 12px;
        background: #fff;
        padding: 0px 14px;
        left: 18px;
        color: #455a64;
        z-index: 1;
        margin-left:10px;
        margin-top:-12px
       
    } */
     /* .form input[type=text], .new-form input[type=email], .new-form input[type=number], .new-form input[type=image], .new-form select {
        background-color: #fff;
        border: 1px solid #C4D3E0;
        border-radius: 5px;
        padding-left: 50px;
        padding-right: 10px;
        color:#455a64;
        width: 80%;
        cursor: pointer;
        font-size: 12px;
    } */
    /* .validstar:after {
        content: "*";
        color: red;
        margin-left:2px;
        font-size: 15px;
        position: absolute;
        top: -3px;
    } */
    
    /* .sachservice{
        width: 80%;
        margin: auto;
        padding: 20px;
        margin-bottom: 30px;
        color:#455861;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        display: flex;
        gap: 10%;
        margin-top:120px;
    } */
    /* .service-h{
        font-size: 36px;
        line-height: 54px;
        color: #011149;
        font-weight: 600;
    } */
    /* .service-p{
        font-size:18px;
        font-weight: bold;
        color: #011149;
        line-height: 25px;
    } */
    /* .service-hr {
        width: 109px;
        height: 6px;
        background: #e8572f;
        border: none;
        margin-left: 0;
        margin-top: -3%;
        margin-bottom: 5%;
    } */
    /* .service_2{
        width: 50%;
        border: 1px solid black;
        position: relative;
        padding: 20px;
    } */
    /* .service_1{
        border: 1px solid red;
        width: 50%;
        line-height:20px;
    } */
    /* .service_3{
        width: 80%;
        border:1px solid #ddd;
        position: relative;
        padding: 25px;
        margin: auto;
        line-height:25px;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        border-radius: 30px;
        margin-bottom: 30px;
    }
    .new-form .check {
        padding: 16px 0px;
        display: block;
        font-size: 14px;
        cursor: pointer;
        padding-top: 0;
    }
      .serspan{
        display: inline-block;
        width: 90%;
        vertical-align: top;
        font-size: 11px;
        margin-bottom: 16px;
        line-height: 16px;
        margin-left: 6px;
    } */
    /* .new-form button, .new-form input[type=button], .btn-blue {
        border: none;
        background: #2917a4;
        color: #fff;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: center;
        padding: 0.25rem 1rem;
        height: 50px;
    } 
    
    @media only screen and (max-width:820px){
        .checkserpa{
            margin-left: -6px;
         }
         .form input[type=text]{
            padding-left: 40px;
            padding-right:0px;
         }
         .sachhead{
            font-size: 15px;
         }
    } 
    @media only screen and (max-width:768px){
        .service_1{
         width:60%;
         padding: 0%;
        }
        .servicejson{
            width: 300px;
        }
        .service_2 .service_3{
         padding:10%;
        }
        .form input[type=text]{
         padding-left: 35px;
         font-size: 10px;
        }
        .sachhead{
         font-size: 14px;
        }
        .checkserpa{
           margin-left: -8px;
        }
        .service_2{
            padding: 0px;
        }
     }
     @media only screen and (max-width:750px){
        .sachservice{
             align-items: center;
             justify-content: center;
        }
        .service_1{
            display: none;
        }
        .service_2{
            width: 100%;
        }
    
        .serpa{
            display: flex;
            align-items:flex-start;
            font-size: 10px;
        }
        .serpa input[type=checkbox] {
            flex: none;
        }
    } 
    @media only screen and (max-width:326px){
        .form input[type=text], .new-form input[type=email], .new-form input[type=number], .new-form input[type=image], .new-form select{
            width: 60%;
        }
      
    }
    @media only screen and (max-width:280px){
       
        .sachhead{
            font-size: 14px;
        }
    } */
  