.money-partner-form {
    z-index: 1;
    position: absolute;
    top: 8%;
    left: 61%;
    border-radius: 20px;
}

.money-partner-bg img {
    position: relative;
    margin-top: 80px;
    width: 100%;
}

.money-partner-bg h1 {
    position: absolute;
    top: 6%;
    color: white;
    font-size: 44px;
    left: 11%;
}

.money-partner-bg p {
    position: absolute;
    color: white;
}

.money-partner-back {
    position: absolute;
    top: 10%;
    left: 11%;
    width: 44%;
    font-size: 30px;
    line-height: 140%;
}

.money-para {
    width: 50%;
    margin: 0 11%;
}

.money-para-1 {
    font-size: 36px;
    color: #263238;
}

.money-map {
    display: flex;
    gap: 10px;
}

.money-map p {
    font-size: 18px;
    line-height: 140%;
    color: #263238;
    font-weight: 600;
}

.money-para button {
    font-size: 20px;
    padding: 15px 20px;
    background-color: #0fa82e;
    border-radius: 10px;
    border: none;
    margin-bottom: 100px;
    margin-top: 50px;
    cursor: pointer;
    transition: transform .4s;
}

.money-para button:hover {
    transform: scale(1.1);
}

.money-para a {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

@media only screen and (max-width:1200px) {
    .money-partner-bg h1 {
        font-size: 36px;
    }

    .money-partner-back {
        font-size: 26px;
    }
}

@media only screen and (max-width:1050px) {
    .money-partner-bg h1 {
        font-size: 32px;
    }

    .money-partner-back {
        font-size: 22px;
        top: 9%;
    }
}

@media only screen and (max-width:900px) {
    /* .money-partner-back {
        display: none;
    } */
    .money-partner-bg h1{
        top: 3%;
        font-size: 24px;
        margin-bottom: 0px;
    }
    .money-partner-back{
        top: 5%;
        width: 80%;
    }
    .money-partner-bg p{
        font-size: 16px;
        margin-top: 0px;
        line-height: 140%;
    }
    .money-partner-form {
        position: relative;
        top: 5%;
        width: 95vw;
        margin: auto;
        margin-top: -16%;
        left: 0;
    }

    .money-para {
        width: 80%;
        margin: auto;
    }
    .money-partner-bg{
        background-image: url('../../Icons/Moneywidepartner-mob.svg');
        background-repeat: no-repeat;
        background-position: left;
        background-position-y: top;
        background-size: 100%;
        height: 420px;
    }
    .money-partner-bg img{
        display: none;
    }
}

@media only screen and (max-width:550px) {
    .money-partner-form {
        width: 90vw;
    }
    .op-form-body{
        margin-top: -56%;
    }
    .money-para-1 {
        font-size: 22px;
    }

    .money-map p {
        font-size: 14px;
    }
    .money-para button{
        margin-left: 40px;
    }
    .money-para{
        margin-top: 60px;
    }
}