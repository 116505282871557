.loanslider-bg {
  width: 80%;
  margin: 80px auto;
}

.ls-head {
  text-align: center;
}

.ls-head.add-btom-line {
  position: relative;
}
.ls-head.add-btom-line::before{
  left: 44%;
  bottom: -10px;
}

.ls-head h2 {
  font-weight: 100;
  color: #011149;
  font-size: 36px;
}

.loan-card {
  margin: auto;
  margin-top: 30px;
}

.loancard {
  background-color: #FFFFFF;
  border: 1px solid #D9DFFF;
  border-radius: 20px;
  height: fit-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 26px;
  margin: 20px 10px 50px 10px;
  
}
.loancard:hover{
  box-shadow: 0px 25px 50px #EAEEFF;
  border: 0px solid;
}

.loan-card .slick-slide img {
  width: 100%;
  margin: 0;
  height: 200px;

}

/* .loanslider-bg .slick-slider{
    margin: 0px 20px;
} */
.loanp {
  color: #011149;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  overflow: hidden;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
}

.loanp2 {
  font-size: 14px;
  color: #525660;
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 140%;
  text-align: center;
  padding: 0px 20px;
}

.lb-contnt {
  font-size: 14px;
  color: #525660;
}

.loancard button {
  background: none;
  border: none;
  margin-top: 20px;
  position: relative;
}

.lb-apply-btn {
  color: #015EF7;
  font-size: 14px;
  text-decoration: none;
  font-family: 'poppinsmedium';
}

.loan-card .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  cursor: pointer;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #fff;
  color: #0d0d0d;
  transition: all 0.3s ease-out 0s;
  border: 1px solid #E4E2F8;
}

.loan-card .slick-arrow:hover {
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 20px 0px rgb(4 7 47 / 10%);
}

.loan-card .slick-arrow.slick-prev::before {
  content: "\276F";
  position: absolute;
  background-image: none;
  color: #4D3Ecc;
  transform: rotate(180deg);
  /* top: 18px;
    left: 16px; */
  right: 0px;
}

.loan-card .slick-arrow.slick-next:hover::before {
  content: "\276F";
  background-image: none;
  transform: rotate(0deg);
  position: absolute;
  /* top: 14px;
    left: 16px; */
  right: 0px;
}

.loan-card .slick-arrow.slick-next::before {
  content: "\276F";
  position: absolute;
  background-image: none;
  color: #4D3Ecc;
  transform: rotate(0deg);
  /* top: 14px;
    left: 16px; */
  right: 0px;
}

.loan-card .slick-arrow.slick-prev:hover::before {
  content: "\276F";
  background-image: none;
  transform: rotate(180deg);
  position: absolute;
  /* top: 18px;
    left: 0px; */
  right: 0px;
}

@media screen and (max-width:1000px) {
  .ls-head h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width:480px) {
  .ls-head.add-btom-line::before{
    left: 36%;
  }
  .loancard{
    width: 96%;
    margin: 0 auto;
  }
  .loanslider-bg {
    width: 90%;
    margin-top: 30px;
  }

  .martop-faq {
    margin-top: 120px;
  }

  .loan-card .slick-next {
    margin-right: -15px;
  }

  .loan-card .slick-prev {
    margin-left: -7px;
  }

  .loan-card .slick-arrow {
    width: 48px;
    height: 48px;
    top: 102%;
  }

  .loan-card .slick-arrow.slick-next::before {
    top: 26%;
    left: 3%;
  }

  /* .loan-card .slick-arrow.slick-next:hover::before {
      top: 20%;
      left: 15%;
    }
  
    .loan-card .slick-arrow.slick-prev:hover::before {
      top: 21%;
    } */

  .loan-card .slick-arrow.slick-next {
    right: -4%;
  }

  .loan-card .slick-arrow.slick-prev {
    left: 8%;
  }
}

@media only screen and (max-width:440px) {
  .loan-card .slick-next {
    margin-right: -10px;
  }

  .loan-card .slick-prev {
    margin-left: -6px;
  }
}