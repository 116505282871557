.congratspopup{
    margin:12% auto;
    width:374px;
    border-radius: 25px;
    background-color: #fff;
    padding: 30px;
} 
.congratspopup-main.inform-box{
    margin-top: 40px;
    text-align: center;
}
.congratspopup .moneyearth{
    display: flex;
    margin: 24px auto;
}
.congratspopup-main h2{
    text-align: center;
    color:#0FA82E;
    margin: 0;
    font-size: 24px;
}
.amount-box{
    text-align: center;
    margin-bottom: 5%;
}
.amount-box p{
    color: #011149;
    font-size: 18px;
    font-family: 'poppinssemibold';
    line-height: 140%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.amount-box p:last-child{
    color: #011149;
    font-size: 20px;
    font-family: 'poppinssemibold';
    line-height: 140%;
    margin-top: 10px;
}
.amount-box span{
    color:#0FA82E;
    font-size: 24px;
    font-family: 'poppinssemibold';
}
.subject-online {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.subject-online p {
    font-size: 13px;
}
.require-doc-box{
    display: grid;
    text-align: center;
    grid-template-columns: repeat(3,1fr);
    margin-top: 24px;
}

.require-doc-box .cont-box p {
    font-size: 12px;
    line-height: 140%;
    color: #011149;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 600;
}
.congrats-offer-button button{
    width: 100%;
    height:56px;
    margin-top: 24px;
    font-size: 20px;
    color: #fff;
    background-color: #0FA82E;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'poppinssemibold';
}
.congrats-looking-offer{
    text-align: center;
    display: flex;
    flex-direction: column;
}
.congrats-looking-offer span{
    font-size: 20px;
    font-family: 'poppinsmedium';
    color: #4D3ECC;
    text-decoration: underline;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin-bottom: 8%;
    margin-top: 5%;
    cursor: pointer;
}
.tenour-case-new {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 6%;
}
.tenour-case{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin-top: 6%;
}
.tenour-Box{
    text-align: center;
}
.tenour-head{
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #525660;    
}
.tenour-content{
    font-family: 'poppinssemibold';
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #011149;
}
.tenour-content .rupee-symbol{
    margin-bottom: -2px;
}
@media screen and (max-width:1366px) {
    .congratspopup h2{
        font-size: 22px;
    }
    
    .tenour-content{
        font-size: 18px;
    }
}

@media screen and (max-width:500px) {
    .congratspopup{
    width: auto;
    margin: 20px;
    padding: 20px;
    }
    .congratspopup .moneyearth{
        width: 64%;       
    }
    .tenour-case{
        margin-top: 10px;
    }
    .tenour-content{
        font-size: 16px;
    }
    .congratspopup h2{
        font-size: 20px;
        margin-top: 0px;
    }
    .amount-box span{
        font-size: 20px;
    }
    .require-doc-box .cont-box p{
        font-size: 10px;
    }
    .congrats-offer-button button{
        font-size: 18px;
    }
}