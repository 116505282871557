.policymain-container-head-app {
  margin-top: 0px !important;
} 
.sachp{
    color:#455a64;
}
 .sach{
    list-style: none;
  }
  .sachmain ul li{
    margin: 20px 0px;
  }
.sach .sachin::before {
    content: "\2022";
    color:orangered;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
    line-height:22px;
  }
  .sachtable{
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    word-break:break-word;
  }
  .sachth{
    width: 33%;
    border: 1px solid black;
    border-collapse: collapse;
    background-color: #1a89ff;
    color: white;
  }
.sachtd{
    width: 33%;
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
}


.policy-translate{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .translate-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #E4E2F8;
  border-radius: 10px;
  padding: 4px 18px;
} */
.translate-area select{
  /* border: none; */
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #E4E2F8;
  border-radius: 10px;
  /* padding: 4px 18px; */
}
.translate-area select {
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  padding-left: 40px; 
  background: transparent; 
  background-image: url("../../Icons/DropDownIcon.svg"); 
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer; 
  background-size: 20px;
  color: #011149;
  font-size: 14px;
  font-family: 'poppinsmedium';
  line-height: 1.5;
}
/* .translate-area select option{
display: flex;
gap: 20px;
} */
.translate-area select:focus{
  outline: none;
}
.translate-left{
  display: flex;
  align-items: center;
}
.translate-left input{
  border: none;
  width: 100px;
}
.translate-left select{
  height: 40px;
  padding: 20px;
}
.translate-left input:focus{
  outline: none;
}
.translate-left img, .translate-right img{
  width: 20px;
  height: 20px;
}
.privacy-lang{
  position: relative;
}
.translate-select {
  display: flex;
  position: absolute;
  right: 0;
  top: -10px;
  }
.translate-select img{
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
  top: 8px;
}
.trans-main-lang ul li{
  list-style-type: none;
}
.trans-main-lang ul li::before{
    color: #ff4500;
    content: "\2022";
    display: inline-block;
    font-weight: 700;
    line-height: 22px;
    margin-left: -1em;
    width: 1em;
}
.trans-main-lang table{
  border: 1px solid #000;
  border-collapse: collapse;
  width: 100%;
  word-break: break-word;
} 
.trans-main-lang table td{
  border: 1px solid #000;
    border-collapse: collapse;
}
.trans-main-lang table th{
    border: 1px solid #000;
    border-collapse: collapse;
    background-color: #1a89ff;
    color: #fff;
    width: 33%;
}

@media screen and (max-width:728px) {
  .policy-translate{
     flex-direction: column-reverse;
  }
  .trans-main-lang{
    margin-top: 80px;
  }
  .trans-main-lang h2{
    text-align: center;
    /* margin-top: 0px; */
  }
  .translate-select{
    top: -68px;
    /* right: 6%; */
    width: 100%;
  }
  .translate-area select{
    width: 100%;
  }
  /* #content-container-privacy h2:nth-child(1){
    margin-top: 80px;
  } */
}

