.site-map-cont{
    width: 80%;
    margin: auto;
}

.policy-img .left-top {
    position: absolute;
    top: -20px;
    left: 10%;
}

.policy-img .left-top,
.policy-img .left-top a {
    color: #fff;
}
.site-map-cont hr {
    width: 50px;
    height: 7px;
    background-color: #4d3ecc;
    border-radius: 10px;
    margin: 0px;
    margin-top: -10px;
    border: none;
}
.site-map-cont-head{
    font-size: 18px;
    color: #000000;
    font-weight: 600;
}
.company-links{
    margin-top: 14px;
    display: flex;
    column-gap: 10px;
    justify-content: start;
    flex-wrap: wrap;
}
.company-links a {
    border-right: 1px solid #D5D7DD;
    padding-right: 10px;
    color: #015EF7;
    text-decoration: none;
    line-height: 140%;
    margin-top: 10px;
}
.company-links-main{
    padding: 5px;
}

@media (max-width:500px) {
    .policy-img .left-top {
        position: relative;
        top: 0;
        left: 0;
    }
}