.eligib-head-main{
    width: 80%;
    margin: 0 auto;
    margin-top: 8%;
}
.elgib-heading{
    text-align: center;
}
.elgib-heading h3{
    color: #011149;
    font-size: 32px;
    position: relative;
    margin-bottom: 32px;
    font-family: 'poppinsregular';
    font-weight: 100;
} 
.elgib-heading h3::after{
    content: '';
    background-color: #4d3ecc;
    height: 6px;
    position: absolute;
    bottom: -7px;
    right: 0;
    left: 44%;
    border-radius: 10px;
    width: 100px;
}
.elgib-heading p{
    font-size: 16px;
    color: #263238;
    line-height: 140%;
    
}
.eligibility-main {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    padding: 3%;
    border-radius: 25px; 
    position: relative;
}

/* .eligib-bg{
    position: absolute;
    z-index: -1;
}
.eligib-bg-new{
    position: absolute;
    z-index: -1;
    right: 6%;
} */
/* .eligib-second{
    position: relative;
}
.eligib-first{
    position: relative;
}

} */
.eligib-circle{
    position: absolute;
    left: -10%;
    top: -7%;
    z-index: -1;
}
.eligib-circle img{
    /* height: 600px; */
    width: 100%;
}
.eligib-pipe{
    position: absolute;
    right: 6%;
    z-index: -1;
    top: 5%;
}
.eligib-pipe img{
    height: 400px;
}
.eligibility-left {
    /* flex: 2 1; */
    padding: 20px 40px;
    padding-right: 116px;
    padding-bottom: 40px;
    background: #FFFFFF;
    box-shadow: 0px 8.59375px 25.7812px rgba(17, 53, 112, 0.16);
    border-radius: 25px;
    width: 40%;
    /* position: relative; */
}

.eligb-head {
    margin: 20px 0px;
    margin-bottom: 30px;
}

.eligb-head span {
    color: #011149;
    font-size: 30px;
    font-family: 'poppinssemibold';
    /* margin: 20px 0px; */
}

.eligibility-right {
    padding: 0% 3% 4% 3%;
    background: #FFFFFF;
    box-shadow: 0px 8.51393px 25.5418px rgb(17 53 112 / 16%);
    border-radius: 25px;
    margin-left: -5%;
    /* position: absolute;
    right: 14%; */
    /* min-width: 480px;
    max-width: 550px; */
    /* width: fit-content; */
    width: 36%;
    /* position: relative; */
    /* top: 22%; */
}
.eligib-criteria{
    margin-top: 4%;
    margin-bottom: 8%;
}
.eligibility-right h3 {
    color: #011149;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'poppinsmedium';
}
/* .eligib-result{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
} */
.eligib-result p {
    color: #525660;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    margin: 0px;
}

.eligib-result b {
    color: #011149;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 14px;
}

.eligibility-right span {
    color: #525660;
    line-height: 25px;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
}

.eligib-apply {
    margin-top: 46px;
    text-align: center;
}

.eligib-result a {
    color: #fff;
    font-size: 16px;
    font-family: 'poppinsmedium';
    background-color: #0FA82E;
    border-radius: 10px;
    padding: 4% 12%;
    text-decoration: none;
}

.eligib-calender>div {
    width: 97%;
}

.date-box.eligib-calender  input {
    width: 97%;
}
.eligib-calender{
    margin-bottom: 30px;
}

.eligib-slide-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.elgib-row-criteria{
    margin-top: 20px;
    justify-content: space-between !important;
}
.elgib-row-criteria .mlc-pl-list{
    gap: 20px;
}
.elgib-row-criteria .mlc-pl-mrgin-top{
    margin-top: 20px;
}
.eligib-age-factor{
    display: flex;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 60px;
}
.age-fact-card{
    border-left: 4px solid #4D3ECC;
    box-shadow: 0px 10px 20px rgba(109, 87, 223, 0.2);
    border-radius: 10px;
    width: 100%;
    text-align: center;
    display: flex;
    padding: 14px 0px;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.age-fact-card span{
    color: #525660;
    font-size: 14px;
}

.age-fact-card p{
    color: #011149;
    font-size: 18px;
    margin: 0px;
    font-family: 'poppinsmedium';

}
.steps-to-loan p{
    font-size: 18px;
    color: #263238;
    line-height: 140%;
}
.elgib-cont-font span{
    color: #011149;
    font-size: 20px;
    font-family: 'poppinsmedium';
}

/* @media screen and (max-width:2048px) and (min-width:1920px) {
    .eligibility-right {
        min-width: 610px;
        max-width: 650px;
    }
}

@media screen and (max-width:1920px) and (min-width:1713px) {
    .eligibility-right {
        min-width: 524px;
        max-width: 650px;
    }
}
@media screen and (max-width:1713px) and (min-width:1500px) {
    .eligibility-right {
        min-width: 480px;
        max-width: 650px;
    }
}
@media screen and (max-width:1500px) and (min-width:1300px) {
    .eligibility-right {
        min-width: 448px;
        max-width: 650px;
    }
} */

/* @media screen and (max-width:1440px) {
    .eligibility-right {
        right: 14%;
    }
} */
@media screen and (max-width:968px) {
    .eligibility-main{
        flex-direction: column;
    }
    .elgib-row-criteria{
        flex-direction: column-reverse !important;
    }
    .eligibility-left{
        width: 100%;
        padding-right: 40px;
    }
    .eligibility-right{
        margin-left: 0%;
        margin-top: 6%;
        width: 100%;
        padding: 0% 6% 4% 6%;
    }
    .eligib-apply{
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .elgib-heading h3{
        font-size: 22px;
    }
    .eligib-head-main{
        margin-top: 18%;
        width: 90%;
    }
    .date-box.eligib-calender input{
        width: 94%;
    }
    .eligb-head span{
        font-size: 20px;
    }
    .eligibility-right h3{
        font-size: 24px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .eligib-circle{
        top: -26%;
    }
    .elgib-heading p{
        font-size: 14px;
    }
    .eligib-age-factor{
        flex-wrap: wrap;
    }
    .eligib-pipe img{
        height: 150px;
    }
    .eligib-pipe{
        top: 70%;
        right: 26%;
    }
    .eligib-age-factor{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    
}
@media only screen and (max-width: 750px) {
    /* .eligibility-main {
        width: 84%;
    } */
    .eligib-criteria{
        margin-top: 18%;
    }

    .eligibility-left {
        width: 100%;
    }  
    .eligib-slide-div{
        gap: 10px;
    }
    .eligib-calender{
        margin-bottom: 10px;
    }
    .eligibility-left{
        padding: 10px 20px;
        padding-bottom: 10px;
    }
    .eligibility-right span{
        font-size: 16px;
    }
    .steps-to-loan p{
        font-size: 14px;
    }
    .elgib-heading h3::after{
        width: 28%;
        left: 34%;
        height: 4px;
    }
    
    
    
}
@media screen and (max-width:414px) {
    .elgib-heading p br {
        display: none;
    }
    .elgib-cont-font span{
        font-size: 14px;
    }
    .eligib-result p{
        font-size: 16px;
    }
    .age-fact-card span{
        
        font-size: 12px;
    }
    .age-fact-card p{
        font-weight: 600;
        font-size: 12px;
    }
    .eligib-result a{
        font-size: 14px;
    }
}