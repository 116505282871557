.tabgap{
width: 78vw;
margin: auto; 
}
.tabgap p{
  line-height: 25px;
  color: #455a64;
}
/* .container{
width: 100%;
margin: auto; 
} */

.col-md-12{
    width: 81vw;
    margin: auto;
}
#ourcoremembers{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.row {
    display: flex;  
}
.tabgap p{
    list-style: 25px;
    font-size: 15px;
    color: #455a64;
}
.table_1 td,
.table_1 th {
  word-break: break-all;
  border: 1px solid #bdbdbd;
  width: 50%;
  padding: 1%;
}
.table_1{
    border-collapse: collapse;
    width: 100%;
    color: #455a64;
    border: 1px solid #bdbdbd;
    border-spacing: 2px;
}
.aboutbanner .bannercontent {
  padding: 80px 0;
}
.aboutbanner .bannercontent h1 {
  font-size: 40px;
  color: #2917a4;
  position: relative;
}
.aboutbanner .bannerimage img {
  max-width: 100%;
}
.abouttab {
  background: #f0f6ff;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  z-index: 9;
  padding: 10px 0;
  position: sticky;
  top: 74px;
}
.abouttab ul {
  padding: 0;
  margin: 0;
  display: flex;
  font-size: 14px;
}
.abouttab ul li a {
  border: 1px solid transparent;
  background: 0 0;
  padding: 6px 14px;
  margin-right: 12px;
  border-radius: 4px;
  line-height: 30px;
}
.abouttab ul li a:hover,
.activetab {
  border: 1px solid #2c1c97 !important;
  background: #2c1c97 !important;
  color: #fff;
}
.abouttab .social-media {
  margin-top: 0;
  height: 30px;
}

.tabgap {
  padding: 20px 0;
}
.journeybox {
  display: flex;
  position: relative;
  margin: 40px 0;
}
.journeybox::after {
  border: 1px dotted #ddd;
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: -25px;
}
.journeybox .smalljb {
  flex: 1;
  position: relative;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 30px 50px;
  margin: 20px;
  background: #fff;
  top: 0;
  transition: top 1s;
  -webkit-transition: top 1s;
}
.journeybox .smalljb:hover {
  box-shadow: 0px 0px 16px #ddd;
  border: 1px solid #1b5ee2;
  top: -16px;
}
.journeybox .smalljb h4 {
  margin-bottom: 0;
}
.journeybox .smalljb p {
  margin: 0;
}
.journeybox .smalljb.one::after,
.journeybox .smalljb.two::after,
.journeybox .smalljb.three::after,
.journeybox .smalljb.four::after {
  content: "";
  height: 16px;
  width: 16px;
  position: absolute;
  border-radius: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -53px;
  z-index: 1;
}
.journeybox .smalljb.one::after {
  background: #f85049;
}
.journeybox .smalljb.two::after {
  background: #1b5ee2;
}
.journeybox .smalljb.three::after {
  background: #f7ab34;
}
.journeybox .smalljb.four::after {
  background: #3fa795;
}
.journeybox .smalljb::before {
  border-left: 1px dotted #ddd;
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  bottom: -47px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
}
.regd {
  padding: 20px 0;
}
.regdbox {
  display: flex;
}
.regdbox p {
  flex: 1;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 0;
}
.regdbox p span {
  display: block;
  color: #fff;
  background-image: linear-gradient(to right, #301bc0, #d73749);
  padding: 2px 12px;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 6px;
}
.mobilescroll_btn {
  display: none;
}
@media screen and (max-width:1000px) {
    #ourcoremembers{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:900px){
    .row{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
}
@media screen and (max-width: 820px) {
  #tab1 p,
  #tab2 p,
  #tab3 p,
  #tab4 p {
    line-height: 22px;
  }
  .abouttab ul {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 60px;
    padding-top: 13px;
    margin-right: 42px;
  }
  .abouttab ul li {
    line-height: 34px !important;
  }
  .mobilescroll_btn {
    display: block;
    position: absolute;
    top: 15px;
    right: 6px;
    background: #2917a4;
    color: #fff;
    line-height: 30px;
    height: 30px;
    width: 30px;
    font-size: 12px;
    text-align: center;
    z-index: 9;
    border-radius: 50%;
  }
  .mobilescroll_btn:hover {
    background: #f85532;
  }
  .mobtababout {
    top: 52px;
    position: sticky;
    padding: 0;
  }
  .mobtababout .container .row .col-md-12 {
    padding-right: 0;
  }
  .aboutbanner {
    text-align: center;
  }
  .aboutbanner .bannercontent {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .aboutbanner .bannercontent h1 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .regdbox {
    display: block;
  }
  .regdbox p {
    padding: 10px;
  }
  .abmobtext {
    text-align: left;
  }
  .abmobtext .container {
    padding: 0;
  }
  .journeybox {
    display: block;
  }
  .journeybox .smalljb::before {
    border-left: 0px dotted #ddd;
  }
  .journeybox .smalljb.one::after,
  .journeybox .smalljb.two::after,
  .journeybox .smalljb.three::after,
  .journeybox .smalljb.four::after {
    width: 0;
    height: 0;
  }
  .journeybox::after {
    border: 0px dotted #ddd;
  }
  .journeybox .smalljb {
    margin: 20px 0;
  }
  #tab1,
  #tab2,
  #tab3,
  #tab4 {
    margin-top: -66px;
  }
}

.tenetsbox {
  display: flex;
}
.tenetsbox .tenetsmallbox {
  flex: 50% 0 0;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin: 18px 0;
}
.tenetsbox .tenetsmallbox:nth-child(even) {
  margin-left: 20px;
}
.tenetsbox .tenetsmallbox:nth-child(odd) {
  margin-right: 20px;
}
.tenetsbox .tenetsmallbox .tenetheading {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 0 20px;
}
.tenetsbox .tenetsmallbox .tenetheading h4 {
  margin-left: 20px;
}
.tenetsbox .tenetsmallbox .tenetcontent {
  padding: 10px 20px;
}
.tenetsbox .tenetsmallbox .tenetcontent ul {
  list-style-type: disc;
}
@media screen and (max-width: 820px) {
  .tenetsbox {
    display: block;
  }
  .tenetsbox .tenetsmallbox:nth-child(even) {
    margin-left: 0;
  }
  .tenetsbox .tenetsmallbox:nth-child(odd) {
    margin-right: 0;
  }
  .tenetsbox .tenetsmallbox .tenetcontent ul {
    padding: 0 20px;
  }
  #ourcoremembers{
    grid-template-columns: repeat(1,1fr);
  }
}

.teamtab ul {
  background: #f9fafe;
  padding: 0;
}
.teamtab ul li {
  border-bottom: 1px solid #ddd;
  padding: 20px 30px;
}
.teamtab ul li a:hover::before,
.activeteam::before {
  content: "";
  background: #f85532;
  width: 3px;
  height: 100%;
  position: absolute;
  left: -15px;
  top: 0;
}
.teamtab ul li a span {
  display: block;
  font-size: 12px;
}
.teamtab ul li a {
  color: #7e7e7e;
  font-size: 14px;
  position: relative;
}
.teamtab ul li a:hover {
  color: #18375f;
}
.tmmenmber {
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  margin-bottom: 20px;
  line-height: 22px;
  color: #455a64;
}
.tmmenmber .memberpic {
  height: 135px;
  width: 135px;
  position: relative;
  background: #ddd;
}
.tmmenmber .memberpic::after {
  content: "";
  position: absolute;
  background: #301bc0;
  width: 100%;
  height: 100%;
  top: -10px;
  right: -10px;
  z-index: -1;
}
.tmmenmber .memberpic img {
  width: 100%;
  height: 135px;
}
.tmmenmber p.personname {
  font-family: poppinssemibold !important;
  font-size: 16px;
}
.tmmenmber p span {
  font-size: 12px;
  font-family: poppinsregular !important;
  display: block;
}
.piccenter {
  margin: 0 auto;
}
.movision{
  display: flex;
  align-items: center;
  justify-content: center;
}
.movision_1{
  width: 50%;
}
.movisionimg{
  width:70%;
  object-fit: contain;
}
@media screen and (max-width: 820px) {
  .tmmenmber {
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
  }
  .teamtab ul {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 40px;
    border-top: 1px solid #ddd;
  }
  .teamtab ul li {
    display: inline-block;
  }
  .teamtab ul li a:hover::before,
  .activeteam::before {
    content: "";
    background: #f85532;
    width: 50px;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: -4px;
    top: auto;
  }
  .pd0mob {
    padding: 0 !important;
  }
  .movision{
     display: grid;
  }
  .movision_1{
    width: 100%;
  }
  .movisionimg{
    width: 70%;
  }
}
@media screen and (max-width:1000px){
  .journeybox{
    display: grid;
  }
}
@media screen and (max-width:500px) {
  .tabgap{
    width:auto
  }
  .col-md-12{
    width: auto;
  }
}