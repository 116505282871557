.sachcont{
    width: 80%;
    margin: auto;
    background:#f9fafe;
    border-radius: 2%;
    /* border: 1px solid black;
    margin-top: 5%; */
 }
 .sachfirst{
    width: 90%;
    margin: auto;
    /* border: 1px solid red; */
    /* color: rgb(29, 29, 212); */
    /* padding-top: 30px; */
    text-align: center;
 }
 .sachfirst h2{
    font-size: 30px;
    line-height: 32px;
    font-family:'poppinsmedium';
    color:#011149;
    margin: 0px;
    padding-top: 30px;
 }
 .sachsecond{
    /* width: 90%; */
    margin: auto;
    /* border: 1px solid blue; */
    /* margin-top: 30px; */
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
    padding: 20px;
 }
 /* .third{
    border: 1px solid greenyellow;
 } */
 .sachpicker{
    height: 70px;
    width: 70px;
    margin: auto;
   padding: 12px;
 }
 .sachfix{
    height: 70px;
    width: 70px;
    line-height: 0%;
 }
 .sachfixer{
    margin-top: 25px;
    font-size: 18px;
    text-align: center;
    line-height: 140%;
    font-size: 18px;
    color: #011149;
    font-family: 'poppinsmedium';
    /* border: 1px solid rgb(24, 2, 23); */
 }
  @media only screen and (max-width:750px){
    .sachsecond{
      grid-template-columns: repeat(2,1fr);
      grid-gap: 15px;
      padding:5%;
    }
    .sachfirst h2{
      font-size: 22px;
      line-height: 32px;
   }
   .sachfixer{
      font-size: 12px;
      margin-top: 10px; 
   }
   .sachcont{
      background-color: white;
   }
  }
  @media screen and (max-width:500px) {
   .sachcont{
      width: 95%;
   }
  }