.fullslider-bg{
  background-image: url('../../assests/bg-slider.svg');
  background-size: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.fullpageApp {
  width: 80%;
  margin: 0 auto;
  margin-bottom:0px ;
  position: relative;
  /* background-color: #fff; */

}
.fullpageslide-main {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 80%;
  margin: 0 auto;
  /* margin-bottom: 200px; */
  /* min-height: 100vh; */
  /* background-image: url('../../assests/workslideback.svg');
  background-size: 54vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 954px 50%; */
}
.fullsteps-main{
  visibility: hidden;
  display: none;
  /* opacity: 0; */
  /* transition: display 3.5s ease-in-out;
  transition: transform 4s; */
}
.fullstepsshow{
  /* display: block; */
  /* opacity: 1; */
    /* -webkit-transform: scale(1.1);
  transform: scale(1.1); */
}
/* .fullsteps-main-1, .fullsteps-main-2, .fullsteps-main-3, .fullsteps-main-4{
  margin-top: 40px;
  /* opacity: 1; */
  /* -webkit-transform: scale(1.1);
  transform: scale(1.1); */

/* @keyframes fade-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
} */

/* .full-right-img {
  height: 300p;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .full-right-img {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.all-main-heading span {
  /* text-align: left;
  padding-left: 10%; */
  color: #011149;
  font-size: 28px;
  font-family: 'poppinsmedium';
}
.all-main-heading p {
  /* text-align: left;
  padding-left: 10%; */
  color: #263238;
  font-size: 18px;
  font-family: 'poppinsregular';
  margin-top: 10px;
}
/* .bg-work{
  position: absolute;
} */
.fullpage-contnt{
  width: 100%;
  position: sticky;
  top: 0;
  padding-top: 30%;
  /* background-image: url('../../assests/workslideback.svg');
  background-size: contain;
  background-repeat: no-repeat; */
}
.left-fullpage-cont{
  /* position: relative; */
  /* position: absolute;
  top: 7%;
  left: 10%; */
  /* margin-top: 100px; */
}
.full-right-img img{
  height: 80vh;
  margin-top: 90px;
}
.fullpage-slides{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.fullsteps{
  margin-top: 36px;
  margin-bottom: 24px;
}
.fullsteps span {
  background-color: #EAFFEE;
  border: 1px solid;
  padding: 6px 12px 4px 12px;
  color: #0FA82E;
  border-radius: 5px;
  line-height: 140%;
  font-size: 14px;
}
.fullstep-heading{
  width: 82%;
}
.fullstep-heading span{
  color: #4D3ECC;
  font-size: 36px;
  font-family: 'poppinsmedium';
  position: relative;
  line-height: 140%;
  margin-bottom: 0px;
}
.fullstep-heading span::after {
  content: '';
  background-color: #4d3ecc;
  height: 5px;
  position: absolute;
  bottom: -14px;
  right: 0;
  left: 0;
  border-radius: 10px;
  width: 100px;
  line-height: 140%;
  /* top: 130px; */
}
.fullstep-heading p {
  color: #000000;
  font-size: 18px;
  margin-top: 40px;
  line-height: 140%;
}
.Fullpage-App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: end;
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes moveFromTop {
  from { top: -100px; opacity: 0; }
  to { top: 0px; opacity: 1; }
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */