.mw-annouce {
  background: url(./Announce.svg) no-repeat;
  height: 300px;
  margin-top: 100px;
  background-size: cover;
  /* background-position: left top; */
}
.annoucement-heading h2{
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: 'poppinsmedium';
  padding-top: 55px;
}
.mw-announce-cont {
    width: 68%;
    margin-left: 24%;
    position: relative;
}
/* .mw-announce-cont .slick-slide{
  margin: 0px 20px;
} */
.contentMain{
  padding: 0px 20px;
  display: flex;
justify-content: center;
align-items: center;
}
.mw-announce-card {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 25px;
  height: 132px;
  margin: 0px 10px;
}

.mw-announce-card-img{
  /* width: 100px;
  height: 80px; */
  /* background-color: #f0f0f0;
  border-radius: 10px; */
}
.mw-announce-cont .slick-slide img{
  width: 86px;
  
}
.mw-announce-card-desc {
  color: #011149;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  padding-left: 20px;
}
.date{
  color: #525660;
  font-size: 14px;
  font-weight: 500;
}
.mw-announce-arrow-des{
 position: absolute;
 top: 20%;
 right: -8%;
 cursor: pointer;
}
.mw-announce-arrow-desscription{
  position: absolute;
  top: 32%;
  left: -5%;
  cursor: pointer;
}
.announce-bg{
  position: relative;
	overflow: hidden;
}
.announce-bg-top{
  position: absolute;
  left: 0px;
}
.announce-bg-bottom{
  position: absolute;
  right: 0px;
  bottom: 0px;
}
@media only screen and (max-width:750px){
  .mw-announce-arrow-desscription {
    left: -17%;
}
.mw-announce-cont {
  margin-left: 19%;
}
.mw-announce-arrow-des {
  position: absolute;
  top: 20%;
  right: -23%;
  cursor: pointer;
}
}
@media screen and (max-width:480px) {
  .announce-bg-top{
    top: -2px;
    left: -8px;
  }
  .announce-bg-bottom{
    bottom: -2px;
    right: -8px;
  }
  .mw-announce-cont{
    width: 90%;
    margin: 0 auto;
    top: 25px;
  }
  .contentMain{
    padding: 10px;
  }
  .mw-announce-arrow-desscription{
    top: 110%;
    left: 3%;
  }
  .mw-announce-arrow-des{
    top: 95%;
    right: -5%;
  }
  .mw-announce-card-desc p{
    font-size: 14px;
  }
  .mw-announce-card-desc{
    line-height: 21px;
  }
  .mw-annouce {
    background: url(./Announcement-mob-BG.svg) no-repeat;
    height: 290px;
    margin-top: 30px;
    background-size: cover;
  }
  .annoucement-heading h2{
    font-size: 22px;
    padding-top: 0px;
  }
}