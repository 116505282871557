.congo-p{
 font-weight: 600;
 font-size: 24px;
 line-height: 140%;
 color: #011149;
 text-align: center;
 margin-bottom: 0px;
}
.congo-text{
font-size: 16px;
text-align: center;
line-height: 140%;
color: #011149;
}