.pdf-back {
    background-color: #4f4b4b;
    padding: 10px 0;
    width: 100%;
}

.pdf-header {
    display: flex;
    background-color: black;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    padding: 6px 0;
    gap: 200px;
}

.pdf-header button {
    background-color: transparent;
    border: 0px solid;
    outline: none;
    cursor: pointer;
}

.pdf-header h1 {
    font-size: 24px;
    margin: 0;
    color: #fff;
}

.pdf-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
    margin-top: 10px;
}

.pdf-box canvas {
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Page__textContent.textLayer span,
.react-pdf__Page__textContent.textLayer br {
    width: 0px !important;
    height: 0px;
    display: none;
}

.react-pdf__Page__textContent.textLayer,
.react-pdf__Page__annotations.annotationLayer {
    height: 0px !important;
}

.react-pdf__Page{
    margin-top: 10px;
}

@media screen and (max-width:760px) {

    .pdf-box {
        display: block;
        overflow: hidden;
    }

    .pdf-box canvas {
        width: 90% !important;
        margin: 0 auto;
        background-color: #4f4b4b;
    }
}

@media screen and (max-width:550px) {
    .pdf-header {
        margin: 0;
        gap: 100px;
    }

    .pdf-header h1 {
        font-size: 18px;
    }

    .pdf-back {
        padding: 4px 0;
    }

    .pdf-box canvas {
        width: 80% !important;
        margin: 0;
        /* height: calc(94vh - 8px) !important; */
    }
}

@media screen and (max-width:450px) {
    .pdf-box canvas {
        width: 70% !important;
    }
}

@media screen and (max-width:410px) {
    .pdf-box canvas {
        width: 60% !important;
    }
}