.pldoc-h2 {
    margin-bottom: 20px;
}
.salary-slip-KYC-documents .bot-6::before{
    bottom: 12px;
}

@media (max-width:414px) {
    .top-five {
        margin-top: 5px !important;
    }
}