.QualificationPopUp{
    margin:12% auto;
    width:360px;
    height:306px;
    border-radius: 20px;
    background-color: #fff;
}
.QualificationPopUp-content{
    margin: 35px 35px;
}
.QualificationPopUp h2{
    text-align: center;
    color:#0FA82E;
    padding-top:25px;
}
.QualificationPopUp .QualificationPopUp-content ul{
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin-top: -3px;
}
.QualificationPopUp .QualificationPopUp-content ul li{
    padding: 18px 0px;
    font-family: 'poppinsregular'!important;
    font-size: 16px;
    color:#011149;
}
.QualificationPopUp .QualificationPopUp-content ul li:hover{
    background-color: #F6F6F8;
    cursor: pointer;
}
@media screen and (max-width:410px) {
    .QualificationPopUp{
        width:86%;
        height:100%;
    }
}

