.KYC_IMG img{
    width: 100%;
}
.boxing{
    border: 1px solid #D5D7DD;
    padding: 2% 5%;
    border-radius: 10px;
    margin: 20px 0;
}
.boxing.Tmargin{
    margin-top: 20px;
}
.KYC-img{
    margin: 2% 0;
}
.boxing:hover{
    box-shadow: 0px 0px 10px #11357026;
    cursor: pointer;
    /* border: 0; */
}
.heading-KYC{
    font-size: 16px;
    font-family: 'poppinssemibold';
}
.KYC-content{
    color: #525660;
    font-size: 14px;
    line-height: 140%;
    margin: 2% 0;
}
.KYC-btn{
    text-align: right;
    margin: 2% 0;
}
.KYC-btn button{
    border: none;
    background: none;
    font-family: 'poppinssemibold';
    font-size: 14px;
    color:#0066FF;
}
.right{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-bottom: 1px;
}
.alert-in-kyc{
    display: flex;
    border: 1px solid #015EF7;
    border-radius: 10px;
    background-color: #FFFFFF;
    align-items: start;
    gap: 10px;
    padding: 10px;
    margin-top: 12%;
}
.alert-in-kyc p{
    font-size: 11px;
    color:#015EF7 ;
    line-height: 140%;
    margin: 0px;
}